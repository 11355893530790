export default function CancelledMatchesPage() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Canceled Match </h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Series Name</th>
                                                        <th>Contest</th>
                                                        <th>Amount</th>
                                                        <th>No. of Participant</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>India Tour of Zimbabwe</td>
                                                        <td>Head-to-Head</td>
                                                        <td>Rs 1000</td>
                                                        <td>300</td>
                                                        <td>Abandoned</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>India Tour of Zimbabwe</td>
                                                        <td>Head-to-Head</td>
                                                        <td>Rs 1000</td>
                                                        <td>300</td>
                                                        <td>Canceled</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>India Tour of Zimbabwe</td>
                                                        <td>Head-to-Head</td>
                                                        <td>Rs 1000</td>
                                                        <td>300</td>
                                                        <td>Canceled</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>India Tour of Zimbabwe</td>
                                                        <td>Head-to-Head</td>
                                                        <td>Rs 1000</td>
                                                        <td>300</td>
                                                        <td>Canceled</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>India Tour of Zimbabwe</td>
                                                        <td>Head-to-Head</td>
                                                        <td>Rs 1000</td>
                                                        <td>300</td>
                                                        <td>Canceled</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
