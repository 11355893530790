//createScoreboardPoint
import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const ScoreBoardAPI = {
    AddScoreBoard: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'createScoreboardPoint', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                //  alert("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    FetchMatchFormat: async () => {
        //alert()
        return await fetch(baseURL + 'fetchMatchFormat', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            // body: JSON.stringify(),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                //  alert("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    FetchScoreBoard: async (match_format) => {
        console.log(match_format);
        //alert()
        return await fetch(baseURL + 'fetchScoreboardPoint?match_format=' + match_format, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                //  alert("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    EditScoreboard: async (jsonbody) => {
        console.log(jsonbody);
        //alert()
        return await fetch(baseURL + 'editScoreboard', {
            method: 'PUT',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                //  alert("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    //fetchMatchFormat
    //fetchScoreboardPoint
};
