import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Notiflix from 'notiflix';
import { ReportAPI } from '../api/ReportAPI';
import './ViewAllUsers.css';

const RefCodeUsageStat = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [downloadData, setDownloadData] = useState([]);
    const [isDataUpdated, setIsDataUpdated] = useState(false);

    useEffect(() => {
        if (isDataUpdated) {
            fetchDownloadDataReferStats();
        } else {
            const cachedData = localStorage.getItem('cachedDownloadData');
            if (cachedData) {
                setDownloadData(JSON.parse(cachedData));
                setIsLoading(false);
            } else {
                fetchDownloadDataReferStats();
            }
        }
    }, [isDataUpdated]);

    const fetchDownloadDataReferStats = async () => {
        try {
            const response = await ReportAPI.ReferReportUsageStats();
            if (response.status_code) {
                setDownloadData(response.data);

                localStorage.setItem('cachedDownloadData', JSON.stringify(response.data));
            } else {
                Notiflix.Notify.failure(response.message);
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
            Notiflix.Notify.failure('Failed to fetch data. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateClick = () => {
        setIsDataUpdated(true);
    };

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Mobile No', key: 'phone' },
        { label: 'Email Id', key: 'email' },
        { label: 'Referral Code Used', key: 'count' },
    ];

    const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='main-ref-ral-div'>
                        <h1 className='coupon_h1-h1'>Referral Usage Stats</h1>
                        <div className=''>
                            <Link to='/refer-report' className='button-report-referral'>
                                Referral Report
                            </Link>
                            <Link to='/indi-refer-report' className='button-report-referral'>
                                Individual User Report
                            </Link>
                            <Link to='/ref-stats' className='button-report-referral'>
                                User Report Stats
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card div-stats-card-reffer'>
                                <button className='button-series-999989' onClick={handleUpdateClick} disabled={isLoading}>
                                    Update
                                </button>
                                {isLoading ? (
                                    <Spin indicator={antIcon} />
                                ) : (
                                    <CSVLink filename='Referral Report' data={downloadData} headers={headers}>
                                        <button className='button-series-9999' disabled={isLoading}>
                                            <div className='div-spin-antd-div'>Download</div>
                                        </button>
                                    </CSVLink>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RefCodeUsageStat;
