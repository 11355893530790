import './ViewAllUsers.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Tooltip, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fundManager } from '../api/FundsApi';

export default function AllWalletLogs() {

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const [walletLogs, setWalletLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 64,
            }}
            spin
        />
    );

    useEffect(() => {
        AllWalletLogsFetch();
    }, []);

    const AllWalletLogsFetch = () => {
        fundManager.AllWalletLogs().then((data) => {
            if (data.status_code) {
                setWalletLogs(data.data);
                console.log(data.data);
            }
        });
    };

    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        return newDate;
    };

    const handleUserName = (strng) => {
        if (strng === undefined) {
            return '';

        } else {
            let name = strng?.substring(0, strng?.lastIndexOf('@'));
            const letter = name?.charAt(0)?.toUpperCase() + name?.slice(1);
            return letter;
        }
    };





    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body cord-body-div-bc'>
                                    <div className='offers_head'>
                                        <div className='form-row'>
                                            <Link className='offer_button active' to='/add-deduct-funds'>Add/Deduct Funds</Link>
                                        </div>
                                        <div className='form-row'>
                                            <Link className='offer_button' to='/funds'>Funds</Link>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className='offers_couponDiv'>
                            <h1 className='coupon_h1'>All Wallet Logs</h1>

                        </div>
                        {/* <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='col'>
                                                <label htmlFor='name'>Case ID</label>
                                                <input
                                                    type='text'
                                                    onChange={handleAllChange(setFilCaseID)}
                                                    className='offers_input'
                                                    placeholder='Search'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Phone Number</label>
                                                <input
                                                    type='text'
                                                    onChange={handleAllChange(setFilPhoneNumber)}
                                                    className='offers_input'
                                                    placeholder='Search'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Email ID</label>
                                                <input
                                                    onChange={handleAllChange(setFilEmailID)}
                                                    type='text'
                                                    className='offers_input'
                                                    placeholder='Search'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Source</label>
                                                <select
                                                    onChange={handleAllChange(setCaseSource)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='1'>Email</option>
                                                    <option value='2'>App</option>
                                                    <option value='3'>Phone</option>
                                                </select>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Priority</label>
                                                <select
                                                    onChange={handleAllChange(setFilPriority)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='1'>Low</option>
                                                    <option value='2'>Medium</option>
                                                    <option value='3'>High</option>
                                                    <option value='4'>Urgent</option>
                                                </select>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Case Type</label>
                                                <select
                                                    onChange={handleAllChange(setFilCaseType)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='1'>Deposit</option>
                                                    <option value='2'>Withdrawl</option>
                                                    <option value='3'>Others</option>
                                                </select>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>

                                {
                                    isLoading ? <Spin className='spinner_cases' indicator={antIcon} />
                                        : <div className='card'>
                                            <div className='card-body'>
                                                <div
                                                // className="table-editable" id="table"
                                                >
                                                    <div className='table-responsive'>
                                                        <table className='table table-striped table-bordered'>
                                                            <thead>
                                                                <tr className='support_main_file_div'>
                                                                    <th>S.No.</th>
                                                                    <th>User ID</th>
                                                                    <th>Date</th>
                                                                    <th>Transaction ID</th>
                                                                    <th>Amount</th>
                                                                    <th>New Net Winning</th>
                                                                    <th>TDS</th>
                                                                    <th>Reason/Comment</th>
                                                                    <th>Description</th>
                                                                    <th className='adduserwalletlog-h1'>Initiated By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    walletLogs && walletLogs.length > 0 && walletLogs.map((logs, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {++i}
                                                                                </td>
                                                                                <td>{logs.user_id}</td>
                                                                                <td>{handleDate(logs.createdAt)}</td>
                                                                                <td>{logs.transaction_id}</td>
                                                                                <td>{logs.amount}</td>
                                                                                <td>{logs.net_winning}</td>
                                                                                <td>{logs.tds}</td>
                                                                                <td>{logs.comment}</td>
                                                                                <td>{logs.description}</td>
                                                                                <td className='adduserwalletlog-h1'>{handleUserName(logs.user_name)}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >);
}
