import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ReactDOM } from 'react-dom';
import { SeriesAPI } from '../api/seriesApi';
import Notiflix from 'notiflix';

function CreateSeriesPage() {
    const [stateVar, setStateVar] = useState('this is the demo');
    const [seriesname, setSeriesName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [fantasyval, setFantasy] = useState('');
    const [fantasyList, setFantasyList] = useState([]);

    useEffect(() => {
        GetFantasy();
    }, []);

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
        });
    };

    const SeriesADD = () => {
        // alert(document.getElementById('seriesName').value)
        const checkStartDate = new Date(startDate.replace(/-/g, '/'));
        const checkEndDate = new Date(endDate.replace(/-/g, '/'));

        if (fantasyval == '' || fantasyval == null) {
            Notiflix.Notify.failure('fantasy can\'t blank');
        } else {
            if (checkStartDate > checkEndDate) {
                Notiflix.Notify.failure('The Start date should not be accepted after the end date');
            } else if (seriesname == '' || seriesname == null) {
                Notiflix.Notify.failure('series name can\'t blank');
            } else {
                if (startDate == '' || startDate == null) {
                    Notiflix.Notify.failure('start date can\'t be blank');
                } else {
                    if (endDate == '' || endDate == null) {
                        Notiflix.Notify.failure('end date can\'t be blank');
                    } else {
                        let seriesData = {
                            datestart: startDate,
                            dateend: endDate,
                            title: seriesname,
                            fantasy: fantasyval,
                        };
                        // alert(JSON.stringify(seriesData))
                        SeriesAPI.CreateSeries(seriesData).then((data) => {
                            Notiflix.Notify.success('Series Created Successfully');
                        });
                    }
                }
            }
        }
    };

    const fantasychange = (e) => {
        setFantasy(e.target.value);
        // alert(e.target.value)
    };
    const seriesOnChange = (e) => {
        setSeriesName(e.target.value);
    };
    const startDateChange = (e) => {
        setStartDate(e.target.value);
    };
    const endDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const reset = () => {
        setFantasy('');
        setSeriesName('');
        setStartDate('');
        setEndDate('');
    };
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <Link
                                    to={'/view_series'}
                                    className='button btn btn-skyblue button-icon'
                                >
                                    <i className='ml-2 ri-eye-fill'></i> View all Series
                                </Link>
                                {/* <a className="button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0" href="#">
                <i className="ri-add-line m-0"></i>
            </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Create New Series</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Select Fantasy :</label>
                                        <select
                                            className='form-control mb-3'
                                            onChange={fantasychange}
                                        >
                                            <option selected value=''>
                                                Open this select fantasy
                                            </option>
                                            {fantasyList.map((fan) => (
                                                <option value={fan.fantasy}>{fan.fantasy}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='text'>Enter Series Name :</label>
                                        <input
                                            type='text'
                                            required
                                            className='form-control'
                                            value={seriesname}
                                            onChange={seriesOnChange}
                                            id='seriesName'
                                            placeholder='ODI Series T20'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputdate'>Start Date :</label>
                                        <input
                                            type='date'
                                            value={startDate}
                                            onChange={startDateChange}
                                            className='form-control'
                                            id='startdate'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputdate'>End Date :</label>
                                        <input
                                            type='date'
                                            value={endDate}
                                            onChange={endDateChange}
                                            className='form-control'
                                            id='enddate'
                                        />
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-primary btn-primary-fix'
                                        onClick={() => {
                                            SeriesADD();
                                        }}
                                    >
                                        Submit
                                    </button>
                                    &nbsp;
                                    {/* <button type="button" className="btn bg-danger" onClick={reset}>Reset</button> */}
                                    <input type='reset' className='btn bg-danger' onClick={reset} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CreateSeriesPage;
