import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');
import { BASEAPIS } from './BaseApis';

export const FetchFullDetailAPI = {
    FetchFullDetail: async (seriesCID) => {
        return BASEAPIS.GETAPI('fullSeriesDetail?cid=' + seriesCID);
    },

    ViewAlljoinedContestUser: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'viewAlljoinedContestUser', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    PaymentHistory: async (
        email,
        teamName,
        userId,
        phone,
        entries,
        pagenumber,
        startDate,
        endDate
    ) => {
        console.log(email);
        console.log(teamName);
        console.log(userId);
        console.log(phone);
        console.log(entries);
        console.log(pagenumber);

        //alert()
        return await fetch(
            baseURL +
            'getUserPayments?phone=' +
            phone +
            '&email=' +
            email +
            '&team_name=' +
            teamName +
            '&user_id=' +
            userId +
            '&entries=' +
            entries +
            '&pagenumber=' +
            pagenumber +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};