import { baseURL } from './apiBaseUrl';

const key = localStorage.getItem('jwtToken');

export const PanAPI = {
    GetPanInfo: async (pageSize, pagenumber) => {
        //alert()
        return await fetch(
            baseURL + 'viewAllUsersPancard?pageSize=' + pageSize + '&total_page=' + pagenumber,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    FilterPanInfo: async (entries, pageNumber, name, email, phone, panNumber, userId, status) => {
        //alert()
        return await fetch(
            baseURL +
                'viewAllUsersPancard?pan_name=' +
                name +
                '&entries=' +
                entries +
                '&pagenumber=' +
                pageNumber +
                '&email=' +
                email +
                '&phone=' +
                phone +
                '&pan_number=' +
                panNumber +
                '&user_id=' +
                userId +
                '&status=' +
                status,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
