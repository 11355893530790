import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AdminWalletAPI } from '../api/adminWalletAPI';
import { RotatingLines } from 'react-loader-spinner';

export default function AdminWallet() {
    const [wallet, setWallet] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        showWalletData();
    }, []);

    const showWalletData = () => {
        AdminWalletAPI.GetWallet().then((data) => {
            console.log(data);
            setWallet(data);
            setIsLoading(false);
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-4 col-lg-12 '>
                                <div className='card'>
                                    <div className='card-body'>
                                        <a>
                                            <Link
                                                to='/search_admin'
                                                className='btn btn-m bg-danger float-right'
                                            >
                                                <i className='ri-add-line'></i>Add New
                                            </Link>
                                        </a>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='phone'> Search By Mobile: </label>{' '}
                                                &nbsp;
                                                <input
                                                    onKeyDown={(evt) =>
                                                        ['e', 'E', '+', '-'].includes(evt.key) &&
                                                        evt.preventDefault()
                                                    }
                                                    type='number'
                                                    className='form-control-sm'
                                                    id='phone'
                                                    placeholder='Enter Mobile No.'
                                                />
                                            </div>
                                        </div>
                                        <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button type='submit' className='btn btn-m btn-primary'>
                                                Search
                                            </button>
                                            &nbsp;
                                            {/* <button type="submit" className="btn btn-m bg-danger"><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                            <input type='reset' className='btn btn-danger' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {/* <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="card">

                                
                            </div>
                        </div>
                    </div> */}

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                    <RotatingLines
                                        marginTop='40'
                                        strokeColor='#c1030d'
                                        strokeWidth='5'
                                        animationDuration='0.75'
                                        width='50'
                                        visible={true}
                                    />
                                </div>
                            </div>
                        )}

                        {!isLoading && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Admin Wallet Details</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table data-table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            <th>Amount</th>
                                                            <th>Amount Type</th>
                                                            <th>Created At</th>
                                                            <th>Updated At</th>
                                                            <th>Description</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {wallet &&
                                                            wallet.length > 0 &&
                                                            wallet.map((walletData, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{++i}</td>
                                                                        <td>
                                                                            {walletData.user_id}
                                                                        </td>
                                                                        <td>{walletData.amount}</td>
                                                                        <td>
                                                                            {walletData.amount_type}
                                                                        </td>
                                                                        <td>
                                                                            {walletData.createdAt}
                                                                        </td>
                                                                        <td>
                                                                            {walletData.updatedAt}
                                                                        </td>
                                                                        <td>
                                                                            {walletData.descrption}
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                className='btn btn-primary btn-primary-fix btn-sm'
                                                                                data-target='#exampleModalScrollable'
                                                                            >
                                                                                <i className='la  fas fa-file-export mr-1'></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
