import React, { useState } from 'react';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import Pagination from '../Component/Pagination';
import { Link } from 'react-router-dom';

function ReferalDetailsPage() {
    const [UserId, setUserId] = useState('');
    const [Phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [teamName, setTeamName] = useState('');
    const [dataArray, setDataArray] = useState([]);

    const setterFunction = (setter) => (e) => {
        const { value } = e.target;
        setter(value);
    };

    const Search = () => {
        AccountManagerAPI.ReferralDetailsOfSpecificUser(UserId, Phone, teamName, email).then(
            (res) => {
                setDataArray([res]);
            }
        );
        console.log(dataArray);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div>
                                                <div className='col'>
                                                    <label htmlFor='name'>User Id :</label>
                                                    <input
                                                        type='text'
                                                        value={UserId}
                                                        onChange={setterFunction(setUserId)}
                                                        className='form-control'
                                                        placeholder='Search By Name'
                                                    />
                                                </div>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Phone :</label>
                                                <input
                                                    type='text'
                                                    value={Phone}
                                                    onChange={setterFunction(setPhone)}
                                                    className='form-control'
                                                    placeholder='Search By Name'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Email :</label>
                                                <input
                                                    type='text'
                                                    value={email}
                                                    onChange={setterFunction(setEmail)}
                                                    className='form-control'
                                                    placeholder='Search By Name'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Team Name :</label>
                                                <input
                                                    type='text'
                                                    value={teamName}
                                                    onChange={setterFunction(setTeamName)}
                                                    className='form-control'
                                                    placeholder='Search By Name'
                                                />
                                            </div>

                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    className='btn btn-primary'
                                                    type='button'
                                                    onClick={() => Search()}
                                                >
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input
                                                    type='reset'
                                                    // onClick={() => Reset()}
                                                    className='btn btn-danger'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>View all series</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                {/* {
    (dataArray && dataArray.length>0)? 
    ( */}
                                {/* <Table
                Table
                dataSource={dataArray}
                columns={table}
                pagination={{
                  onChange(change, pageSize) {
                    setPage(change);
                    setSize(pageSize);
                  },
                  // onShowSizeChange(cha){
                  //   setSize(cha)
                  // },
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              ></Table> */}
                                {/* ):<></>
} */}

                                <div className='table-responsive'>
                                    <span>
                                        <table className='table  table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>User ID</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Team Name</th>
                                                    <th>Refer Code</th>
                                                    <th>Refer Code Used</th>
                                                    <th>Referred User Id</th>
                                                    <th>Refer Code Of</th>
                                                    <th>User Id Of Refer Code Used</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataArray &&
                                                    dataArray.length > 0 &&
                                                    dataArray.map((name, ind) => {
                                                        return (
                                                            <tr key={name.user_id}>
                                                                <td>{++ind}</td>
                                                                <td>{name.user_id}</td>
                                                                <td>{name.name}</td>
                                                                <td>{name.email}</td>
                                                                <td>{name.phone}</td>
                                                                <td>{name.team_name}</td>
                                                                <td>{name.refCode}</td>
                                                                <td>{name.isreferCodeUsed?'yes':'no'}</td>
                                                                <td>{name.referredUser_id.map(user=>{return(<td>{user.user_id}</td>);})}</td>
                                                                <td>{name.referCodeOf}</td>
                                                                <td>{name.userIdOfReferCodeUsed.user_id}</td>
                                                                
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <Pagination
                        totalEntires={totalEntires}
                        totalPages={totalPages}
                        changePage={changePage}
                        pageNumber={pageNumber}
                    /> */}
                    </div>

                    <div
                        className='modal fade'
                        id='exampleModalScrollable'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='exampleModalScrollableTitle'
                        aria-hidden='true'
                    >
                        <div className='modal-dialog modal-dialog-scrollable' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                        Edit Series
                                    </h5>
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Series Id :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                // value={seriesId}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Series Name :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                // value={editSeries}
                                                // onChange={EditSeriesChange}
                                                placeholder='Chennai Super King'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>From :</label>
                                            <input
                                                type='date'
                                                className='form-control'
                                                disabled
                                                // value={editSDate}
                                                // onChange={EditSDateChange}
                                                id=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>To :</label>
                                            <input
                                                type='date'
                                                className='form-control'
                                                disabled
                                                // value={editEDate}
                                                // onChange={EditEDateChange}
                                                id=''
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        data-dismiss='modal'
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        // onClick={EditSubmit}
                                    >
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReferalDetailsPage;
