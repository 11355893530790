import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FetchFullDetailAPI } from '../api/fullDetailAPI';
import { RotatingLines } from 'react-loader-spinner';
import { SeriesAPI } from '../api/seriesApi';
import Select from 'react-select';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function FullDetailPage() {
    const [detail, setDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingParams, setIsLoadingParams] = useState(false);
    const [title, setTitle] = useState('');
    const [seriesname, setSeriesName] = useState('');
    const [series, setSeries] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [seriesCID, setSeriesCID] = useState('');

    const saveStateToLocalStorage = () => {
        const stateToSave = {
            detail, isLoading, isLoadingParams, title, seriesname, series, startDate, endDate, seriesCID,
        };
        localStorage.setItem('fullDetailPageState', JSON.stringify(stateToSave));
    };

    useEffect(() => {
        const savedState = localStorage.getItem('fullDetailPageState');
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            setDetail(parsedState.detail);
            setIsLoading(parsedState.isLoading);
            setIsLoadingParams(parsedState.isLoadingParams);
            setTitle(parsedState.title);
            setSeriesName(parsedState.seriesname);
            setSeries(parsedState.series);
            setStartDate(parsedState.startDate);
            setEndDate(parsedState.endDate);
            setSeriesCID(parsedState.seriesCID);
        }
    }, []);

    useEffect(() => {
        saveStateToLocalStorage();
    }, [detail, isLoading, isLoadingParams, title, seriesname, series, startDate, endDate, seriesCID]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
                marginLeft: '16px',
                marginTop: '10px'
            }}
            spin
        />
    );

    useEffect(() => {
        GetSeries();
    }, []);


    useEffect(() => {
        if (seriesCID === '') {
            '';
        } else {
            fetchAllDetail();
        }
    }, [seriesCID]);

    const fetchAllDetail = () => {
        setIsLoading(true);
        FetchFullDetailAPI.FetchFullDetail(seriesCID).then((data) => {
            if (data.status_code) {
                setDetail(data.data);
                setIsLoading(false);
                console.log(data.data);
            } else {
                Notiflix.Notify.failure(data.msg);
                setIsLoading(false);
            }
        });
    };

    const GetSeries = () => {
        setIsLoadingParams(true);
        SeriesAPI.DropdownSeries(startDate, endDate).then((data) => {
            if (data.status_code) {
                setSeries(data.data);
                setIsLoadingParams(false);
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoadingParams(false);
            }
        });
    };

    const resetData = () => {
        setEndDate('');
        setStartDate('');
        setSeries([]);
        setDetail([]);
        setSeriesCID('');
        GetSeries();
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <div className='form-group'>
                                                <label htmlFor='exampleInputdate'>
                                                    Start Date :
                                                </label>
                                                <input
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    type='date'
                                                    className='form-control'
                                                    id='exampleInputdate'
                                                />
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='form-group'>
                                                <label htmlFor='exampleInputdate'>End Date :</label>
                                                <input
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    type='date'
                                                    className='form-control'
                                                    id='exampleInputdate'
                                                />
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Select Series :</label>

                                            <div className='div-fullseries-details'>
                                                {
                                                    isLoadingParams ? <Spin indicator={antIcon} /> :
                                                        <Select
                                                            id="matchId"
                                                            className='select-series-main-but-not-main'
                                                            options={series}
                                                            value={series.find((seriesList) => seriesList.title === seriesname)}
                                                            onChange={(selectedOption) => setSeriesCID(selectedOption._id)}
                                                            isClearable
                                                            isSearchable
                                                            placeholder="Search Series"
                                                            getOptionLabel={(option) => `${option.title} || ${option.startDate} - ${option.endDate}`}
                                                            getOptionValue={(option) => option.title}
                                                            isDisabled={isLoadingParams ? true : false}
                                                        />
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <button onClick={GetSeries} className='full-series-btn-round'>Search</button>
                                    <button onClick={resetData} className='full-series-btn-round'>Reset</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        {isLoading ? (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                    <RotatingLines
                                        marginTop='40'
                                        strokeColor='#c1030d'
                                        strokeWidth='5'
                                        animationDuration='0.75'
                                        width='50'
                                        visible={true}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>
                                                Series Listing - All Series
                                            </h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    // id="datatable"
                                                    className='table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Match Name</th>
                                                            <th>Series Name</th>
                                                            <th>Match Format</th>
                                                            <th>Start Date</th>
                                                            <th>Match Status</th>
                                                            <th>Launch Status</th>
                                                            <th>Squad Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {detail &&
                                                            detail?.length > 0 &&
                                                            detail?.map((data, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{++i}</td>
                                                                        <td>{data.MatchName}</td>
                                                                        <td>{data.title}</td>
                                                                        <td>{data.MatchFormat}</td>
                                                                        <td>{data.StartDate}</td>
                                                                        <td>{data.MatchStatus}</td>
                                                                        <td>{data.LaunchStatus === true ? 'YES' : 'NO'}</td>
                                                                        <td>{data.SquadStatus === true ? '✅' : '❌'}</td>
                                                                        <td>
                                                                            <Link
                                                                                to={'/allcontests'}
                                                                                state={{
                                                                                    matchId:
                                                                                        data.matchId,
                                                                                    APIMATCHID:
                                                                                        data.apiMatchId,
                                                                                }}
                                                                                type='submit'
                                                                                className='btn btn-primary btn-sm'
                                                                            >
                                                                                View Contest
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Pagination */}

                    </div>
                </div>
            </div>
        </div>
    );
}
