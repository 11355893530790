import React, { useState } from 'react';
import { Modal, Result } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CreateContestAPI } from '../api/LudoAPI';
import Notiflix from 'notiflix';
import { useDecimalFunc } from '../Component/DecimalHook';
import TopRoute from '../Component/TopRoute';

export default function LudoCreateContest() {
    const location = useLocation();
    const history = useNavigate();
    const [minAmount, setMinAmount] = useState('2');
    const [maxAmount, setMaxAmount] = useState('4');
    const [entryFees, setEntryFees] = useState('100');
    const [winningPercentage, setWinningPercentage] = useState('80');
    const [numberOfWinners, setNumberOfWinners] = useState('2');
    const [winPositions, setWinPositions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [gameType, setgameType] = useState('ludo');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const handleWinPositionsChange = (index) => (e) => {
        const { value } = e.target;
        const updatedWinPositions = [...winPositions];
        updatedWinPositions[index] = value;
        setWinPositions(updatedWinPositions);
    };

    const renderTableRows = () => {
        const totalPoolPrice = calculatePoolPrice();
        const rows = [];

        for (let i = 0; i < numberOfWinners; i++) {
            const percentage = parseInt(winPositions[i]) || 0;
            const amount = useDecimalFunc((percentage / 100) * totalPoolPrice);

            rows.push(
                <tr key={i}>
                    <td>
                        <input
                            type='text'
                            placeholder={`Position ${i + 1}`}
                            value={winPositions[i] || ''}
                            onChange={handleWinPositionsChange(i)}
                        />
                    </td>
                    <td>
                        <input
                            type='text'
                            placeholder='Amount'
                            value={useDecimalFunc(amount.toFixed(2))}
                            readOnly
                        />
                    </td>
                </tr>
            );
        }
        return rows;
    };

    const calculatePoolPrice = () => {
        let totalPrice = parseInt(maxAmount) * parseInt(entryFees);

        if (entryFees === 0 || maxAmount === 0 || winningPercentage === 0) {
            Notiflix.Notify.failure('Check Inputs');
            return 0;
        } else {
            if (totalPrice === 0 || totalPrice < 0) {
                return 0;
            } else {
                return useDecimalFunc((totalPrice * useDecimalFunc(winningPercentage)) / 100);
            }
        }
    };

    const HandlegameType = (e) => {
        setgameType(e.target.value);
    };

    const CreateContestPost = () => {
        // const gameType = 'ludo';
        const gameStatus = 'active';

        const jsonBody = {
            'gameType': gameType,
            'entryPrice': parseInt(entryFees),
            'min_spots': parseInt(minAmount),
            'max_spots': parseInt(maxAmount),
            'poolPrice': calculatePoolPrice(),
            'status': gameStatus,
            'winPrice': winPositions.map((position, index) => ({
                'position': index + 1,
                'amount': parseFloat((position / 100) * calculatePoolPrice()).toFixed(2)
            }))
        };

        CreateContestAPI.CreateContest(jsonBody).then((data) => {
            if (data.success_code) {
                setIsModalOpen(true);
                Notiflix.Notify.success('Contest Created Successfully');
            } else {
                Notiflix.Notify.failure('Something Went Wrong');
            }
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        history('/ludo-get');
    };

    return (
        <div className='wrapper'>
            {/* <ToastContainer /> */}
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div>
                        <TopRoute name={'Create Ludo Contest'} />
                        <div className='row'>
                            <div className='col-sm-12 col-lg-16'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'></div>
                                        <div className='offers_div'>
                                            <div>
                                                <label className='offer_checkbox'>
                                                    Select
                                                </label>
                                                <select
                                                    value={gameType}
                                                    className='offers_input'
                                                    onChange={HandlegameType}
                                                >
                                                    <option value='ludo'>Ludo</option>
                                                    <option value='rummy'>Rummy</option>
                                                    <option value='poker'>Poker</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className='offer_checkbox'>
                                                    Type Entry Fees
                                                </label>
                                                <input
                                                    value={entryFees}
                                                    onChange={handleAllChange(setEntryFees)}
                                                    className='offers_input'
                                                    placeholder='Enter Entry Fees'
                                                />
                                            </div>
                                            <div>
                                                <label className='offer_checkbox'>
                                                    Type Minimum Spots
                                                </label>
                                                <input
                                                    value={minAmount}
                                                    required
                                                    onChange={handleAllChange(setMinAmount)}
                                                    className='offers_input'
                                                    placeholder='Enter Maximum Spots'
                                                />
                                            </div>
                                            <div>
                                                <label className='offer_checkbox'>
                                                    Type Maximum Spots
                                                </label>
                                                <input
                                                    value={maxAmount}
                                                    onChange={handleAllChange(setMaxAmount)}
                                                    className='offers_input'
                                                    placeholder='Enter Minimum Spots'
                                                />
                                            </div>
                                            <div>
                                                <label className='offer_checkbox'>
                                                    Winning Percentage
                                                </label>
                                                <input
                                                    value={winningPercentage}
                                                    onChange={handleAllChange(setWinningPercentage)}
                                                    className='offers_input'
                                                    placeholder='Enter Winning Percentage'
                                                />
                                            </div>
                                            <div>
                                                <label className='offer_checkbox'>
                                                    Win Position
                                                </label>
                                                <select
                                                    value={numberOfWinners}
                                                    className='offers_input'
                                                    onChange={handleAllChange(setNumberOfWinners)}
                                                >
                                                    <option value='2'>Two Winners</option>
                                                    <option value='1'>One Winner</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h1 className='final-ludo-contest'>Final Ludo Contest Card</h1>
                        </div>
                        <div>
                            <table className='styled-table'>
                                <thead>
                                    <tr>
                                        <th>Position (In Percentage)</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>{renderTableRows()}</tbody>
                            </table>
                        </div>
                        <div className='offers_button_div'>
                            <button onClick={CreateContestPost} className='offers_button-real'>
                                Create Ludo Contest
                            </button>
                        </div>
                        <Modal footer={null} open={isModalOpen} onCancel={handleCancel}>
                            <Result status='success' title='Successfully Created the Contest' />
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}
