import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { ProfitLossAPI } from '../api/ProfitLossAPI';
import { SeriesAPI } from '../api/seriesApi';

export default function ViewProfitLossPage() {
    const [fantasyList, setFantasyList] = useState([]);
    const [profitLoss, setProfitLoss] = useState([]);
    const [fantasyVal, setFantasyVal] = useState('');
    const [matchName, setMatchName] = useState('');
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');
    const [range, setRange] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFantasy();
        GetProfitLoss();
    }, []);

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
            console.log(data.message);
        });
    };

    // const search=()=>{

    //   console.log(fantasyVal,
    //     matchName,
    //     sDate,
    //     eDate,
    //     range)
    //   // SeriesAPI.GetFantasy().then((data)=>{
    //   //     if(data.status)
    //   //     setFantasyList(data.message)
    //   //  })
    // }

    const GetProfitLoss = () => {
        ProfitLossAPI.GetProfitLoss(fantasyVal, matchName, sDate, eDate, range).then((data) => {
            setProfitLoss(data.data);
            console.log(data.data);
        });
    };

    const fantasyChange = (e) => {
        setFantasyVal(e.target.value);
        // console.log(fantasyVal)
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Select Fantasy :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={fantasyChange}
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    {fantasyList.map((fan) => (
                                                        <option value={fan.fantasy}>
                                                            {fan.fantasy}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <div className='col'>
                                                    <label htmlFor='name'>Match Name</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Search By Name'
                                                        value={matchName}
                                                        onChange={(e) =>
                                                            setMatchName(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className='col'>
                                                    <label htmlFor='name'>Start Date :</label>
                                                    <input
                                                        type='date'
                                                        className='form-control'
                                                        value={sDate}
                                                        onChange={(e) => setSDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className='col'>
                                                    <label htmlFor='name'>End Date :</label>
                                                    <input
                                                        type='date'
                                                        className='form-control'
                                                        value={eDate}
                                                        onChange={(e) => setEDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Search By Range :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={(e) => setRange(e.target.value)}
                                                >
                                                    <option selected value=''>
                                                        Select Date Range :
                                                    </option>
                                                    <option value='today'>Today</option>
                                                    <option value='this_week'>This Week</option>
                                                    <option value='this_month'>This Month</option>
                                                </select>
                                            </div>
                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    onClick={GetProfitLoss}
                                                    className='btn btn-primary'
                                                    type='button'
                                                >
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input type='reset' className='btn btn-danger' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {/* <div className="row"> */}
                </div>

                {/* <div className="row"> */}
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>View Match Player Points</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Match</th>
                                                <th>Match Date</th>
                                                <th>Investment Amount</th>
                                                <th>Refund Amount</th>
                                                <th>TDS Amount</th>
                                                <th>Winning Amout</th>
                                                <th>Youtuber Bonus</th>
                                                <th>Cashback</th>
                                                <th>Profit or Loss</th>
                                                <th>Amount of P&L</th>
                                                <th>Contest Report</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {profitLoss &&
                                                profitLoss.length > 0 &&
                                                profitLoss.map((data, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{++i}</td>
                                                            <td>{data.match}</td>
                                                            <td>{data.match_date}</td>
                                                            <td>{data.investment_amount}</td>
                                                            <td>{data.refund_amount}</td>
                                                            <td>{data.tds_amount}</td>
                                                            <td>{data.winning_amount}</td>
                                                            <td>
                                                                <center>
                                                                    {data.youtuber_bonus}{' '}
                                                                    <a
                                                                        className='button btn btn-secondary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                                                        href='#'
                                                                    >
                                                                        View Report
                                                                    </a>
                                                                </center>
                                                            </td>
                                                            <td>{data.cashback}</td>
                                                            <td>{data.profit_or_loss}</td>
                                                            <td>{data.amount_of_p_and_l}</td>
                                                            <td>
                                                                <a
                                                                    className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                                                    href='#'
                                                                >
                                                                    Contest Report
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
        // </div>
    );
}
