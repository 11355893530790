import { useEffect, useState } from 'react';
import { MatchAPI } from '../api/matchApi';
import Notiflix from 'notiflix';

export default function UpdatePlayerRole() {
    const [matches, setMatches] = useState([]);
    const [ApiMatchId, setApiMatchId] = useState('');
    const [matchSquad, setMatchSquad] = useState('');
    const [teamA, setTeamA] = useState([]);
    const [teamB, setTeamB] = useState([]);
    const [playerName, setPlayerName] = useState('');
    const [apiPlayerID, setApiPlayerID] = useState('');
    const [playingRole, setPlayingRole] = useState('');
    const [announcedPoint, setAnnouncedPoint] = useState('');
    const [fantasyPlayerRating, setFantasyPlayerRating] = useState('');
    const [is_playing, setIs_playing] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetMatch();
    }, []);

    const GetMatch = () => {
        MatchAPI.FormatMatches().then((data) => {
            console.log(data);
            setMatches(data.message);
        });
    };

    const editPlayer = (player) => {
        setPlayerName(player.PlayerName);
        setApiPlayerID(player.ApiPlayerID);
        setPlayingRole(player.PlayingRole);
        setApiMatchId(player.ApiMatchId);
        setAnnouncedPoint(player.AnnouncedPoint.toString());
        setFantasyPlayerRating(player.FantasyPlayerRating.toString());
        setIs_playing(player.is_playing);
    };

    const GetMatchSquad = (matchid) => {
        // console.log(ApiMatchId);
        const json = {
            ApiMatchId: matchid,
        };

        MatchAPI.GetMatchSquad(json).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success(data.message);
                setMatchSquad(data.data);
                for (let i = 0; i <= data.data.length; i++) {
                    if (data.data[i].Team === 'a') {
                        setTeamA((prev) => [...prev, data.data[i]]);
                    } else if (data.data[i].Team === 'b') {
                        setTeamB((prev) => [...prev, data.data[i]]);
                    }
                }
                console.log(teamA);
                console.log(teamB);
            } else {
                Notiflix.Notify.failure(data.message);
            }
        });
    };

    const subtitleChange = (e) => {
        setTeamA([]);
        setTeamB([]);
        setMatchSquad([]);

        if (!e) {
            GetMatchSquad(ApiMatchId);
        } else {
            const obj = JSON.parse(e.target.value);
            setApiMatchId(obj.apiMatchId);
            GetMatchSquad(obj.apiMatchId);
        }
    };

    const EditSubmit = (e) => {
        e.preventDefault();
        const json = {
            ApiMatchId: ApiMatchId,
            ApiPlayerID: apiPlayerID,
            PlayingRole: playingRole,
            AnnouncedPoint: announcedPoint,
            FantasyPlayerRating: fantasyPlayerRating,
            is_playing: is_playing == '1' ? true : false,
        };
     
        MatchAPI.UpdatePlayerRole(json).then((res) => {
            if (res.status_code) {
                Notiflix.Notify.success(res.message);
                console.log(ApiMatchId);
                subtitleChange();
            } else {
                Notiflix.Notify.failure(res.message);
            }
        });
    };

    let roles = ['bat', 'bowl', 'wk', 'all'];
    let isPlay = ['1', '0'];

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'>
                                        <label htmlFor='name'>Subtitle Visibilty*</label>
                                        <select
                                            id='matchId'
                                            className='form-control mb-3'
                                            onChange={subtitleChange}
                                        >
                                            <option select=''>Select</option>
                                            {matches.map((fan) => (
                                                <option
                                                    value={JSON.stringify(fan)}
                                                >{`${fan.matchName}   ||   ${fan.StartDateIST}}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <h4 className='m-3'>Team A</h4>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        // id='datatable'
                                        className='table table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Player Name</th>
                                                <th>Player Role</th>
                                                <th>Fantasy Player Rating</th>
                                                <th>Announced Point</th>
                                                <th>Is Playing</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamA &&
                                                teamA.length > 0 &&
                                                teamA.map((player, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{++i}</td>
                                                            <td>{player.PlayerName}</td>
                                                            <td>{player.PlayingRole}</td>
                                                            <td>{player.FantasyPlayerRating}</td>
                                                            <td>{player.AnnouncedPoint}</td>
                                                            <td>
                                                                {player.is_playing ? 'Yes' : 'No'}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    // className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                                                    onClick={() =>
                                                                        editPlayer(player)
                                                                    }
                                                                    className='btn btn-sm btn-skyblue'
                                                                    data-toggle='modal'
                                                                    data-target='#exampleModalScrollable'
                                                                    title='Edit'
                                                                    type='button'
                                                                >
                                                                    Update
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <h4 className='m-3'>Team B</h4>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        // id='datatable'
                                        className='table table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Player Name</th>
                                                <th>Player Role</th>
                                                <th>Fantasy Player Rating</th>
                                                <th>Announced Point</th>
                                                <th>Is Playing</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamB &&
                                                teamB.length > 0 &&
                                                teamB.map((player, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{++i}</td>
                                                            <td>{player.PlayerName}</td>
                                                            <td>{player.PlayingRole}</td>
                                                            <td>{player.FantasyPlayerRating}</td>
                                                            <td>{player.AnnouncedPoint}</td>
                                                            <td>
                                                                {player.is_playing ? 'Yes' : 'No'}
                                                            </td>
                                                            <td>
                                                                <button
                                                                    // className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                                                    onClick={() =>
                                                                        editPlayer(player)
                                                                    }
                                                                    className='btn btn-sm btn-skyblue'
                                                                    data-toggle='modal'
                                                                    data-target='#exampleModalScrollable'
                                                                    title='Edit'
                                                                    type='button'
                                                                >
                                                                    Update
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade'
                    id='exampleModalScrollable'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalScrollableTitle'
                    aria-hidden='true'
                >
                    <div className='modal-dialog modal-dialog-scrollable' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                    Edit Player
                                </h5>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Player Name</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={playerName}
                                            // onChange={EditSeriesChange}
                                            placeholder=' '
                                            disabled
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Playing Role</label>

                                        <select
                                            id='matchId'
                                            className='form-control mb-3'
                                            onChange={(e) => setPlayingRole(e.target.value)}
                                        >
                                            <option select=''>Select</option>
                                            {roles.map((fan) => (
                                                <option
                                                    selected={fan === playingRole ? true : false}
                                                    value={fan}
                                                >
                                                    {fan}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Announced Point</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            // disabled
                                            onChange={(e) => setAnnouncedPoint(e.target.value)}
                                            value={announcedPoint}
                                            id=''
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Fantasy Player Rating</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            // disabled
                                            value={fantasyPlayerRating}
                                            onChange={(e) => setFantasyPlayerRating(e.target.value)}
                                            id=''
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Is Playing</label>

                                        <select
                                            id='matchId'
                                            className='form-control mb-3'
                                            onChange={(e) => setIs_playing(e.target.value)}
                                        >
                                            <option select=''>Select</option>
                                            {isPlay.map((fan) => (
                                                <option
                                                    selected={fan == is_playing ? true : false}
                                                    value={fan}
                                                >
                                                    {fan == true ? 'Yes' : 'No'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    className='btn btn-secondary'
                                    data-dismiss='modal'
                                >
                                    Close
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary'
                                    data-dismiss='modal'
                                    onClick={EditSubmit}
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
