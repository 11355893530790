import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SeriesAPI } from '../api/seriesApi';
import { RotatingLines } from 'react-loader-spinner';
import { Table, Form, FormControl, Button, Pagination } from 'react-bootstrap';

function AllUsersPage() {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const matchId = location.state.MatchId;
    const contestPoolId = location.state.ContestPoolId;
    const apiMatchId = location.state.APiMatchId;
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const indexOfLastUser = currentPage * rowsPerPage;
    const indexOfFirstUser = indexOfLastUser - rowsPerPage;

    useEffect(() => {
        ViewAlljoinedContestUser();
    }, []);

    const ViewAlljoinedContestUser = () => {
        setIsLoading(true);
        const json = {
            MatchId: location.state.MatchId,
            ContestPoolId: location.state.ContestPoolId,
            APIMatchId: location.state.APiMatchId,
        };

        SeriesAPI.viewAlljoinedContestUser(json).then((result) => {
            if (result.Status) {
                setData(result.Data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    };

    const handleDecimal = (number) => {
        if (number === undefined) {
            return '';
        } else {
            let result = Math?.round(number * 100) / 100;
            return result;
        }
    };

    const currentUsers = filteredData.length > 0 ? filteredData.slice(indexOfFirstUser, indexOfLastUser) : data.slice(indexOfFirstUser, indexOfLastUser);

    const filterData = (searchTerm) => {
        const filteredUsers = data.filter(user => {
            const searchTermLowerCase = searchTerm.toLowerCase();
            return (
                (user.TeamName && user.TeamName.toLowerCase().includes(searchTermLowerCase)) ||
                (user.UserID && user.UserID.includes(searchTermLowerCase)) ||
                (user.PhoneNumber && user.PhoneNumber.includes(searchTermLowerCase))
            );
        });

        setFilteredData(filteredUsers);
        setCurrentPage(1); // Reset to the first page when filtering
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        filterData(searchTerm);
    };

    const handleReset = () => {
        setFilteredData([]);
        ViewAlljoinedContestUser();
    };

    const handleTableChange = (type, { page }) => {
        setCurrentPage(page);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>View all users</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            {isLoading ? (
                                <div className='loader-for-all'>
                                    <div>
                                        <RotatingLines
                                            marginTop='70'
                                            strokeColor='#000000'
                                            strokeWidth='5'
                                            animationDuration='0.75'
                                            width='50'
                                            visible={true}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Form className='mb-3'>
                                        <FormControl
                                            type='text'
                                            placeholder='Type TeamName'
                                            onChange={handleSearch}
                                        />
                                        <Button className='button-reset-allUsers' variant='primary' onClick={handleReset}>
                                            Reset
                                        </Button>
                                    </Form>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Team Name</th>
                                                <th>Rank</th>
                                                <th>Points</th>
                                                <th>Winning Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentUsers.map((user, i) => (
                                                <tr key={i}>
                                                    <td>{user.TeamName}</td>
                                                    <td>{user.Rank}</td>
                                                    <td>{handleDecimal(user.Point)}</td>
                                                    <td>{handleDecimal(user.WinPrice)}</td>
                                                    <td>
                                                        <Link
                                                            to={'/user_team'}
                                                            state={{
                                                                matchId: matchId,
                                                                contestPoolId: contestPoolId,
                                                                user_id: user.UserID,
                                                                teamId: user.TeamID,
                                                            }}
                                                            type='submit'
                                                            className='btn btn-primary btn-sm m-1'
                                                        >
                                                            View Team
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {/* Pagination */}
                                    <div className="d-flex justify-content-center">
                                        <Pagination>
                                            {[...Array(Math.ceil(filteredData.length / rowsPerPage)).keys()].map(page => (
                                                <Pagination.Item
                                                    key={page + 1}
                                                    active={page + 1 === currentPage}
                                                    onClick={() => setCurrentPage(page + 1)}
                                                >
                                                    {page + 1}
                                                </Pagination.Item>
                                            ))}
                                        </Pagination>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllUsersPage;
