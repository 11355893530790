// Staging
// export const baseURL = process.env.REACT_APP_BASE_URL || 'https://app-staging.super4.in/api/auth/';
// export const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:5002/api/auth/';
// export const baseURLLudo = 'https://ludo-staging.super4.in/api/auth/';
// export const baseURLRummy = 'https://rummy-staging.super4.in/api/auth/';
// export const crashBaseURL = 'https://crash-staging.super4.in/api/auth/';


// Production
export const baseURL = process.env.REACT_APP_BASE_URL || 'https://super4-app-prod.super4.in/api/auth/';
export const baseURLLudo = 'http://34.100.225.194:3000/api/auth/';
export const baseURLRummy = 'https://rummy-staging.super4.in/api/auth/';
export const crashBaseURL = 'https://crash-staging.super4.in/api/auth/';


export var imageURL = 'http://13.235.169.237:5003';