import React from 'react';

const Pagination = ({ totalEntries, totalPages, changePage, pageNumber }) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const visiblePages = 3;
    const halfVisiblePages = Math.floor(visiblePages / 2);

    let startPage = Math.max(1, pageNumber - halfVisiblePages);
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    let showLeftWaaleDots = false;
    let showRightWaaleDots = false;

    if (totalPages > visiblePages && pageNumber > halfVisiblePages) {
        showLeftWaaleDots = true;
        startPage = pageNumber + halfVisiblePages >= totalPages
            ? totalPages - visiblePages + 1
            : pageNumber - halfVisiblePages;
    }

    if (totalPages > visiblePages && pageNumber <= totalPages - halfVisiblePages) {
        showRightWaaleDots = true;
        endPage = pageNumber > halfVisiblePages
            ? pageNumber + halfVisiblePages
            : visiblePages;
    }

    const showFirst = startPage > 1;
    const showLast = endPage < totalPages;

    return (
        <nav className='pagination-css-div'>
            <ul className="pagination justify-content-center">
                {showFirst && (
                    <li className="page-item pagination-css-mr">
                        <button className="page-link" onClick={() => changePage(1)}>
                            First
                        </button>
                    </li>
                )}

                {startPage > 1 && (
                    <li className="page-item pagination-css-mr">
                        <button className="page-link" onClick={() => changePage(pageNumber - 1)}>
                            Previous
                        </button>
                    </li>
                )}

                {showLeftWaaleDots && (
                    <li className="page-item">
                        <span className="page-link">...</span>
                    </li>
                )}

                {pageNumbers?.slice(startPage - 1, endPage).map((number) => (
                    <li
                        key={number}
                        className={`page-item ${pageNumber === number ? 'active' : ''}`}
                    >
                        <button className="page-link" onClick={() => changePage(number)}>
                            {number}
                        </button>
                    </li>
                ))}

                {showRightWaaleDots && (
                    <li className="page-item">
                        <span className="page-link">...</span>
                    </li>
                )}

                {showLast && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => changePage(totalPages)}>
                            {totalPages}
                        </button>
                    </li>
                )}

                {endPage < totalPages && (
                    <li className="page-item pagination-css-ml">
                        <button className="page-link" onClick={() => changePage(pageNumber + 1)}>
                            Next
                        </button>
                    </li>
                )}

                <div className='pagin-div-h4'>
                    <h4>Total:- {totalEntries}</h4>
                </div>

            </ul>
        </nav>
    );
};

export default Pagination;
