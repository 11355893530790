import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { AccountManagerAPI } from '../api/AccountManagerAPI';

export default function DailyReport() {
    const [data, setData] = useState('');
    const [sDate, setSdate] = useState('');
    const [eDate, setEdate] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        getDailyReport();
    }, []);

    const getDailyReport = () => {
        // console.log('hi')
        AccountManagerAPI.GetDailyReport(sDate, eDate).then((data) => {
            setData(data.data);
            //   console.log(data.data)
        });
    };

    const startDateChange = (e) => {
        // console.log(e.target.value)
        setSdate(e.target.value);
    };

    const endDateChange = (e) => {
        setEdate(e.target.value);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>Start Date :</label>
                                                <input
                                                    onChange={startDateChange}
                                                    type='date'
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>End Date :</label>
                                                <input
                                                    onChange={endDateChange}
                                                    type='date'
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>

                                        <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                onClick={() => getDailyReport(eDate, sDate)}
                                                className='btn btn-primary'
                                                type='button'
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                            <input type='reset' className='btn btn-danger' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <a className='button btn btn-skyblue' href='#'>
                                    <i className='la la-download'></i>Export Day wise Report
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row"> */}
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>View Daily Report</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <td>S.No.</td>
                                                <td>Date</td>
                                                <td>Received Amount</td>
                                                <td>Withdraw Amount</td>
                                                <td>Youtuber Amount</td>
                                                <td>Profit</td>
                                                <td>Loss</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data &&
                                                data.length > 0 &&
                                                data.map((data, i) => {
                                                    return (
                                                        <tr>
                                                            <th>{++i}</th>
                                                            <th>{data.date}</th>
                                                            <th>{data.received_amount}</th>
                                                            <th>{data.withdraw_amount}</th>
                                                            <th>{data.youtuber_amount}</th>
                                                            <th>{data.profit}</th>
                                                            <th>{data.loss}</th>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
        // </div>
    );
}
