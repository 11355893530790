import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { SeriesAPI } from '../api/seriesApi';
import { CSVLink } from 'react-csv';

function UserTeamPage() {
    const location = useLocation();
    const { matchId, contestPoolId, user_id, teamId } = location.state;
    console.log(location.state);
    const [dataArray, setArray] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetParticipateContestUserTeam();
    }, []);

    const GetParticipateContestUserTeam = () => {
        SeriesAPI.GetParticipateContestUserTeam(matchId, contestPoolId, user_id, teamId).then((data) => {
            if (data.Status) {
                setArray(data.Data);
                console.log(data.Data);
            }
        });
    };

    const getAllPlayers = (data) => {
        console.log(data);
        const comma = ',';

        const allPlayers = [];
        for (let i = 0; i < data.length; i++) {
            allPlayers.push(`${Object.values(data[i])} ${comma}`);
        }
        return allPlayers;
    };

    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>View Users Team</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>MatchId</th>
                                                <th>TeamId</th>
                                                <th>Captain</th>
                                                <th>Players</th>
                                                <th>Vice Captain</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataArray &&
                                                dataArray.length > 0 &&
                                                dataArray.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{++i}</td>
                                                            <td>{item.MatchId}</td>
                                                            <td>{item.TeamId}</td>
                                                            <td>{item.Captain}</td>
                                                            <td>
                                                                {getAllPlayers(item.PlayerData)}
                                                            </td>
                                                            <td>{item.ViceCaptain}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
        // </div>
    );
}

export default UserTeamPage;
