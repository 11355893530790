import './ViewAllUsers.css';
import { useState } from 'react';
import Notiflix from 'notiflix';
import { fundManager } from '../api/FundsApi';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

export default function BonusFunds({ dashLoggedIn, fundsUserId }) {

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    // Modal States
    const [bonusAmount, setBonusAmount] = useState('');
    const [unutilizeAmount, setUnutilizeAmount] = useState('');

    const [bonusMasterPassword, setBonusMasterPassword] = useState('');
    const [unutilizeMasterPassword, setUnutilizeMasterPassword] = useState('');

    const [bonusInternalComment, setBonusInternalComment] = useState('');
    const [unutilizeInternalComment, setUnutilizeInternalComment] = useState('');


    const [bonusFailedDate, setBonusFailedDate] = useState('');
    const [unutilizeFailedDate, setUnutilizeFailedDate] = useState('');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const BonusFunds = () => {
        const mainBonusType = 'bonus';
        const bonusSubType = '1';
        const data = {
            'user_id': fundsUserId,
            'amount': parseFloat(bonusAmount),
            'type': mainBonusType,
            'comment': bonusInternalComment,
            'master_password': bonusMasterPassword,
            'user_name': dashLoggedIn,
            'failed_date': bonusFailedDate,
            'subType': bonusSubType
        };

        fundManager.addBonusinFunds(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Bonus Added Successfully');
                reset();
            } else {
                Notiflix.Notify.failure('Something Went Wrong, Check Comlete Filled Details');
            }
        });
    };

    const UnutilizeFunds = () => {
        const mainUnType = 'bonus';
        const unSubType = '2';
        const data = {
            'user_id': fundsUserId,
            'amount': parseFloat(unutilizeAmount),
            'type': mainUnType,
            'comment': unutilizeInternalComment,
            'master_password': unutilizeMasterPassword,
            'user_name': dashLoggedIn,
            'failed_date': unutilizeFailedDate,
            'subType': unSubType
        };

        fundManager.addUnutilizeinFunds(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Unutilize Added Succesfully');
                reset();
            } else {
                Notiflix.Notify.failure('Something Went Wrong, Check Comlete Filled Details');
            }
        });
    };

    const reset = () => {
        setBonusAmount('');
        setBonusInternalComment('');
        setBonusFailedDate('');
        setBonusMasterPassword('');
        setUnutilizeMasterPassword('');
        setUnutilizeAmount('');
        setUnutilizeFailedDate('');
        setUnutilizeInternalComment('');
    };

    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }

    return (
        <div className='modal-1-div-main'>

            <div className='main-div-for-funds-manager'>
                <div className='modal-div-1'>
                    <h2>UserId</h2>
                    <h1>{fundsUserId}</h1>
                </div>

                <div className='modal-div-1'>
                    <h2>Name</h2>
                    <h1>{dashLoggedIn}</h1>
                </div>
            </div>

            <Tabs
                defaultActiveKey="1"
                onChange={callback}
                tabBarStyle={{ color: '#8D949C' }}
                style={{ width: '100%' }}
            >

                <TabPane tab="Bonus" key="1" >
                    <div>
                        <div className='modal-div-2'>
                            <label className='offer_checkbox-2'>Enter Amount</label>
                            <input value={bonusAmount} onChange={handleAllChange(setBonusAmount)} className='offers_input-2' placeholder='Type Amount Here' />
                        </div>


                        <div className='modal-div-2'>
                            <label className='offer_checkbox-2'>Failed Date</label>
                            <input value={bonusFailedDate} type='date' onChange={handleAllChange(setBonusFailedDate)} className='offers_input-2' placeholder='Type TDS amount here' />
                        </div>

                        <div className='modal-div-2'>
                            <label className='offer_checkbox-2'>Internal Comment</label>
                            <textarea value={bonusInternalComment} onChange={handleAllChange(setBonusInternalComment)} className='offers_input-2' placeholder='Type Internal Comment' />
                        </div>

                        <div className='modal-div-2'>
                            <label htmlFor="password" className='offer_checkbox-2'>Enter Master Password</label>
                            <input value={bonusMasterPassword}
                                onChange={handleAllChange(setBonusMasterPassword)}
                                autoComplete="off"
                                type={passwordVisible ? 'text' : 'password'}
                                className='offers_input-2'
                                placeholder='Master Password Here' />
                            {passwordVisible ? (
                                <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                            ) :
                                (
                                    <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                )}
                        </div>

                        <div className='div-add-fund-div-multi'>
                            <button onClick={BonusFunds} className='modal-div-button-withdraw'>Add Bonus</button>
                            <div className='div-add-fund-div-multi-h1'>
                                <h1 className='add-funds-api-number'>Bonus also expires in</h1>
                                <h1 className='span-amount-add-fundss'>30 Days</h1>
                            </div>
                        </div>
                    </div>
                </TabPane>

                <TabPane tab="Unutilize" key="2" >

                    <div>
                        <div className='modal-div-2'>
                            <label className='offer_checkbox-2'>Enter Amount</label>
                            <input value={unutilizeAmount} onChange={handleAllChange(setUnutilizeAmount)} className='offers_input-2' placeholder='Type Amount Here' />
                        </div>

                        <div className='modal-div-2'>
                            <label className='offer_checkbox-2'>Failed Date</label>
                            <input value={unutilizeFailedDate} type='date' onChange={handleAllChange(setUnutilizeFailedDate)} className='offers_input-2' placeholder='Type TDS amount here' />
                        </div>

                        <div className='modal-div-2'>
                            <label className='offer_checkbox-2'>Internal Comment</label>
                            <textarea value={unutilizeInternalComment} onChange={handleAllChange(setUnutilizeInternalComment)} className='offers_input-2' placeholder='Type Internal Comment' />
                        </div>

                        <div className='modal-div-2'>
                            <label htmlFor="password" className='offer_checkbox-2'>Enter Master Password</label>
                            <input value={unutilizeMasterPassword}
                                onChange={handleAllChange(setUnutilizeMasterPassword)}
                                autoComplete="off"
                                type={passwordVisible ? 'text' : 'password'}
                                className='offers_input-2'
                                placeholder='Master Password Here' />
                            {passwordVisible ? (
                                <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                            ) :
                                (
                                    <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                )}
                        </div>

                        <div className='div-add-fund-div-multi'>
                            <button onClick={UnutilizeFunds} className='modal-div-button-withdraw'>Add Unutilize</button>
                            <div className='div-add-fund-div-multi-h1'>
                                <h1 className='add-funds-api-number'>Super</h1>
                                <h1 className='span-amount-add-fundss'>4</h1>
                            </div>
                        </div>

                    </div>

                </TabPane>
            </Tabs>

        </div>
    );
}
