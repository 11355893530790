import React, { useState } from 'react';
import { fundManager } from '../api/FundsApi';
import Notiflix from 'notiflix';

export default function Razorpayfunds() {

    const [account_num, setAccount_num] = useState('');

    const callRazoAPI = async () => {
        const response = await fundManager.fetchAllPayOutRazorPayX(account_num);
        if (response.status_code === true) {
            Notiflix.Notify.success('All Razorpay Payment');
        } else {
            Notiflix.Notify.failure('Something Went Wrong');
        }
    };

    return (
        <div>
            ;
        </div>
    );
}
