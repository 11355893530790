import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { PrvtContest } from '../api/PvtContestAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useLocation } from 'react-router-dom';
import { TbCricket } from 'react-icons/tb';
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

export default function PrivateContestDetail() {

    const location = useLocation();
    const matchid = location.state.matchId;
    const prvCont_MatchName = location.state.matchName;
    const prvt_matchStatus = location.state.matchStatus;


    const [allPrivate, setAllPrivate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [matchName, setMatchName] = useState('');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    useEffect(() => {
        getAllPrivateContestMatchesData();
    }, []);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 64,
            }}
            spin
        />
    );

    const getAllPrivateContestMatchesData = () => {
        setIsLoading(true);
        PrvtContest.GetAllPrivateContestMatches(matchid).then((data) => {
            if (data.status_code === true) {
                setIsLoading(false);
                setAllPrivate(data.data);
                console.log(data.data);
                Notiflix.Notify.success('Successfully Fetched');
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    // const handleFilter = () => {
    // };

    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        return newDate;
    };

    const handleTime = (e) => {
        var newTime = e?.slice(11, 18);
        var updateTime = moment(newTime, 'HH:mm:ss').format('hh:mm A');
        return updateTime;
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='offers_couponDiv-div'>
                            <TbCricket className='dashboard-icon' />
                            <h1 className='coupon_h1-h1'>{prvCont_MatchName}</h1>
                            <h4 className='offers-div-main-private-comnt'>{prvt_matchStatus}</h4>
                        </div>

                    </form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        {
                                            isLoading ? <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='70'
                                                        strokeColor='#c1030d'
                                                        strokeWidth='5'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                                : (allPrivate.length === 0 ?
                                                    <Result
                                                        icon={<SmileOutlined />}
                                                        title="No Private Contest Was Created"
                                                        className='result-for-result'
                                                    />
                                                    :
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div
                                                            >
                                                                <div className='table-responsive'>
                                                                    <table className='table table-striped table-bordered'>
                                                                        <thead>
                                                                            <tr className='support_main_file_div'>
                                                                                <th>S.No.</th>
                                                                                <th>Created Date</th>
                                                                                <th>Created Time</th>
                                                                                <th>Fantasy</th>
                                                                                <th>Contest Code</th>
                                                                                <th>Joining Fees</th>
                                                                                <th>is Confirmed</th>
                                                                                <th>is MultiEntry</th>
                                                                                <th>is Running</th>
                                                                                <th>Max Spots</th>
                                                                                <th>Joined Users</th>
                                                                                <th>Left Spot</th>
                                                                                <th>is Refund</th>
                                                                                <th>Winning Amount</th>
                                                                                <th>is Cancelled</th>
                                                                                <th>Contest Details</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                allPrivate && allPrivate.map((privateCont, i) => {
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td>{++i}</td>
                                                                                            <td>{handleDate(privateCont.createdAt)}</td>
                                                                                            <td>{handleTime(privateCont.createdAt)}</td>
                                                                                            <td>{privateCont.fantasy}</td>
                                                                                            <td>{privateCont.contestCode}</td>
                                                                                            <td>{privateCont.JoiningFees}</td>
                                                                                            <td>{privateCont.IsConfirmed ? 'Yes' : 'No'}</td>
                                                                                            <td>{privateCont.IsMultiEntry ? 'Yes' : 'No'}</td>
                                                                                            <td>{privateCont.IsRunning ? 'Yes' : 'No'}</td>
                                                                                            <td>{privateCont.MaxSpots}</td>
                                                                                            <td>{(parseInt(privateCont.MaxSpots)) - (parseInt(privateCont.LeftSpots))}</td>
                                                                                            <td>{privateCont.LeftSpots}</td>
                                                                                            <td>{privateCont.IsRefund ? 'Yes' : 'No'}</td>
                                                                                            <td>{privateCont.WinningRank}</td>
                                                                                            <td>{privateCont.IsCancelled ? 'Yes' : 'No'}</td>
                                                                                            <td><button className='some_898989898988'>
                                                                                                <Link
                                                                                                    className='some_898989898988'
                                                                                                    to={
                                                                                                        '/private-contest-details-info'
                                                                                                    }
                                                                                                    state={{
                                                                                                        PVTuserId: privateCont.userId,
                                                                                                        PVTmatchId: privateCont.matchId,
                                                                                                        matchName: prvCont_MatchName,
                                                                                                        poolId: privateCont.contestId,
                                                                                                        matchStatus: prvt_matchStatus,
                                                                                                        fantasy: privateCont.fantasy,
                                                                                                        userId: privateCont.userId
                                                                                                    }}
                                                                                                    type='submit'
                                                                                                // className='py-4'
                                                                                                >
                                                                                                    Detail
                                                                                                </Link>
                                                                                            </button></td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >);
}
