import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { MatchAPI } from '../api/matchApi';
import { RotatingLines } from 'react-loader-spinner';

export default function AllMatchesPage() {
    const [allMatches, setAllMatches] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [editRescheduleMatch, setEditRescheduleMatch] = useState('');
    const [editChangeDate, setEditChangeDate] = useState('');
    const [id, setId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const role = localStorage.getItem('role');
    const [matchName, setMatchName] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        fetchAllMatches();
    }, []);

    const headers = [
        { label: 'Start Date', key: 'StartDateIST' },
        { label: 'Match', key: 'matchName' },
        { label: 'Launch Status', key: 'launchStatus' },
        { label: 'Status', key: 'status' },
    ];

    const EditReschedule = (data) => {
        setId(data.matchId);
        setEditRescheduleMatch(data);
    };

    const fetchAllMatches = () => {
        setAllMatches([]);
        MatchAPI.FetchAllMatches().then((data) => {
            setAllMatches(data.message);
            setIsLoading(false);
        });
    };

    const filterAllMatches = () => {
        const filteredData = allMatches.filter((match) =>
            match.matchName.toLowerCase().includes(matchName.toLowerCase())
        );
        setFilteredMatches(filteredData);
    };

    const matchNameChange = (e) => {
        setMatchName(e.target.value);
    };

    const Reset = () => {
        setMatchName('');
        setFilteredMatches([]);
        fetchAllMatches();
    };

    const unlaunchHandler = (apiMatchId) => {
        const data = {
            launchStatus: false,
            apiMatchId,
        };
        MatchAPI.UnlaunchMatch(data).then((data) => {
            Notiflix.Notify.success(data.message);
            fetchAllMatches();
        });
    };

    const updateHandler = (apiMatchId) => { };

    const EditSubmitHandler = () => {
        if (editChangeDate == null || editChangeDate === '') {
            Notiflix.Notify.failure('Change Date Cant be Blank');
            return;
        }

        const json = {
            matchId: id,
            changeTime: editChangeDate,
        };

        MatchAPI.EditRescheduleMatch(json).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('success');
                fetchAllMatches();
            } else {
                Notiflix.Notify.failure('failure');
            }
        });
    };

    const dateChange = (e) => {
        setEditChangeDate(e.target.value);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Match Name</label>
                                            <input
                                                value={matchName}
                                                onChange={matchNameChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Match Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                onClick={() => filterAllMatches()}
                                                type='button'
                                                className='mt-2 btn btn-primary'
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            <button
                                                onClick={Reset}
                                                type='button'
                                                className='mt-2 btn btn-danger'
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <div className='btn-group m-2' role='group'>
                                    <button
                                        id='btnGroupDrop1'
                                        type='button'
                                        className='btn btn-primary dropdown-toggle'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                    >
                                        Export
                                    </button>
                                    <div
                                        className='dropdown-menu'
                                        aria-labelledby='btnGroupDrop1'
                                        style={{}}
                                    >
                                        <button className='dropdown-item' href='#'>
                                            Export All data
                                        </button>
                                        <button className='dropdown-item' href='#'>
                                            Export This Data
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isLoading && (
                    <div className='col-sm-12 col-lg-12'>
                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                            <RotatingLines
                                marginTop='40'
                                strokeColor='#c1030d'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='50'
                                visible={true}
                            />
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Matches</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th className='th'>S.No.</th>
                                                    <th className='th'>Start Date</th>
                                                    <th className='th'>Match</th>
                                                    <th className='th'>Prediction Contest</th>
                                                    <th className='th'>Cricket Contest</th>
                                                    <th className='th bg-danger text-white'>
                                                        Total Contest
                                                    </th>
                                                    <th className='th'>Total Cricket users</th>
                                                    <th className='th'>Total Prediction users</th>
                                                    <th className='th bg-danger text-white'>
                                                        Total users
                                                    </th>
                                                    <th className='th'>Squad Available</th>
                                                    {role !== 3 && role !== 5 && <th className='th'>Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(filteredMatches.length > 0 ? filteredMatches : allMatches).map(
                                                    (match, i) => (
                                                        <tr key={i}>
                                                            <td>{++i}</td>
                                                            <td>{match.StartDateIST}</td>
                                                            <td>{match.matchName}</td>
                                                            <td>{match.prediction_contest}</td>
                                                            <td>{match.cricket_contest}</td>
                                                            <td>{match.total_contest}</td>
                                                            <td>{match.total_team_users}</td>
                                                            <td>{match.total_scoreboard_users}</td>
                                                            <td>
                                                                {match.total_scoreboard_users +
                                                                    match.total_team_users}
                                                            </td>
                                                            <td>{match.preSquad}</td>
                                                            {role !== 3 && role !== 5 && (
                                                                <td>
                                                                    <button
                                                                        title='Unlaunch Match'
                                                                        onClick={(e) => {
                                                                            if (
                                                                                window.confirm(
                                                                                    'Are you sure to unlaunch match?'
                                                                                )
                                                                                    ? unlaunchHandler(
                                                                                        match.apiMatchId
                                                                                    )
                                                                                    : e.preventDefault()
                                                                            );
                                                                        }}
                                                                        className='btn btn-primary btn-sm my-2'
                                                                        data-target='#exampleModalScrollable'
                                                                    >
                                                                        <i className='ri-edit-line'></i> Unlaunch
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            EditReschedule(match);
                                                                        }}
                                                                        type='button'
                                                                        data-toggle='modal'
                                                                        data-target='#exampleModalScrollable'
                                                                        className='btn btn-skyblue btn-sm'
                                                                    >
                                                                        <i className='ri-edit-line'></i> Edit
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div
                className='modal fade'
                id='exampleModalScrollable'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalScrollableTitle'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-scrollable' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                Reschedule Match
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form>
                                <div className='form-group'>
                                    <label htmlFor='name'>Type :</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='T20'
                                        defaultValue='T20'
                                        disabled
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>MatchName</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Team Short Name'
                                        defaultValue={editRescheduleMatch.matchName}
                                        disabled
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>Date Time :</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder=' '
                                        defaultValue={editRescheduleMatch.StartDateIST}
                                        disabled
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>Change Date Time :</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder=' '
                                        defaultValue={editRescheduleMatch.StartDateIST}
                                        value={editChangeDate}
                                        onChange={(e) => dateChange(e)}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                data-dismiss='modal'
                            >
                                Close
                            </button>
                            &nbsp;
                            <button
                                onClick={EditSubmitHandler}
                                data-dismiss='modal'
                                type='button'
                                className='btn btn-primary'
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
