import { baseURL } from './apiBaseUrl';
import { BASEAPIS } from './BaseApis';

const key = localStorage.getItem('jwtToken');

export const TeamAPI = {
    GetTeam: async (team) => {
        //alert()
        return await fetch(baseURL + 'fetchTeamData?teamName=' + team, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    ExportTeam: async () => {
        //alert()
        return await fetch(baseURL + 'storeTeamDataApi', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    FilterExportTeam: async () => {
        //alert()
        return await fetch(baseURL + 'storeTeamDataApi', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    EditTeam: async (imageData) => {
        return await fetch(baseURL + 'editTeam', {
            method: 'POST',
            body: imageData,
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                // console.log(json,'data')
                if (json != null) {
                    //    console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
        // }
    },


    DeletePlayerFromTeam: (dataArr) => {
        // let finalTeamId = parseInt(deleteTeamId);
        console.log(typeof (dataArr.teamId));
        return BASEAPIS.POSTAPI(dataArr, 'fetchUserTeamByTeamId');
    },

    DeletePlayer: (data) => {
        console.log(data);
        return BASEAPIS.DELETEAPI(data, 'deleteExtraPlayerFromUserTeam');
    },



    DeletePlayerCompletely: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'removeUsersTeamInjoining');
    },


};
