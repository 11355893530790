export default function CashFreePage() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Start Date :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Start Date'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>End Date :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By End Date'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>User Name :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Mobile :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Mobile No.'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Search by Range :</label>
                                            <select className='form-control mb-3'>
                                                <option select=''>Select Range</option>
                                                <option value='1'>Cricket</option>
                                                <option value='2'>Cricket</option>
                                                <option value='3'>Cricket</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <button type='submit' className='btn btn-primary'>
                                                Submit
                                            </button>
                                            &nbsp;
                                            <button type='submit' className='btn bg-danger'>
                                                <i className='ri-creative-commons-sa-line'></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <button
                                                type='button'
                                                className='mt-2 btn btn-danger float-right'
                                            >
                                                Export
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>All CashFree in Reports</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>User Id</th>
                                                        <th>Name</th>
                                                        <th>Mobile No.</th>
                                                        <th>Transaction Method</th>
                                                        <th>Transaction Id</th>
                                                        <th>Transaction Date</th>
                                                        <th>Transaction Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>5200</td>
                                                        <td>MOHD MOHEED</td>
                                                        <td>7983190579</td>
                                                        <td>Cashfree</td>
                                                        <td>S4-Add-457489742902489</td>
                                                        <td>2022-08-28 14:59:38</td>
                                                        <td>100</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>5200</td>
                                                        <td>MOHD MOHEED</td>
                                                        <td>7983190579</td>
                                                        <td>Cashfree</td>
                                                        <td>S4-Add-457489742902489</td>
                                                        <td>2022-08-28 14:59:38</td>
                                                        <td>100</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>5200</td>
                                                        <td>MOHD MOHEED</td>
                                                        <td>7983190579</td>
                                                        <td>Cashfree</td>
                                                        <td>S4-Add-457489742902489</td>
                                                        <td>2022-08-28 14:59:38</td>
                                                        <td>100</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>5200</td>
                                                        <td>MOHD MOHEED</td>
                                                        <td>7983190579</td>
                                                        <td>Cashfree</td>
                                                        <td>S4-Add-457489742902489</td>
                                                        <td>2022-08-28 14:59:38</td>
                                                        <td>100</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>5200</td>
                                                        <td>MOHD MOHEED</td>
                                                        <td>7983190579</td>
                                                        <td>Cashfree</td>
                                                        <td>S4-Add-457489742902489</td>
                                                        <td>2022-08-28 14:59:38</td>
                                                        <td>100</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>User Id</th>
                                                        <th>Name</th>
                                                        <th>Mobile No.</th>
                                                        <th>Transaction Method</th>
                                                        <th>Transaction Id</th>
                                                        <th>Transaction Date</th>
                                                        <th>Transaction Amount</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
