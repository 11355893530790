import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Input } from 'antd';
import { BannerAPI } from '../api/bannerAPI';
import Switch from '@mui/material/Switch';


const label = { inputProps: { 'aria-label': 'Switch demo' } };

function ViewPopUpBannerPage() {
    const [dataArray,setDataArray] =useState([]);
    const [active,setActive] =useState([]);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        getAllBanners();

    }, []);

    const getAllBanners = () => {
        BannerAPI.GetBanner().then((res)=>{
            console.log(res);
            if(res.status_code){
                setDataArray(res.Data);
            }
            else if(res.status_code==false){
                Notiflix.Notify.success(res);
            }

        });
    };

    const activeChange = (e,bannerId)=>{
        
        // setActive(e.target.checked);
        // console.log(e.target.checked);
        // console.log(bannerId);
        let active;
        const json = {
            banner_id: bannerId,
            active: e.target.checked
        };
        console.log(json);
        BannerAPI.UpdateBannerStatus(json).then((res)=>{
            getAllBanners();


        });
    };

    const deleteBanner = (bannerId)=>{

        const json ={
            banner_id:bannerId
        };

        BannerAPI.DeleteBannerPopUp(json).then((res)=>{
            console.log(res);
            if(res.status_code){
                Notiflix.Notify.success(res.message);
                getAllBanners();

            }
            else{
                Notiflix.Notify.failure('Error Please Try Again!!!!');
            }


        });


    };

    return (
        <div className='content-page rtl-page'>
            {/* <div className='container-fluid'>
                
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                       
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                         

                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>

                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <CSVLink
                                                filename='View All Series'
                                                data={dataArray}
                                                headers={headers}
                                            >
                                                <button
                                                    onClick={handleExport}
                                                    className='dropdown-item'
                                                    href='#'
                                                >
                                                    Export This Data
                                                </button>
                                            </CSVLink>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>View All Pop-Up Banners</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table className='table  table-striped table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Banner Id</th>
                                                <th>image</th>
                                                <th>Max Count</th>
                                                <th>Time Interval</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Date</th>
                                                <th>Activate / Deactivate </th>
                                                <th>Delete </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataArray &&
                                                dataArray.length > 0 &&
                                                dataArray.map((name, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{++ind}</td>
                                                            <td>{name.banner_id}</td>
                                                            <td><img src={name.image} height={'150px'}/></td>
                                                            <td>{name.max_count}</td>
                                                            <td>{name.time_interval}</td>
                                                            <td>{name.start_time}</td>
                                                            <td>{name.end_time}</td>
                                                            <td>{name.select_date}</td>
                                                            <td> <Switch {...label} value={active} checked={name.active} onChange={(e)=>activeChange(e,name.banner_id)}  /></td>
                                                            <td> <button type="button"
                                                                className="btn btn-sm bg-danger" onClick={()=>deleteBanner(name.banner_id)}> Delete</button> </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}

export default ViewPopUpBannerPage;
