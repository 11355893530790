import { useEffect, useState } from 'react';
import { PanAPI } from '../api/verifyPanApi';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';

export default function VerifyPanPage() {
    const [panVerify, setPanVerify] = useState();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [pageSize, setPageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(1);
    const [entries, setEntries] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [totalLength, setTotalLength] = useState(1);

    const [previousPage, setPreviousPage] = useState(0);

    const [panNumber, setPanNumber] = useState('');
    const [userId, setUserId] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        panFilter();
    }, []);

    // Pagination ---------------

    useEffect(() => {
        panFilter();
    }, [pageNumber]);
    // ---------------------------

    const subtitleChange = (e) => {
        setIsLoading(true);
        // console.log(e.target.value)
        // setTitle(e.target.value)
        panInfo(e.target.value);
    };

    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };
    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    // const HandlePaginate = (item, index) => {
    //     // console.log(index)
    //     setActiveIndex(index)
    //     setPagenumber(item)
    //     // console.log(item)
    // }

    const panInfo = () => {
        PanAPI.GetPanInfo(pageSize, pageNumber).then((data) => {
            var pan_i = JSON.parse(JSON.stringify(data));
            console.log(pan_i.user);
            if (data.status_code === true) {
                setPanVerify(pan_i.user);
                setIsLoading(false);
                setTotalPages(data.total_page);
                setTotalLength(data.total_length);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const panFilter = () => {
        PanAPI.FilterPanInfo(
            entries,
            pageNumber,
            name,
            email,
            phone,
            panNumber,
            userId,
            status
        ).then((data) => {
            var panInfo_i = JSON.parse(JSON.stringify(data));
            console.log(panInfo_i);
            setPanVerify(panInfo_i.user);
            setTotalPages(data.total_page);
            setTotalLength(data.total_length);
            setIsLoading(false);
        });
    };

    const nameChange = (e) => {
        setName(e.target.value);
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
    };

    const panNumberChange = (e) => {
        setPanNumber(e.target.value);
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
    };

    // const startDateChange = (e) => {
    //     setStartDate(e.target.value)
    // }

    // const endDateChange = (e) => {
    //     setEndDate(e.target.value)
    // }

    const handleUserId = (e) => {
        setUserId(e.target.value);
    };

    const Reset = () => {
        setName('');
        setEmail('');
        setPhone('');
        setPanNumber('');
        setUserId('');
        panInfo();
    };

    // const handleDate = (e) => {
    //     const time = moment(e).format('hh:mm:s a')
    //     const date = moment(e).format('DD/MM/YYYY')
    //     return `${date} | ${time}`
    // }

    const handleStatus = (e) => {
        setStatus(e.target.value);
        console.log(e.target.value);
    };

    const ExportAllData = () => {};

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Status :</label>

                                                <select
                                                    className='form-control mb-3'
                                                    onChange={handleStatus}
                                                >
                                                    <option value='Approved'>Approved</option>
                                                    <option value='Not-Uploaded'>
                                                        Not-Uploaded
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Pan Name :</label>
                                                <input
                                                    onChange={nameChange}
                                                    value={name}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Enter Username'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='userId'>User Id :</label>
                                                <input
                                                    onChange={handleUserId}
                                                    value={userId}
                                                    type='numner'
                                                    className='form-control'
                                                    placeholder='Enter User ID'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Pan Number :</label>
                                                <input
                                                    onChange={panNumberChange}
                                                    value={panNumber}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Enter Pan Number'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Email Id :</label>
                                                <input
                                                    onChange={emailChange}
                                                    value={email}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Enter Email Id'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Mobile :</label>
                                                <input
                                                    onChange={phoneChange}
                                                    value={phone}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Enter Mobile No.'
                                                />
                                            </div>

                                            <div className='col' style={{ paddingTop: '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    onClick={() => panFilter()}
                                                    type='button'
                                                    className='btn btn-primary'
                                                >
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button onClick={() => Reset()} type="submit" className="btn bg-danger"><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input
                                                    type='reset'
                                                    className='btn btn-danger'
                                                    onClick={() => Reset()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button
                                                onClick={ExportAllData}
                                                className='dropdown-item'
                                                href='#'
                                            >
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                    <RotatingLines
                                        marginTop='40'
                                        strokeColor='#c1030d'
                                        strokeWidth='5'
                                        animationDuration='0.75'
                                        width='50'
                                        visible={true}
                                    />
                                </div>
                            </div>
                        )}

                        {!isLoading && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            <th>Pan Name</th>
                                                            <th>Email</th>
                                                            <th>Mobile No.</th>
                                                            <th>DOB</th>
                                                            <th>PAN Number</th>
                                                            <th>Status</th>
                                                            {/* <th>Date</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {panVerify &&
                                                            panVerify.length > 0 &&
                                                            panVerify.map((pan, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{++i}</td>
                                                                            <td>{pan.user_id}</td>
                                                                            <td>{pan.pan_name}</td>
                                                                            <td>{pan.email}</td>
                                                                            <td>{pan.phone}</td>
                                                                            <td>
                                                                                {pan.date_of_birth}
                                                                            </td>
                                                                            <td>
                                                                                {pan.pan_number}
                                                                            </td>
                                                                            <td>
                                                                                {pan.isPanverify}
                                                                            </td>
                                                                            {/* <td>{handleDate(pan.date)}</td> */}
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 d-flex align-items-center'>
                                        <label className='p-2' htmlFor='name'>
                                            Show
                                        </label>
                                        <select
                                            // onChange={changePageSize}
                                            className='form-control m-2 mb-3 col-2 d-flex align-items-center'
                                        >
                                            <option selected>50</option>
                                        </select>
                                        <nav aria-label='Page navigation example '>
                                            <ul className='pagination'>
                                                <li className='page-item' onClick={handlePrevious}>
                                                    <a
                                                        className='page-link'
                                                        // href="#"
                                                        aria-label='Previous'
                                                    >
                                                        <span aria-hidden='true'>&laquo;</span>
                                                        <span className='sr-only'>Previous</span>
                                                    </a>
                                                </li>
                                                <li
                                                    className='page-item active'
                                                    // onClick={() => {
                                                    //     HandlePaginate(item, i);
                                                    // }}
                                                >
                                                    <button className='page-link'>
                                                        {pageNumber}
                                                    </button>
                                                </li>

                                                {/* {page.map((item, i) => (
                                                <li
                                                    className={`page-item  
                                                    ${activeIndex == i ? "active" : " "
                                                        }`}
                                                    onClick={() => {
                                                        HandlePaginate(item, i);
                                                    }}
                                                >
                                                    <button className="page-link">{item}</button>
                                                </li>
                                            ))} */}

                                                <li
                                                    className='page-item'
                                                    onClick={() => {
                                                        handleNext();
                                                    }}
                                                >
                                                    <a className='page-link' aria-label='Next'>
                                                        <span aria-hidden='true'>&raquo;</span>
                                                        <span className='sr-only'>Next</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h5 className='d-flex p-2 mb-3'>
                                                of {totalPages} Pages
                                            </h5>

                                            <h5 className='d-flex p-2 mb-3'>
                                                and {totalLength} users
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
