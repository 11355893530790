import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const PrvtContest = {


    // CreateTicket: async (jsonbody) => {
    //     return await fetch(baseURL + 'createSupport', {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json',
    //             'x-api-key': key,
    //             'mai-hu': 'motaBhai007'
    //         },
    //         body: jsonbody,
    //     })
    //         .then((response) => response.json())
    //         .then((json) => {
    //             if (json != null) {
    //                 return json;
    //             } else return false;
    //         })
    //         .catch((err) => {
    //             console.log('Some error occured, please retry');
    //             console.log(err);
    //             return false;
    //         });
    // },

    GetAllMatches: () => {
        return BASEAPIS.GETAPI('getMatchWithPvtContest');
    },

    GetAllFilteredMatches: (matchName, matchStartDate) => {
        return BASEAPIS.GETAPI('getMatchWithPvtContest?MatchName=' + matchName + '&MatchStartDate=' + matchStartDate);
    },

    GetAllPrivateContestMatches: (matchid) => {
        return BASEAPIS.GETAPI('getAllPvtContestinMatch?matchId=' + matchid);
    },

    GetAllMatchesInfo: (userId, matchId, pool) => {
        console.log(userId, matchId);
        return BASEAPIS.GETAPI('pvtContestUserTeamInfo?UserID=' + userId + '&MatchId=' + matchId + '&contestPoolId=' + pool);
    },

    GetAllUserMatchesInfo: (poolId, matchID,fan) => {
        console.log(poolId, matchID);
        return BASEAPIS.GETAPI('getAllUsersInPvtContest?contestPoolId=' + poolId + '&matchId=' + matchID + '&fantasy='+fan);
    },

    // GetAllMatchesInfo
    // 

    // verifyUserDetail: (phoneNumber, emailId) => {
    //     return BASEAPIS.GETAPI('verifyUserInCase?email=' + emailId + '&phone=' + phoneNumber);
    // },

    // getAllCases: (pageNumber, pageSize) => {
    //     return BASEAPIS.GETAPI('getAllCaseList?pageNumber=' + pageNumber + '&entries=' + pageSize);
    // },

    // filterAllCase: (filEmailStatus, filCaseSource, filPriority, filCaseType, filStatus, filCreatedBy, filCaseID, filPhoneNumber, filEmailID) => {
    //     return BASEAPIS.GETAPI('getAllCaseList?priority=' + filPriority + '&case_type=' + filCaseType + '&status=' +
    //         filStatus + '&created_by=' + filCreatedBy + '&email=' + filEmailID + '&phone=' + filPhoneNumber +
    //         '&case_number=' + filCaseID + '&read=' + filEmailStatus + '&source=' + filCaseSource);
    // },

    // upDateTicketStatusCases: (jsonBody) => {
    //     return BASEAPIS.PUTAPI(jsonBody, 'updateSupportStatus');
    // },

    // updateEmailStatus: (jsonBody) => {
    //     return BASEAPIS.PUTAPI(jsonBody, 'updateSupportReadField');
    // }
};
