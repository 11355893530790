import React, { useState, useEffect } from 'react';
import { SeriesAPI } from '../api/seriesApi';
import { Link } from 'react-router-dom';
import Notiflix from 'notiflix';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function ViewSeriesPage() {
    const [dataArray, setArray] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [seriesname, setSeriesName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [editSeries, setSeriesEdit] = useState('');
    const [editSDate, setSDateEdit] = useState('');
    const [editEDate, setEDateEdit] = useState('');
    const [seriesId, setSeriesId] = useState('');
    const [seriesStatus, setSeriesStatus] = useState('');

    const role = localStorage.getItem('role');

    const [updateStatusCount, setUpdateStatusCount] = useState(0);

    useEffect(() => {
        GetSeries();
    }, [currentPage, seriesname, startDate, endDate, seriesStatus, updateStatusCount]);

    const updateStatus = (seriesId, status) => {
        setUpdateStatusCount((count) => count + 1);
        console.log(updateStatusCount);
        const myStatus = status === 'active' ? 'inactive' : 'active';
        let updateData = {
            cid: seriesId,
            status: myStatus,
        };
        SeriesAPI.UpdateSeriesStatus(updateData).then((data) => {
            console.log(data.message);
        });
    };

    const GetSeries = () => {
        SeriesAPI.FilterSeries(seriesname, startDate, endDate, seriesStatus).then((data) => {
            if (data.status_code === true) {
                setArray(data.data);
            }
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataArray.slice(indexOfFirstItem, indexOfLastItem);

    const pageCount = Math.ceil(dataArray.length / itemsPerPage);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 1; i <= pageCount; i++) {
            pageNumbers.push(
                <li key={i} className={currentPage === i ? 'active' : null}>
                    <button className="page-link" onClick={() => handlePageClick(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return pageNumbers;
    };

    const seriesChange = (e) => {
        setSeriesName(e.target.value);
    };

    const SdateChange = (e) => {
        setStartDate(e.target.value);
    };

    const EdateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleStatusChange = (e) => {
        setSeriesStatus(e.target.value);
    };

    const EditSeriesChange = (e) => {
        setSeriesEdit(e.target.value);
    };

    const EditSDateChange = (e) => {
        setSDateEdit(e.target.value);
    };

    const EditEDateChange = (e) => {
        setEDateEdit(e.target.value);
    };
    
    const EditView = (sid, series, sdate, edate) => {
        setSeriesId(sid);
        setSeriesEdit(series);
        setSDateEdit(sdate);
        setEDateEdit(edate);
    };

    const EditSubmit = () => {
        if (editSeries == '' || editSeries == null)
            Notiflix.Notify.failure('Series name can\'t blank');
        else if (editSDate == '' || editSDate == null)
            Notiflix.Notify.failure('Start date can\'t blank');
        else if (editEDate == '' || editEDate == null)
            Notiflix.Notify.failure('End date can\'t blank');
        else {
            let data = {
                cid: seriesId,
                title: editSeries,
                datestart: editSDate,
                dateend: editEDate,
            };
            SeriesAPI.EditSeries(data).then((data) => {
                Notiflix.Notify.success(data.message);
            });
        }
    };

    const [importLoad, setImportLoad] = useState(false);

    const importSeriesPOST = () => {
        setImportLoad(true);
        SeriesAPI.StoreSeries().then((response) => {
            if (response.status_code) {
                Notiflix.Notify.success('Series Imported Successfully');
                setImportLoad(false);
            } else {
                Notiflix.Notify.success('Something Went Wrong');
                setImportLoad(false);
            }
        });
    };



    return (
        <div className="content-page rtl-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="col">
                                        <label htmlFor="name">Series Status</label>
                                        <select
                                            className="form-control mb-3"
                                            onChange={handleStatusChange}
                                            value={seriesStatus}
                                        >
                                            <option value="">All</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="name">Series Name :</label>
                                        <input
                                            type="text"
                                            value={seriesname}
                                            onChange={seriesChange}
                                            className="form-control"
                                            placeholder="Search By Name"
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="name">From :</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={startDate}
                                            onChange={SdateChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="name">To :</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={endDate}
                                            onChange={EdateChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className='card'>
                            <div style={{ padding: 12 }}>
                                <button
                                    style={{ border: 'none', width: 220, backgroundColor: 'purple', color: 'white', paddingTop: 12, paddingBottom: 12, paddingLeft: 18, paddingRight: 18, borderRadius: 6 }} onClick={importSeriesPOST}>
                                    IMPORT SERIES
                                    {importLoad ?
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'white', marginLeft: 10 }} spin />} />
                                        :
                                        ''
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">View all series</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table  table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Name of the Tournament</th>
                                                <th>Start Date</th>
                                                <th>End date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems.map((name, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>
                                                            <Link
                                                                to="/series_match"
                                                                state={{
                                                                    seriesCID: name.cid,
                                                                }}
                                                            >
                                                                {name.title}
                                                            </Link>
                                                        </td>
                                                        <td>{name.datestart}</td>
                                                        <td>{name.dateend}</td>
                                                        <td>{name.series_status}</td>
                                                        {role != 3 && role != 5 && <td>
                                                            <button
                                                                title='Edit'
                                                                type='button'
                                                                onClick={() =>
                                                                    EditView(
                                                                        name._id,
                                                                        name.title,
                                                                        name.datestart,
                                                                        name.dateend
                                                                    )
                                                                }
                                                                className='btn btn-sm btn-skyblue'
                                                                data-toggle='modal'
                                                                data-target='#exampleModalScrollable'
                                                            >
                                                                <i className='ri-edit-line'></i>
                                                            </button>
                                                            &nbsp;
                                                            <button
                                                                type='button'
                                                                className='btn btn-sm'
                                                                style={{
                                                                    backgroundColor:
                                                                        name.series_status ===
                                                                            'inactive'
                                                                            ? 'green'
                                                                            : 'red',
                                                                    color: 'white',
                                                                }}
                                                                onClick={(e) => {
                                                                    (
                                                                        window.confirm(
                                                                            `Do you want to ${name.series_status ===
                                                                                'inactive'
                                                                                ? 'Active'
                                                                                : 'Inactive'
                                                                            } this Series ?`
                                                                        )
                                                                            ? updateStatus(
                                                                                name.cid,
                                                                                name.series_status
                                                                            )
                                                                            : e.preventDefault()
                                                                    );
                                                                }}
                                                            >
                                                                {name.series_status ===
                                                                    'inactive'
                                                                    ? 'Active'
                                                                    : 'Inactive'}
                                                            </button>
                                                        </td>}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Pagination Start */}
                            {/* Pagination */}
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageClick(1)}
                                            disabled={currentPage === 1}
                                        >
                                            First
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageClick(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    {renderPageNumbers()}
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageClick(currentPage + 1)}
                                            disabled={currentPage === pageCount}
                                        >
                                            Next
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageClick(pageCount)}
                                            disabled={currentPage === pageCount}
                                        >
                                            Last
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                            {/* End Pagination */}
                            {/* Pagination End */}

                            <div
                                className='modal fade'
                                id='exampleModalScrollable'
                                tabIndex='-1'
                                role='dialog'
                                aria-labelledby='exampleModalScrollableTitle'
                                aria-hidden='true'
                            >
                                <div className='modal-dialog modal-dialog-scrollable' role='document'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                                Edit Series
                                            </h5>
                                            <button
                                                type='button'
                                                className='close'
                                                data-dismiss='modal'
                                                aria-label='Close'
                                            >
                                                <span aria-hidden='true'>&times;</span>
                                            </button>
                                        </div>
                                        <div className='modal-body'>
                                            <form>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Series Id :</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        value={seriesId}
                                                        disabled
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Series Name :</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        value={editSeries}
                                                        onChange={EditSeriesChange}
                                                        placeholder='Chennai Super King'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>From :</label>
                                                    <input
                                                        type='date'
                                                        className='form-control'
                                                        disabled
                                                        value={editSDate}
                                                        onChange={EditSDateChange}
                                                        id=''
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>To :</label>
                                                    <input
                                                        type='date'
                                                        className='form-control'
                                                        disabled
                                                        value={editEDate}
                                                        onChange={EditEDateChange}
                                                        id=''
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div className='modal-footer'>
                                            <button
                                                type='button'
                                                className='btn btn-secondary'
                                                data-dismiss='modal'
                                            >
                                                Close
                                            </button>
                                            <button
                                                type='button'
                                                className='btn btn-primary'
                                                onClick={EditSubmit}
                                            >
                                                Save changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewSeriesPage;
