import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { Link, NavLink } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { fundManager } from '../api/FundsApi';
import moment from 'moment';
import { CSVLink } from 'react-csv';

export default function TotalDeposit() {
    const [depositData, setDepositData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCustomDates, setIsCustomDates] = useState(false);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [currentPage, setCurrentPage] = useState(1);
    const [amount, setAmount] = useState(0);
    const [totalCountDeposit, setTotalCountDeposit] = useState();
    const itemsPerPage = 20;

    useEffect(() => {
        // if (isCustomDates) {
        getAllDepositsData();
        // }
    }, []);

    const getAllDepositsData = () => {
        setIsLoading(true);
        fundManager.DepositAPI(startDate, endDate, amount).then(({ status_code, data }) => {
            if (status_code) {
                setDepositData(data);
                console.log(data);
                setIsLoading(false);
                let totalAmount = 0;
                for (let i = 0; i < data.length; i++) {
                    if (!isNaN(parseInt(data[i].deposit_amount))) {
                        totalAmount += parseInt(data[i].deposit_amount);
                    } else {
                        console.log('Invalid deposit amount:', data[i].deposit_amount);
                    }
                }
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const handleTime = (e) => moment(e?.slice(11, 18), 'HH:mm:ss').format('hh:mm A');
    const handleDate = (date) => date?.slice(0, 10);

    const handleTodayDeposit = () => {
        const todayStartDate = moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD');
        const todayEndDate = moment().format('YYYY-MM-DD');
        setStartDate(todayStartDate);
        setEndDate(todayEndDate);
        // setIsCustomDates(true);
    };

    const handleLastWeekDeposit = () => {
        const lastWeekStartDate = moment().startOf('week').subtract(1, 'week').format('YYYY-MM-DD');
        const lastWeekEndDate = moment().format('YYYY-MM-DD');
        setStartDate(lastWeekStartDate);
        setEndDate(lastWeekEndDate);
        // setIsCustomDates(true);
    };

    const handleReset = () => {
        setIsCustomDates(false);
        setStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        getAllDepositsData();
    };


    const handleSubmit = () => {
        getAllDepositsData();
    };

    const pageNumbers = Array.from(
        { length: Math.ceil(depositData.length / itemsPerPage) },
        (_, i) => i + 1
    );

    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= pageNumbers.length) {
            setCurrentPage(pageNumber);
        }
    };

    const pageRange = 2; // Number of pages to show before and after the current page
    const startPage = Math.max(1, currentPage - pageRange);
    const endPage = Math.min(startPage + pageRange * 2, pageNumbers.length);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = depositData.slice(indexOfFirstItem, indexOfLastItem);

    const paginationButtons = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
    );

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'User Joined Date', key: 'user_joined_date' },
        { label: 'State', key: 'state' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'Deposit Amount', key: 'deposit_amount' },
        { label: 'Deposit Count', key: 'deposit_count' },
        { label: 'First Deposit Date', key: 'first_deposit_date' },
        { label: 'Last Deposit Date', key: 'last_deposit_date' },
    ];

    const handleFinalAmount = (gst, total) => {
        return Math.floor(parseFloat(total)) - Math.floor(parseFloat(gst));
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div>
                        <div className='div-proper-deposit'>
                            <h1 className='coupon_h1-h1-h1'>Deposit Report</h1>
                            <h1 className='coupon_h1-h1-h1-h1'>{`${startDate} - ${endDate}`}</h1>
                            <h1 className='coupon_h1-h1-h1-h1-h1'>
                                Total Deposit - ₹ {parseInt(totalCountDeposit)}
                            </h1>
                        </div>
                        <div className='shatkon-refer-deposit'>
                            <div className='main-ref-ral-div'>
                                <div>
                                    <button
                                        onClick={handleTodayDeposit}
                                        className='button-report-referral'
                                    >
                                        Today's Deposits
                                    </button>
                                    <button
                                        onClick={handleLastWeekDeposit}
                                        className='button-report-referral'
                                    >
                                        Last Week Deposit
                                    </button>
                                    {isCustomDates ? (
                                        <div className='main-ref-ral-div'>
                                            <input
                                                onChange={(e) => setStartDate(e.target.value)}
                                                type='date'
                                                className='button-report-referrals'
                                            />
                                            <input
                                                onChange={(e) => setEndDate(e.target.value)}
                                                type='date'
                                                className='button-report-referrals'
                                            />
                                            {/* <button onClick={getAllDepositsData} className='button-report-referral'>Search</button> */}
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => setIsCustomDates(true)}
                                            className='button-report-referral'
                                        >
                                            Custom Date Inputs
                                        </button>
                                    )}

                                    <input
                                        onChange={(e) => setAmount(e.target.value)}
                                        value={amount}
                                        className='input-report-referral'
                                        Custom
                                        Date
                                        Inputs
                                    />
                                    {/* <input
                                        type='texmatch'
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                        }}
                                        className='form-control'
                                        placeholder='Search By Name'
                                    /> */}

                                    <button
                                        onClick={handleSubmit}
                                        className='button-report-referral-2'
                                    >
                                        Submit
                                    </button>


                                    <button
                                        onClick={handleReset}
                                        className='button-report-referral-2'
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <div className='link-csv-div'>
                                <CSVLink filename='Deposit Report' data={depositData} headers={headers}>
                                    <button className='button-report-referral-2'>
                                        Download CSV File
                                    </button>
                                </CSVLink>

                                <Link to='/first-time-deposit-report' className='button-report-referral-2'>
                                    First Time Deposit Report
                                </Link>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body '>
                                        <div className='div-liread-get'>
                                            <div className='col'>
                                                <div className='row'>
                                                    <div className='col-sm-12 col-lg-12'>
                                                        {isLoading ? (
                                                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                                <RotatingLines
                                                                    marginTop='160'
                                                                    marginBottom='160'
                                                                    strokeColor='#000000'
                                                                    strokeWidth='3'
                                                                    animationDuration='0.75'
                                                                    width='50'
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className='card'>
                                                                <div className='card-body'>
                                                                    <div>
                                                                        <div className='table-responsive'>
                                                                            <table className='table table-striped table-bordered'>
                                                                                <thead>
                                                                                    <tr className='support_main_file_div'>
                                                                                        <th>
                                                                                            S.No.
                                                                                        </th>
                                                                                        <th>
                                                                                            User Id
                                                                                        </th>
                                                                                        <th>
                                                                                            Team
                                                                                            Name
                                                                                        </th>
                                                                                        <th>
                                                                                            Phone
                                                                                        </th>
                                                                                        <th>
                                                                                            Deposit
                                                                                            Amount
                                                                                        </th>
                                                                                        <th>
                                                                                            Deposit
                                                                                            Count
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {currentItems &&
                                                                                        currentItems?.length >
                                                                                        0 &&
                                                                                        currentItems?.map(
                                                                                            (
                                                                                                deposit,
                                                                                                i
                                                                                            ) => (
                                                                                                <tr
                                                                                                    key={
                                                                                                        i
                                                                                                    }
                                                                                                >
                                                                                                    <td>
                                                                                                        {
                                                                                                            ++i
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            deposit.user_id
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            deposit.team_name
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            deposit.phone
                                                                                                        }
                                                                                                    </td>
                                                                                                    {/* <td>{handleDate(deposit.createdAt)}</td>
                                                                                                <td>{handleTime(deposit.createdAt)}</td> */}
                                                                                                    <td>
                                                                                                        {
                                                                                                            deposit.deposit_amount
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            deposit.deposit_count
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='client-pagi-div'>
                                                        <div className='pagination'>
                                                            {currentPage > 1 && (
                                                                <>
                                                                    <button
                                                                        onClick={() => paginate(1)}
                                                                    >
                                                                        1
                                                                    </button>
                                                                    {currentPage > 2 && (
                                                                        <span>...</span>
                                                                    )}
                                                                </>
                                                            )}
                                                            {paginationButtons.map((number) => (
                                                                <button
                                                                    key={number}
                                                                    onClick={() => paginate(number)}
                                                                    className={
                                                                        currentPage === number
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                >
                                                                    {number}
                                                                </button>
                                                            ))}
                                                            {currentPage <
                                                                pageNumbers.length - 1 && (
                                                                <span>...</span>
                                                            )}
                                                            {currentPage < pageNumbers.length && (
                                                                <button
                                                                    onClick={() =>
                                                                        paginate(pageNumbers.length)
                                                                    }
                                                                >
                                                                    {pageNumbers.length}
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div className='total-entries'>
                                                            Total Entries: {depositData.length}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
