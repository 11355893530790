import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { RoyaltyPointsApi } from '../api/royalPointsApi';
import swal from 'sweetalert';
import Notiflix from 'notiflix';

export default function RoyaltyPointsPage() {
    const [pointsData, setPointsData] = useState([]);
    const [editPoint, setEditPoint] = useState('');
    const [editCat, setEditCat] = useState('');
    const [createCategory, setCreateCategory] = useState('');
    const [createPoints, setCreatePoints] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetRoyalPoint();
    }, []);

    const GetRoyalPoint = () => {
        RoyaltyPointsApi.GetRoyalPoint().then((data) => {
            if (data.Status) setPointsData(data.Data);
            console.log(data.Data);
        });
    };

    const UpdateRoyalPoint = () => {
        RoyaltyPointsApi.UpdateRoyalPoint(id, editPoint).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Done');
                GetRoyalPoint();
            } else if (!data.Status) {
                Notiflix.Notify.failure('Error');
            }
        });
    };

    const editSubmit = () => {
        UpdateRoyalPoint();
    };

    const createCategoryHandler = () => {
        const json = {
            royalCategory: createCategory,
            royalPoint: createPoints,
        };

        RoyaltyPointsApi.CreateRoyalPointCategory(json).then((data) => {
            if (data.Status) {
                Notiflix.Notify.success('Done');
                setCreateCategory('');
                setCreatePoints('');

                GetRoyalPoint();
            } else if (!data.Status) {
                Notiflix.Notify.failure('Error');
            }
        });
    };

    const EditRoyaltyHandler = (data) => {
        console.log(data);
        setId(data._id);
        setEditPoint(data.royalPoint);
        setEditCat(data.royalCategory);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>Royalty Points</h4>
                            </div>
                        </div>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <button
                                        title='Edit'
                                        type='button'
                                        className='btn btn btn-skyblue'
                                        data-toggle='modal'
                                        data-target='#exampleModalScrollable1'
                                    >
                                        <i className='ml-2 ri-add-line'></i>Create Royalty Category
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className='modal fade'
                            id='exampleModalScrollable1'
                            tabIndex='-1'
                            role='dialog'
                            aria-labelledby='exampleModalScrollableTitle'
                            aria-hidden='true'
                        >
                            <div className='modal-dialog modal-dialog-scrollable' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h5
                                            className='modal-title'
                                            id='exampleModalScrollableTitle'
                                        >
                                            Create Royalty Category
                                        </h5>
                                        <button
                                            type='button'
                                            className='close'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            <span aria-hidden='true'>&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <form>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Royalty Category Name</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={createCategory}
                                                    onChange={(e) => {
                                                        setCreateCategory(e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Royalty Points </label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id=''
                                                    value={createPoints}
                                                    onChange={(e) => {
                                                        setCreatePoints(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-secondary'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                        <button
                                            onClick={createCategoryHandler}
                                            type='button'
                                            className='btn btn-primary'
                                            data-dismiss='modal'
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Category</th>
                                                <th>Royalty Points</th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pointsData.map((point, i) => {
                                                return (
                                                    <tr>
                                                        <td>{++i}</td>
                                                        <td>{point.royalCategory}</td>
                                                        <td>
                                                            {point.royalPoint
                                                                ? point.royalPoint
                                                                : 0}
                                                        </td>
                                                        <td>
                                                            {/* <button type="button" className="btn btn-skyblue">Edit</button> */}
                                                            <button
                                                                onClick={() => {
                                                                    EditRoyaltyHandler(point);
                                                                }}
                                                                title='Edit'
                                                                type='button'
                                                                className='btn btn-sm btn-skyblue'
                                                                data-toggle='modal'
                                                                data-target='#exampleModalScrollable'
                                                            >
                                                                <i className='ri-edit-line'>
                                                                    {' '}
                                                                    Add / Edit
                                                                </i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade'
                    id='exampleModalScrollable'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalScrollableTitle'
                    aria-hidden='true'
                >
                    <div className='modal-dialog modal-dialog-scrollable' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                    Edit Royalty Points
                                </h5>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Category</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            disabled
                                            value={editCat}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Edit Points</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={editPoint}
                                            onChange={(e) => {
                                                setEditPoint(e.target.value);
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    type='button'
                                    className='btn btn-secondary'
                                    data-dismiss='modal'
                                >
                                    Close
                                </button>
                                <button
                                    onClick={editSubmit}
                                    type='button'
                                    className='btn btn-primary'
                                    data-dismiss='modal'
                                >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='modal fade'
                id='exampleModalScrollable'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalScrollableTitle'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-scrollable' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                Edit Series
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form>
                                <div className='form-group'>
                                    <label htmlFor='name'>Series Id :</label>
                                    <input type='text' className='form-control' disabled />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>Series Name :</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Chennai Super King'
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>Start Date :</label>
                                    <input type='date' className='form-control' disabled id='' />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='name'>End Date :</label>
                                    <input type='date' className='form-control' disabled id='' />
                                </div>
                            </form>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                data-dismiss='modal'
                            >
                                Close
                            </button>
                            <button type='button' className='btn btn-primary'>
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </div>
        // </div>
    );
}
