import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { UsersAPI } from '../api/userApi';
import { RotatingLines } from 'react-loader-spinner';

export default function UsersWalletsPage() {
    const [report, setReport] = useState();
    const [user_id, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [isLaoding, setIsLoading] = useState(true);
    const [teamName, setTeamName] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
        fetchWalletReport();
    }, []);

    const fetchWalletReport = () => {
        UsersAPI.WalletReport().then((data) => {
            var walletData_i = JSON.parse(JSON.stringify(data));
            //  console.log(feedData_i.message[0])
            if (data.status_code) {
                setReport(walletData_i.message);
                console.log(walletData_i.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            setIsLoading(false);
        });
    };

    const filterWallet = () => {
        UsersAPI.FilterWalletReport(user_id, email, phone, teamName).then((data) => {
            setIsLoading(true);
            var filterData = JSON.parse(JSON.stringify(data));
            setReport(filterData.message);
            console.log(filterData.message);
            setIsLoading(false);
        });
    };

    const nameChange = (e) => {
        setTeamName(e.target.value);
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
    };

    const userChange = (e) => {
        setUserId(e.target.value);
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
    };

    const getCurrentDate = (date) => {
        const split = date.split(' ');
        const mydate = `${split[1]}-${split[0]}-${split[2]}`;
        return mydate;
    };

    const handleReset = () => {
        setIsLoading(true);
        setEmail('');
        setPhone('');
        setUserId('');
        setName('');
        setReport();
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    {/* <form> */}
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>User Id :</label>
                                            <input
                                                value={user_id}
                                                onChange={userChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By User Id'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Team Name:</label>
                                            <input
                                                value={teamName}
                                                onChange={nameChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Team Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Email Id :</label>
                                            <input
                                                value={email}
                                                onChange={emailChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Email Id'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Mobile :</label>
                                            <input
                                                onKeyDown={(evt) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                value={phone}
                                                onChange={phoneChange}
                                                type='number'
                                                className='form-control'
                                                placeholder='Search By Mobile No.'
                                            />
                                        </div>
                                        {/* <div className="col">
                                            <label htmlFor="name">Team Name :</label>
                                            <input type="text" className="form-control" placeholder="Search By Team Name" />
                                        </div> */}
                                    </div>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                onClick={() => filterWallet()}
                                                className='btn btn-primary'
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            <input
                                                type='reset'
                                                onClick={() => handleReset()}
                                                className='btn btn-danger'
                                            />
                                            {/* <input type="reset" className="btn btn-danger" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </form> */}

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLaoding && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                    <RotatingLines
                                        marginTop='40'
                                        strokeColor='#c1030d'
                                        strokeWidth='5'
                                        animationDuration='0.75'
                                        width='50'
                                        visible={true}
                                    />
                                </div>
                            </div>
                        )}

                        {!isLaoding && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>View Wallet Report</h4>
                                        </div>
                                    </div>

                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table data-table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            <th>User Name</th>
                                                            <th>Team Name</th>
                                                            <th>Email</th>
                                                            <th>Mobile No.</th>
                                                            <th>Current Date</th>
                                                            <th>Unutilized</th>
                                                            <th>Winning</th>
                                                            <th>Cash Bonus</th>
                                                            <th>Total Balance</th>
                                                            <th>Total Withdraw</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {report &&
                                                            report.length > 0 &&
                                                            report.map((data, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{++i}</td>
                                                                            <td>{data.user_id}</td>
                                                                            <td>{data.name}</td>
                                                                            <td>
                                                                                {data.team_name}
                                                                            </td>
                                                                            <td>{data.email}</td>
                                                                            <td>{data.phone}</td>
                                                                            <td>
                                                                                {getCurrentDate(
                                                                                    data.current_date
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {data.unutilized}
                                                                            </td>
                                                                            <td>{data.winning}</td>
                                                                            <td>{data.bonus}</td>
                                                                            <td>
                                                                                {data.total_balance}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    data.total_withdrawl
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
