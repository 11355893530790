import Notiflix from 'notiflix';
import { useState } from 'react';
import { useEffect } from 'react';
import { BonusAPI } from '../api/bonusApi';
import './Custom.css';

export default function GeneralPage() {
    const [bonus, setBonus] = useState();
    const [edit, setEdit] = useState();
    const [bonus_type, setBonus_Type] = useState('');
    const [amount, setAmount] = useState('');
    const [_id, set_Id] = useState('');
    // const [type, setType] = useState('')
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        bonusInfo('', '');
    }, []);

    const bonusInfo = (bonus, amount) => {
        BonusAPI.GetBonus(bonus, amount).then((data) => {
            var bonus_i = JSON.parse(JSON.stringify(data));
            //  console.log(bonus_i.message)
            if (data.status_code === true) {
                setBonus(bonus_i.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            } //  setType(data.message);
            //  console.log(bonus);
            //  console.log(dashboard_i[0].totalUser)
            // console.log(dashboard_i[0].totalUser
            // ,"hi");
        });
    };

    const filterSearch = (event) => {
        event.preventDefault();
        //    console.log(event.target[0].value)
        //    console.log(event.target[1].value)
        //bonusInfo();
        bonusInfo(event.target[0].value, event.target[1].value);
    };
    const deleteHandler = (id) => {
        console.log(id);
        BonusAPI.DeleteResponse(id).then((data) => {
            Notiflix.Notify.success('Successfully deleted!!');
            bonusInfo('', '');
        });
    };
    const editHandler = (event) => {
        event.preventDefault();
        let data = {
            bonus_type: bonus_type,
            amount: amount,
            _id: _id,
            is_deleted: false,
        };
        BonusAPI.EditResponse(data).then((data) => {
            Notiflix.Notify.success(data.message);
            console.log(data);
        });
        bonusInfo('', '');
        // console.log(event.target[0].value);
        // console.log(event.target[1].value);
        // console.log(event.target[2].value);
        // (BonusAPI.EditResponse(event.target[0].value,event.target[1].value,event.target[2].value).then(data => {

        //     console.log(data)

        // }))
    };
    const bonusChange = (e) => {
        setBonus_Type(e.target.value);
    };

    const amountChange = (e) => {
        setAmount(e.target.value);
    };
    //   const typeChange = (e) => {
    //        setType(e.target.value);
    //   }
    const EditView = (_id, bonus_type, amount) => {
        set_Id(_id);
        setBonus_Type(bonus_type);
        setAmount(amount);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <form onSubmit={filterSearch}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Select Type :</label>
                                                <select className='form-control mb-3' id='Bonus'>
                                                    <option value=''>Select Type</option>
                                                    <option value='sign-up bonus'>
                                                        Signup Bonus
                                                    </option>
                                                    {/* <option value="referral bonus">Refer Bonus</option> */}
                                                    <option value='Pan Bonus'>Pan Bonus</option>
                                                    <option value='Email Bonus'>Email Bonus</option>
                                                    <option value='Mobile Bonus'>
                                                        Mobile Bonus
                                                    </option>
                                                    <option value='Bank Bonus'>Bank Bonus</option>
                                                    {/* <option value="Refer Commission">Refer Commission</option> */}
                                                </select>
                                                &nbsp; &nbsp;
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Amount :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='Amount'
                                                    placeholder='Please Enter Amount'
                                                />
                                            </div>
                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary btn-primary-fix'
                                                >
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button type="submit" className="btn bg-danger" ><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input type='reset' className='btn btn-danger' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* Modal starts from here */}

                        <div
                            className='modal fade'
                            id='exampleModalScrollable'
                            tabIndex='-1'
                            role='dialog'
                            aria-labelledby='exampleModalScrollableTitle'
                            aria-hidden='true'
                        >
                            <div className='modal-dialog modal-dialog-scrollable' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h5
                                            className='modal-title'
                                            id='exampleModalScrollableTitle'
                                        >
                                            Edit Bonus
                                        </h5>
                                        <button
                                            type='button'
                                            className='close'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            <span aria-hidden='true'>&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <form>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Type Of Bonus :</label>
                                                <input
                                                    type='hidden'
                                                    className='form-control'
                                                    value={_id}
                                                />
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={bonus_type}
                                                    onChange={bonusChange}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Amount :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={amount}
                                                    onChange={amountChange}
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary mt-2'
                                                    onClick={editHandler}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-secondary'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Type</th>
                                                        <th>Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bonus &&
                                                        bonus.length > 0 &&
                                                        bonus.map((bonuss, i) => {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{++i}</td>
                                                                        <td>{bonuss.bonus_type}</td>
                                                                        <td>{bonuss.amount}</td>
                                                                        <td>
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            'Are you sure to delete?'
                                                                                        )
                                                                                            ? deleteHandler(
                                                                                                bonuss._id
                                                                                            )
                                                                                            : e.preventDefault()
                                                                                    );
                                                                                }}
                                                                                className='btn btn-danger btn-sm pr-1'
                                                                            >
                                                                                <i className='la fa fa-trash'></i>
                                                                            </button>
                                                                            <span></span>&nbsp;
                                                                            <button
                                                                                onClick={() =>
                                                                                    EditView(
                                                                                        bonuss._id,
                                                                                        bonuss.bonus_type,
                                                                                        bonuss.amount
                                                                                    )
                                                                                }
                                                                                type='button'
                                                                                className='btn btn-primary btn-sm btn-primary-fix pr-1'
                                                                                data-toggle='modal'
                                                                                data-target='#exampleModalScrollable'
                                                                            >
                                                                                <i className='la fa fa-edit'></i>
                                                                            </button>
                                                                            &nbsp;
                                                                            {/* <button className="btn btn-primary btn-sm"><i className="la fa fa-trash"></i></button> */}
                                                                            {/* <IconButton color="secondary" aria-label="delete"><DeleteIcon  onClick={()=>deleteHandler(bonuss._id)}/></IconButton> */}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
