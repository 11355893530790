import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { PrvtContest } from '../api/PvtContestAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useLocation } from 'react-router-dom';
import { TbCricket } from 'react-icons/tb';

export default function PrivateContestTeamInfo() {

    const location = useLocation();
    const prvCont_userId = location.state.PVTuserId;
    const prvCont_matchId = location.state.PVTmatchId;
    const prvCont_MatchName = location.state.matchName;
    const prvtCont_poolId = location.state.poolId;
    const prvtCont_matchStatus = location.state.matchStatus;
    const fantasy = location.state.fantasy;
    const [teamInfo, setTeamInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllMatchesDataInfo();
        console.log(fantasy);
    }, []);

    const getAllMatchesDataInfo = () => {
        setIsLoading(true);
        PrvtContest.GetAllMatchesInfo(prvCont_userId, prvCont_matchId, prvtCont_poolId).then((data) => {
            if (data.status_code === true) {
                setIsLoading(false);
                setTeamInfo(data.data[0].team);
                console.log(data.data[0].team);
                Notiflix.Notify.success('Successfully Fetched');
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const handleTeamRole = (captain, viceCaptain) => {
        if (captain) {
            return (
                <h6>Captain</h6>
            );
        } else if (viceCaptain) {
            return (
                <h6>Vice Captain</h6>
            );
        } else {
            return (
                <h6>Player</h6>
            );
        }
    };

    const handleTeamNameDivide = (team) => {
        const string = prvCont_MatchName.split('vs');
        const teamA = string[0];
        const teamB = string[1];
        if (team === 'a') {
            return teamA;
        } else {
            return teamB;
        }
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='offers_couponDiv-div'>
                            <TbCricket className='dashboard-icon' />
                            <h1 className='coupon_h1-h1'>{prvCont_MatchName}</h1>
                            <h4 className='offers-div-main-private-comnt'>{prvtCont_matchStatus}</h4>
                        </div>

                    </form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        {
                                            isLoading ? <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='70'
                                                        strokeColor='#c1030d'
                                                        strokeWidth='5'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                                : <div className='card'>
                                                    <div className='card-body'>
                                                        <div
                                                        >
                                                            <div className='table-responsive'>
                                                                {fantasy == 'cricket' && (<table className='table table-striped table-bordered'>
                                                                    <thead>
                                                                        <tr className='support_main_file_div'>
                                                                            <th>Index</th>
                                                                            <th>Player ID</th>
                                                                            <th>Team Id</th>
                                                                            <th>Player Name</th>
                                                                            <th>Player Short Name</th>
                                                                            <th>Playing Role</th>
                                                                            <th>Role</th>
                                                                            <th>Cricket Team Name</th>
                                                                            <th>Points Earned</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            teamInfo && teamInfo.map((team, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{++i}</td>
                                                                                        <td>{team.PlayerID}</td>
                                                                                        <td>{team.TeamID}</td>
                                                                                        <td>{team.PlayerName}</td>
                                                                                        <td>{team.PlayerShortName}</td>
                                                                                        <td>{team.PlayingRole}</td>
                                                                                        <td>{handleTeamRole(team.IsCaptain, team.IsViceCaptain)}</td>
                                                                                        <td>{handleTeamNameDivide(team.Team)}</td>
                                                                                        <td>{team.EarningPoint}</td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>)}





                                                                {fantasy == 'Prediction' && (<table className='table table-striped table-bordered'>
                                                                    <thead>
                                                                        <tr className='support_main_file_div'>
                                                                            {/* <th>Index</th>
                                                                            <th>Player ID</th>
                                                                            <th>Team Id</th>
                                                                            <th>Player Name</th>
                                                                            <th>Player Short Name</th>
                                                                            <th>Playing Role</th>
                                                                            <th>Role</th>
                                                                            <th>Cricket Team Name</th>
                                                                            <th>Points Earned</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            teamInfo && teamInfo.map((team, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        {/* <td>{++i}</td>
                                                                                        <td>{team.PlayerID}</td>
                                                                                        <td>{team.TeamID}</td>
                                                                                        <td>{team.PlayerName}</td>
                                                                                        <td>{team.PlayerShortName}</td>
                                                                                        <td>{team.PlayingRole}</td>
                                                                                        <td>{handleTeamRole(team.IsCaptain, team.IsViceCaptain)}</td>
                                                                                        <td>{team.Team}</td>
                                                                                        <td>{team.EarningPoint}</td> */}
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>)}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >);
}
