import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import { RotatingLines } from 'react-loader-spinner';

function TdsReport() {
    const [dataArray, setArray] = useState([]);
    const [reverseData, setReverseData] = useState([]);
    const [tdsData, setTdsData] = useState([]);
    const [startdateChange, setStartDateChange] = useState('');
    const [endDateChange, setEndDateChange] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);


    const Headers = [
        { label: 'Transaction ID', key: 'transaction_id' },
        { label: 'Pan Name', key: 'pan_name' },
        { label: 'Bank Name', key: 'bank_name' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Account ', key: 'accountNumber' },
        { label: 'Total Withdraw Amount', key: 'total_withdraw_amount' },
        { label: 'TDS Amount', key: 'tds_amount' },
        { label: 'TDS on Amount', key: 'tds_on_amount' },
        { label: 'Created At', key: 'created_at' },
        { label: 'Pan Number', key: 'panNumber' },

    ];


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const getData = () => {
        setIsSearch(true);
        AccountManagerAPI.GetTDSReport(startdateChange, endDateChange).then((data) => {

            if (data.status_code) {
                Notiflix.Notify.success('SuccessFully Data Fetched');
                // setArray(data.data);
                setReverseData(data.reverseData);
                setTdsData(data.tdsData);
                setIsLoading(false);
                setIsSearch(false);
            }

            else {
                Notiflix.Notify.failure('Error Please Retry Again');
                setIsLoading(false);
                setIsSearch(false);
            }

        });
    };

    const Search = () => {
        getData();

    };

    // const Reset = () => {
    //     setIsLoading(true);
    //     setIsSearch(false);
    //     startdateChange('');
    //     endDateChange('');

    // };


    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>TDS Report</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='form-row p-2'>
                                <div>
                                    <div className='col'>
                                        <label htmlFor='name'>Start Date : </label>
                                        <input
                                            type='date'
                                            value={startdateChange}
                                            onChange={(e) => setStartDateChange(e.target.value)}
                                            className='form-control'
                                            placeholder='Search By Name'
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className='col'>
                                        <label htmlFor='name'>End Date : </label>
                                        <input
                                            type='date'
                                            value={endDateChange}
                                            onChange={(e) => setEndDateChange(e.target.value)}
                                            className='form-control'
                                            placeholder='Search By Name'
                                        />
                                    </div>
                                </div>

                                <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                    <br />
                                    <label htmlFor='name'></label>
                                    <button
                                        className='btn btn-primary'
                                        type='button'
                                        onClick={() => Search()}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    {/* <button type="sreset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}

                                </div>
                            </div>

                            {isSearch && isLoading && (
                                <div className='col-sm-12 col-lg-12'>
                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                        <RotatingLines
                                            marginTop='40'
                                            strokeColor='#c1030d'
                                            strokeWidth='5'
                                            animationDuration='0.75'
                                            width='50'
                                            visible={true}
                                        />
                                    </div>
                                </div>
                            )}

                            <div>

                                <div style={{ display: 'flex' }}>
                                    {!isLoading && reverseData && reverseData.length > 0 && <div className="d-flex   bg-white">
                                        <CSVLink
                                            filename='Reverse Report'
                                            data={reverseData}
                                            headers={Headers}
                                            style={{ marginTop: '50px', fontSize: '50px' }}
                                            className=''>
                                            <button className='bg-secondary border-0 p-2'>Download Reverse Data</button>
                                        </CSVLink>
                                    </div>}
                                    <div style={{ margin: '10px' }}></div>

                                    {!isLoading && tdsData && tdsData.length > 0 && <div className="d-flex   bg-white">
                                        <CSVLink
                                            filename='TDS Report'
                                            data={tdsData}
                                            headers={Headers}
                                            style={{ marginTop: '50px', fontSize: '50px' }}
                                            className=''>
                                            <button className='bg-secondary border-0 p-2'>Download TDS Data</button>
                                        </CSVLink>
                                    </div>}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TdsReport;
