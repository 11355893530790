import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import { UsersAPI } from '../api/userApi';
import { CSVLink } from 'react-csv';
import Notiflix from 'notiflix';
import './ViewAllUsers.css';
import { BsFillFileEarmarkRuledFill } from 'react-icons/bs';
import moment from 'moment';
import { RotatingLines } from 'react-loader-spinner';
import Pagination from '../Component/Pagination';
import SerialNumber from './SerialNumber';
import { Select } from 'antd';
import ExcelDownload from '../Component/ExcelDownloadHook';

const role = localStorage.getItem('role');

export default function UserTransactions() {
    const location = useLocation();
    const userId = location.state.userId;

    console.log(userId);
    const [transactionData, setTransactionData] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [allTransactionData, setAllTransactionData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openFundsModal, setFundsModalOpen] = useState(false);
    const [openModalFunds2, setFundsModalOpen2] = useState(false);

    // ---Pagination------------------------------------------------------
    const [totalEntires, setTotalEntries] = useState(1);
    const [activeIndex, setActiveIndex] = useState(0);
    const [entries, setEntries] = useState(50);
    const [totalPages, setTotalPages] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [errorData, setErrorData] = useState('');
    const [dateHandling, setDateHandling] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    // User Data
    const [user_userName, setUser_userName] = useState('');
    const [user_userId, setUser_userId] = useState('');
    const [user_userEmail, setUser_userEmail] = useState('');
    const [user_userTeamName, setUser_userTeamName] = useState('');
    const [user_userPhone, setUser_userPhone] = useState('');
    const [user_userState, setUser_userState] = useState('');
    const [manipulateData, setManipulateData] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        // getUserTransaction();
        // generatePagination(1, 10);
    }, [activeIndex]);

    useEffect(() => {
        getUserTransaction();
        console.log('first');
    }, [pageNumber]);

    // useEffect(() => {
    //   console.log(fromDate)

    // }, [fromDate])

    const handleAddFund = () => { };

    const header = [
        { label: 'Date', key: 'date' },
        { label: 'Time', key: 'time' },
        { label: 'Add Bonus', key: 'add_bonus' },
        { label: 'Add Unutilized', key: 'add_unutilized' },
        { label: 'Winnings', key: 'add_winning' },
        { label: 'Consumed Bonus', key: 'consumed_bonus' },
        { label: 'Consumed Unutilized', key: 'consumed_unutilized' },
        { label: 'Consumed Winnings', key: 'consumed_winning' },
        { label: 'Total Available Bonus', key: 'total_available_bonus' },
        { label: 'Total Available Unutilized', key: 'total_available_unutiized' },
        { label: 'Total Available Winnings', key: 'total_available_winning' },
        { label: 'Total Available Balance', key: 'total_avaialable_balance' },
        { label: 'Net Winning', key: 'net_winning' },
        { label: 'Amount', key: 'amount' },
        { label: 'Point Type', key: 'point_type' },
        { label: 'Transaction Type', key: 'transaction_type' },
        { label: 'TDS', key: 'tds' },
        { label: 'Super Coins', key: 'royalty_points' },
        { label: 'Joining Fees', key: 'joining_fees' },
        { label: 'Description', key: 'description' },
    ];

    const handleOpenModal = () => {
        setFundsModalOpen(true);
    };

    const handleOpenModal2 = () => {
        setFundsModalOpen2(true);
    };

    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const handleDate = (date) => {
        var dateNew = date.slice(0, 10);
        return dateNew;
    };

    const handleTime = (e) => {
        var newTime = e.slice(11, 18);
        var updateTime = moment(newTime, 'HH:mm:ss').format('hh:mm A');
        return updateTime;
    };
    // console.log(dt)
    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const [startSerialNumber, setStartSerialNumber] = useState(1);

    const getUserTransaction = () => {
        setIsLoading(true);
        // setPageNumber(1);
        UsersAPI.UserTransactions(userId, entries, pageNumber, fromDate, toDate, itemSelection, lowOrHigh).then((res) => {
            if (res.status_code === true) {
                setIsLoading(false);
                const arr = [];

                for (let i = 0; i < res.data.length; i++) {
                    const date = handleDate(res.data[i].date);
                    const time = handleTime(res.data[i].date);
                    const add_bonus =
                        res.data[i].point_type === 'SIGN' ||
                            (res.data[i].point_type === 'BONUS' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'REFUND' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'REVERSE' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'PROMO' &&
                                res.data[i].transaction_type === 'add')
                            ? handleDecimalAfter(res.data[i]?.bonus)
                            : 0;
                    const add_unutilized =
                        (res.data[i].point_type === 'RAZ' &&
                            res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'REFUND' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'REVERSE' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'PROMO' &&
                                res.data[i].transaction_type === 'add')
                            ? handleDecimalAfter(res.data[i]?.unutilized)
                            : 0;
                    const add_winning =
                        (res.data[i].point_type === 'WIN' &&
                            res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'REFUND' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'REVERSE' &&
                                res.data[i].transaction_type === 'add') ||
                            (res.data[i].point_type === 'PROMO' &&
                                res.data[i].transaction_type === 'add')
                            ? handleDecimalAfter(res.data[i].winning)
                            : 0;
                    const consumed_bonus =
                        (res.data[i].point_type === 'JOIN' &&
                            res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'BONUS' &&
                                res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'REFUND' &&
                                res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'REVERSE' &&
                                res.data[i].transaction_type === 'deduct')
                            ? handleDecimalAfter(res.data[i].bonus)
                            : 0;
                    const consumed_unutilized =
                        (res.data[i].point_type === 'JOIN' &&
                            res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'REFUND' &&
                                res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'REVERSE' &&
                                res.data[i].transaction_type === 'deduct')
                            ? handleDecimalAfter(res.data[i].unutilized)
                            : 0;
                    const consumed_winning =
                        (res.data[i].point_type === 'JOIN' &&
                            res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'WITHDRAW' &&
                                res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'WIN' && res.data[i].point_type === 'deduct') ||
                            (res.data[i].point_type === 'REFUND' &&
                                res.data[i].transaction_type === 'deduct') ||
                            (res.data[i].point_type === 'REVERSE' &&
                                res.data[i].transaction_type === 'deduct')
                            ? handleDecimalAfter(res.data[i].winning)
                            : 0;
                    const total_available_bonus = handleDecimalAfter(
                        res.data[i].new_balance?.bonus
                    );
                    const total_available_unutiized = handleDecimalAfter(
                        res.data[i].new_balance?.unutilized
                    );
                    const total_available_winning = handleDecimalAfter(
                        res.data[i].new_balance?.winning
                    );
                    const total_avaialable_balance = handleDecimalAfter(
                        res.data[i]?.new_balance?.total_balance
                    );
                    const point_type = res.data[i]?.point_type;
                    const transaction_type = res.data[i]?.transaction_type;
                    const tds = res.data[i]?.gst_amount;
                    const joining_fees =
                        res.data[i].point_type === 'JOIN' &&
                            res.data[i].transaction_type === 'deduct'
                            ? res.data[i]?.amount
                            : 0;
                    const payment = res.data[i].payment;
                    const net_winning = res.data[i].new_balance?.net_winning;
                    const amount = res.data[i].amount;
                    const royalty_points =
                        res.data[i].new_balance?.royalty_points > 0
                            ? res.data[i].new_balance?.royalty_points
                            : 0;
                    const description = res.data[i].description;
                    arr.push({
                        date,
                        time,
                        add_bonus,
                        add_unutilized,
                        add_winning,
                        consumed_bonus,
                        consumed_unutilized,
                        consumed_winning,
                        total_available_bonus,
                        total_available_unutiized,
                        total_available_winning,
                        total_avaialable_balance,
                        point_type,
                        // super_coins,
                        // super_coins_type,
                        transaction_type,
                        tds,
                        joining_fees,
                        payment,
                        net_winning,
                        amount,
                        royalty_points,
                        description,
                    });
                }
                setTransactionData(arr);

                setTotalEntries(res.total_length);
                setTotalPages(res.total_page);
                setUser_userName(res.name === '' ? 'Not Found' : res.name);
                setUser_userEmail(res.email === '' ? 'Not Found' : res.email);
                setUser_userId(res.user_id);
                setUser_userTeamName(res.team_name);
                setUser_userPhone(res.phone === '' ? 'Not Found' : res.phone);
                setUser_userState(res.state);
                console.log(res);
                setIsLoading(false);
                const startNumber = (pageNumber - 1) * entries + 1;
                setStartSerialNumber(startNumber);
            } else {
                Notiflix.Notify.failure(res.message);
                setErrorData(res.message);
                setIsLoading(false);
            }
        });
    };

    // States for mapping Data
    const manipulateTransaction = (transData) => {
        const arr = [];
        for (let i = 0; i < transData.length; i++) {
            const date = handleDate(transData[i].date);
            const time = handleTime(transData[i].date);
            const add_bonus =
                transData[i].point_type === 'SIGN' ||
                    (transData[i].point_type === 'BONUS' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'REFUND' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'REVERSE' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'PROMO' && transData[i].transaction_type === 'add')
                    ? handleDecimalAfter(transData[i]?.bonus)
                    : 0;
            const add_unutilized =
                (transData[i].point_type === 'RAZ' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'REFUND' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'REVERSE' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'PROMO' && transData[i].transaction_type === 'add')
                    ? handleDecimalAfter(transData[i]?.unutilized)
                    : 0;
            const add_winning =
                (transData[i].point_type === 'WIN' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'REFUND' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'REVERSE' && transData[i].transaction_type === 'add') ||
                    (transData[i].point_type === 'PROMO' && transData[i].transaction_type === 'add')
                    ? handleDecimalAfter(transData[i].winning)
                    : 0;
            const consumed_bonus =
                (transData[i].point_type === 'JOIN' && transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'BONUS' && transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'REFUND' &&
                        transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'REVERSE' && transData[i].transaction_type === 'deduct')
                    ? handleDecimalAfter(transData[i].bonus)
                    : 0;
            const consumed_unutilized =
                (transData[i].point_type === 'JOIN' && transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'REFUND' &&
                        transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'REVERSE' && transData[i].transaction_type === 'deduct')
                    ? handleDecimalAfter(transData[i].unutilized)
                    : 0;
            const consumed_winning =
                (transData[i].point_type === 'JOIN' && transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'WITHDRAW' &&
                        transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'WIN' && transData[i].point_type === 'deduct') ||
                    (transData[i].point_type === 'REFUND' &&
                        transData[i].transaction_type === 'deduct') ||
                    (transData[i].point_type === 'REVERSE' && transData[i].transaction_type === 'deduct')
                    ? handleDecimalAfter(transData[i].winning)
                    : 0;
            const total_available_bonus = handleDecimalAfter(transData[i].new_balance?.bonus);
            const total_available_unutiized = handleDecimalAfter(
                transData[i].new_balance?.unutilized
            );
            const total_available_winning = handleDecimalAfter(transData[i].new_balance?.winning);
            const total_avaialable_balance = handleDecimalAfter(
                transData[i]?.new_balance?.total_balance
            );
            const point_type = transData[i]?.point_type;
            const transaction_type = transData[i]?.transaction_type;
            const tds = transData[i]?.gst_amount;
            const joining_fees =
                transData[i].point_type === 'JOIN' && transData[i].transaction_type === 'deduct'
                    ? transData[i]?.amount
                    : 0;
            const payment = transData[i].payment;
            const net_winning = transData[i].new_balance?.net_winning;
            const amount = transData[i].amount;
            const royalty_points =
                transData[i].new_balance?.royalty_points > 0
                    ? transData[i].new_balance?.royalty_points
                    : 0;
            const description = transData[i].description;
            arr.push({
                date,
                time,
                add_bonus,
                add_unutilized,
                add_winning,
                consumed_bonus,
                consumed_unutilized,
                consumed_winning,
                total_available_bonus,
                total_available_unutiized,
                total_available_winning,
                total_avaialable_balance,
                point_type,
                // super_coins,
                // super_coins_type,
                transaction_type,
                tds,
                joining_fees,
                payment,
                net_winning,
                amount,
                royalty_points,
                description,
            });
        }
        setManipulateData(arr);
    };

    const allGetUSerTransaction = () => {
        setIsButtonLoading(true);
        UsersAPI.AllUserTransactions(userId).then((res) => {
            if (res.status_code === true) {
                setAllTransactionData(res.data);
                manipulateTransaction(res.data);
                setTotalEntries(res.total_length);
                console.log(res.data);
                setIsButtonLoading(false);
            } else {
                Notiflix.Notify.failure(res.message);
            }
        });
    };


    const Reset = () => {
        setFromDate('');
        setToDate('', getUserTransaction());
        setItemSelection('createdAt');
        setLowOrHigh('-1');
    };

    const handlePointType = (e) => {
        if (e === 'RAZ') {
            return 'Deposit Money';
        } else if (e === 'WIN') {
            return 'Contest Winning';
        } else {
            return 'Contest Joining';
        }
    };

    const HandlePaginate = (index) => {
        // console.log(index)
        setActiveIndex(index);
        // console.log(item)
    };

    const changePage = (data) => {
        setPageNumber(data);
    };

    const handleDecimalAfter = (number) => {
        if (number === undefined) {
            return '';
        } else {
            let result = Math?.round(number * 100) / 100;
            return result;
        }
    };

    const handleAmountDeci = (e) => {
        let arr = JSON.stringify(e)?.split('.');
        let SecoArr = arr[1]?.split('').splice(0, 2).join('');
        return parseFloat(`${arr[0]}.${SecoArr}`);
    };

    const [itemSelection, setItemSelection] = useState('createdAt');
    const [lowOrHigh, setLowOrHigh] = useState('-1');

    const handleItemSelection = (value) => {
        setItemSelection(value);
    };

    const handleLowOrHigh = (value) => {
        setLowOrHigh(value);
    };

    const headerForCSV = ['date', 'time', 'add_bonus', 'add_unutilized', 'add_winning', 'consumed_bonus', 'consumed_unutilized', 'consumed_winning', 'total_available_bonus', 'total_available_unutiized', 'total_available_winning', 'total_avaialable_balance', 'point_type', 'transaction_type', 'tds', 'joining_fees', 'payment', 'net_winning', 'amount', 'royalty_points', 'description'];

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <form>
                    {dateHandling ? (
                        <h1 className='transaction_error'>
                            Please Fill Both the Start Date and End Date
                        </h1>
                    ) : (
                        ''
                    )}
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div style={{ padding: 26 }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 10,
                                    }}>
                                        <div>
                                            <label htmlFor='Date'>From</label>
                                            <input
                                                onChange={(e) => setFromDate(e.target.value)}
                                                type='Date'
                                                className='form-control'
                                                value={fromDate}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor='Date'>To</label>
                                            <input
                                                onChange={(e) => setToDate(e.target.value)}
                                                type='Date'
                                                className='form-control'
                                                value={toDate}
                                            />
                                        </div>

                                        <Select
                                            defaultValue="createdAt"
                                            style={{
                                                border: 'none', borderRadius: 4, marginTop: 26, width: 180
                                            }}
                                            onChange={handleItemSelection}
                                            options={[
                                                {
                                                    value: 'createdAt',
                                                    label: 'Date',
                                                },
                                                {
                                                    value: 'amount',
                                                    label: 'Amount',
                                                },
                                                {
                                                    value: 'joining_fee',
                                                    label: 'Joining Fees',
                                                },
                                                {
                                                    value: 'net_winning',
                                                    label: 'Net Winning',
                                                },
                                                {
                                                    value: 'unutilized',
                                                    label: 'Unutilized',
                                                },
                                                {
                                                    value: 'winning',
                                                    label: 'Winning',
                                                },
                                            ]}
                                        />

                                        <Select
                                            defaultValue="1"
                                            style={{
                                                border: 'none', borderRadius: 4, marginTop: 26, width: 180
                                            }}
                                            onChange={handleLowOrHigh}
                                            options={[
                                                {
                                                    value: '1',
                                                    label: 'Low to High',
                                                },
                                                {
                                                    value: '-1',
                                                    label: 'High to Low',
                                                },
                                            ]}
                                        />


                                        <div className='form-row p-2'>
                                            <div className='col'>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    onClick={getUserTransaction}
                                                    type='button'
                                                    className='btn btn-primary'
                                                >
                                                    Search
                                                </button>
                                                <input
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                    type='reset'
                                                    onClick={Reset}
                                                    className='btn btn-danger'
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className='container-fluid'>
                    {/* CSV DOWNLOAD */}
                    <div className='userTrasnaction_div'>
                        <div className='transaction_show_div'>
                            <div className='transaction_divv'>
                                <h4>User Id</h4>
                                <h6>{user_userId}</h6>
                            </div>

                            <div className='transaction_divv'>
                                <h4>Team Name</h4>
                                <h6>{user_userTeamName}</h6>
                            </div>
                            <div className='transaction_divv'>
                                <h4>Phone</h4>
                                <h6
                                    style={{
                                        color: user_userPhone === 'Not Found' ? 'red' : 'darkgreen',
                                    }}
                                >
                                    {user_userPhone}
                                </h6>
                            </div>
                        </div>

                        {transactionData ? <ExcelDownload data={transactionData} header={headerForCSV} filename={`${user_userTeamName}'s Transaction`} /> : ''}

                        {/* {allTransactionData?.length > 0 ? (
                            <button className='userTransaction_button' type='button'>
                                <CSVLink
                                    className='userTransaction_csv'
                                    filename={
                                        `${user_userName === 'Not Found'
                                            ? user_userTeamName
                                            : user_userName
                                        }-${user_userState}'s Transactions`
                                    }
                                    data={manipulateData}
                                    headers={header}
                                >
                                    {' '}
                                    Download User Transactions
                                    <BsFillFileEarmarkRuledFill className='bs_fillname' />
                                </CSVLink>
                            </button>
                        ) : (
                            ''
                        )} */}
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                {isLoading && (
                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                        <div>
                                            <RotatingLines
                                                marginTop='70'
                                                strokeColor='#c1030d'
                                                strokeWidth='5'
                                                animationDuration='0.75'
                                                width='50'
                                                visible={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className='card'>
                                        <div className='card-header d-flex justify-content-between'>
                                            <div className='header-title'>
                                                <h4 className='card-title'>User Transactions</h4>
                                            </div>
                                        </div>
                                        <div className='table-responsive'>
                                            <span>
                                                <table className='table  table-striped table-bordered'>
                                                    <thead>
                                                        <tr className='trans-table-head'>
                                                            <th>S.no</th>
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                            <th>Add Bonus</th>
                                                            <th>Add Unutilized</th>
                                                            <th>Add Winnings</th>
                                                            <th>Consumed Bonus</th>
                                                            <th>Consumed Unutilized</th>
                                                            <th>Consumed Winnings</th>
                                                            <th>Available Bonus: Total</th>
                                                            <th>Available Unutilized: Total</th>
                                                            <th>Available Winnings: Total</th>
                                                            <th>Total Available balance</th>
                                                            <th>Net Winning</th>
                                                            <th>Amount</th>
                                                            <th>Point Type</th>
                                                            <th>Transaction Type</th>
                                                            <th>TDS</th>
                                                            <th>Super Coins</th>
                                                            <th>Joining Fee</th>
                                                            <th>Coupon</th>
                                                            <th>Transaction Details</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactionData.length > 0 &&
                                                            transactionData.map((trans, index) => {
                                                                const serialNoOnNextPage =
                                                                    startSerialNumber + index;
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <SerialNumber
                                                                                currentPage={
                                                                                    currentPage
                                                                                }
                                                                                entriesPerPage={
                                                                                    entries
                                                                                }
                                                                                activeIndex={
                                                                                    serialNoOnNextPage
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td>{trans.date}</td>
                                                                        <td>{trans.time}</td>
                                                                        <td>{trans.add_bonus}</td>
                                                                        <td>
                                                                            {trans.add_unutilized}
                                                                        </td>
                                                                        <td>{trans.add_winning}</td>
                                                                        <td>
                                                                            {trans.consumed_bonus}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                trans.consumed_unutilized
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {trans.consumed_winning}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                trans.total_available_bonus
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                trans.total_available_unutiized
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                trans.total_available_winning
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                trans.total_avaialable_balance
                                                                            }
                                                                        </td>
                                                                        <td>{Math.ceil(trans.net_winning)}</td>
                                                                        <td>{handleAmountDeci(trans.amount)}</td>
                                                                        <td>{trans?.point_type}</td>
                                                                        <td>
                                                                            {
                                                                                trans?.transaction_type
                                                                            }
                                                                        </td>
                                                                        <td>{trans.tds}</td>
                                                                        <td>
                                                                            {trans?.royalty_points}
                                                                        </td>
                                                                        <td>
                                                                            {trans.joining_fees}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                trans?.payment[0]
                                                                                    ?.amount
                                                                            }
                                                                        </td>
                                                                        <td>{trans.description}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </span>
                                        </div>

                                        {/* Pagination Here */}
                                        <Pagination
                                            className='pagination-css'
                                            totalEntries={totalEntires}
                                            totalPages={totalPages}
                                            changePage={changePage}
                                            pageNumber={pageNumber}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
