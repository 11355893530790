import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { MatchAPI } from '../api/matchApi';
import { ContestAPI } from '../api/contestApi';
import Notiflix from 'notiflix';

export default function AddPoolsAndWinningsPage() {
    const [MatchList, setMatchList] = useState([]);
    const [ContestCatList, setContestCatList] = useState([]);
    const [matchId, setMatchId] = useState('');
    const [ContestCatId, setContestCat] = useState('');
    const [ContestNm, setContestNm] = useState('');
    const [joinfees, setJoinFees] = useState('');
    const [winAmount, setWinAmount] = useState('');
    const [TotalWinner, setTotalWinner] = useState('');
    const [MaxTeam, setMaxTeam] = useState('');
    const [IsMultiEntry, setMultiEntry] = useState(false);
    const [IsBonus, setIsBonus] = useState(false);
    const [IsConfirm, setIsConfirm] = useState(false);
    const [UserMaxJoin, setUserMaxJoin] = useState('');
    const [BonusPrice, setBonusPrice] = useState('');
    const [status, setStatus] = useState('');
    const [IsRunning, setIsRunning] = useState(false);
    useEffect(() => {
        GetMatch();
        GetContestCategory();
    }, []);

    const GetMatch = () => {
        // MatchAPI.GetUpcoming().then((data)=>{
        //     // console.log(data)
        //     if(data)
        //     setMatchList(data)
        // })

        MatchAPI.FetchAllMatches().then((data) => {
            if (data) setMatchList(data.message);
            else setMatchList([]);
        });
    };
    const GetContestCategory = () => {
        ContestAPI.GetContestCategory().then((data) => {
            if (data) setContestCatList(data.Data);
        });
    };
    const matchChange = (e) => {
        setMatchId(e.target.value);
    };
    const contestChange = (e) => {
        // alert(e.target.value.split(','))
        const arr = e.target.value.split(',');
        setContestCat(arr[0]);
        setContestNm(arr[1]);
        // alert(arr[0])
    };
    const joinfeesChange = (e) => {
        setJoinFees(e.target.value);
    };
    const winAmountChange = (e) => {
        setWinAmount(e.target.value);
    };
    const totalWinnerChange = (e) => {
        setTotalWinner(e.target.value);
    };
    const maxTeamChange = (e) => {
        setMaxTeam(e.target.value);
    };
    const statusChange = (e) => {
        setStatus(e.target.value);
    };

    const SubmitBtn = () => {
        if (matchId == '') Notiflix.Notify.failure('please select match');
        else if (ContestCatId == '') Notiflix.Notify.failure('please select contest category');
        else if (joinfees == '') Notiflix.Notify.failure('Join fees can\'t be blank');
        // else if(joinfees<winAmount){
        //     Notiflix.Notify.failure('Joining Fees Should be lesser Than Winning Amount!!')
        // }
        else if (winAmount == '') Notiflix.Notify.failure('Winning Amount can\'t be blank');
        else if (TotalWinner == '') Notiflix.Notify.failure('Total winner can\'t be blank');
        else if (MaxTeam == '') Notiflix.Notify.failure('Spots can\'t be blank');
        else if (IsMultiEntry == true && UserMaxJoin == '')
            Notiflix.Notify.failure('User Max join Team can\'t be blank');
        else if (IsBonus == true && BonusPrice == '')
            Notiflix.Notify.failure('Bonus can\'t be blank');
        // else
        // if(status=='')
        // alert("Status can't be blank")
        else {
            let contestData = {
                MatchId: matchId,
                ContestCategoryId: ContestCatId,
                ContestCategoryName: ContestNm,
                JoiningFees: joinfees,
                TotalWinningAmount: winAmount,
                TotalWinners: TotalWinner,
                MaxSpots: MaxTeam,
                IsMultiEntry: IsMultiEntry,
                UserMaxTeamJoin: IsMultiEntry == false ? 1 : UserMaxJoin,
                IsBonus: IsBonus,
                Bonus: IsBonus == false ? 0 : BonusPrice,
                IsConfirmed: IsConfirm,
                IsRunning: IsRunning,
                // Status:status
            };
            // alert(JSON.stringify(contestData))
            // alert(UserMaxJoin)
            ContestAPI.CreatePoolContest(contestData).then((data) => {
                alert(data.Message);
                reset();
            });
        }
    };

    const reset = () => {
        setMatchId('');
        setContestCat('');
        setContestNm('');
        setJoinFees('');
        setWinAmount('');
        setTotalWinner('');
        setMaxTeam('');
        setMultiEntry(false);
        setIsBonus(false);
        setIsConfirm(false);
        setBonusPrice('');
        setUserMaxJoin('');
        setStatus('');
    };

    const MultientryChange = () => {
        setMultiEntry(document.getElementById('checkbox1').checked);
    };
    const IsBonusChange = () => {
        setIsBonus(document.getElementById('checkbox2').checked);
    };
    const IsConfirmChange = () => {
        setIsConfirm(document.getElementById('checkbox3').checked);
    };
    const BonusChange = (e) => {
        setBonusPrice(e.target.value);
    };
    const UserMaxJoinChange = (e) => {
        setUserMaxJoin(e.target.value);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/pool_list'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-add-line'></i>View Pool and Winnings
                                        list
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add Pool and Winnings</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Select Match :</label>
                                            <select
                                                className='form-control mb-3'
                                                onChange={matchChange}
                                            >
                                                <option selected value=''>
                                                    Open this select menu
                                                </option>
                                                {MatchList.map((match) => (
                                                    <option value={match.matchId}>
                                                        {match.matchName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='name'>Select Contest Category :</label>
                                            <select
                                                className='form-control mb-3'
                                                onChange={contestChange}
                                            >
                                                <option selected value=''>
                                                    Open this select menu
                                                </option>
                                                {ContestCatList.map((contest) => (
                                                    <option
                                                        value={[
                                                            contest.ContestCatId,
                                                            contest.ContestCategoryName,
                                                        ]}
                                                    >
                                                        {contest.ContestCategoryName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='name'>Joining Fees:</label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                type='number'
                                                className='form-control'
                                                value={joinfees}
                                                onChange={joinfeesChange}
                                                placeholder='Enter joining fee'
                                                defaultValue=''
                                                min={1}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Total Winning Amount:</label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                type='number'
                                                className='form-control'
                                                value={winAmount}
                                                onChange={winAmountChange}
                                                placeholder='Total winning'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Winners (Total Numbers of winners for a Particular
                                                Pool):
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                type='number'
                                                className='form-control'
                                                value={TotalWinner}
                                                onChange={totalWinnerChange}
                                                placeholder='Total numbers of winners'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Spots (Max Number of Team can Join the Pool):
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                type='number'
                                                className='form-control'
                                                value={MaxTeam}
                                                onChange={maxTeamChange}
                                                placeholder='Max Total Number of Team'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Offer Entry Fee:</label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                type='number'
                                                className='form-control'
                                                defaultValue='0'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Offered Entry Fee:</label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                type='number'
                                                className='form-control'
                                                defaultValue='0'
                                            />
                                        </div>
                                        {/* <div className="form-group">
                        <label htmlFor="name">Pool Type</label>
                        <input type="text" className="form-control" placeholder="Enter pool type" defaultValue=""/>
                    </div> */}

                                        {/* <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" for="flexCheckDefault">MultiEntry</label>
                        </div> */}
                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='checkbox1'
                                                    checked={IsMultiEntry}
                                                    onChange={MultientryChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='checkbox1'
                                                >
                                                    Multi Entry
                                                </label>
                                            </div>

                                            <input
                                                type='number'
                                                className='form-control my-3'
                                                value={UserMaxJoin}
                                                onChange={UserMaxJoinChange}
                                                style={{
                                                    display:
                                                        IsMultiEntry == false ? 'none' : 'flex',
                                                }}
                                                placeholder='User Max Number of Team Join'
                                                defaultValue=''
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input '
                                                    id='checkbox2'
                                                    checked={IsBonus}
                                                    onChange={IsBonusChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='checkbox2'
                                                >
                                                    Is Bonus
                                                </label>
                                            </div>

                                            <input
                                                type='number'
                                                className='form-control my-3'
                                                value={BonusPrice}
                                                onChange={BonusChange}
                                                style={{
                                                    display: IsBonus == false ? 'none' : 'flex',
                                                }}
                                                placeholder='Bonus Percentage'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='checkbox3'
                                                    checked={IsConfirm}
                                                    onChange={IsConfirmChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='checkbox3'
                                                >
                                                    Is Confirm
                                                </label>
                                            </div>
                                        </div>

                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='checkbox9'
                                                    checked={IsRunning}
                                                    onChange={(e) => setIsRunning(e.target.checked)}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='checkbox9'
                                                >
                                                    Is Running
                                                </label>
                                            </div>
                                        </div>

                                        {/* <div className="form-group">
                        <label htmlFor="name">Status </label>
                        <select className="form-control mb-3"  onChange={statusChange}>
                            <option value>Select</option>
                            <option value="Active">Active</option>
                            <option value="Deactive">Deactive</option>
                        </select>
                    </div> */}

                                        {/* <div className="form-group">
                        <div className="row">
                            <div className="col-md-5">
                                <label htmlFor="name">Add Range</label>
                                <input type="text" className="form-control" placeholder="Rank : 6-100" defaultValue=""/>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="name">Add Amount</label>
                                <input type="text" className="form-control" placeholder="Rs 100" defaultValue=""/>
                            </div>
                            <div className="col-md-2">
                                <br/>
                                <label htmlFor="name" className="mt-4"></label>
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                         </div>   
                        <div className="row mt-3">
                            <div className="col-md-5">
                                <label htmlFor="name">Add Range</label>
                                <input type="text" className="form-control" placeholder="Rank : 101-2000" defaultValue=""/>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="name">Add Amount</label>
                                <input type="text" className="form-control" placeholder="Rs 55" defaultValue=""/>
                            </div>
                            <div className="col-md-2">
                                <br/>
                                <label htmlFor="name" className="mt-4"></label>
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </div>                     
                    </div> */}

                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={SubmitBtn}
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
