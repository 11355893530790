import { Link } from 'react-router-dom';

export default function PoolList() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/assign_contest'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ri-arrow-left-line'></i>Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Pool List</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Pool Id</th>
                                                    <th>Fee (Pool Fee)</th>
                                                    <th>Winning Amount</th>
                                                    <th>Max Team</th>
                                                    <th>Winners</th>
                                                    <th>Delete</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>BAN VS AFG</td>
                                                    <td>Rs 33</td>
                                                    <td>Rs 138500</td>
                                                    <td>5000 Teams</td>
                                                    <td>2000</td>
                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>{' '}
                                                            Delete
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className='btn-group' role='group'>
                                                            <button
                                                                id='btnGroupDrop1'
                                                                type='button'
                                                                className='btn btn-primary dropdown-toggle'
                                                                data-toggle='dropdown'
                                                                aria-haspopup='true'
                                                                aria-expanded='false'
                                                            >
                                                                Action
                                                            </button>
                                                            <div
                                                                className='dropdown-menu'
                                                                aria-labelledby='btnGroupDrop1'
                                                                style={{}}
                                                            >
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Assign/Unassign
                                                                </a>
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Deactivate
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>BAN VS AFG</td>
                                                    <td>Rs 33</td>
                                                    <td>Rs 138500</td>
                                                    <td>5000 Teams</td>
                                                    <td>2000</td>
                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>{' '}
                                                            Delete
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className='btn-group' role='group'>
                                                            <button
                                                                id='btnGroupDrop1'
                                                                type='button'
                                                                className='btn btn-primary dropdown-toggle'
                                                                data-toggle='dropdown'
                                                                aria-haspopup='true'
                                                                aria-expanded='false'
                                                            >
                                                                Action
                                                            </button>
                                                            <div
                                                                className='dropdown-menu'
                                                                aria-labelledby='btnGroupDrop1'
                                                                style={{}}
                                                            >
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Assign/Unassign
                                                                </a>
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Deactivate
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>BAN VS AFG</td>
                                                    <td>Rs 33</td>
                                                    <td>Rs 138500</td>
                                                    <td>5000 Teams</td>
                                                    <td>2000</td>
                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>{' '}
                                                            Delete
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className='btn-group' role='group'>
                                                            <button
                                                                id='btnGroupDrop1'
                                                                type='button'
                                                                className='btn btn-primary dropdown-toggle'
                                                                data-toggle='dropdown'
                                                                aria-haspopup='true'
                                                                aria-expanded='false'
                                                            >
                                                                Action
                                                            </button>
                                                            <div
                                                                className='dropdown-menu'
                                                                aria-labelledby='btnGroupDrop1'
                                                                style={{}}
                                                            >
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Assign/Unassign
                                                                </a>
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Deactivate
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>BAN VS AFG</td>
                                                    <td>Rs 33</td>
                                                    <td>Rs 138500</td>
                                                    <td>5000 Teams</td>
                                                    <td>2000</td>
                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>{' '}
                                                            Delete
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className='btn-group' role='group'>
                                                            <button
                                                                id='btnGroupDrop1'
                                                                type='button'
                                                                className='btn btn-primary dropdown-toggle'
                                                                data-toggle='dropdown'
                                                                aria-haspopup='true'
                                                                aria-expanded='false'
                                                            >
                                                                Action
                                                            </button>
                                                            <div
                                                                className='dropdown-menu'
                                                                aria-labelledby='btnGroupDrop1'
                                                                style={{}}
                                                            >
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Assign/Unassign
                                                                </a>
                                                                <a
                                                                    className='dropdown-item'
                                                                    href='#'
                                                                >
                                                                    Deactivate
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Pool Id</th>
                                                    <th>Fee (Pool Fee)</th>
                                                    <th>Winning Amount</th>
                                                    <th>Max Team</th>
                                                    <th>Winners</th>
                                                    <th>Delete</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
