import React, { useEffect, useState } from 'react';

export default function AuthorityMaster() {
    const data = ['Employee Id', 'Emp ID', 'Employee Name', 'Mobile No', 'Email id', 'Role'];
    const [roleName, setRoleName] = useState(data);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Employee Master</h4>
                                    </div>
                                </div>
                                <form>
                                    {/* <div class="form-group">
    <label for="exampleInputEmail1">Role </label>
    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
   </div> */}

                                    {/* <button type="submit" class="btn btn-primary">Save</button> */}
                                </form>
                                <div className='card'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>#</th>
                                                <th scope='col'>Employees Name</th>
                                                <th scope='col'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope='row'>1</th>
                                                <td>Ajit Singh</td>
                                                <div className='d-flex align-items-center '>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            All Users
                                                        </label>
                                                    </div>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            Payment Options
                                                        </label>
                                                    </div>
                                                    <button className='btn btn-primary btn-sm'>
                                                        Done
                                                    </button>
                                                </div>
                                            </tr>

                                            <tr>
                                                <th scope='row'>2</th>
                                                <td>Rakesh Jain</td>
                                                <div className='d-flex align-items-center '>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            All Users
                                                        </label>
                                                    </div>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            Payment Options
                                                        </label>
                                                    </div>
                                                    <button className='btn btn-primary btn-sm'>
                                                        Done
                                                    </button>
                                                </div>
                                            </tr>
                                            <tr>
                                                <th scope='row'>3</th>
                                                <td>Arman Kohli</td>
                                                <div className='d-flex align-items-center '>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            All Users
                                                        </label>
                                                    </div>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            Payment Options
                                                        </label>
                                                    </div>
                                                    <button className='btn btn-primary btn-sm'>
                                                        Done
                                                    </button>
                                                </div>
                                            </tr>

                                            <tr>
                                                <th scope='row'>4</th>
                                                <td>Pratik viswas</td>
                                                <div className='d-flex align-items-center '>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            All Users
                                                        </label>
                                                    </div>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            Payment Options
                                                        </label>
                                                    </div>
                                                    <button className='btn btn-primary btn-sm'>
                                                        Done
                                                    </button>
                                                </div>
                                            </tr>

                                            <tr>
                                                <th scope='row'>5</th>
                                                <td>Dinesh karmik</td>
                                                <div className='d-flex align-items-center '>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            All Users
                                                        </label>
                                                    </div>
                                                    <div className='form-check mr-2'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            value=''
                                                            id='flexCheckChecked'
                                                            checked
                                                        />
                                                        <label
                                                            className='form-check-label'
                                                            htmlFor='flexCheckChecked'
                                                        >
                                                            Payment Options
                                                        </label>
                                                    </div>
                                                    <button className='btn btn-primary btn-sm'>
                                                        Done
                                                    </button>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
