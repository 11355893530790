//import { Api } from '@mui/icons-material';
import Notiflix from 'notiflix';
import { baseURL } from './apiBaseUrl';
import { BASEAPIS } from './BaseApis';
const key = localStorage.getItem('jwtToken');
// 'x-api-key': key

export const SeriesAPI = {

    EditMatchPoint: async (jsonbody) => {
        return BASEAPIS.PUTAPI(jsonbody, 'editMatchPoint');
    },

    GetSeries: async () => {
        return BASEAPIS.GETAPI('fetchSeries1?entries=' + 10000);
    },

    FilterSeries: async (seriesname, startDate, endDate, seriesStatus) => {
        return BASEAPIS.GETAPI('fetchSeries1?start_date_from=' + startDate + '&end_date_from=' + endDate + '&title=' + seriesname + '&status=' + seriesStatus);
    },

    DropdownSeries: async (startDate, endDate) => {
        const statusSeries = 'all';
        return BASEAPIS.GETAPI('fetchFullActiveSeries?start=' + startDate + '&end=' + endDate + '&status=' + statusSeries);
    },

    UpdateSeriesStatus: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'seriesStatus');
    },

    CreateSeries: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'createSeries');
    },

    GetFantasy: async () => {
        return BASEAPIS.GETAPI('fetchFantasy');
    },

    EditSeries: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'editSeries');
    },

    SeriesDetails: async (page_no, per_page) => {
        return BASEAPIS.GETAPI('seriesDetail?page_no=' + page_no + '&per_page=' + per_page,);
    },

    StoreSeries: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'storeSeriesApi');
    },

    GetMatchDetail: async (cid) => {
        return BASEAPIS.GETAPI('getSeriesMatches?cid=' + cid);
    },

    MatchPlayerPoint: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'matchPlayerPoint');
    },

    ResultDeclare: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'resultDeclare');
    },

    ResultAbandoned: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'resultAbandoned');
    },

    ViewSeriesContestDetails: async (matchId, fantasy) => {
        return BASEAPIS.GETAPI('viewSeriesContestDetails?MatchId=' + matchId + '&fantasy=' + fantasy,);
    },

    viewAlljoinedContestUser: async (jsonbody) => {
        const undefinedNumber = 2;
        return BASEAPIS.GETAPI('GetScoreLeaderboard?MatchId=' + jsonbody.MatchId + '&ApiMatchId=' + jsonbody.APIMatchId + '&ContestPoolID=' + jsonbody.ContestPoolId + '&UserID=' + undefinedNumber);
    },

    PredictionResultDone: async (MatchId, ApiMatchId) => {
        return BASEAPIS.GETAPI('PredictionResultDone?MatchId=' + MatchId + '&ApiMatchId=' + ApiMatchId,);
    },

    GetParticipateContestUserTeam: async (matchId, contestId, userId, teamId) => {
        return BASEAPIS.GETAPI('GetParticipateContestUserTeam?MatchId=' +
            matchId +
            '&ContestId=' +
            contestId +
            '&UserId=' +
            userId + '&TeamId=' + teamId,);
    },

    FullSeriesDetail: async () => {
        return BASEAPIS.GETAPI('fullSeriesDetail');
    },

    GetUndeclareAllResultsMatches: async (fantasy) => {
        return BASEAPIS.GETAPI('getUndeclareAllResultsMatches?fantasy=' + fantasy);
    },
};
