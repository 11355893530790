import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { QuizAPI } from '../api/quizApi';
import Notiflix from 'notiflix';

export default function CreateQuizContestPage() {
    const [ContestCatList, setContestCatList] = useState([]);
    const [ContestCatId, setContestCat] = useState('');
    const [joinfees, setJoinFees] = useState('');
    const [winAmount, setWinAmount] = useState('');
    const [TotalWinner, setTotalWinner] = useState('');
    const [MaxTeam, setMaxTeam] = useState('');
    const [IsBonus, setIsBonus] = useState(false);
    const [IsConfirm, setIsConfirm] = useState(false);
    const [BonusPrice, setBonusPrice] = useState('');
    const [questionCount, setQuestionCount] = useState('');
    const [startDate, setstartDate] = useState('');
    useEffect(() => {
        GetContestCategory();
    }, []);

    const GetContestCategory = () => {
        QuizAPI.GetQuizCategory().then((data) => {
            if (data.Status) setContestCatList(data.Data);
        });
    };
    const contestChange = (e) => {
        // alert(e.target.value.split(','))
        // const arr=e.target.value.split(',');
        setContestCat(e.target.value);
        // setContestNm(arr[1])
        // alert(arr[0])
    };
    const joinfeesChange = (e) => {
        setJoinFees(e.target.value);
    };
    const winAmountChange = (e) => {
        setWinAmount(e.target.value);
    };
    const totalWinnerChange = (e) => {
        setTotalWinner(e.target.value);
    };
    const maxTeamChange = (e) => {
        setMaxTeam(e.target.value);
    };
    const QuestionCountChange = (e) => {
        setQuestionCount(e.target.value);
    };

    const SubmitBtn = () => {
        if (ContestCatId == '') Notiflix.Notify.failure('please select quiz contest category');
        else if (joinfees == '') Notiflix.Notify.failure('Join fees can\'t be blank');
        else if (winAmount == '') Notiflix.Notify.failure('Winning Amount can\'t be blank');
        else if (TotalWinner == '') Notiflix.Notify.failure('Total winner can\'t be blank');
        else if (MaxTeam == '') Notiflix.Notify.failure('Spots can\'t be blank');
        else if (questionCount == '') Notiflix.Notify.failure('Question Count can\'t be blank');
        else if (IsBonus == true && BonusPrice == '')
            Notiflix.Notify.failure('Bonus can\'t be blank');
        else if (startDate == '') Notiflix.Notify.failure('Start Date Time can\'t be blank');
        else {
            let contestData = {
                QuizCatId: ContestCatId,
                JoiningFees: joinfees,
                TotalWinningAmount: winAmount,
                TotalWinners: TotalWinner,
                MaxSpots: MaxTeam,
                QuestionCount: questionCount,
                Bonus: IsBonus == false ? 0 : BonusPrice,
                isGuranted: IsConfirm,
                DateAndTime: startDate,
            };
            // alert(JSON.stringify(contestData))

            QuizAPI.CreateQuizContest(contestData).then((data) => {
                Notiflix.Notify.success(data.Message);
                reset();
            });
        }
    };

    const reset = () => {
        setContestCat('');
        setJoinFees('');
        setWinAmount('');
        setTotalWinner('');
        setMaxTeam('');
        setIsBonus(false);
        setIsConfirm(false);
        setBonusPrice('');
        setQuestionCount('');
        setstartDate('');
    };

    const IsBonusChange = () => {
        setIsBonus(document.getElementById('checkbox2').checked);
    };
    const IsConfirmChange = () => {
        setIsConfirm(document.getElementById('checkbox3').checked);
    };
    const BonusChange = (e) => {
        setBonusPrice(e.target.value);
    };
    const StartDateChange = (e) => {
        setstartDate(e.target.value.split('T')[0] + ' ' + e.target.value.split('T')[1] + ':00');
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_all_quiz_contest'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-add-line'></i>View All Quiz Contest
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Create Quiz Contest</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Select Quiz Contest Category :
                                            </label>
                                            <select
                                                className='form-control mb-3'
                                                onChange={contestChange}
                                            >
                                                <option selected value=''>
                                                    Open this select menu
                                                </option>
                                                {ContestCatList.map((contest) => (
                                                    <option value={contest.QuizCatId}>
                                                        {contest.QuizCatName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='name'>Joining Fees</label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={joinfees}
                                                onChange={joinfeesChange}
                                                placeholder='Enter joining fee'
                                                defaultValue=''
                                                min={1}
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Total Winning Amount</label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={winAmount}
                                                onChange={winAmountChange}
                                                placeholder='Total winning'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Winners (Total Numbers of winners for a Particular
                                                Pool)
                                            </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={TotalWinner}
                                                onChange={totalWinnerChange}
                                                placeholder='Total numbers of winners'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Spots (Max Number of Team can Join the Pool)
                                            </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={MaxTeam}
                                                onChange={maxTeamChange}
                                                placeholder='Max Total Number of Team'
                                                defaultValue=''
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='name'>Question Count</label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={questionCount}
                                                onChange={QuestionCountChange}
                                                placeholder='Number of Question'
                                                defaultValue=''
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input '
                                                    id='checkbox2'
                                                    checked={IsBonus}
                                                    onChange={IsBonusChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='checkbox2'
                                                >
                                                    Is Bonus
                                                </label>
                                            </div>

                                            <input
                                                type='number'
                                                className='form-control my-3'
                                                value={BonusPrice}
                                                onChange={BonusChange}
                                                style={{
                                                    display: IsBonus == false ? 'none' : 'flex',
                                                }}
                                                placeholder='Bonus Percentage'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='checkbox3'
                                                    checked={IsConfirm}
                                                    onChange={IsConfirmChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='checkbox3'
                                                >
                                                    Is Guranted
                                                </label>
                                            </div>
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='exampleInputdatetime'>
                                                Match Start Date
                                            </label>
                                            <input
                                                type='datetime-local'
                                                className='form-control'
                                                id='exampleInputdatetime'
                                                value={startDate}
                                                onChange={StartDateChange}
                                            />
                                        </div>

                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={SubmitBtn}
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
