import React, { useEffect } from 'react';

export default function LegalityPage() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Legality</h4>

                                        <h4 className='card-title text-center my-4'>
                                            GAME OF SKILLS
                                        </h4>

                                        <p className='card-text'>
                                            Super4 is considered as a “Game of Skill.” The game of
                                            skills can be defined as a game wherein the skills of
                                            the individuals participating in any online fantasy
                                            sports gaming play a dominant role rather than the mere
                                            luck of the individuals. The individuals in the game of
                                            skills use their knowledge, skills, training and
                                            attention for participation and winning. Online fantasy
                                            sports gaming is considered to be a legal practice
                                            around the world with certain states being an exception.
                                            Online fantasy sports gaming is entirely based on the
                                            concept of Game of Skills, wherein the users form their
                                            own teams and are allocated points on the basis of
                                            on-field performance of their chosen players. Super4 is
                                            completely legal as it offers services, and Contest(s)
                                            related to fantasy cricket, fantasy football, fantasy
                                            kabaddi, fantasy volleyball, fantasy basketball &
                                            fantasy hockey. The services, contest(s) and program(s)
                                            related to online fantasy sports gaming offered by
                                            Super4 enables its users to create their own team prior
                                            the match begins and then the teams are awarded points
                                            on the basis of the real-life performance of the players
                                            chosen by the users in the team. The individual whose
                                            team scores the highest aggregate of points is announced
                                            as the winner. The formation of a team by the users of
                                            Super4 is entirely dependent on the skills, knowledge
                                            and attention of the users which makes online fantasy
                                            sports gaming offered at Super4 a game of skills and
                                            100% legal.
                                        </p>

                                        <p className='card-text'>
                                            <span className='font-weight-bold'>
                                                ALIGNMENT WITH THE INDIAN LAWS{' '}
                                            </span>
                                            The game of skills such as online fantasy sports gaming
                                            are considered to be legal all over India. expect
                                            certain states such as Sikkim, Assam, Odisha, Telangana,
                                            Nagaland, Andhra Pradesh. The Public Gambling Act, 1867
                                            (“PGA, 1867”) is recognized as the primary legality
                                            driving the prevalence of gambling in India. The PGA
                                            defines the act of “public gambling” and the keeping of
                                            a common gaming house as a criminal and punishable act
                                            in India. However, online fantasy sports gaming is
                                            considered to be an exception under the PGA, wherein the
                                            provisions and punishments of PGA shall not be
                                            applicable to games played by the user using their
                                            skills and knowledge as primary tools. SIKKIM, ASSAM,
                                            ODISHA, TELANGANA, NAGALAND, ANDHRA PRADESH. There are
                                            various states in India wherein the laws related to the
                                            game of skills are unclear. In this regard, for the
                                            websites offering online fantasy sports gaming, it
                                            remains unclear whether to offer the services for free
                                            or not. The Indian states with no clearance regarding
                                            the offering of pay-to-play contests include Sikkim,
                                            Assam, Odisha, Telangana, Nagaland, Andhra In this
                                            context, Super4 does not permit the users identified as
                                            the residents of Sikkim, Assam, Odisha, Telangana,
                                            Nagaland, Andhra Pradesh to participate in the
                                            pay-to-play contest(s) organized by Super4.
                                        </p>
                                    </div>
                                </div>

                                <div className='card'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
