import { BASEAPIS } from './BaseApis';

export const fundManager = {
    GETFunds: () => {
        return BASEAPIS.GETAPI('getDepositPayments');
    },

    AllWalletLogs: () => {
        return BASEAPIS.GETAPI('getUserWalletLog');
    },

    POSTORDERID: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'rectifyPaymentStatus');
    },

    PostAddFundDashboard: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'addUserWalletLog');
    },

    addFundsWithdrawl: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'addUserWalletLog');
    },

    addBonusinFunds: (data) => {
        return BASEAPIS.POSTAPI(data, 'addUserWalletLog');
    },

    addUnutilizeinFunds: (data) => {
        return BASEAPIS.POSTAPI(data, 'addUserWalletLog');
    },

    PostDeductFundDashboard: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'deductUserWalletLog');
    },

    createMasterPassowrdForDashboard: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'createMasterPassword');
    },

    CancelAbandonedFunds: (data) => {
        console.log(data);
        return BASEAPIS.POSTAPI(data, 'addUserWalletLog');
    },

    DepositAPI: async (start, end, amount) => {
        return await BASEAPIS.GETAPI('userDepositReport?startDate=' + start + '&endDate=' + end + '&amount=' + amount);
    },

    FirstTimeDepositAPI: async (start, end) => {
        return await BASEAPIS.GETAPI('firstTransaction1?start=' + start + '&end=' + end);
    },

    GetAllCoupons: async () => {
        return await BASEAPIS.GETAPI('getCouponInMonth');
    },

    CancelMatchNames: async (startDate, endDate) => {
        return await BASEAPIS.GETAPI('getMatchInDay?start=' + startDate + '&end=' + endDate);
    },

    fetchAllPayOutRazorPayX: async (account_num) => {
        return await BASEAPIS.GETAPI('fetchAllPayOutRazorPayX?account_number=' + account_num);
    }


    // ChangeCouponEndDate: async (json) => {
    //     return await BASEAPIS.PUTAPI(json, 'promoUpdate');
    // },

    // ChangeCouponStatusUpdate: async (jsonbody) => {
    //     return await BASEAPIS.PUTAPI(jsonbody, 'promoStatusUpdate');
    // }
};