import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { FetchAllPlayerAPI } from '../api/viewAllPlayerApi';

export default function EditPlayerPage() {
    const location = useLocation();
    const [player_name, setplayer_name] = useState(location.state.player_name);
    // console.log(location.state.player_name)
    const [pid, setpid] = useState(location.state.pid);
    // console.log(location.state.pid)
    const [credit, setCredit] = useState(location.state.credit);
    // console.log(location.state.credit)
    const [role, setRole] = useState(location.state.role);
    // console.log(location.state.role)
    const [tmp, setTmp] = useState(0);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        // Datasubmit();
    }, []);

    const playerNameChange = (e) => {
        setTmp(1);
        setplayer_name(e.target.value);
    };
    const creditChange = (e) => {
        setCredit(e.target.value);
    };

    const roleChange = (e) => {
        setRole(e.target.value);
    };

    const Datasubmit = (e) => {
        e.preventDefault();
        if (player_name == '' || player_name == null)
            Notiflix.Notify.failure('Player Name name can\'t blank');
        else if (credit == '' || credit == null) Notiflix.Notify.failure('Credit can\'t blank');
        else if (tmp == 1) {
            let editData = {
                pid: pid,
                player_name: player_name,
                credit: credit,
                role: role,
            };
            Notiflix.Notify.success(JSON.stringify(editData));
            FetchAllPlayerAPI.EditPlayer(editData).then((data) => {
                console.log(data);
            });
        } else {
            let editData = {
                pid: pid,
                player_name: player_name,
                credit: credit,
                role: role,
            };
            Notiflix.Notify.success(JSON.stringify(editData));
            FetchAllPlayerAPI.EditPlayer(editData).then((data) => {
                console.log(data);
            });
        }
    };

    // const EditView=(pName,role,credit)=>{
    //     // setSeriesId(sid);
    //     setPnameEdit(pName);
    //     // setRoleEdit(role);
    //     setCreditEdit(credit);
    //     // setSeriesEdit(series);
    //     // setSDateEdit(sdate);
    //     // setEDateEdit(edate);
    // }

    // const EditSubmit=()=>{
    //     let data={
    //         pName: pNameEdit,
    //         credit: creditEdit
    //     }
    //     FetchAllPlayerAPI.EditPlayer(data).then((data)=>{
    //         alert(data.message);

    //     })
    // }
    // const editPname=(e)=>{
    //     setPnameEdit(e.target.value)
    // }
    // const editCredit=(e)=>{
    //     setCreditEdit(e.target.value)

    // }
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <Link
                                    to={'/view_player'}
                                    className='button btn btn-skyblue button-icon'
                                >
                                    <i className='ml-2 ri-add-line'></i>View All Players
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Edit Players</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Player Name :</label>
                                        <input
                                            value={player_name}
                                            onChange={playerNameChange}
                                            type='text'
                                            className='form-control'
                                            placeholder='Player Name'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Credit :</label>
                                        <input
                                            value={credit}
                                            onChange={creditChange}
                                            type='number'
                                            className='form-control'
                                            placeholder='Credit'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Select Role :</label>
                                        <select
                                            className='form-control mb-3'
                                            value={role}
                                            onChange={roleChange}
                                        >
                                            <option>Select Role</option>
                                            <option value='all'>All Rounder</option>
                                            <option value='bowl'>Bowler</option>
                                            <option value='bat'>Batman</option>
                                            <option value='wk'>Wicket Keeper</option>
                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                    <label htmlFor="name">Image upload :</label>
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="customFile"/>
                        <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                    </div>
                </div> */}
                                    <button
                                        onClick={(e) => Datasubmit(e)}
                                        className='btn btn-primary'
                                    >
                                        Submit
                                    </button>
                                    &nbsp;
                                    {/* <button className="btn bg-danger">Reset</button> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
