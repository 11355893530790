import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { TeamAPI } from '../api/teamApi';
import { CSVLink } from 'react-csv';

function ViewTeamPage() {
    const [teamAll, setTeamAll] = useState([]);
    const [searchTeam, setSearch] = useState('');
    const [teamId, setTeamId] = useState('');
    const [teamTitle, setTeamTitle] = useState('');
    const [teamabbr, setTeamAbbr] = useState('');
    const [selImg, setSelImg] = useState('');
    const [color, setColor] = useState('');
    const role = localStorage.getItem('role');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const headers = [
        { label: 'Team Name', key: 'title' },
        { label: 'Team Short Name', key: 'abbr' },
        { label: 'Team Logo', key: 'logo_url' },
        { label: 'Team Id', key: 'teamId' },
    ];

    const anotherScript = () => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
    };
    const GetTeamDetail = () => {
        TeamAPI.ExportTeam().then((data) => {
            console.log(data);
            // alert(data.status_code)
            if (data.status_code)
                TeamAPI.GetTeam(searchTeam).then((data) => {
                    // console.log(data)
                    data.status_code == true ? setTeamAll(data.message) : setTeamAll([]);
                });
        });
    };

    const filterTeamDetail = () => {
        TeamAPI.FilterExportTeam().then((data) => {
            setTeamAll(data);
        });
    };

    const getTeam = () => {
        TeamAPI.GetTeam(searchTeam).then((data) => {
            console.log(data);
            data.status_code == true ? setTeamAll(data.message) : setTeamAll([]);
        });
    };

    const editData = () => {
        const data = new FormData();
        data.append('tid', teamId);
        data.append('Image', selImg);
        data.append('title', teamTitle);
        data.append('abbr', teamabbr);
        data.append('color', color);
        TeamAPI.EditTeam(data).then((res) => {
            // swal('Banner Added');
            Notiflix.Notify.success('Data editted!!');
            // console.log(data)
        });
    };

    const SearchTeam = (e) => {
        setSearch(e.target.value);
    };
    const reset = () => {
        setSearch('');
        getTeam();
    };
    const editView = (tid, img, title, abbr, color) => {
        setTeamId(tid);
        setSelImg(img);
        setTeamTitle(title);
        setTeamAbbr(abbr);
        setColor(color);
        anotherScript();
    };
    const EditTeamAbbr = (e) => {
        setTeamAbbr(e.target.value);
    };
    const EditTeamTitle = (e) => {
        setTeamTitle(e.target.value);
    };
    const imgPath = (e) => {
        // if (e.target.files && e.target.files[0]) {
        //     let reader =new FileReader();
        //     reader.onload=(ev)=>{
        //         alert(ev.target.result)
        //         setSelImg(ev.target.result)
        //     };
        //     reader.readAsDataURL(e.target.files[0]);
        // }
        setSelImg(e.target.files[0]);
    };

    const colorChange = (e) => {
        setColor(e.target.value);
    };
    // const Idimg=()=>{
    //     alert(JSON.stringify(document.getElementById('customFile').files))
    // }

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={searchTeam}
                                                onChange={SearchTeam}
                                                placeholder='Search By Team Name'
                                            />
                                        </div>

                                        <div className='col'>
                                            <br />
                                            <button
                                                type='submit'
                                                className='mt-2 btn btn-primary'
                                                onClick={getTeam}
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            <button className='mt-2 btn bg-danger' onClick={reset}>
                                                <i className='ri-creative-commons-sa-line'></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    <div className='navbar-breadcrumb'>
                                        <h4 className='mb-0'>&nbsp;</h4>
                                    </div>
                                    <div className=''>
                                        <div className='btn-group m-2' role='group'>
                                            <button
                                                id='btnGroupDrop1'
                                                type='button'
                                                className='btn btn-primary dropdown-toggle'
                                                data-toggle='dropdown'
                                                aria-haspopup='true'
                                                aria-expanded='false'
                                            >
                                                Export
                                            </button>
                                            <div
                                                className='dropdown-menu'
                                                aria-labelledby='btnGroupDrop1'
                                                style={{}}
                                            >
                                                <button className='dropdown-item' href='#'>
                                                    Export All data
                                                </button>
                                                <CSVLink
                                                    filename='View All Teams'
                                                    data={teamAll}
                                                    headers={headers}
                                                >
                                                    <button className='dropdown-item' href='#'>
                                                        Export This Data
                                                    </button>
                                                </CSVLink>
                                                {/* <a className="dropdown-item" href="#">Delete</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Team</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Team Name</th>
                                                    <th>Team Short Name</th>
                                                    <th>Team Logo</th>
                                                    {role!=3 && role!=5 && <th>Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {teamAll.map((team, ind) => (
                                                    <tr>
                                                        <td>{++ind}</td>
                                                        <td>{team.title}</td>
                                                        <td>{team.abbr}</td>
                                                        <td>
                                                            <img
                                                                src={team.logo_url}
                                                                style={{ width: '50px' }}
                                                            />
                                                        </td>
                                                        {role!=3 && role!=5 &&<td>
                                                            <button
                                                                title='Edit'
                                                                type='button'
                                                                onClick={() =>
                                                                    editView(
                                                                        team.teamId,
                                                                        team.logo_url,
                                                                        team.title,
                                                                        team.abbr
                                                                    )
                                                                }
                                                                className='btn btn-primary btn-m'
                                                                data-toggle='modal'
                                                                data-target='#exampleModalScrollable'
                                                            >
                                                                <i className='ri-edit-line'></i>
                                                            </button>
                                                        </td>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='modal fade'
                            id='exampleModalScrollable'
                            tabIndex='-1'
                            role='dialog'
                            aria-labelledby='exampleModalScrollableTitle'
                            aria-hidden='true'
                        >
                            <div className='modal-dialog modal-dialog-scrollable' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h5
                                            className='modal-title'
                                            id='exampleModalScrollableTitle'
                                        >
                                            Edit Team
                                        </h5>
                                        <button
                                            type='button'
                                            className='close'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            <span aria-hidden='true'>&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <form>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Team Name :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={teamTitle}
                                                    onChange={EditTeamTitle}
                                                    placeholder='Chennai Super King'
                                                    defaultValue='Chennai Super King'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Team Short Name :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={teamabbr}
                                                    onChange={EditTeamAbbr}
                                                    placeholder='Team Short Name'
                                                    defaultValue='CSK'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Team ID :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    disabled
                                                    value={teamId}
                                                    placeholder='Team Key'
                                                    defaultValue='610'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Team Colour :</label>
                                                <input
                                                    value={color}
                                                    onChange={colorChange}
                                                    type='color'
                                                    className='form-control'
                                                    placeholder='Team Colour'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Select Team Logo :</label>
                                                <div className='custom-file'>
                                                    <input
                                                        type='file'
                                                        name='Choose file'
                                                        className='custom-file-input'
                                                        accept='image/png, image/jpeg'
                                                        onChange={imgPath}
                                                        id='customFile'
                                                    />
                                                    <label
                                                        className='custom-file-label'
                                                        htmlFor='customFile'
                                                    >
                                                        Choose file
                                                    </label>
                                                    {/* <img src={selImg} alt="preview image" style={{width:"100px"}}  /> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-secondary'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                        <button
                                            onClick={() => editData()}
                                            type='button'
                                            className='btn btn-primary'
                                            data-dismiss='modal'
                                        >
                                            Save changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewTeamPage;
