import Notiflix from 'notiflix';
import { useState } from 'react';
import { useEffect } from 'react';
import { BankAPI } from '../api/VerifyBankApi';
import { RotatingLines } from 'react-loader-spinner';
import { NotificationAPI } from '../api/NotificationApi';
import { Link } from 'react-router-dom';

export default function VerifyBankAccountPage() {
    const [bank, setBank] = useState();
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [token, setToken] = useState('');
    const [entries, setEntries] = useState(50);
    const [editUserId, setEditUserId] = useState('');
    const [editBank_name, setEditBank_name] = useState('');
    const [editAccount_number, setEditAccount_number] = useState('');
    const [editIfsc_code, setEditIfsc_code] = useState('');
    const [editBranch, setEditBranch] = useState('');
    const [editState, setEditState] = useState('');
    // const [isIfscEditable, setisIfscEditable] = useState(true);
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [user_id, setuser_id] = useState('');

    const [pageSize, setPageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalLength, setTotalLength] = useState(1);
    const role = localStorage.getItem('role');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        bankInfo();
    // bankFilter();
    }, []);
    const bankInfo = () => {
        setEditUserId('');
        setEditBank_name('');
        setEditAccount_number('');
        setEditIfsc_code('');
        setEditBranch('');
        setEditState('');
        setToken('');
        setIsLoading(true);

        BankAPI.GetBankInfo().then((data) => {
            var bank_i = JSON.parse(JSON.stringify(data));
            console.log(bank_i);
            setTotalLength(bank_i.total_length);
            setTotalPages(bank_i.total_page);
            setBank(bank_i.details);
            setIsLoading(false);
        });
    };

    // Pagination ---------------

    useEffect(() => {
        bankFilter();
    }, [pageNumber]);
    // ---------------------------

    const rejectFilter = ()=>{
        BankAPI.RejectBankInfo(user_id,state,phone,email,date).then((res)=>{

            if(res.status_code){

                setTotalLength(res.total_length);
                setTotalPages(res.total_page);
                setBank(res.details);
                setBank(res.details);
      
            }
            setIsLoading(false);
        });
    };

    const bankFilter = () => {
        setIsLoading(true);


        if(status==='Rejected'){
            rejectFilter();
            return;
        }
        try {
            BankAPI.FilterBankInfo(
                status,
                entries,
                name,
                user_id,
                email,
                phone,
                state
            ).then((data) => {
                var bankInfo_i = JSON.parse(JSON.stringify(data));
                // console.log(bankInfo_i.details);
                setTotalLength(bankInfo_i.total_length);
                setTotalPages(bankInfo_i.total_page);
                setBank(bankInfo_i.details);
                setIsLoading(false);
            });
        } catch (err) {
            Notiflix.Notify.failure('error', err);
        }
    };

    const editValues =(user_id, bank_name, account_number, ifsc_code,branch,state)=>{



        setEditUserId(user_id);
        setEditBank_name(bank_name);
        setEditAccount_number(account_number);
        setEditIfsc_code(ifsc_code);
        setEditBranch(branch);
        setEditState(state);

    };

    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };
    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const nameChange = (e) => {
        setName(e.target.value);
    };

    const userIdChange = (e) => {
        setuser_id(e.target.value);
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
    };

    const statusChange = (e) => {
        setStatus(e.target.value);
    };

    const stateChange = (e) => {
        setState(e.target.value);
    };

    const Reset = () => {
        setName('');
        setEmail('');
        setuser_id('');
        setPhone('');
        setStatus('');
        setState('');
        bankInfo();
    };

    const RejectBankStatus = (user_id, status) => {
        setComment('');
        let data = {
            user_id: editUserId,
            status: status,
            comment:comment
        };

        const json = {
            to: token,
            notification: {
                body: comment,
                title: 'Bank Verification Rejected',
            },
        };

        const userData ={
            'userId':user_id
        };
   
        BankAPI.UpdateBankVerificationStatus(data).then((res) => {
            NotificationAPI.FCMCustomNotification(json).then((fcmRes) => {});
            var bank_i = JSON.parse(JSON.stringify(res));
            Notiflix.Notify.success(bank_i.message);
            bankInfo();
            //   console.log(bank_i)
        });
    };

    const updateBankStatus = (e, user_id, status) => {


        e.preventDefault();

        let data = {
            user_id: user_id,
            status: status,
        };

        const Myjson = {
            to: token,
            notification: {
                body: 'Your Bank Verification is Successfull',
                title: 'Bank Verification',
            },
        };
        // console.log(data)






        BankAPI.UpdateBankVerificationStatus(data).then((res) => {
            NotificationAPI.FCMCustomNotification(Myjson).then((fcmRes) => {});
            var bank_i = JSON.parse(JSON.stringify(res));
            Notiflix.Notify.success(bank_i.message);
            bankInfo();
            //   console.log(bank_i)
        });
    };


    const EditSubmit = ()=>{

    };

    const handleIfsc =(e)=>{
    // setEditIfsc_code(e.target.value)
        if(editIfsc_code.length==11){

            BankAPI.GetIfsc(editIfsc_code).then((res)=>{
                console.log(res);
                setEditBranch(res.BRANCH);
                setEditState(res.STATE);
                // setisIfscEditable(false)
  
            });
        }
    };


    const clearAllData = ()=>{

        setEditUserId('');
        setEditBank_name('');
        setEditAccount_number('');
        setEditIfsc_code('');
        setEditBranch('');
        setEditState('');
        // setisIfscEditable(true)

    };

    const EditBankDetails = ()=>{
        const json = {
            'userId':editUserId,
            'bank_name':editBank_name,
            'account_number':editAccount_number,
            'ifsc_code':editIfsc_code,
            'state':editState,
            'branch':editBranch

        };

        console.log(json);
        BankAPI.EditBankDetails(json).then((res)=>{
            if(res.status){

                Notiflix.Notify.success(res.message);
                bankInfo();

                setEditUserId('');
                setEditBank_name('');
                setEditAccount_number('');
                setEditIfsc_code('');
                setEditBranch('');
                setEditState('');
                // setisIfscEditable(true)
            }
        });
    };

    const handleRejectedDate = (myDate)=>{

        const d = new Date(myDate);
        console.log(d);
        return d;

  

    };

    return (
        <div className="wrapper">
            <div className="content-page rtl-page">
                <div className="container-fluid">
                    <form>
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label htmlFor="name">Status :</label>
                                                <select
                                                    value={status}
                                                    onChange={statusChange}
                                                    className="form-control mb-3"
                                                >
                                                    <option defaultValue={'Pending'} value="Pending">
                            Pending
                                                    </option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Not-Uploaded">Not-Uploaded</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <label htmlFor="name">Search By User Id</label>
                                                <input
                                                    onChange={userIdChange}
                                                    value={user_id}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search By User ID"
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="name">Search By Name :</label>
                                                <input
                                                    onChange={nameChange}
                                                    value={name}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search By Username"
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="email">Search By Email Id :</label>
                                                <input
                                                    onChange={emailChange}
                                                    value={email}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search By Email Id"
                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="phone">Search By Mobile :</label>
                                                <input
                                                    onChange={phoneChange}
                                                    value={phone}
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Search By Mobile No."
                                                />
                                            </div>



                                            {status==='Rejected' &&<div className="col">
                                                <label htmlFor="phone">Search By Date:</label>
                                                <input
                                                    onChange={(e)=>setDate(e.target.value)}
                                                    value={date}
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="Search By Mobile No."
                                                />
                                            </div>}

                                            {/* <div className="col">
                        <label htmlFor="phone">Search By State :</label>
                        <input
                          onChange={stateChange}
                          value={state}
                          type="text"
                          className="form-control"
                          placeholder="Search By Mobile No."
                        />
                      </div> */}

                                            <div className="col" style={{ paddingTop: '0.5pc' }}>
                                                <br />
                                                {/* <label htmlFor="name"></label> */}
                                                <button
                                                    type="button"
                                                    onClick={() => bankFilter()}
                                                    className="btn btn-primary"
                                                >
                          Search
                                                </button>
                        &nbsp;
                                                {/* <label htmlFor="reset"></label> */}
                                                {/* <button onClick={() => Reset()} type="submit" className="btn bg-danger"><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input
                                                    type="reset"
                                                    className="btn btn-danger"
                                                    onClick={() => Reset()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <div className="d-flex align-items-center justify-content-between welcome-content">
                                <div className="navbar-breadcrumb">
                                    <h4 className="mb-0">&nbsp;</h4>
                                </div>
                                <div className="">
                                    <div className="btn-group m-2" role="group">
                                        <button
                                            id="btnGroupDrop1"
                                            type="button"
                                            className="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                      Export
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="btnGroupDrop1"
                                            style={{}}
                                        >
                                            <button className="dropdown-item" href="#">
                        Export All data
                                            </button>
                                            <button className="dropdown-item" href="#">
                        Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">
                    Bank Account Verification Requests List
                                    </h4>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="px-2 h-60 d-flex align-items-center justify-content-center bg-gradient">
                                    <RotatingLines
                                        marginTop="40"
                                        strokeColor="#c1030d"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="50"
                                        visible={true}
                                    />
                                </div>
                            </div>
                        )}

                        {!isLoading && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-editable" id="table">
                                            <div className="table-responsive">
                                                <table
                                                    // id="datatable"
                                                    className="table table-striped table-bordered"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            <th>Account Holder Name</th>
                                                            <th>PAN Name</th>
                                                            {/* <th>Name</th> */}
                                                            <th>Email</th>
                                                            <th>Mobile No.</th>
                                                            <th>Account Numer</th>
                                                            <th>IFSC Code</th>
                                                            
                                                            {status==='Rejected' && <th>Created At</th>}
                                                            {status==='Rejected' && <th>Commennt</th>}
                                                           
                                                            <th>Status</th>
                                                            {role!=3 && role!=5 &&<th>Action</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bank &&
                              bank.length > 0 &&
                              bank.map((bank, i) => {
                                  return (
                                      <>
                                          <tr>
                                              <td>{++i}</td>
                                              <td>
                                                  <button className='users_link'>
                                                      <Link
                                                          className='users_linkk'
                                                          to={
                                                              '/get_user_details'
                                                          }
                                                          state={{
                                                              userId: bank.user_id,
                                                          }}
                                                          type='submit'
                                                          // className='py-4'
                                                      >
                                                          {bank.user_id}
                                                      </Link>
                                                  </button>
                                              </td>
                                              <td>{bank.bank_name}</td>
                                              <td>{bank.pan_name}</td>
                                              <td>{bank.email}</td>
                                              <td>{bank.phone}</td>
                                              <td>{bank.account_number}</td>
                                              <td>{bank.ifsc_code}</td>
                                          
                                              {/* <td>{()=>handleRejectedDate(bank.createdAt)}</td> */}
                                              {status==='Rejected' &&<td>{bank.createdAt}</td>}
                                              {status==='Rejected' &&<td>{bank.comment}</td>}
                                          
                                              <td>{bank.status}</td>
                                              {role!=3 && role!=5 &&<td>
                                                  {!(
                                                      bank.status === 'Not-Uploaded' ||
                                          bank.status === 'rejected' ||
                                          bank.status === 'Approved'
                                                  ) && (
                                                      <button
                                                          className="btn btn-primary btn-sm"
                                                          onClick={(e) =>
                                                              updateBankStatus(
                                                                  e,
                                                                  bank.user_id,
                                                                  true
                                                              )
                                                          }
                                                      >
                                                          <i className="ri-delete-bin-line"></i>
                                            Verify
                                                      </button>
                                                  )}

                                                  <span></span>&nbsp;
                                      
                                                  <br />
                                                  {(bank.status === 'pending') && (
                                                      <button
                                                          title="Edit"
                                                          type="button"
                                                          onClick={() => editValues(bank.user_id, bank.bank_name, bank.account_number, bank.ifsc_code,bank.branch,bank.state)}
                                                          className="btn btn-sm btn-skyblue"
                                                          data-toggle="modal"
                                                          data-target="#editmodal"
                                                      >
                                                          <i className="ri-delete-bin-line"></i>
                                            Edit
                                           
                                                      </button>
                                                  )}
                                      
                                                  <span></span>&nbsp;
                                      
                                                  <br />
                                                  <br />
                                                  {(bank.status === 'pending' ||
                                          bank.status === 'Approved') && (
                                                      <button
                                                          title="Edit"
                                                          type="button"
                                                          className="btn btn-primary btn-sm"
                                                          data-toggle="modal"
                                                          data-target="#exampleModalScrollable"
                                                          onClick={() => {
                                                              setEditUserId(bank.user_id);
                                                              setToken(bank.token);
                                                          }}
                                                      >
                                                          <i className="ri-edit-line"></i>
                                            Reject
                                                      </button>
                                                  )}
                                              </td>}
                                          </tr>
                                      </>
                                  );
                              })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-12 d-flex align-items-center">
                            <label className="p-2" htmlFor="name">
                Show
                            </label>
                            <select
                                // onChange={changePageSize}
                                className="form-control m-2 mb-3 col-2 d-flex align-items-center"
                            >
                                <option selected>50</option>
                            </select>
                            <nav aria-label="Page navigation example ">
                                <ul className="pagination">
                                    <li className="page-item" onClick={handlePrevious}>
                                        <a
                                            className="page-link"
                                            // href="#"
                                            aria-label="Previous"
                                        >
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item active">
                                        <button className="page-link">{pageNumber}</button>
                                    </li>

                                    <li
                                        className="page-item"
                                        onClick={() => {
                                            handleNext();
                                        }}
                                    >
                                        <a className="page-link" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="col-6 d-flex align-items-center">
                                <h5 className="d-flex p-2 mb-3">of {totalPages} Pages</h5>

                                <h5 className="d-flex p-2 mb-3">and {totalLength} users</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="exampleModalScrollable"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalScrollableTitle">
                  Why are you Rejecting this Bank Request ?
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name">User Id</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editUserId}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Comment</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            placeholder="Enter Comment Here"
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                  
                                >
                  Close
                                </button>
                                <button
                                    data-dismiss="modal"
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => RejectBankStatus(bank.user_id, false)}
                                >
                  Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
 


                <div
                    className="modal fade"
                    id="editmodal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalScrollableTitle"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalScrollableTitle">
                  Edit Bank Details
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={clearAllData}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name">UserId</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editUserId}
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Account Holder Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editBank_name}
                                            onChange={(e)=>{setEditBank_name(e.target.value);}}
                                            // placeholder="Chennai Super King"
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="name">Account Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editAccount_number}
                                            onChange={(e)=>{setEditAccount_number(e.target.value);}}
                                            // placeholder="Chennai Super King"
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="name">Ifsc Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editIfsc_code}
                                            onChange={(e)=>{setEditIfsc_code(e.target.value);}}
                                            // disabled={!isIfscEditable}

                                            // placeholder="Chennai Super King"
                                        />
                                        <button onClick={handleIfsc} title="Edit"
                                            type="button"
                                            className="btn btn-primary btn-sm">Verify Ifsc</button>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="name">Branch</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editBranch}
                                            onChange={(e)=>{setEditBranch(e.target.value);}}
                                            disabled
                                            // placeholder="Chennai Super King"
                                        />
                                    </div>



                                    <div className="form-group">
                                        <label htmlFor="name">State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={editState}
                                            onChange={(e)=>{setEditState(e.target.value);}}
                                            disabled
                                            // placeholder="Chennai Super King"
                                        />
                                    </div>
                   
                   
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={clearAllData}
                                >
                  Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={EditBankDetails}
                                >
                  Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
        </div>
    );
}