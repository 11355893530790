import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ScoreBoardAPI } from '../api/scoreBoardAPI';

export default function ScoreBoard() {
    const [match, setMatchFormat] = useState([]);
    const [format, setFormat] = useState([]);
    const [selectedFormat, setSelectedFormat] = useState('');
    const [editLBWBonus, setEditLBWBonus] = useState('');
    const [editBowledBonus, setEditBowledBonus] = useState('');
    const [editCatch, setEditCatch] = useState('');
    const [editDuck, setEditDuck] = useState('');
    const [editFour, setEditFour] = useState('');
    const [editMaidenBonus, setEditMaidenBonus] = useState('');
    const [editMaidenOver, setEditMaidenOver] = useState('');
    const [editRunout, setEditRunout] = useState('');
    const [editRuns, setEditRuns] = useState('');
    const [editSix, setEditSix] = useState('');
    const [editStarting11, setEditStarting11] = useState('');
    const [editStumping, setEditStumping] = useState('');
    const [editWicket, setEditWicket] = useState('');
    const [id, setId] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        fetchPoint();
        GetFormat();
    }, []);
    const fetchPoint = (match_format) => {
        ScoreBoardAPI.FetchScoreBoard(match_format).then((data) => {
            // var point = JSON.parse(JSON.stringify(data));
            // console.log(playerInfo_i.message)
            if (data.status_code) {
                setMatchFormat(data.message);
                console.log(data.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            // GetFormat();
        });
    };
    const GetFormat = () => {
        ScoreBoardAPI.FetchMatchFormat().then((data) => {
            console.log(data.message);
            if (data.status_code) setFormat(data.message);
        });
        fetchPoint();
    };
    const matchChange = (e) => {
        setSelectedFormat(e.target.value);
    };

    const EditSubmit = (e) => {
        const json = {
            _id: id,
            match_format: selectedFormat,
            LBW_bonus: editLBWBonus,
            bowled_bonus: editBowledBonus,
            per_catch: editCatch,
            duck: editDuck,
            four: editFour,
            maiden_bonus: editMaidenBonus,
            maiden_over: editMaidenOver,
            runout: editRunout,
            runs: editRuns,
            six: editSix,
            starting11: editStarting11,
            stumping: editStumping,
            wicket: editWicket,
        };
        EditMatchPoint(json);
    };

    const EditMatchPoint = (data) => {
        ScoreBoardAPI.EditScoreboard(data).then((data) => {
            // var point = JSON.parse(JSON.stringify(data));
            // console.log(playerInfo_i.message)
            if (data.status_code) {
                Notiflix.Notify.success('Edited Successfully');
                console.log(data.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            // GetFormat();
        });
    };

    const editHandler = (data) => {
        // setSelectedFormat (e.target.value)

        setEditLBWBonus(data.LBW_bonus);
        setEditCatch(data.per_catch);
        setEditDuck(data.duck);
        setEditFour(data.four);
        setEditMaidenBonus(data.maiden_bonus);
        setEditMaidenOver(data.maiden_over);
        setEditRunout(data.runout);
        setEditRuns(data.runs);
        setEditSix(data.six);
        setEditStarting11(data.starting11);
        setEditStumping(data.stumping);
        setEditWicket(data.wicket);
        setEditBowledBonus(data.bowled_bonus);
        setId(data._id);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Match Format :</label>
                                                {/* <input id="match" value={match_format} onChange={matchChange} type="text" className="form-control" /> */}
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={matchChange}
                                                >
                                                    <option selected value=''>
                                                        Select Match Format
                                                    </option>
                                                    {format &&
                                                        format.length > 0 &&
                                                        format.map((format) => (
                                                            <option value={format.format}>
                                                                {format.format}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>

                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    type='button'
                                                    onClick={() => fetchPoint(selectedFormat)}
                                                    className='btn btn-primary'
                                                >
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button type="submit" className="btn bg-danger" onClick={resetBtn}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                {/* <input type="reset" onClick={() => resetBtn()} className="btn btn-danger"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {/* <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="card">

                                
                            </div>
                        </div>
                    </div> */}

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Points Table</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>LBW Bonus</th>
                                                        <th>bowled_bonus</th>
                                                        <th>catch</th>
                                                        <th>duck</th>
                                                        <th>four</th>
                                                        <th>maiden_bonus</th>
                                                        <th>maiden_over</th>
                                                        <th>runout</th>
                                                        <th>runs</th>
                                                        <th>six</th>
                                                        <th>starting11</th>
                                                        <th>stumping</th>
                                                        <th>wicket</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {match &&
                                                        match.length > 0 &&
                                                        match.map((data, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{data.LBW_bonus}</td>
                                                                    <td>{data.bowled_bonus}</td>
                                                                    <td>{data.per_catch}</td>
                                                                    <td>{data.duck}</td>
                                                                    <td>{data.four}</td>
                                                                    <td>{data.maiden_bonus}</td>
                                                                    <td>{data.maiden_over}</td>
                                                                    <td>{data.runout}</td>
                                                                    <td>{data.runs}</td>
                                                                    <td>{data.six}</td>
                                                                    <td>{data.starting11}</td>
                                                                    <td>{data.stumping}</td>
                                                                    <td>{data.wicket}</td>
                                                                    <td>
                                                                        <button
                                                                            onClick={() => {
                                                                                editHandler(data);
                                                                            }}
                                                                            title='Edit'
                                                                            type='button'
                                                                            className='btn btn-sm btn-skyblue'
                                                                            data-toggle='modal'
                                                                            data-target='#exampleModalScrollable'
                                                                        >
                                                                            <i className='ri-edit-line'>
                                                                                Edit
                                                                            </i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className='modal fade'
                id='exampleModalScrollable'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalScrollableTitle'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-scrollable' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                Edit Scoreboard
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form>
                                <div className='form-group'>
                                    <label htmlFor='name'>Edit LBW Bonus</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editLBWBonus}
                                        onChange={(e) => {
                                            setEditLBWBonus(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Bowled Bonus</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editBowledBonus}
                                        onChange={(e) => {
                                            setEditBowledBonus(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Catch</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editCatch}
                                        onChange={(e) => {
                                            setEditCatch(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Duck</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editDuck}
                                        onChange={(e) => {
                                            setEditDuck(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Four</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editFour}
                                        onChange={(e) => {
                                            setEditFour(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Maiden Bonus</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editMaidenBonus}
                                        onChange={(e) => {
                                            setEditMaidenBonus(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Maiden Over</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editMaidenOver}
                                        onChange={(e) => {
                                            setEditMaidenOver(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Runout</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editRunout}
                                        onChange={(e) => {
                                            setEditRunout(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Runs</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editRuns}
                                        onChange={(e) => {
                                            setEditRuns(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Six</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editSix}
                                        onChange={(e) => {
                                            setEditSix(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Starting11</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editStarting11}
                                        onChange={(e) => {
                                            setEditStarting11(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Stumping</label>
                                    <input
                                        type='number'
                                        className='form-control'
                                        value={editStumping}
                                        onChange={(e) => {
                                            setEditStumping(e.target.value);
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='name'>Edit Wicket</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        value={editWicket}
                                        onChange={(e) => {
                                            setEditWicket(e.target.value);
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                data-dismiss='modal'
                            >
                                Close
                            </button>
                            <button type='button' className='btn btn-primary' onClick={EditSubmit}>
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
