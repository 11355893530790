import { useState } from 'react';
import { Link } from 'react-router-dom';
import './ViewAllUsers.css';
import Notiflix from 'notiflix';
import { SupportAPI } from '../api/Support';
import { ReportAPI } from '../api/ReportAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Modal, Table } from 'antd';
import moment from 'moment';


export default function ModificationReport() {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [userId, setUserId] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [modificationData, setModificationData] = useState([]);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [changeArray, setChangeArray] = useState([]);

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const modificationUsersData = () => {
        setIsLoading(true);
        if (emailId.length > 0 || phoneNumber.length > 0 || teamName.length > 0 || userId.length > 0) {
            ReportAPI.ModificationReport(phoneNumber, emailId, teamName, userId).then((data) => {
                if (data.status_code) {
                    console.log(data);
                    Notiflix.Notify.success('✅');
                    setModificationData(data.data);
                    setIsLoading(false);
                }
                else {
                    Notiflix.Notify.failure('No Changes in User Details');
                    setIsLoading(false);
                }
            });
        } else {
            Notiflix.Notify.warning('Please Fill any one Field');
            setIsLoading(false);
        }
    };

    const reset = () => {
        setEmailId('');
        setPhoneNumber('');
        setTeamName('');
        setUserId('');
        setModificationData([]);
        setChangeArray([]);
    };

    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        return newDate;
    };

    const handleDataChange = (changeArray) => {
        setIsModalOpen(true);

        setChangeArray(changeArray);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='offers_couponDiv'>
                                <h1 className='coupon_h1'>Modification Report</h1>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 34, alignItems: 'center' }}>
                                        <div >
                                            <label className='offer_checkbox'>Phone Number</label>
                                            <input type='number' value={phoneNumber} required onChange={handleAllChange(setPhoneNumber)} className='offers_input' placeholder='Enter Phone Number' />
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Email ID</label>
                                            <input value={emailId} onChange={handleAllChange(setEmailId)} className='offers_input' placeholder='Enter Email ID' />
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Team Name</label>
                                            <input value={teamName} onChange={handleAllChange(setTeamName)} className='offers_input' placeholder='Enter Team Name' />
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>User Id</label>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                                                <input value={userId} onChange={handleAllChange(setUserId)} className='offers_input' placeholder='Enter User ID' />

                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                                                    <button className='modification-button' onClick={modificationUsersData}>Search User</button>
                                                    <button className='modification-button-reset' onClick={reset}>Reset</button>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-sm-12 col-lg-12'>
                                    <div className='card'>
                                        <div className='row'>
                                            <div className='col-sm-12 col-lg-12'>
                                                {
                                                    isLoading ? <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                        <div>
                                                            <RotatingLines
                                                                marginTop='70'
                                                                strokeColor='#c1030d'
                                                                strokeWidth='5'
                                                                animationDuration='0.75'
                                                                width='50'
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </div>
                                                        : <div className='card'>
                                                            <div className='card-body'>
                                                                <div
                                                                >
                                                                    <div className='table-responsive'>
                                                                        <table className='table table-striped table-bordered'>
                                                                            <thead>
                                                                                <tr className='support_main_file_div'>
                                                                                    <th>S.No.</th>
                                                                                    <th>User Id</th>
                                                                                    <th>Updated At</th>
                                                                                    <th>Email</th>
                                                                                    <th>Phone</th>
                                                                                    <th>Team Name</th>
                                                                                    <th>Bank Account</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    modificationData?.length === 0 ?
                                                                                        <div className='allMatches-div-res'>
                                                                                            <h1>Search for User</h1>
                                                                                        </div>
                                                                                        :
                                                                                        modificationData?.map((userData, i) => {
                                                                                            return (
                                                                                                <tr key={i}>
                                                                                                    <td>{++i}</td>
                                                                                                    <td>{userData.user_id}</td>
                                                                                                    <td>{handleDate(userData.updatedAt)}</td>
                                                                                                    <td>{userData.email.length === 0 ? 'No Changes' : <button className='viewAllModificatiolList' onClick={() => handleDataChange(userData.email)}>See</button>}</td>
                                                                                                    <td>{userData.phone.length === 0 ? 'No Changes' : <button className='viewAllModificatiolList' onClick={() => handleDataChange(userData.phone)}>See</button>}</td>
                                                                                                    <td>{userData.team_name.length === 0 ? 'No Changes' : <button className='viewAllModificatiolList' onClick={() => handleDataChange(userData.team_name)}>See</button>}</td>
                                                                                                    <td>{userData.account.length === 0 ? 'No Changes' : <button className='viewAllModificatiolList' onClick={() => handleDataChange(userData.account)}>See</button>}</td>

                                                                                                </tr>
                                                                                            );
                                                                                        })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-bordered'>
                                        <thead>
                                            <tr className='support_main_file_div'>
                                                <th>Value</th>
                                                <th>Updated At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {changeArray && changeArray.length > 0 && changeArray.map((items, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{items.value}</td>
                                                        <td>{handleDate(items.updated_at)}</td>
                                                    </tr>
                                                );

                                                
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </Modal>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );
}
