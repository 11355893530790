import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { SeriesAPI } from '../api/seriesApi';
import { ContestAPI } from '../api/contestApi';
import { GlobalContestAPI } from '../api/globalContestApi';
import Notiflix from 'notiflix';
import { Checkbox } from 'antd';

export default function CreateGlobalContestPage() {
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const [fantasyList, setFantasyList] = useState([]);
    const [amountType, setAmountType] = useState();
    const [priceCardType, setPriceCardType] = useState();
    const [contestType, setContestType] = useState();
    const [fantasy, setFantasy] = useState();
    const [category, setCategory] = useState();
    const [category_id, setCategoryId] = useState();
    const [entryFee, setEntryFee] = useState();
    const [offerEntryFee, setOfferEntryFee] = useState();
    const [offeredEntry, setOfferedEntry] = useState();
    const [winningAmount, setWinningAmount] = useState();
    const [maxUsers, setMaxUsers] = useState();
    const [orders, setOrders] = useState();
    const [contestId, setContestId] = useState('');
    const [IsMultiEntry, setMultiEntry] = useState(false);
    const [UserMaxJoin, setUserMaxJoin] = useState();
    const [IsBonus, setIsBonus] = useState(false);
    const [BonusPrice, setBonusPrice] = useState();
    const [isFlexible, setIsFlexible] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFantasy();
        // GetCategory();
    }, []);

    const GetFantasy = () => {
        SeriesAPI.GetFantasy(fantasy).then((data) => {
            console.log(data);
            if (data.status_code) {

                setFantasyList(data.message);
            }
            //console.log(fantasyList);
        });
    };

    const submitChange = (e) => {
        const IsConfirmed = e.target[16].checked;

        const IsRunning = e.target[17].checked;

        // const IsBonusAllowed= e.target[17].checked
        e.preventDefault();
        e.stopPropagation();

        if (!IsMultiEntry) {
            setUserMaxJoin(0);
        }

        if (!IsBonus) {
            setBonusPrice(0);
        }

        if (entryFee === undefined || offerEntryFee === undefined || offeredEntry === undefined) {
            Notiflix.Notify.failure('Please Fill all the Values');
            return;
        }

        // if(entryFee>0 && entryFee*maxUsers<winningAmount){
        //   Notiflix.Notify.failure("Decrease Winning Amount or increase Entry fee or Maximum Users")
        //   return

        // }
        else {
            const data = {
                contestId: contestId,
                fantasy: fantasy,
                contest_amount_type: amountType,
                contest_prize_card_type: priceCardType,
                contest_category: category,
                contest_category_id: category_id,
                entry_fee: entryFee,
                offer_entry_fee: offerEntryFee,
                offered_entry: offeredEntry,
                type_of_contest: contestType,
                winning_amount: winningAmount,
                maximum_user: maxUsers,
                order: orders,
                is_multiple_entry: IsMultiEntry,
                is_confirmed: IsConfirmed,
                is_running: IsRunning,
                is_bonus: IsBonus,
                isFlexible: isFlexible,

                multi_entry: UserMaxJoin,
                bonus: BonusPrice,
            };

            CreateGlobalContest(data);
        }
    };

    const CreateGlobalContest = (data) => {
        console.log(data);
        GlobalContestAPI.CreateGlobalContest(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Done');
                console.log(data);
                navigate('/add_pricecard', { state: data.data });
            }
        });
    };
    const AmountTypeChange = (e) => {
        setAmountType(e.target.value);
    };
    const PriceCardTypeChange = (e) => {
        setPriceCardType(e.target.value);
    };

    const ContestTypeChange = (e) => {
        setContestType(e.target.value);
    };

    const GetCategory = (cat) => {
        ContestAPI.GetContestCategory(cat).then((data) => {
            setCategoryList(data.data);
            console.log(data);
        });
    };

    const fantasyChange = (e) => {
        setFantasy(e.target.value);

        GetCategory(e.target.value);
    };

    const CategoryChange = (e) => {
        console.log(e.target.value);
        // var element = document.getElementById('contest-category');

        // setCategory(element.options[element.selectedIndex].text);

        // setCategoryId(e.target.value);
        // console.log(e.target.value);
        // console.log(categoryList);
        // setTimeout(()=>{},1)

        // const filter = categoryList.filter((ee) => ee.ContestCategoryName === e.target.value);
        // setContestId(e.target.value);
        const object = JSON.parse(e.target.value);
        setCategory(object.ContestCategoryName);
        setCategoryId(object.ContestCatId);
    };

    const OfferEntryFeeChange = (e) => {
        setOfferEntryFee(Number(e.target.value));
    };

    const OfferedEntryChange = (e) => {
        setOfferedEntry(Number(e.target.value));
    };



    const MultientryChange = () => {
        setMultiEntry(document.getElementById('checkbox1').checked);
    };

    const UserMaxJoinChange = (e) => {
        setUserMaxJoin(e.target.value);
    };

    const IsBonusChange = () => {
        setIsBonus(document.getElementById('checkbox4').checked);
    };

    const BonusChange = (e) => {
        setBonusPrice(e.target.value);
    };

    const handleFlexibleTrue = (e) => {
        setIsFlexible(e.target.checked);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/global_contests'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View All Global Contest
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Global Contest</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form
                                        onSubmit={(e) => {
                                            submitChange(e);
                                        }}
                                    >
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='name'>Select Fantasy :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={fantasyChange}
                                                >
                                                    <option selected value=''>
                                                        Select
                                                    </option>
                                                    {fantasyList?.map((fan) => (
                                                        <option value={fan.fantasy}>
                                                            {fan.fantasy}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-5'>
                                                <label htmlFor='name'>Contest Amount Type:</label>
                                                <br />
                                                <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio6'
                                                        name='customRadio-1'
                                                        className='custom-control-input'
                                                        value='Amount Based Contest'
                                                        onChange={AmountTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio6'
                                                    >
                                                        {' '}
                                                        Amount Based Contest{' '}
                                                    </label>
                                                </div>
                                                <div className='custom-control custom-radio custom-control-inline'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio7'
                                                        name='customRadio-1'
                                                        className='custom-control-input'
                                                        value='Percentage Based Contest'
                                                        onChange={AmountTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio7'
                                                    >
                                                        {' '}
                                                        Percentage Based Contest
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6'>
                                                <label htmlFor='name'>
                                                    Contest PriceCard Type:
                                                </label>
                                                <br />
                                                <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio8'
                                                        name='customRadio-2'
                                                        className='custom-control-input'
                                                        value='Amount Based Price Card'
                                                        onChange={PriceCardTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio8'
                                                    >
                                                        {' '}
                                                        Amount Based Price Card{' '}
                                                    </label>
                                                </div>
                                                <div className='custom-control custom-radio custom-control-inline'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio9'
                                                        name='customRadio-2'
                                                        className='custom-control-input'
                                                        value='Percentage based Pricecard'
                                                        onChange={PriceCardTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio9'
                                                    >
                                                        {' '}
                                                        Percentage based Pricecard
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='text'>Contest Category :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={CategoryChange}
                                                    id='contest-category'
                                                >
                                                    <option select=''>
                                                        Select Contest Category
                                                    </option>
                                                    {categoryList?.map((category) => (
                                                        <option value={JSON.stringify(category)}>
                                                            {category.ContestCategoryName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Entry Fee :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Entry Fee'
                                                    value={entryFee}
                                                    onChange={(e) => {
                                                        setEntryFee(Number(e.target.value));
                                                    }}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Offer Entry Fee :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Offer Entry Fee'
                                                    value={offerEntryFee}
                                                    onChange={OfferEntryFeeChange}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Offered Entry :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Offered Entry'
                                                    value={offeredEntry}
                                                    onChange={OfferedEntryChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='name'>Type of Contest:</label>{' '}
                                                <br />
                                                <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                    <input
                                                        type='radio'
                                                        id='price'
                                                        name='contest1'
                                                        className='custom-control-input'
                                                        value='Price'
                                                        onChange={ContestTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='price'
                                                    >
                                                        {' '}
                                                        Price{' '}
                                                    </label>
                                                </div>
                                                <div className='custom-control custom-radio custom-control-inline'>
                                                    <input
                                                        type='radio'
                                                        id='prize'
                                                        name='contest1'
                                                        className='custom-control-input'
                                                        value='Prize'
                                                        onChange={ContestTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='prize'
                                                    >
                                                        {' '}
                                                        Prize
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Winning Amount:</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Winning Amount'
                                                    value={winningAmount}
                                                    onChange={(e) => {
                                                        setWinningAmount(Number(e.target.value));
                                                    }}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>
                                                    Maximum Number of Users:
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Maximum Number of Users'
                                                    value={maxUsers}
                                                    onChange={(e) => {
                                                        setMaxUsers(Number(e.target.value));
                                                    }}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Orders :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter orders'
                                                    value={orders}
                                                    onChange={(e) => {
                                                        setOrders(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <div className='form-group'>
                                                    <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                        <input
                                                            type='checkbox'
                                                            className='custom-control-input'
                                                            id='checkbox1'
                                                            checked={IsMultiEntry}
                                                            onChange={MultientryChange}
                                                        />
                                                        <label
                                                            className='custom-control-label'
                                                            htmlFor='checkbox1'
                                                        >
                                                            Multi Entry
                                                        </label>
                                                    </div>

                                                    <input
                                                        type='number'
                                                        className='form-control my-3'
                                                        value={UserMaxJoin}
                                                        onChange={UserMaxJoinChange}
                                                        style={{
                                                            display:
                                                                IsMultiEntry == false
                                                                    ? 'none'
                                                                    : 'flex',
                                                        }}
                                                        placeholder='User Max Number of Team Join'
                                                        defaultValue=''
                                                    />
                                                </div>

                                                <div className='form-group'>
                                                    <div className='custom-control custom-checkbox custom-control-inline mr-3'>
                                                        <input
                                                            type='checkbox'
                                                            className='custom-control-input'
                                                            id='checkbox2'
                                                        />
                                                        <label
                                                            className='custom-control-label'
                                                            htmlFor='checkbox2'
                                                        >
                                                            Is Confirmed
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='custom-control custom-checkbox custom-control-inline mr-3'>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id='checkbox3'
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='checkbox3'
                                                    >
                                                        Is Running
                                                    </label>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                        <input
                                                            type='checkbox'
                                                            className='custom-control-input '
                                                            id='checkbox4'
                                                            checked={IsBonus}
                                                            onChange={IsBonusChange}
                                                        />
                                                        <label
                                                            className='custom-control-label'
                                                            htmlFor='checkbox4'
                                                        >
                                                            Is Bonus
                                                        </label>
                                                    </div>

                                                    <input
                                                        type='number'
                                                        className='form-control my-3'
                                                        value={BonusPrice}
                                                        onChange={BonusChange}
                                                        style={{
                                                            display:
                                                                IsBonus == false ? 'none' : 'flex',
                                                        }}
                                                        placeholder='Bonus Percentage'
                                                    />
                                                </div>

                                                <div className='form-group'>
                                                    <Checkbox onChange={handleFlexibleTrue}>Is Flexible</Checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-primary'>
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button type='submit' className='btn bg-danger'>
                                            Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
