import { useEffect } from 'react';

export default function HowToPlayPage() {
    // useEffect(()=>{
    //     const script =document.createElement('script');
    //     script.src="assets/js/chart-custom.js";
    //     script.async=true;
    //     document.body.appendChild(script);
    // })
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add page</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Title :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Title'
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Editor</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div id='editor'></div>

                                    <button type='submit' className='btn btn-success mt-4'>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
