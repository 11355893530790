export default function NotificationHistoryPage() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card card-block card-stretch card-height blog blog-list'>
                                <div className='card-body'>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <div className='col-md-3 p-0'>
                                            <div className='image-block pr-md-3 pl-md-3'>
                                                <img
                                                    src='/assets/images/blog/01.jpg'
                                                    className='img-fluid rounded w-100'
                                                    alt='blog-img'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-9 mt-3 mt-md-0 p-0'>
                                            <div className='blog-description'>
                                                <div className='blog-meta-date mb-2'>
                                                    <div className='date'>
                                                        <a href='#' tabIndex='-1'>
                                                            22 March, 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <h5 className='mb-2'>Notification Heading</h5>
                                                <div className='blog-meta d-flex align-items-center pb-2 mb-2 border-bottom'>
                                                    <div className='author mr-3 rtl-mr-0 rtl-ml-3'>
                                                        <i className='ri-user-fill pr-2'></i>By:
                                                        Admin
                                                    </div>
                                                    <div className='hit'>
                                                        <i className='las la-heart pr-2 rtl-pl-2 rtl-pr-0'></i>
                                                        0 Hits
                                                    </div>
                                                </div>
                                                <p>
                                                    In the notification, declared winner, upcoming
                                                    match and all updates.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='card card-block card-stretch card-height blog blog-list'>
                                <div className='card-body'>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <div className='col-md-3 p-0'>
                                            <div className='image-block pr-md-3 pl-md-3'>
                                                <img
                                                    src='/assets/images/blog/02.jpg'
                                                    className='img-fluid rounded w-100'
                                                    alt='blog-img'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-9 mt-3 mt-md-0 p-0'>
                                            <div className='blog-description'>
                                                <div className='blog-meta-date mb-2'>
                                                    <div className='date'>
                                                        <a href='#' tabIndex='-1'>
                                                            22 March, 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <h5 className='mb-2'>Notification Heading</h5>
                                                <div className='blog-meta d-flex align-items-center pb-2 mb-2 border-bottom'>
                                                    <div className='author mr-3 rtl-mr-0 rtl-ml-3'>
                                                        <i className='ri-user-fill pr-2'></i>By:
                                                        Admin
                                                    </div>
                                                    <div className='hit'>
                                                        <i className='las la-heart pr-2 rtl-pl-2 rtl-pr-0'></i>
                                                        0 Hits
                                                    </div>
                                                </div>
                                                <p>
                                                    In the notification, declared winner, upcoming
                                                    match and all updates.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='card card-block card-stretch card-height blog blog-list'>
                                <div className='card-body'>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <div className='col-md-3 p-0'>
                                            <div className='image-block pr-md-3 pl-md-3'>
                                                <img
                                                    src='/assets/images/blog/03.jpg'
                                                    className='img-fluid rounded w-100'
                                                    alt='blog-img'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-9 mt-3 mt-md-0 p-0'>
                                            <div className='blog-description'>
                                                <div className='blog-meta-date mb-2'>
                                                    <div className='date'>
                                                        <a href='#' tabIndex='-1'>
                                                            22 March, 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <h5 className='mb-2'>Notification Heading</h5>
                                                <div className='blog-meta d-flex align-items-center pb-2 mb-2 border-bottom'>
                                                    <div className='author mr-3 rtl-mr-0 rtl-ml-3'>
                                                        <i className='ri-user-fill pr-2'></i>By:
                                                        Admin
                                                    </div>
                                                    <div className='hit'>
                                                        <i className='las la-heart pr-2 rtl-pl-2 rtl-pr-0'></i>
                                                        0 Hits
                                                    </div>
                                                </div>
                                                <p>
                                                    In the notification, declared winner, upcoming
                                                    match and all updates.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='card card-block card-stretch card-height blog blog-list'>
                                <div className='card-body'>
                                    <div className='d-flex flex-wrap align-items-center'>
                                        <div className='col-md-3 p-0'>
                                            <div className='image-block pr-md-3 pl-md-3'>
                                                <img
                                                    src='/assets/images/blog/04.jpg'
                                                    className='img-fluid rounded w-100'
                                                    alt='blog-img'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-9 mt-3 mt-md-0 p-0'>
                                            <div className='blog-description'>
                                                <div className='blog-meta-date mb-2'>
                                                    <div className='date'>
                                                        <a href='#' tabIndex='-1'>
                                                            22 March, 2020
                                                        </a>
                                                    </div>
                                                </div>
                                                <h5 className='mb-2'>Notification Heading</h5>
                                                <div className='blog-meta d-flex align-items-center pb-2 mb-2 border-bottom'>
                                                    <div className='author mr-3 rtl-mr-0 rtl-ml-3'>
                                                        <i className='ri-user-fill pr-2'></i>By:
                                                        Admin
                                                    </div>
                                                    <div className='hit'>
                                                        <i className='las la-heart pr-2 rtl-pl-2 rtl-pr-0'></i>
                                                        0 Hits
                                                    </div>
                                                </div>
                                                <p>
                                                    In the notification, declared winner, upcoming
                                                    match and all updates.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
