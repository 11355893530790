import { BASEAPIS } from './BaseApis';

export const createCoupon = {
    AddCoupon: (json) => {
        return BASEAPIS.POSTAPI(json, 'promoCreate');
    },

    GetAllCoupon: async () => {
        return await BASEAPIS.GETAPI('allPromo');
    },

    ChangeCouponEndDate: async (json) => {
        return await BASEAPIS.PUTAPI(json, 'promoUpdate');
    },

    ChangeCouponStatusUpdate: async (jsonbody) => {
        return await BASEAPIS.PUTAPI(jsonbody, 'promoStatusUpdate');
    }
};



