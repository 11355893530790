import { useEffect, useState } from 'react';
import { AddReferralAPI } from '../api/referralApi';
import { Notiflix } from 'notiflix';

export default function ReferralsUsersPage() {
    const [user, setUser] = useState();
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        getUserInfo();
    }, []);

    const getUserInfo = () => {
        AddReferralAPI.ViewReferUserInfo().then((data) => {
            var userInfo_i = JSON.parse(JSON.stringify(data));
            //  console.log(userInfo_i,'hi')
            if (data.status_code === true) {
                setUser(userInfo_i);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Referral from </th>
                                                    <th>Referral User</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {user &&
                                                    user.length > 0 &&
                                                    user.map((user, i) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>{++i}</td>
                                                                    <td>{user.refer_by_name}</td>
                                                                    <td>{user.refer_user_name}</td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })}

                                                {/* <tr>
                                <td>2.</td>
                                <td>Deepak Singh</td>
                                <td>Sonia Pal</td>
                            </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
