import { useEffect, useState } from 'react';
// import { CSVLink } from "react-csv";
import { WithdrawalAPI } from '../api/ViewAllWithdrawalAPI';
import { RotatingLines } from 'react-loader-spinner';

export default function WithdrawAmountPage() {
    const [account, setAccount] = useState();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        withdrawalInfo();
    }, []);

    // const headers = [
    //   { label: "User Id", key: "user_id" },
    //   { label: "Account Numer", key: "account_number" },
    //   { label: "Withdrawal Amount", key: "total_withdrawl" },
    //   { label: "User Name", key: "name" },
    //   { label: "State", key: "state" },
    //   { label: "Bank IFSC Code", key: "ifsc_code" },
    //   { label: "Bank Name", key: "bank_name" },
    //   { label: "Branch", key: "branch" },
    //   { label: "Email", key: "email" },
    //   { label: "Status", key: "status" },
    // ];

    // console.log(headers)

    const withdrawalInfo = () => {
        WithdrawalAPI.GetWithdrawInfo().then((data) => {
            var allAccount = JSON.parse(JSON.stringify(data));
            console.log(allAccount.message);
            setAccount(allAccount.message);
            setIsLoading(false);
        });
    };

    const filterWithdraw = () => {
        WithdrawalAPI.FilterWithdrawInfo(email, phone).then((data) => {
            var withdraw = JSON.parse(JSON.stringify(data));
            console.log(withdraw.message);
            setAccount(withdraw.message);
        });
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
    };
    const phoneChange = (e) => {
        setPhone(e.target.value);
    };

    const Reset = () => {
        setEmail('');
        setPhone('');
        withdrawalInfo();
        // PaymentMethodChangeEvent()
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Status :</label>
                                            <select className='form-control mb-3'>
                                                <option select=''>Select</option>
                                                <option value='1'>Pending</option>
                                                <option value='2'>Approved</option>
                                                <option value='3'>Rejected</option>
                                            </select>
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Requested Date :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Requested Date'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Approved Date :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Approved Date'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Search By Email :</label>
                                            <input
                                                value={email}
                                                onChange={emailChange}
                                                type='email'
                                                className='form-control'
                                                placeholder='Search By Email'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Search By Mobile :</label>
                                            <input
                                                value={phone}
                                                onChange={phoneChange}
                                                type='number'
                                                className='form-control'
                                                placeholder='Search By Mobile No.'
                                            />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <button
                                                onClick={() => filterWithdraw()}
                                                type='submit'
                                                className='btn btn-primary'
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            <button
                                                onClick={() => Reset()}
                                                type='submit'
                                                className='btn bg-danger'
                                            >
                                                <i className='ri-creative-commons-sa-line'></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            {/* <CSVLink
                        filename="Withdraw Amount"
                        data={account}
                        headers={headers}
                      > */}

                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>

                                            {/* </CSVLink> */}
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Withdraw Requests List</h4>
                                    </div>
                                </div>

                                {isLoading && (
                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                        <div className='table-editable' id='table'>
                                            <div>
                                                <RotatingLines
                                                    marginTop='40'
                                                    strokeColor='#c1030d'
                                                    strokeWidth='5'
                                                    animationDuration='0.75'
                                                    width='50'
                                                    visible={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table  table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            <th>Account Numer</th>
                                                            <th>Withdrawal Amount</th>
                                                            <th>User Name</th>
                                                            <th>Status</th>
                                                            <th>Bank IFSC Code</th>
                                                            <th>Bank Name</th>
                                                            <th>Branch</th>
                                                            <th>Email</th>
                                                            <th>Mobile No.</th>
                                                            {/* <th>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {account &&
                                                            account.length > 0 &&
                                                            account.map((data, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{++i}</td>
                                                                            <td>{data.user_id}</td>
                                                                            <td>
                                                                                {
                                                                                    data.account_number
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    data.total_withdrawl
                                                                                }
                                                                            </td>
                                                                            <td>{data.name}</td>
                                                                            <td>Status</td>
                                                                            <td>
                                                                                {data.ifsc_code}
                                                                            </td>
                                                                            <td>
                                                                                {data.bank_name}
                                                                            </td>
                                                                            <td>{data.branch}</td>
                                                                            <td>{data.email}</td>
                                                                            <td>{data.phone}</td>
                                                                            {/* <th>Action</th> */}
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
