import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { AllUsersAPI } from '../api/allUsers';
import './ViewAllUsers.css';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { BsArrowClockwise } from 'react-icons/bs';
import { MdOutlineModeEdit } from 'react-icons/md';
import SerialNumber from './SerialNumber';
import Pagination from '../Component/Pagination';
import { Modal } from 'antd';

export default function ViewAllUserPage() {
    const [user, setUser] = useState([]);
    const [team_name, setTeam] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [user_id, setUserId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize, setPageSize] = useState(50);
    const [allStates, setAllStates] = useState([]);
    // const [state, setState] = useState('');
    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(10);
    const [thisData, setThisData] = useState([]);
    const [status, setStatus] = useState([]);
    const [thisFilteredData, setThisFilteredData] = useState([]);
    const role = localStorage.getItem('role');

    // Pagination
    const [totalEntires, setTotalEntries] = useState(1);
    const [entries, setEntries] = useState(10);
    const [totalPages, setTotalPages] = useState(100);
    const [nextPage, setNextpage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [state, setUserState] = useState('');


    // Modal
    const [editId, setEditId] = useState('');
    const [editName, setEditName] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [nameChangeInput, setNameChangeInput] = useState('');
    const [emailChangeInput, setEmailChangeInput] = useState('');
    const [phoneChangeInput, setPhoneChangeInput] = useState('');
    const [startSerialNumber, setStartSerialNumber] = useState(1);

    // Pagination--------------------
    const [pageNumber, setPageNumber] = useState(1);
    const [totalLength, setTotalLength] = useState(1);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        setIsLoading(true);
        Filter();
        getAllStates();
        Reset();
        console.log('first');
    }, []);

    useEffect(() => {
        Filter();
    }, [pageNumber]);

    const getAllStates = () => {
        AllUsersAPI.GetAllStates().then((data) => {
            console.log(data);
            if (data.status_code) {
                setAllStates(data.states);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            // setIsLoading(false);
        });
    };

    //  Pagination
    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Name', key: 'name' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Mobile No', key: 'phone' },
        { label: 'Email Id', key: 'email' },
        { label: 'Date and Time', key: 'createdAt' },
        { label: 'Address', key: 'address' },
        { label: 'State', key: 'state' },
        { label: 'isphoneverify', key: 'isphoneverify' },
        { label: 'isBankverify', key: 'isBankverify' },
        { label: 'isEmailverify', key: 'isEmailverify' },
        { label: 'isPanverify', key: 'isPanverify' },
    ];

    const allUsersInfo = () => {
        setIsLoading(true);
        AllUsersAPI.GetAllUsersInfo(pageSize, pageNumber).then((data) => {
            if (data.status_code) {
                // var allUsers_i = JSON.parse(JSON.stringify(data));
                setUser(data.data);
                setTotalPages(data.total_page);
                // setTotalEntries(data.total_entries);
                setTotalLength(data.total_length);

                const startNumber = (pageNumber - 1) * pageSize + 1;
                setStartSerialNumber(startNumber);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            setIsLoading(false);
        });
    };

    // Fetch This ALL Data
    const handleFetchData = () => {
        setIsLoading(true);
        AllUsersAPI.GetAllUsersDownload().then((data) => {
            if (data.status_code) {
                // var allUsers_i = JSON.parse(JSON.stringify(data));
                setThisData(data.data);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    //  Fetch This ALL Filtered Data
    const handleThisFilteredData = (status) => {
        setIsLoading(true);
        AllUsersAPI.FilterAllUsersDownload(
            totalEntires,
            fromDate,
            toDate,
            state,
            status,
            pageSize,
            pageNumber
        ).then((data) => {
            if (data.status_code) {
                console.log(data);
                // setUser(data.data);
                setThisFilteredData(data.data);
            } else {
                Notiflix.Notify.warning(data.msg);
            }
            setIsLoading(false);
        });
    };

    const Filter = () => {
        setIsLoading(true);
        // console.log('hello')
        // e.preventDefault()
        // e.stopPropagation()
        AllUsersAPI.FilterAllUsersInfo(
            entries,
            pageNumber,
            team_name,
            email,
            mobile,
            user_id,
            fromDate,
            toDate,
            state,
            status
        ).then((data) => {
            if (data.status_code) {
                console.log(data);
                setUser(data.data);
                setTotalPages(data.total_page);
                setTotalEntries(data.total_length);

                const startNumber = (pageNumber - 1) * entries + 1;
                setStartSerialNumber(startNumber);
                console.log(`startNumber is ${startNumber}`);
            } else {
                Notiflix.Notify.warning('No User Found');

            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (totalEntires <= 50) {
            setPageNumber(1);
        } else {
            setPageNumber(pageNumber);
        }
    }, [Filter]);


    const Reset = () => {
        setTeam('');
        setEmail('');
        setMobile('');
        setUserId('', allUsersInfo());
        setFromDate('');
        setToDate('');
        setUserState('');
        setPageNumber(1);
        setTotalEntries(totalLength);
    };

    const getDate = (e) => {
        if (!e) {
            return '';
        } else {
            const time = moment(e).format('hh:mm:s a');
            const date = moment(e).format('DD/MM/YYYY');
            return `${date} | ${time}`;
        }
    };

    const changeStatus = (e) => {
        setStatus(e.target.value);
    };

    const stateChange = (e) => {
        setUserState(e.target.value);
    };

    const EditUserDetail = (eId, eName, ePhone, eEmail) => {
        setEditId(eId);
        setEditName(eName);
        setEditPhone(ePhone);
        setEditEmail(eEmail);
        console.log(editId, editName, editPhone, editEmail);
    };

    const handleNameChange = (e) => {
        setNameChangeInput(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmailChangeInput(e.target.value);
    };
    const handlePhoneChange = (e) => {
        setPhoneChangeInput(e.target.value);
    };

    const handleEditSubmit = () => {
        let data = {
            userId: editId,
            team_name: editName,
            phone: editPhone,
            email: editEmail,
        };
        console.log(data);
        AllUsersAPI.EditUserInformation(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success(data.message);
                allUsersInfo();
            } else {
                Notiflix.Notify.failure(data.message);
            }
        });
    };

    const [commentToBlockUnblock, setCommentToBlockUnblock] = useState('');
    const [blockUnblockUserId, setBlockUnblockUserId] = useState();
    const [blockUnblockStatus, setBlockUnblockStatus] = useState();
    const [blockUnblockTeamName, setBlockUnblockTeamName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Block & Unblock
    const handleBlockUnblock = (user_id, e, teamName) => {
        setBlockUnblockUserId(user_id);
        setBlockUnblockStatus(e);
        setBlockUnblockTeamName(teamName);
        setIsModalOpen(true);
    };

    const handleBlockAPI = (user_id, e) => {
        if (commentToBlockUnblock === '') {
            Notiflix.Notify.failure('Please Provide Block/Unblock Reason');
        } else {
            const value = !e;
            let data = {
                userId: user_id,
                status: value.toString(),
                comment: commentToBlockUnblock
            };
            console.log(data);
            AllUsersAPI.EditUserStatus(data).then((data) => {
                Notiflix.Notify.success(`User ${e ? 'Unblocked' : 'Blocked'} Successfully`);
                allUsersInfo();
                setBlockUnblockUserId();
                setBlockUnblockStatus();
                setCommentToBlockUnblock('');
                setIsModalOpen(false);
            });
        }
    };

    const handleClose = () => {
        setEditName('');
        setEditPhone('');
        setEditEmail('');
    };

    const handleTime = (e) => {
        var newTime = e?.slice(11, 18);
        var updateTime = moment(newTime, 'HH:mm:ss').format('hh:mm A');
        return updateTime;
    };

    const handleDate = (date) => {
        var dateNew = date?.slice(0, 10);
        return dateNew;
    };

    const changePage = (data) => {
        setPageNumber(data);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='col'>
                                                <label htmlFor='name'>Status </label>
                                                <select
                                                    onChange={changeStatus}
                                                    className='form-control mb-3'
                                                >
                                                    <option value='all'> All </option>
                                                    <option value='true'>Blocked</option>
                                                    <option value='false'>Unblocked</option>
                                                </select>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>User Id :</label>
                                                <input
                                                    onChange={(e) => setUserId(e.target.value)}
                                                    type='text'
                                                    value={user_id}
                                                    className='form-control'
                                                    placeholder='Search User ID'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Team Name :</label>
                                                <input
                                                    onChange={(e) => setTeam(e.target.value)}
                                                    type='text'
                                                    value={team_name}
                                                    className='form-control'
                                                    placeholder='Search By Team Name'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='email'>Email Id :</label>
                                                <input
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type='text'
                                                    value={email}
                                                    className='form-control'
                                                    placeholder='Search By Email Id'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='state'>State :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={stateChange}
                                                >
                                                    <option select=''>Select</option>
                                                    {
                                                        allStates.map((fan) => (
                                                            <option key={fan} value={fan}>
                                                                {fan}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className='d-flex flex-wrap'>
                                                <label htmlFor='mobile'>Mobile :</label>
                                                <input
                                                    onKeyDown={(evt) =>
                                                        ['e', 'E', '+', '-'].includes(evt.key) &&
                                                        evt.preventDefault()
                                                    }
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    type='number'
                                                    value={mobile}
                                                    className='form-control'
                                                    placeholder='Search By Mobile No.'
                                                />
                                            </div>
                                            <div className='p-2'>
                                                <label htmlFor='Date'>From</label>
                                                <input
                                                    onChange={(e) => setFromDate(e.target.value)}
                                                    type='Date'
                                                    className='form-control'
                                                    value={fromDate}
                                                />
                                            </div>

                                            <div className='p-2'>
                                                <label htmlFor='Date'>To</label>
                                                <input
                                                    onChange={(e) => setToDate(e.target.value)}
                                                    type='Date'
                                                    className='form-control'
                                                    value={toDate}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='form-row'>
                                                <div className='col'>
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        value="search"
                                                        href='#'
                                                        onClick={(e) => Filter(e)}
                                                        type='button'
                                                        className='btn btn-primary'
                                                        disabled={isLoading ? true : false}
                                                    >
                                                        Search
                                                    </button>
                                                    &nbsp;
                                                    <input
                                                        type='reset'
                                                        onClick={() => Reset()}
                                                        className='btn btn-danger'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>

                                {/* Export All Data */}
                                <div className='div-series'>
                                    {thisData.length > 0 ? (
                                        <CSVLink
                                            filename='View All Users'
                                            data={thisData}
                                            headers={headers}
                                        >
                                            <button className='button-series-1' href='#'>
                                                Download All Data <AiOutlineCheckCircle />
                                            </button>
                                        </CSVLink>
                                    ) : (
                                        ''
                                    )}

                                    {/* Export All Filtered Data */}

                                    {thisFilteredData.length > 0 ? (
                                        <CSVLink
                                            filename='Filtered Users'
                                            data={thisFilteredData}
                                            headers={headers}
                                        >
                                            <button className='button-series' href='#'>
                                                Download This Filtered Data <AiOutlineCheckCircle />
                                            </button>
                                        </CSVLink>
                                    ) : (
                                        ''
                                    )}
                                    {thisData.length > 0 || thisFilteredData.length > 0 ? (
                                        <button
                                            className='button-series'
                                            onClick={() => window.location.reload(true)}
                                        >
                                            <BsArrowClockwise />
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                {/* Export Buttons */}
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='button-series-2'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Fetch - <BsFillArrowDownCircleFill />
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button
                                                className='dropdown-item'
                                                href='#'
                                                onClick={handleFetchData}
                                            >
                                                Fetch All data
                                            </button>
                                            <button
                                                className='dropdown-item'
                                                href='#'
                                                onClick={handleThisFilteredData}
                                            >
                                                Fetch This Filtered Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Users</h4>
                                    </div>
                                </div>
                                {isLoading && (
                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                        <div>
                                            <RotatingLines
                                                marginTop='70'
                                                strokeColor='#c1030d'
                                                strokeWidth='5'
                                                animationDuration='0.75'
                                                width='50'
                                                visible={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className='card-body'>
                                        <div
                                        // className="table-editable" id="table"
                                        >
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            {/* <th>Name</th> */}
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                            <th>Team Name</th>
                                                            <th>Mobile No.</th>
                                                            <th>Email Id</th>
                                                            {/* <th>Date</th> */}
                                                            {/* <th>Address</th> */}
                                                            <th>State</th>
                                                            <th>Verification</th>
                                                            {/* <th>Total Referals</th>
                                                            <th>Refering Amount (Rs.)</th>
                                                            <th>Refer Code</th> */}
                                                            <th>TDS Report</th>
                                                            {role != 3 && <th>Action</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {user &&
                                                            user.length > 0 &&
                                                            user.map((userInfo, i) => {
                                                                const serialNoOnNextPage = startSerialNumber + i;
                                                                console.log(`serialNoOnNextPage is ${serialNoOnNextPage}`);
                                                                return (
                                                                    <tr key={userInfo._id}>
                                                                        <td>
                                                                            <SerialNumber
                                                                                currentPage={currentPage}
                                                                                entriesPerPage={entries}
                                                                                activeIndex={serialNoOnNextPage} />
                                                                        </td>
                                                                        <td>
                                                                            <button className='users_link'>
                                                                                <Link
                                                                                    className='users_linkk'
                                                                                    to={
                                                                                        '/get_user_details'
                                                                                    }
                                                                                    state={{
                                                                                        userId: userInfo.user_id,
                                                                                    }}
                                                                                    type='submit'
                                                                                // className='py-4'
                                                                                >
                                                                                    {userInfo.user_id}
                                                                                </Link>
                                                                            </button>
                                                                        </td>
                                                                        {/* <td>{userInfo.name}</td> */}
                                                                        <td>
                                                                            {handleDate(
                                                                                userInfo.date
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {handleTime(
                                                                                userInfo.date
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {userInfo.team_name}
                                                                        </td>
                                                                        <td>{userInfo.phone}</td>
                                                                        <td>{userInfo.email}</td>
                                                                        {/* <td>{userInfo.createdAt}</td> */}
                                                                        {/* <td>{userInfo.address}</td> */}
                                                                        <td>{userInfo.state}</td>
                                                                        <td>
                                                                            {userInfo.isphoneverify ? (
                                                                                <i
                                                                                    className='ri-smartphone-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-smartphone-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}
                                                                            {userInfo.isEmailverify ? (
                                                                                <i
                                                                                    className='ri-mail-check-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-mail-check-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}

                                                                            {userInfo.isPanverify ? (
                                                                                <i
                                                                                    className='ri-bank-card-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-bank-card-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}

                                                                            {userInfo.isBankverify ? (
                                                                                <i
                                                                                    className='ri-bank-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-bank-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}
                                                                            <br />
                                                                        </td>
                                                                        {/* <td>0</td>
                                                                        <td>0</td>
                                                                        <td>{userInfo.refCode}</td> */}
                                                                        <td>
                                                                            <button
                                                                                type='submit'
                                                                                className='btn btn-orange btn-sm'
                                                                            >
                                                                                <i className='ri-edit-line'></i>{' '}
                                                                                Send TDS Report
                                                                            </button>
                                                                        </td>
                                                                        {role != 3 && <td>
                                                                            <div
                                                                                className='btn-group'
                                                                                role='group'
                                                                            >
                                                                                <button
                                                                                    id='btnGroupDrop1'
                                                                                    type='button'
                                                                                    className='btn btn-primary dropdown-toggle'
                                                                                    data-toggle='dropdown'
                                                                                    aria-haspopup='true'
                                                                                    aria-expanded='false'
                                                                                >
                                                                                    Action
                                                                                </button>
                                                                                <div
                                                                                    className='dropdown-menu'
                                                                                    aria-labelledby='btnGroupDrop1'
                                                                                    style={{}}
                                                                                >
                                                                                    <Link
                                                                                        to={
                                                                                            '/user_transaction'
                                                                                        }
                                                                                        state={{
                                                                                            userId: userInfo.user_id,
                                                                                        }}
                                                                                    >
                                                                                        <button className='dropdown-item'>
                                                                                            User
                                                                                            Transaction
                                                                                        </button>
                                                                                    </Link>
                                                                                    {role != 5 && <button
                                                                                        title='Edit'
                                                                                        type='button'
                                                                                        onClick={() =>
                                                                                            EditUserDetail(
                                                                                                userInfo.user_id,
                                                                                                userInfo.team_name,
                                                                                                userInfo.phone,
                                                                                                userInfo.email
                                                                                            )
                                                                                        }
                                                                                        className='dropdown-item'
                                                                                        data-toggle='modal'
                                                                                        data-target='#exampleModalScrollable'
                                                                                    >
                                                                                        Edit User
                                                                                        Detail
                                                                                    </button>}
                                                                                    {role != 5 && (userInfo.blocked_account ===
                                                                                        true ?
                                                                                        (
                                                                                            <button
                                                                                                className='dropdown-item'
                                                                                                href='#'
                                                                                                onClick={() =>
                                                                                                    handleBlockUnblock(
                                                                                                        userInfo.user_id,
                                                                                                        userInfo.blocked_account,
                                                                                                        userInfo.team_name
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Unblock
                                                                                            </button>
                                                                                        ) : (
                                                                                            <button
                                                                                                className='dropdown-item'
                                                                                                href='#'
                                                                                                onClick={() =>
                                                                                                    handleBlockUnblock(
                                                                                                        userInfo.user_id,
                                                                                                        userInfo.blocked_account,
                                                                                                        userInfo.team_name
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Block
                                                                                            </button>
                                                                                        ))}
                                                                                    {/* <a className="dropdown-item" href="#">Delete</a> */}
                                                                                </div>
                                                                            </div>
                                                                            &nbsp;

                                                                        </td>}
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Pagination
                                    className='pagination-css' totalEntries={totalEntires}
                                    totalPages={totalPages} changePage={changePage}
                                    pageNumber={pageNumber}
                                />

                                <Modal title="Type Reason" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 16
                                    }}>

                                        <textarea
                                            style={{
                                                height: 100,
                                                borderRadius: 8,
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingRight: 12,
                                                paddingLeft: 12
                                            }}
                                            value={commentToBlockUnblock} placeholder='Type reason' onChange={(e) => setCommentToBlockUnblock(e.target.value)} />
                                        <button
                                            style={{
                                                borderRadius: 8,
                                                backgroundColor: 'black',
                                                color: 'white',
                                                paddingTop: 16,
                                                paddingBottom: 16,
                                                paddingRight: 12,
                                                paddingLeft: 12,
                                                border: 'none'
                                            }}
                                            onClick={() => handleBlockAPI(blockUnblockUserId, blockUnblockStatus, blockUnblockTeamName)}>
                                            {
                                                blockUnblockStatus ? `Unblock ${blockUnblockTeamName}` : `Block ${blockUnblockTeamName}`
                                            }
                                        </button>
                                    </div>


                                </Modal>

                                {/* Edit Modal */}
                                <div
                                    className='modal fade'
                                    id='exampleModalScrollable'
                                    tabIndex='-1'
                                    aria-labelledby='exampleModalScrollableTitle'
                                    aria-hidden='true'
                                >
                                    <div
                                        className='modal-dialog modal-dialog-scrollable'
                                        role='document'
                                    >
                                        <div className='modal-content'>
                                            <div className='modal-header'>
                                                <h5
                                                    className='modal-title'
                                                    id='exampleModalScrollableTitle'
                                                >
                                                    Edit Users Detail
                                                </h5>
                                            </div>
                                            <div className='modal-body'>
                                                <form>
                                                    <div className='form-group'>
                                                        <label htmlFor='name'>User Id</label>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            value={editId}
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className='form-group user_div'>
                                                        <label htmlFor='name'>Team Name</label>
                                                        <MdOutlineModeEdit className='users_editButton' />

                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            value={editName}
                                                            // placeholder={editName ? editName : "Name was not specified"}
                                                            onChange={(e) =>
                                                                setEditName(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className='form-group user_div'>
                                                        <label htmlFor='name'>
                                                            User Phone Number
                                                        </label>
                                                        <MdOutlineModeEdit className='users_editButton' />

                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            value={editPhone}
                                                            onChange={(e) =>
                                                                setEditPhone(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                    <div className='form-group user_div'>
                                                        <label htmlFor='name'>User Email Id</label>
                                                        <MdOutlineModeEdit className='users_editButton' />

                                                        <input
                                                            type='text'
                                                            value={editEmail}
                                                            className='form-control'
                                                            onChange={(e) =>
                                                                setEditEmail(e.target.value)
                                                            }
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className='modal-footer'>
                                                <button
                                                    onClick={handleClose}
                                                    type='button'
                                                    className='btn btn-secondary'
                                                    data-dismiss='modal'
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-primary'
                                                    onClick={handleEditSubmit}
                                                    data-dismiss='modal'
                                                >
                                                    Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Edit Modale */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
