import React, { useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { AllUsersAPI } from '../api/allUsers';
import Notiflix from 'notiflix';

function BannedUserReport() {


    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [bannedData, setBannedData] = useState([]);

    const handleBannedUserReportData = async () => {
        setIsLoading(true);
        await AllUsersAPI.GetBannedUsers(startDate, endDate).then((response) => {
            if (response.status_code) {
                Notiflix.Notify.success(response.message);
                setIsLoading(false);
                setBannedData(response.data);
            } else {
                Notiflix.Notify.failure(response.message);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        handleBannedUserReportData();
    }, []);

    const resetData = () => {
        setEndDate('');
        setStartDate('');
        handleBannedUserReportData();
    };


    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12 }}>

                                        <div>
                                            <label htmlFor='name'>Start Date :</label>
                                            <input
                                                onChange={(e) => setStartDate(e.target.value)}
                                                type='date'
                                                value={startDate}
                                                className='form-control'
                                                placeholder='Search User ID'
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor='name'>End Date :</label>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12 }}>
                                                <input
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    type='date'
                                                    value={endDate}
                                                    className='form-control'
                                                    placeholder='Search By Team Name'
                                                />


                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12 }}>
                                                    <button onClick={handleBannedUserReportData}>Search</button>
                                                    <button onClick={resetData}>Reset</button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Table */}
                    {isLoading && (
                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                            <div>
                                <RotatingLines
                                    marginTop='70'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}

                    {!isLoading && (
                        <div className='card-body'>
                            <div
                            >
                                <div className='table-responsive'>
                                    <table className='table table-striped table-bordered'>
                                        <thead>
                                            <tr className='users_link'>
                                                <th>S.No.</th>
                                                <th>Team Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>State</th>
                                                <th>Is Blocked</th>
                                                <th>Blocked Comment</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bannedData &&
                                                bannedData.map((userInfo, i) => {
                                                    return (
                                                        <tr key={userInfo._id}>
                                                            <td>{++i}</td>
                                                            <td>{userInfo.team_name}</td>
                                                            <td>{userInfo.email}</td>
                                                            <td>{userInfo.phone}</td>
                                                            <td>{userInfo.state}</td>
                                                            <td>{userInfo.blocked_account ? 'YES' : 'NO'}</td>
                                                            <td>{userInfo.blockedComment}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BannedUserReport;
