import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';

export const MatchAPI = {
    GetUpcoming: async (dateStart, teamName, matchChangeData, pagesize, pagenumber) => {
        return BASEAPIS.GETAPI(
            'fetchUpcomingMatches?dateStart=' +
            dateStart +
            '&teamName=' +
            teamName +
            '&matchType=' +
            matchChangeData + '&entries=' + pagesize + '&pagenumber=' + pagenumber
        );
    },

    NewUpcoming: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'importUpcomingMatches');
    },

    LaunchUpcoming: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'launchUpcomingMatch');
    },

    // api_match_id: apiMatchID,
    // match_id: match_id,
    // cid: cid,

    LaunchEleven: async (apiMatchID, match_id, cid) => {
        return BASEAPIS.GETAPI('createMatchPlaying11?apiMatchID=' + apiMatchID + '&match_id=' + match_id + '&cid=' + cid);
    },

    GetMatchFormat: async () => {
        return BASEAPIS.GETAPI('fetchMatchFormat');
    },

    EditUpcomingMatch: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'editUpcomingMatches');
    },

    FetchAllMatches: async () => {
        return BASEAPIS.GETAPI('fetchAllMatches');
    },

    FilterAllMatches: async (matchName) => {
        return BASEAPIS.GETAPI('fetchAllMatches?matchName=' + matchName);
    },

    UnlaunchMatch: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'editAllMatches');
    },

    FormatMatches: async () => {
        return BASEAPIS.GETAPI('fetchAllMatches');
    },

    GetPlayingXI: async (json) => {
        return BASEAPIS.GETAPI('getAllPlaying?MatchId=' + json);
    },

    ImpactPlayer: async (jsonbody) => {
        return BASEAPIS.PUTAPI(jsonbody, 'impactPlayerDash');
    },

    Playing11Status: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'playing11Status');
    },

    RescheduleMatch: async () => {
        return BASEAPIS.GETAPI('rescheduleMatch');
    },

    AddMultipleGlobalContestInMatch: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'AddMultipleGlobalContestInMatch');
    },

    StorePlayingEleven: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'storePlaying11');
    },

    RemoveGlobalContestPool: async (matchid, id) => {
        return BASEAPIS.GETAPI('RemoveGlobalContestPool?MatchId=' + matchid + '&Id=' + id);
    },

    EditRescheduleMatch: async (jsonbody) => {
        return BASEAPIS.PUTAPI(jsonbody, 'editRescheduleMatch');
    },

    GetCompleteMatch: async () => {
        return BASEAPIS.GETAPI('GetCompleteMatch');
    },

    GetContestInMatch: async () => {
        return BASEAPIS.GETAPI('getContestInMatch');
    },

    // const json ={
    //     ApiMatchId:ApiMatchId,
    //     cid:cid,
    //     MatchId:matchId
    // };

    UpdatePlaying11: async (ApiMatchId, cid, matchId) => {
        return BASEAPIS.GETAPI('updatePlaying11?ApiMatchId=' + ApiMatchId + '&cid=' + cid + '&MatchId=' + matchId);
    },

    GetMatchSquad: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'getMatchSquad');
    },

    UpdatePlayerRole: async (jsonbody) => {
        return BASEAPIS.POSTAPI(jsonbody, 'updatePlayerRole');
    },

};
