import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { MatchAPI } from '../api/matchApi';
import { ContestAPI } from '../api/contestApi';
import { RotatingLines } from 'react-loader-spinner';

export default function AssignContestPage() {
    const [MatchList, setMatchList] = useState([]);
    const [ContestList, setContestList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        GetMatch();
    }, []);

    const GetMatch = () => {
        // MatchAPI.GetUpcoming().then((data)=>{
        //     // console.log(data)
        //     if(data)
        //     setMatchList(data)
        // })

        MatchAPI.FetchAllMatches().then((data) => {
            if (data) setMatchList(data.message);
            else setMatchList([]);
            setIsLoading(false);
        });
    };
    const GetContest = (e) => {
        ContestAPI.GetPoolContest(e.target.value).then((data) => {
            // console.log(JSON.stringify(data))
            if (data)
                if (data.Status) {
                    setContestList(data.Data);
                    console.log(data.Data);
                } else setContestList([]);
            setIsLoading(false);
        });
    };
    const resetBtn = () => {
        setContestList([]);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Select Match* :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={GetContest}
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    {MatchList.map((match, ind) => (
                                                        <option value={match.matchId}>
                                                            {match.matchName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button type='submit' className='btn btn-primary'>
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button type="submit" className="btn bg-danger" onClick={resetBtn}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input
                                                    type='reset'
                                                    onClick={() => resetBtn()}
                                                    className='btn btn-danger'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/add_poolsand_winning'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-add-line'></i>Add Pool &amp; Winnings
                                    </Link>

                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isLoading && (
                        <div className='col-sm-12 col-lg-12'>
                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                <RotatingLines
                                    marginTop='40'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}

                    {!isLoading && (
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Contest List</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Match</th>
                                                        {/* <th>Contest</th> */}
                                                        <th>Contest Category</th>
                                                        {/* <th>Sub Title</th> */}
                                                        <th>Entry Fee</th>
                                                        {/* <th>Win Type</th> */}
                                                        <th>Winning Amount</th>
                                                        <th>Total Winners</th>
                                                        <th>Max Users</th>
                                                        <th>R</th>
                                                        <th>C</th>
                                                        <th>B</th>
                                                        <th>Rank Price</th>
                                                        {/* <th>Image</th>                                */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ContestList.map((contest, ind) =>
                                                        contest.map((data, i) => (
                                                            <tr>
                                                                <td>{++i}</td>
                                                                <td>Asia Cup 2022</td>
                                                                {/* <td><Link to={"/pool_list"}>Mega Contest</Link></td> */}
                                                                <td>{data.ContestCategoryName}</td>
                                                                {/* <td>Jeeto Dil Khol ke</td> */}
                                                                <td>{data.JoiningFees}</td>
                                                                {/* <td>Money</td> */}
                                                                <td>{data.TotalWinningAmount}</td>
                                                                <td>{data.TotalWinners}</td>
                                                                <td>{data.MaxSpots}</td>
                                                                <td>
                                                                    <i className='ri-check-line'></i>
                                                                </td>
                                                                <td>
                                                                    <i className='ri-close-fill'></i>
                                                                </td>
                                                                <td>
                                                                    <i className='ri-close-fill'></i>
                                                                </td>
                                                                <td>
                                                                    <Link
                                                                        to='/contest_rank'
                                                                        state={{
                                                                            MatchId: data.MatchId,
                                                                            ContestPoolId:
                                                                                data.ContestPoolId,
                                                                            TotalWinningAmount:
                                                                                data.TotalWinningAmount,
                                                                            JoiningFees:
                                                                                data.JoiningFees,
                                                                            MaxSpots: data.MaxSpots,
                                                                            TotalWinners:
                                                                                data.TotalWinners,
                                                                            Status: data.Status,
                                                                            ContestCategoryName:
                                                                                data.ContestCategoryName,
                                                                        }}
                                                                        className='btn btn-skyblue btn-sm'
                                                                    >
                                                                        <i className='ri-edit-line'></i>
                                                                        Add/Edit
                                                                    </Link>
                                                                </td>
                                                                {/* <td><a href="pool_list"><img src="/assets/images/cricket-team/1q.png" style={{width:"50px"}} /></a></td>                      */}
                                                            </tr>
                                                        ))
                                                    )}

                                                    {/* <tr>
                                <td>2</td>
                                <td>Asia Cup 2022</td>
                                <td><a href="pool_list">Mega Contest</a></td>
                                <td>Jeeto Dil Khol ke</td>
                                <td>Rs 55</td>
                                <td>Money</td>
                                <td>2,30,000</td>
                                <td>5000</td>
                                <td><i className="ri-check-line"></i></td>
                                <td><i className="ri-close-fill"></i></td>
                                <td><i className="ri-close-fill"></i></td>
                                <td><a href="pool_list"><img src="/assets/images/cricket-team/1q.png" style={{width: "50px"}} /></a></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Asia Cup 2022</td>
                                <td><a href="pool_list">Mega Contest</a></td>
                                <td>Jeeto Dil Khol ke</td>
                                <td>Rs 55</td>
                                <td>Money</td>
                                <td>2,30,000</td>
                                <td>5000</td>
                                <td><i className="ri-check-line"></i></td>
                                <td><i className="ri-close-fill"></i></td>
                                <td><i className="ri-close-fill"></i></td>
                                <td><a href="pool_list"><img src="/assets/images/cricket-team/1q.png" style={{width: "50px"}} /></a></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Asia Cup 2022</td>
                                <td><a href="pool_list">Mega Contest</a></td>
                                <td>Jeeto Dil Khol ke</td>
                                <td>Rs 55</td>
                                <td>Money</td>
                                <td>2,30,000</td>
                                <td>5000</td>
                                <td><i className="ri-check-line"></i></td>
                                <td><i className="ri-close-fill"></i></td>
                                <td><i className="ri-close-fill"></i></td>
                                <td><a href="pool_list"><img src="/assets/images/cricket-team/1q.png" style={{width: "50px"}} /></a></td>
                            </tr>                           */}
                                                </tbody>
                                                {/* <tfoot>
                            <tr>
                                <th>S.No.</th>
                                <th>Match</th>
                                <th>Contest</th>
                                <th>Sub Title</th>
                                <th>Entry Fee</th>
                                <th>Win Type</th>
                                <th>Winning Amt</th>
                                <th>Max Users</th>
                                <th>R</th>
                                <th>C</th>
                                <th>B</th>
                                <th>Image</th> 
                            </tr>
                        </tfoot> */}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
