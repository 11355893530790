import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { EmailAPI } from '../api/emailApi';

export default function EmailNotificationPage() {
    const [specificUser, setSpecificUser] = useState();
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        emailInfo();
    }, []);

    const emailInfo = () => {
        EmailAPI.GetEmailUserInfo().then((data) => {
            var email_i = JSON.parse(JSON.stringify(data));
            // console.log(email_i.result);
            if (data.status_code === true) {
                setSpecificUser(email_i.result);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Send Notification</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Select Users :</label>
                                            <div>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        value='option1'
                                                        checked={true}
                                                    />{' '}
                                                    All User
                                                </label>
                                                &nbsp;
                                                <label>
                                                    <input type='radio' value='option2' /> Specific
                                                    User
                                                </label>
                                                &nbsp;
                                                <label>
                                                    <input type='radio' value='option2' /> Users By
                                                    Date
                                                </label>
                                            </div>
                                            <br />
                                            <select className='form-control mb-3'>
                                                <option select=''>Open this select menu</option>
                                                <option value='1'>All Users</option>
                                                <option value='2'>Specific Users</option>
                                                <option value='3'>Users By Date</option>
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Notification Title :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Notification Title Here'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Add Image :</label>
                                            <div className='custom-file'>
                                                <input
                                                    type='file'
                                                    className='custom-file-input'
                                                    id='customFile'
                                                />
                                                <label
                                                    className='custom-file-label'
                                                    htmlFor='customFile'
                                                >
                                                    Choose file
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Enter Message :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Message Here'
                                            />
                                        </div>
                                        <button type='submit' className='btn btn-success'>
                                            Reset
                                        </button>
                                        &nbsp;
                                        <button type='submit' className='btn btn-primary'>
                                            Send Notification
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
