import { useEffect, useState } from 'react';
import { PanAPI } from '../api/verifyPanApi';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import './ViewAllUsers.css';
import { ReportAPI } from '../api/ReportAPI';
import { Link, useNavigate } from 'react-router-dom';
import ExcelDownload from '../Component/ExcelDownloadHook';

export default function MatchDataGST() {

    const navigation = useNavigate();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [gstData, setGstData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const gstDataAPI = () => {
        setIsLoading(true);
        (ReportAPI.GSTDATAAPI(startDate, endDate).then(data => {
            if (data.status_code === true) {
                setGstData(data.data);
                console.log(data);
                Notiflix.Notify.success('Data Fetched Successfully');
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure('Something Went Wrong');
                setIsLoading(false);
            }
        }));
    };

    const handleReset = () => {
        setGstData([]);
        setIsLoading(false);
    };

    const headers = ['amount', 'order_id', 'date', 'state', 'CGST', 'SGST', 'IGST'];

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div style={{
                    marginLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', gap: 10
                    }}>
                        <input className='offers_input-gstData' type='date' onChange={(e) => setStartDate(e.target.value)} />
                        <input className='offers_input-gstData' type='date' onChange={(e) => setEndDate(e.target.value)} />
                        <button className='button-gst-div' onClick={gstDataAPI}>Search</button>
                        <button className='button-gst-div-reset' onClick={handleReset}>Reset</button>

                        {
                            gstData ? <ExcelDownload data={gstData} filename={'GST per Match Data'} headers={headers} /> : ''
                        }


                    </div>
                    <div>
                        <Link style={{
                            backgroundColor: 'red', color: 'white', padding: 10, borderRadius: 8
                        }} to={'/gst'}>
                            GST Match Data
                        </Link>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>Amount</th>
                                                        <th>Order Id</th>
                                                        <th>Date</th>
                                                        <th>CGST</th>
                                                        <th>SGST</th>
                                                        <th>IGST</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isLoading ? (
                                                            <div className=''>
                                                                <div className=''>
                                                                    <RotatingLines
                                                                        marginTop='40'
                                                                        strokeColor='#c1030d'
                                                                        strokeWidth='5'
                                                                        animationDuration='0.75'
                                                                        width='50'
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) :
                                                            gstData.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td>{item.amount}</td>
                                                                            <td>{item.order_id}</td>
                                                                            <td>{item.date}</td>
                                                                            <td>{item.CGST}</td>
                                                                            <td>{item.SGST}</td>
                                                                            <td>{item.IGST}</td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {
                                    gstData.length <= 0 ? <h style={{
                                        textAlign: 'center', marginBottom: 40, marginTop: 40
                                    }}>Please Search With Dates</h> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
