import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const ContestAPI = {
    GetContestCategory: async (Fantasy, contestName) => {
        if (!Fantasy && contestName) {
            console.log('from contest name');

            return await fetch(baseURL + 'GetContestCategory?ContestCategoryName=' + contestName, {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            })
                .then((response) => response.json())
                .then((json) => {
                    //notifyMessage("processing....")
                    // notifyMessage("Processing....")
                    //  console.log("api===="+json)
                    if (json != null) {
                        //  console.log("api===="+JSON.stringify(json))
                        return json;
                    } else return false;
                })
                .catch((err) => {
                    console.log('Some error occured, please retry');

                    console.log(err);
                    return false;
                });
        } else if (!contestName && Fantasy) {
            console.log('from fantasyy');
            return await fetch(baseURL + 'GetContestCategory?Fantasy=' + Fantasy, {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            })
                .then((response) => response.json())
                .then((json) => {
                    //notifyMessage("processing....")
                    // notifyMessage("Processing....")
                    //  console.log("api===="+json)
                    if (json != null) {
                        //  console.log("api===="+JSON.stringify(json))
                        return json;
                    } else return false;
                })
                .catch((err) => {
                    console.log('Some error occured, please retry');

                    console.log(err);
                    return false;
                });
        } else if (Fantasy && contestName) {
            // console.log('from')

            return await fetch(
                baseURL +
                'GetContestCategory?Fantasy=' +
                Fantasy +
                '&ContestCategoryName=' +
                contestName,
                {
                    method: 'GET',
                    headers: {
                        // these could be different for your API call
                        Accept: 'application/json',
                        'Content-Type': 'application/json; charset=utf-8',
                        'x-api-key': key,
                    },
                    // body: JSON.stringify(jsonbody),*/
                }
            )
                .then((response) => response.json())
                .then((json) => {
                    //notifyMessage("processing....")
                    // notifyMessage("Processing....")
                    //  console.log("api===="+json)
                    if (json != null) {
                        //  console.log("api===="+JSON.stringify(json))
                        return json;
                    } else return false;
                })
                .catch((err) => {
                    console.log('Some error occured, please retry');

                    console.log(err);
                    return false;
                });
        } else {
            console.log('from both empty');
            return await fetch(baseURL + 'GetContestCategory', {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            })
                .then((response) => response.json())
                .then((json) => {
                    //notifyMessage("processing....")
                    // notifyMessage("Processing....")
                    //  console.log("api===="+json)
                    if (json != null) {
                        //  console.log("api===="+JSON.stringify(json))
                        return json;
                    } else return false;
                })
                .catch((err) => {
                    console.log('Some error occured, please retry');

                    console.log(err);
                    return false;
                });
        }
        //alert()
    },

    POSTAPI: async (jsonbody) => {
        console.log(jsonbody);

        return await fetch(baseURL + 'CreateContestCategory', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    CreatePoolContest: async (jsonbody) => {
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'CreatePoolContest', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetPoolContest: async (matchId) => {
        //alert()
        return await fetch(baseURL + 'GetPoolContest/MatchId=' + matchId, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetPoolContestWinning: async (matchId, contestID) => {
        //alert()
        return await fetch(
            baseURL + 'GetPoolContestWinning/MatchId=' + matchId + '&ContestPoolId=' + contestID,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
                // body: JSON.stringify(jsonbody),*/
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    CreatePoolContestWinningRank: async (jsonbody) => {
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'CreatePoolContestWinning', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    EditContestCategory: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'editContestCategory', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    DeleteContestCategory: async (jsonbody) => {
        const ContestCatId = jsonbody;
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'deleteContestCategory', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify({ ContestCatId: ContestCatId }),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    DeleteGlobalContest: async (jsonbody) => {
        const id = jsonbody;
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'deleteGlobalContest', {
            method: 'DELETE',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify({ _id: id }),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
