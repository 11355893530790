import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SeriesAPI } from '../api/seriesApi';
import { GlobalContestAPI } from '../api/globalContestApi';
import { ContestAPI } from '../api/contestApi';
import Notiflix from 'notiflix';
import { CSVLink } from 'react-csv';
import { RotatingLines } from 'react-loader-spinner';

export default function GlobalContestPage() {
    const [fantasyList, setFantasyList] = useState([]);
    const [fantasyVal, setFantasyVal] = useState('cricket');
    const [globalContest, setGlobalContest] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const role = localStorage.getItem('role');
    const [selectedContests, setSelectedContests] = useState([]);

    const headers = [
        { label: 'Contest Category', key: 'contest_category' },
        { label: 'Entry Fee', key: 'entry_fee' },
        { label: 'Win Type', key: 'contest_prize_card_type' },
        { label: 'Winning Amt', key: 'winning_amount' },
        { label: 'Max Users', key: 'maximum_user' },
        { label: 'M', key: 'is_multiple_entry' },
        { label: 'R', key: 'is_running' },
        { label: 'C', key: 'is_confirmed' },
        { label: 'B', key: 'is_bonus' },
        { label: 'CType', key: 'contest_category' },
        { label: 'Order', key: 'order' },
        { label: 'fantasy', key: 'fantasy' },
        { label: 'Contest Amount Type', key: 'contest_amount_type' },
    ];

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFantasy();
        GetGlobalContest();
    }, []);

    const GetFantasy = () =>
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
            setIsLoading(false);
        });

    const GetGlobalContest = () =>
        GlobalContestAPI.FetchAllGlobalContest(fantasyVal).then((data) => {
            if (data.status_code) setGlobalContest(data.message);
            setIsLoading(false);
        });

    const fantasyChange = (e) => setFantasyVal(e.target.value);

    const handleCheckboxChange = (contestId) => {
        setSelectedContests((prevSelected) =>
            prevSelected.includes(contestId)
                ? prevSelected.filter((id) => id !== contestId)
                : [...prevSelected, contestId]

        );
    };

    const handleSelectAll = () => {
        const allContestIds = globalContest.map((contest) => contest._id);
        setSelectedContests(allContestIds);
    };

    const [permission, setPermisiion] = useState(false);

    const isAllowedTrue = () => {
        setPermisiion(true);
    };

    const deleteMultipleContest = () => {
        setPermisiion(false);
        if (selectedContests.length > 0) {
            GlobalContestAPI.DeleteMultipleGlobalContest(selectedContests).then(
                (data) => {
                    if (data.status_code) {
                        Notiflix.Notify.success('Deleted Successful');
                        setSelectedContests([]);
                        GetGlobalContest();
                    }
                }
            );
        } else {
            Notiflix.Notify.warning('Please select more than one contest to delete');
        }
    };

    const deleteChange = (id) =>
        ContestAPI.DeleteGlobalContest(id).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Deleted Successfully');
                GetGlobalContest();
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });

    const submit = () => GetGlobalContest();

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <select
                                                className='form-control mb-3'
                                                onChange={fantasyChange}
                                            >
                                                {fantasyList.map((fan) => (
                                                    <option key={fan.fantasy} value={fan.fantasy}>
                                                        {fan.fantasy}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-primary p-2'
                                            onClick={submit}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    {(role !== '3' && role !== '5') && (
                                        <Link
                                            to='/create_global_contest'
                                            className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                        >
                                            Create Global <i className='ri-add-line m-0'></i>
                                        </Link>
                                    )}
                                    &nbsp;
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <CSVLink
                                                filename='Global Contest'
                                                data={globalContest}
                                                headers={headers}
                                            >
                                                <button className='dropdown-item' href='#'>
                                                    Export This Data
                                                </button>
                                            </CSVLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isLoading && (
                        <div className='col-sm-12 col-lg-12'>
                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                <RotatingLines
                                    marginTop='40'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}


                    {
                        selectedContests.length <= 0 ?
                            null
                            :
                            <button
                                type="button"
                                className="delete-button-globalContest"
                                onClick={deleteMultipleContest}

                            >
                                Delete All
                            </button>
                    }

                    {!isLoading && (
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Global Contest</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input
                                                                type='checkbox'
                                                                className='checkbox-input'
                                                                id='selectAllCheckbox'
                                                                onChange={handleSelectAll}
                                                            />
                                                        </th>
                                                        <th>S.No.</th>
                                                        <th>Contest Category</th>
                                                        <th>Entry Fee</th>
                                                        <th>Win Type</th>
                                                        <th>Winning Amt</th>
                                                        <th>Max Users</th>
                                                        <th>Fantasy</th>
                                                        <th>M</th>
                                                        <th>R</th>
                                                        <th>C</th>
                                                        <th>B</th>
                                                        <th>Multi Entry</th>
                                                        <th>Bonus</th>
                                                        <th>Order</th>
                                                        {(role !== '3' && role !== '5') && <th>Price Card</th>}
                                                        {(role !== '3' && role !== '5') && <th>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {globalContest.map((contest, i) => (
                                                        <tr key={contest._id}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkbox-input"
                                                                    id={`checkbox-${contest._id}`}
                                                                    checked={selectedContests.includes(contest._id)}
                                                                    onChange={() => handleCheckboxChange(contest._id)}
                                                                />
                                                            </td>
                                                            <td>{i + 1}</td>
                                                            <td>{contest.contest_category}</td>
                                                            <td>{contest.entry_fee}</td>
                                                            <td>{contest.contest_prize_card_type}</td>
                                                            <td>{contest.winning_amount}</td>
                                                            <td>{contest.maximum_user}</td>
                                                            <td>{contest.fantasy}</td>
                                                            <td>{contest.is_multiple_entry ? '✓' : 'x'}</td>
                                                            <td>{contest.is_running ? '✓' : 'x'}</td>
                                                            <td>{contest.is_confirmed ? '✓' : 'x'}</td>
                                                            <td>{contest.is_bonus ? '✓' : 'x'}</td>
                                                            <td>{contest.multi_entry}</td>
                                                            <td>{contest.bonus}</td>
                                                            <td>{contest.order}</td>

                                                            {(role !== '3' && role !== '5') && (
                                                                <>
                                                                    <td>
                                                                        <Link
                                                                            to='/add_pricecard'
                                                                            state={contest}
                                                                            className='btn btn-skyblue btn-sm'
                                                                        >
                                                                            <i className='ri-edit-line'></i> Add/Edit
                                                                        </Link>
                                                                    </td>
                                                                    <td>

                                                                        <Link
                                                                            to='/edit_global_contest'
                                                                            state={contest}
                                                                            className='btn btn-skyblue btn-sm'
                                                                        >
                                                                            <i className='ri-edit-line'></i> Edit
                                                                        </Link>

                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

