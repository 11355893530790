import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import LoadingView from './screens/Structure/Loader';
import SidebarView from './screens/Structure/Sidebar';
import TopbarView from './screens/Structure/Topbar';
import DashboardPage from './screens/Dashboard';
import CreateSeriesPage from './screens/CreateSeries';
import ViewSeriesPage from './screens/ViewSeries';
import ViewTeamPage from './screens/ViewTeam';
import ViewPlayerPage from './screens/ViewPlayer';
import EditPlayerPage from './screens/EditPlayer';
import PlayersSusbstitutePage from './screens/PlayersSusbstitute';
import UpcomingMatchePage from './screens/UpcomingMatches';
import FooterView from './screens/Structure/Footer';
import EditExistingMatchesPage from './screens/EditExistingMatches';
import AllMatchesPage from './screens/AllMatches';
import RescheduleMatchPage from './screens/RescheduleMatch';
import EditContestCategoryPage from './screens/EditContestCategory';
import ViewContestCategoryPage from './screens/ViewContestCategory';
import UpdatePlayingXi from './screens/UpdatePlayingXi';
import ImpactPlayer from './screens/ImpactPlayer';

import CreatQuizCategoryPage from './screens/CreatQuizCategory';
import ViewQuizContestCategoryPage from './screens/ViewQuizContestCategory';
import ViewAllQuestionPage from './screens/ViewAllQuestion';
import AddNewQuestionPage from './screens/AddNewQuestion';
import ViewAllQuizContestPage from './screens/ViewAllQuizContest';
import ViewChallengeQuestionPage from './screens/ViewChallengeQuestions';
import JoinUserListingPage from './screens/JoinedUsersListing';
import CreateCategoryPage from './screens/CreateCategory';
import ContestWiseReports from './screens/ContestWiseReports';
import AssignContestPage from './screens/AssignContest';
import AddPoolsAndWinningsPage from './screens/AddPoolsandWinnings';
import PoolList from './screens/PoolList';
import GlobalContestPage from './screens/GlobalContests';
import CreateGlobalContestPage from './screens/CreateGlobalContest';
import AddPriceCardPage from './screens/AddPriceCard';
import CreateCustomContest from './screens/CreateCustomContest';
import ViewAllUserPage from './screens/ViewAllUsers';
import UsersWalletsPage from './screens/UsersWallets';
import ViewAllFeedbackPage from './screens/ViewAllFeedback';
import VerifyPanPage from './screens/VerifyPan';
import VerifyBankAccountPage from './screens/VerifyBankAccount';
import WithdrawAmountPage from './screens/WithdrawAmount';
import MatchResultPage from './screens/MatchResult';
import MatchDetailsPage from './screens/MatchDetail';
import CancelledMatchesPage from './screens/CancelledMatches';
import FullDetailPage from './screens/FullDetail';
import AllContests from './screens/AllContests';
import CashFreePage from './screens/CashFree';
import ViewSideBanner from './screens/ViewSideBanner';
import EditSideBannerPage from './screens/EditSideBanner';
import PushNotificationPage from './screens/PushNotifcations';
import EmailNotificationPage from './screens/EmailNotifications';
import GeneralPage from './screens/General';
import PointsPage from './screens/Points';
import ReferralsPage from './screens/Referrals';
import ReferralsUsersPage from './screens/ReferralsUsers';
import AddOfferPage from './screens/AddOffers';
import GetOffersPage from './screens/GetOffers';
import EditOffersPage from './screens/EditOffers';
import AboutUsPage from './screens/AboutUs';
import AddYoutuberPage from './screens/AddYoutuber';
import ViewYoutuberPage from './screens/ViewYoutuber';
import ViewTDS from './screens/ViewTds';
import EarningPage from './screens/Earning';
import TotalBalancePage from './screens/TotalBalance';
import NotificationHistoryPage from './screens/NotificationHistory';
import { Helmet } from 'react-helmet';
import HowToPlayPage from './screens/HowToPlay';
import ContestRankPage from './screens/ContestRank';
import Settings from './screens/SettingsPage';
import LoginPage from './screens/Login';
import MenuMaster from './screens/MenuMaster';
import Protected from './screens/Protected';
import MasterTable from './screens/MasterTable';
import RoleMaster from './screens/RoleMaster';
import EmployeeMaster from './screens/EmployeeMaster';
import AuthorityMaster from './screens/AuthorityMaster';
import ForgotPassword from './screens/ForgotPassword';
import React, { useState } from 'react';
import AdminWallet from './screens/AdminWallet';
import SearchAdminWallet from './screens/SearchAdminWallet';
import EditSecondSideBanner from './screens/EditSecondSideBanner';
import ScoreBoard from './screens/ScoreBoard';
import StrikeRate from './screens/StrikeRate';
import EconomyTable from './screens/EconomyTable';
import CreateQuizContestPage from './screens/CreateQuizContest';
import QuizContestRankPage from './screens/QuizContestRank';
import { CreateScoreBoard } from './screens/CreateScoreBoard';
import LegalityPage from './screens/LegalityPage';
import EditGlobalContestPage from './screens/EditGlobalContest';
import AddNewCustomContest from './screens/AddNewCustomContest';
import ViewReferrals from './screens/ViewReferrals';
import RoyaltyPointsPage from './screens/RoyaltyPoints';
// import MatchPlayerPointsPage from './screens/MatchPlayerPoints';
import UserActivityLogPage from './screens/UserActivityLog';
import SidebarRoleTwoView from './screens/Structure/SidebarRoleTwo';
import Cookies from 'universal-cookie';
import DailyReport from './screens/DailyReport';
import SeriesMatchDetail from './screens/SeriesMatchDetail';
import ViewProfitLoss from './screens/ViewProfitLoss';
import AllUsersPage from './screens/AllUsers';
import UserTeamPage from './screens/UserTeam';
import GetUserDetailsPage from './screens/GetUserDetails';
// import {QueryClient, QueryClientProvider} from 'react-query';
import UserTransactions from './screens/UserTransactions';
import ViewTransactionPage from './screens/ViewTransaction';
import PaymentHistoryPage from './screens/PaymentHistory';
import UserAccountDetail from './screens/UserAccountDetail';
import GST from './screens/GST';
import Banner from './screens/Banner';
import ViewPopUpBannerPage from './screens/ViewPopUpBanners';
import KycReportPage from './screens/KycReport';
import WalletReport from './screens/WalletReport';
import UnutilizedReport from './screens/UnutilizedReport';
import Cases from './screens/Cases';
import AllCases from './screens/AllCases';
import SidebarRoleThreeView from './screens/Structure/SidebarRoleThree';
import SidebarRoleFiveView from './screens/Structure/SidebarRoleFive';
import SidebarRoleFourView from './screens/Structure/SidebarRoleFour';

import UserManagerCase from './screens/UserManagerCase';
import SingleUserDetail from './screens/SingleUserDetail';
import CreateCases from './screens/CreateCases';
import SupportUserTransaction from './screens/SupportUserTransaction';
import WithdrawWithoutKYC from './screens/WithdrawWithoutKYC';
import CreateUser from './CreateUser';
import RemoveUserScreen from './screens/RemoveUserScreen';
import TotalUsersRegistered from './screens/TotalUsersRegistered';
import Funds from './screens/Funds';
import AddDeductFunds from './screens/Add-Deduct-Funds';
import AllWalletLogs from './screens/AllWalletLogs';
import ReferalDetailsPage from './screens/ReferalDetails';
import ReferReportData from './screens/ReferReport';
import PrivateContest from './screens/PrivateContestMatches';
import PrivateContestDetail from './screens/PrivateContestDetail';
import PrivateContestInfo from './screens/PrivateContestInfo';
import PrivateContestTeamInfo from './screens/PrivateContestTeamInfo';
import TdsReport from './screens/TdsReport';
import IndividualRefReport from './screens/IndividualRefReport';
import ActiveInactiveUserReport from './screens/Active-Inactive-User-Report';
import UpdatePlayerRole from './screens/UpdatePlayerRole';
// import RefUsageStat from './screens/RefCodeUsageStat';
import LudoCreateContest from './screens/LudoCreateContest';
import LudoGet from './screens/LudoGet';
import RefCodeUsageStat from './screens/RefCodeUsageStat';
import ActiveUserWithDeposit from './screens/ActiveUserWithDeposit';
import TotalDeposit from './screens/TotalDeposit';
import DeleteExtraPlayer from './screens/DeleteExtraPlayer';
import GST_Report from './screens/Gst_Report';
import FirstTimeDepositReport from './screens/FirstTimeDepositReport';
import RummyCreateContest from './screens/RummyCreateContest';
import MatchDataGST from './screens/MatchDataGST';
import ModificationReport from './screens/ModificationReport';
import BonusExpiry from './screens/BonusExpiry';
import LudoContestReport from './screens/LudoReport';
import LudoPlayerTransaction from './screens/LudoPlayerTransaction';
import CrashCreateContest from './screens/CrashCreateContest';
import BannedUserReport from './screens/BannedUserReport';
import Razorpayfunds from './screens/Razorpayfunds';
// import { Outlet } from "react-router-dom";
function App() {
    const [isTopBar, setIsTopBar] = useState(true);
    const cookies = new Cookies();
    const role = localStorage.getItem('role');
    // const queryClient = new QueryClient();
    console.log(role);

    const authenticated = localStorage.getItem('loggedin');

    function setTopBar(bool) {
        setIsTopBar(bool);
    }

    return (
        <div>
            <div>
                {/* <Helmet>
        </Helmet> */}
                <BrowserRouter>
                    <LoadingView />

                    {isTopBar && <TopbarView />}
                    {isTopBar && role == 1 && <SidebarView />}
                    {isTopBar && role == 2 && <SidebarRoleTwoView />}
                    {isTopBar && role == 3 && <SidebarRoleThreeView />}
                    {isTopBar && role == 4 && <SidebarRoleFourView />}
                    {isTopBar && role == 5 && <SidebarRoleFiveView />}
                    {isTopBar && (role == '' || !role) && <SidebarRoleFiveView />}
                    <Routes>
                        <Route path='/login' element={<LoginPage topbar={setTopBar} />} />
                        <Route path='/remove_user' element={<RemoveUserScreen />} />
                        <Route path='/' element={<Protected Component={DashboardPage} />} />
                        <Route
                            path='/create_series'
                            element={<Protected Component={CreateSeriesPage} />}
                        />
                        <Route
                            path='/view_series'
                            element={<Protected Component={ViewSeriesPage} />}
                        />
                        <Route path='/view_team' element={<Protected Component={ViewTeamPage} />} />
                        <Route
                            path='/view_player'
                            element={<Protected Component={ViewPlayerPage} />}
                        />
                        <Route
                            path='/edit_player'
                            element={<Protected Component={EditPlayerPage} />}
                        />
                        {/* iske upar shyad comment */}
                        <Route
                            path='/players_substitute'
                            element={<Protected Component={PlayersSusbstitutePage} />}
                        />
                        <Route
                            path='/upcoming_matches'
                            element={<Protected Component={UpcomingMatchePage} />}
                        />
                        <Route
                            path='/edit_existing_matches'
                            element={<Protected Component={EditExistingMatchesPage} />}
                        />
                        <Route
                            path='/all_matches'
                            element={<Protected Component={AllMatchesPage} />}
                        />
                        <Route
                            path='/reschedule_match'
                            element={<Protected Component={RescheduleMatchPage} />}
                        />
                        <Route
                            path='/edit_contest_category'
                            element={<Protected Component={EditContestCategoryPage} />}
                        />
                        <Route
                            path='/view_contest_category'
                            element={<Protected Component={ViewContestCategoryPage} />}
                        />
                        <Route
                            path='/update_playing_xi'
                            element={<Protected Component={UpdatePlayingXi} />}
                        />

                        <Route
                            path='/impactplayer'
                            element={<Protected Component={ImpactPlayer} />}
                        />
                        <Route
                            path='/creat_quiz_category'
                            element={<Protected Component={CreatQuizCategoryPage} />}
                        />
                        <Route
                            path='/view_quiz_contest_category'
                            element={<Protected Component={ViewQuizContestCategoryPage} />}
                        />
                        <Route
                            path='/view_all_question'
                            element={<Protected Component={ViewAllQuestionPage} />}
                        />
                        <Route
                            path='/add_new_question'
                            element={<Protected Component={AddNewQuestionPage} />}
                        />
                        <Route
                            path='/view_all_quiz_contest'
                            element={<Protected Component={ViewAllQuizContestPage} />}
                        />
                        <Route
                            path='/view_challenge_question'
                            element={<Protected Component={ViewChallengeQuestionPage} />}
                        />
                        <Route
                            path='/join_user_listing'
                            element={<Protected Component={JoinUserListingPage} />}
                        />
                        <Route
                            path='/create_category'
                            element={<Protected Component={CreateCategoryPage} />}
                        />
                        <Route
                            path='/contest_wise_reports'
                            element={<Protected Component={ContestWiseReports} />}
                        />
                        <Route
                            path='/assign_contest'
                            element={<Protected Component={AssignContestPage} />}
                        />
                        <Route
                            path='/add_poolsand_winning'
                            element={<Protected Component={AddPoolsAndWinningsPage} />}
                        />
                        <Route path='/pool_list' element={<Protected Component={PoolList} />} />
                        <Route
                            path='/global_contests'
                            element={<Protected Component={GlobalContestPage} />}
                        />
                        <Route
                            path='/create_global_contest'
                            element={<Protected Component={CreateGlobalContestPage} />}
                        />
                        <Route
                            path='/edit_global_contest'
                            element={<Protected Component={EditGlobalContestPage} />}
                        />
                        <Route
                            path='/add_pricecard'
                            element={<Protected Component={AddPriceCardPage} />}
                        />
                        <Route
                            path='/create_custom_contest'
                            element={<Protected Component={CreateCustomContest} />}
                        />
                        <Route
                            path='/view_all_users'
                            element={<Protected Component={ViewAllUserPage} />}
                        />
                        <Route
                            path='/users_wallets'
                            element={<Protected Component={UsersWalletsPage} />}
                        />
                        <Route
                            path='/view_all_feedback'
                            element={<Protected Component={ViewAllFeedbackPage} />}
                        />
                        <Route
                            path='/verifypan'
                            element={<Protected Component={VerifyPanPage} />}
                        />
                        <Route
                            path='/verifybankaccount'
                            element={<Protected Component={VerifyBankAccountPage} />}
                        />
                        <Route
                            path='/withdraw_amount'
                            element={<Protected Component={WithdrawAmountPage} />}
                        />
                        <Route
                            path='/match_result'
                            element={<Protected Component={MatchResultPage} />}
                        />
                        <Route
                            path='/match_detail'
                            element={<Protected Component={MatchDetailsPage} />}
                        />
                        <Route
                            path='/cancelled_matches'
                            element={<Protected Component={CancelledMatchesPage} />}
                        />
                        <Route
                            path='/fulldetail'
                            element={<Protected Component={FullDetailPage} />}
                        />
                        <Route
                            path='/allcontests'
                            element={<Protected Component={AllContests} />}
                        />
                        <Route path='/cashfree' element={<Protected Component={CashFreePage} />} />
                        <Route
                            path='/view_side_banner'
                            element={<Protected Component={ViewSideBanner} />}
                        />
                        <Route
                            path='/edit_side_banner'
                            element={<Protected Component={EditSideBannerPage} />}
                        />
                        <Route
                            path='/push_notification'
                            element={<Protected Component={PushNotificationPage} />}
                        />
                        <Route
                            path='/email_notification'
                            element={<Protected Component={EmailNotificationPage} />}
                        />
                        <Route path='/general' element={<Protected Component={GeneralPage} />} />
                        <Route path='/points' element={<Protected Component={PointsPage} />} />
                        <Route
                            path='/referrals'
                            element={<Protected Component={ReferralsPage} />}
                        />
                        <Route
                            path='/referrals_users'
                            element={<Protected Component={ReferralsUsersPage} />}
                        />
                        <Route path='/addoffers' element={<Protected Component={AddOfferPage} />} />
                        <Route
                            path='/getoffers'
                            element={<Protected Component={GetOffersPage} />}
                        />
                        <Route
                            path='/editoffers'
                            element={<Protected Component={EditOffersPage} />}
                        />
                        <Route
                            path='/add_aboutus'
                            element={<Protected Component={AboutUsPage} />}
                        />
                        <Route
                            path='/add_youtuber'
                            element={<Protected Component={AddYoutuberPage} />}
                        />
                        <Route
                            path='/view_youtuber'
                            element={<Protected Component={ViewYoutuberPage} />}
                        />
                        <Route path='/view_tds' element={<Protected Component={ViewTDS} />} />
                        <Route path='/earning' element={<Protected Component={EarningPage} />} />
                        <Route
                            path='/total_balance'
                            element={<Protected Component={TotalBalancePage} />}
                        />
                        <Route
                            path='/notification_history'
                            element={<Protected Component={NotificationHistoryPage} />}
                        />
                        <Route
                            path='/add_howto_play'
                            element={<Protected Component={HowToPlayPage} />}
                        />
                        <Route
                            path='/contest_rank'
                            element={<Protected Component={ContestRankPage} />}
                        />
                        <Route path='/settings' element={<Protected Component={Settings} />} />
                        <Route path='/master' element={<Protected Component={MenuMaster} />} />
                        <Route
                            path='/master_table'
                            element={<Protected Component={MasterTable} />}
                        />
                        <Route path='/role_master' element={<Protected Component={RoleMaster} />} />
                        <Route
                            path='/employee_master'
                            element={<Protected Component={EmployeeMaster} />}
                        />
                        <Route
                            path='/authority_master'
                            element={<Protected Component={AuthorityMaster} />}
                        />
                        <Route path='/forgot' element={<Protected Component={ForgotPassword} />} />
                        <Route path='/admin_wallet' element={<Protected Component={AdminWallet} />}
                        />
                        <Route
                            path='/search_admin'
                            element={<Protected Component={SearchAdminWallet} />}
                        />
                        <Route
                            path='/edit_second_side_banner'
                            element={<Protected Component={EditSecondSideBanner} />}
                        />
                        <Route path='/scoreBoard' element={<Protected Component={ScoreBoard} />} />
                        <Route path='/strikeBoard' element={<Protected Component={StrikeRate} />} />
                        <Route
                            path='/economyBoard'
                            element={<Protected Component={EconomyTable} />}
                        />
                        <Route
                            path='/create_quiz_contest'
                            element={<Protected Component={CreateQuizContestPage} />}
                        />
                        <Route
                            path='/quiz_contest_rank'
                            element={<Protected Component={QuizContestRankPage} />}
                        />
                        <Route
                            path='/createScoreBoard'
                            element={<Protected Component={CreateScoreBoard} />}
                        />
                        <Route path='/Legality' element={<Protected Component={LegalityPage} />} />
                        <Route
                            path='/customContestPage'
                            element={<Protected Component={AddNewCustomContest} />}
                        />
                        <Route
                            path='/view_referrals'
                            element={<Protected Component={ViewReferrals} />}
                        />
                        <Route
                            path='/royalty_points'
                            element={<Protected Component={RoyaltyPointsPage} />}
                        />

                        <Route
                            path='/user_activity_log'
                            element={<Protected Component={UserActivityLogPage} />}
                        />
                        <Route
                            path='/daily_report'
                            element={<Protected Component={DailyReport} />}
                        />
                        <Route
                            path='/series_match'
                            element={<Protected Component={SeriesMatchDetail} />}
                        />
                        <Route
                            path='/daily_report'
                            element={<Protected Component={DailyReport} />}
                        />
                        <Route
                            path='/profit_loss'
                            element={<Protected Component={ViewProfitLoss} />}
                        />
                        <Route path='/all_users' element={<Protected Component={AllUsersPage} />} />
                        <Route path='/user_team' element={<Protected Component={UserTeamPage} />} />
                        <Route path='/view_team' element={<Protected Component={ViewTeamPage} />} />


                        <Route path='/funds' element={<Protected Component={Funds} />} />
                        <Route path='/add-deduct-funds' element={<Protected Component={AddDeductFunds} />} />

                        <Route
                            path='/user_details'
                            element={<Protected Component={SingleUserDetail} />}
                        />
                        <Route
                            path='/support_user_transaction'
                            element={<Protected Component={SupportUserTransaction} />}
                        />

                        <Route
                            path='/create_cases'
                            element={<Protected Component={CreateCases} />}
                        />
                        <Route
                            path='/get_user_details'
                            element={<Protected Component={GetUserDetailsPage} />}
                        />
                        <Route
                            path='/user_transaction'
                            element={<Protected Component={UserTransactions} />}
                        />
                        <Route
                            path='/view_transaction'
                            element={<Protected Component={ViewTransactionPage} />}
                        />
                        <Route
                            path='/payment_history'
                            element={<Protected Component={PaymentHistoryPage} />}
                        />
                        <Route
                            path='/user_account_detail'
                            element={<Protected Component={UserAccountDetail} />}
                        />
                        <Route path='/gst' element={<Protected Component={GST} />} />
                        <Route path='/banner' element={<Protected Component={Banner} />} />
                        <Route
                            path='/view_pop_up_banners'
                            element={<Protected Component={ViewPopUpBannerPage} />}
                        />

                        <Route
                            path='/kyc_report'
                            element={<Protected Component={KycReportPage} />}
                        />

                        <Route
                            path='/wallet_report'
                            element={<Protected Component={WalletReport} />}
                        />

                        <Route
                            path='/unutilizedReport'
                            element={<Protected Component={UnutilizedReport} />}
                        />
                        <Route path='/cases' element={<Protected Component={Cases} />} />
                        <Route path='/allcases' element={<Protected Component={AllCases} />} />

                        <Route
                            path='/userManager'
                            element={<Protected Component={UserManagerCase} />}
                        />

                        <Route
                            path='/withdraw_without_kyc'
                            element={<Protected Component={WithdrawWithoutKYC} />}
                        />

                        <Route
                            path='/total_users_registered'
                            element={<Protected Component={TotalUsersRegistered} />}
                        />
                        <Route path='/create_user' element={<Protected Component={CreateUser} />} />
                        <Route path='/allwalletlogs' element={<Protected Component={AllWalletLogs} />} />
                        <Route path='/referal_details' element={<Protected Component={ReferalDetailsPage} />} />
                        <Route path='/refer-report' element={<Protected Component={ReferReportData} />} />
                        <Route path='/indi-refer-report' element={<Protected Component={IndividualRefReport} />} />
                        <Route path='/pvt-contest' element={<Protected Component={PrivateContest} />} />
                        <Route path='/private-contest-detail' element={<Protected Component={PrivateContestDetail} />} />
                        <Route path='/private-contest-details-info' element={<Protected Component={PrivateContestInfo} />} />
                        <Route path='/prvt-contest-team-info' element={<Protected Component={PrivateContestTeamInfo} />} />
                        <Route path='/TdsReport' element={<Protected Component={TdsReport} />} />
                        <Route path='/activeInactiveUserReport' element={<Protected Component={ActiveInactiveUserReport} />} />
                        <Route path='/updatePlayerRole' element={<Protected Component={UpdatePlayerRole} />} />

                        <Route path='/ref-stats' element={<Protected Component={RefCodeUsageStat} />} />

                        <Route path='/ludo-get' element={<Protected Component={LudoGet} />} />
                        <Route path='/ludo-create-contest' element={<Protected Component={LudoCreateContest} />} />
                        <Route path='/activeUserWithDeposit' element={<Protected Component={ActiveUserWithDeposit} />} />

                        <Route path='/deposit-report' element={<Protected Component={TotalDeposit} />} />
                        <Route path='/first-time-deposit-report' element={<Protected Component={FirstTimeDepositReport} />} />

                        <Route path='/delete-player' element={<Protected Component={DeleteExtraPlayer} />} />

                        <Route path='/gst_report' element={<Protected Component={GST_Report} />} />

                        <Route path='rummy-create-contest' element={<Protected Component={RummyCreateContest} />} />

                        <Route path='/crash-create-contest' element={<Protected Component={CrashCreateContest} />} />

                        <Route path='/match-gst' element={<Protected Component={MatchDataGST} />} />

                        <Route path='modification-report' element={<Protected Component={ModificationReport} />} />

                        <Route path='/bonus-expire' element={<Protected Component={BonusExpiry} />} />

                        <Route path='/ludo-contest-report' element={<Protected Component={LudoContestReport} />} />

                        <Route path='/ludoPlayerTransaction' element={<Protected Component={LudoPlayerTransaction} />} />

                        <Route path='/banned-report' element={<Protected Component={BannedUserReport} />} />
                        <Route path='/Razpayout' element={<Protected Component={Razorpayfunds} />} />

                    </Routes>
                    <FooterView />
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
