import Notiflix from 'notiflix';
import { baseURL } from './apiBaseUrl';
import { BASEAPIS } from './BaseApis';
const key = localStorage.getItem('jwtToken');

export const AllUsersAPI = {
    GetAllUsersInfo: async (entries, pagenu) => {
        //alert()
        return await fetch(baseURL + 'viewAllUsers1?entries=' + entries + '&pagenumber=' + pagenu, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    GetAllUsersDownload: async () => {
        //alert()
        return await fetch(baseURL + 'getAllUsersDataDownload', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'

            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    FilterAllUsersInfo: async (
        entries,
        pagenumber,
        team_name,
        email,
        mobile,
        user_id,
        fromDate,
        endDate,
        state,
        status
    ) => {
        console.log(entries);
        console.log(status);
        //alert()
        return await fetch(
            baseURL +
            'viewAllUsers1?team_name=' +
            team_name +
            '&pagenumber=' +
            pagenumber +
            '&entries=' +
            entries +
            '&email=' +
            email +
            '&phone=' +
            mobile +
            '&user_id=' +
            user_id +
            '&start=' +
            fromDate +
            '&end=' +
            endDate +
            '&state=' +
            state +
            '&status=' +
            status,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                    'mai-hu': 'motaBhai007'
                },
                // body: JSON.stringify(jsonbody),*/
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    FilterAllUsersDownload: async (
        entries,
        fromDate,
        endDate,
        state,
        status,
        pageSize,
        pagenumber
    ) => {
        console.log(fromDate);
        //alert()
        return await fetch(
            baseURL +
            'viewAllUsers1?&start=' +
            fromDate +
            '&entries=' +
            entries +
            '&end=' +
            endDate +
            '&state=' +
            state +
            '&status=' +
            status,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                    'mai-hu': 'motaBhai007'

                },
                // body: JSON.stringify(jsonbody),*/
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    GetAllStates: async () => {
        // console.log(fromDate)
        //alert()
        return await fetch(baseURL + 'getStates', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    EditUserInformation: async (jsonbody) => {
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'editUserDetail', {
            method: 'PUT',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    EditUserStatus: async (jsonbody) => {
        console.log(jsonbody);
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'editUserStatus', {
            method: 'PUT',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    },

    GetUserInfo: (userId) => {
        return BASEAPIS.GETAPI('viewAllUsers1?user_id=' + userId);
    },

    GetBannedUsers: (start, end) => {
        return BASEAPIS.GETAPI('userBannedDash?start=' + start + '&end=' + end);
    },
};
