export const useDecimalFunc = (number) => {
    if (number === undefined) {
        return '';
    } else {
        let result = Math?.round(number * 100) / 100;
        return result;
    }
};

export const useDecimalAmount = (number) => {
    let arr = JSON.stringify(number)?.split('.');
    let SecoArr = arr[1]?.split('').splice(0, 2).join('');
    return parseFloat(`${arr[0]}.${SecoArr}`);
};