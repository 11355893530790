import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { Modal } from 'antd';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { CreateContestAPI } from '../api/LudoAPI';
import { RotatingLines } from 'react-loader-spinner';
import TopRoute from '../Component/TopRoute';

export default function LudoGet() {
    const location = useLocation();

    const [ludoData, setLudoData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [winPriceTableData, setWinPriceTableData] = useState([]);
    const [gameType, setgameType] = useState('ludo');
    const [ludoContestStatus, setLudoContestStatus] = useState('all');

    useEffect(() => {
        getLudoData();
    }, [ludoContestStatus]);

    const getLudoData = () => {
        setIsLoading(true);
        CreateContestAPI.GetLudoGame(gameType, ludoContestStatus).then((data) => {
            if (data.status_code) {
                setLudoData(data.data);
                console.log(data.data);
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const [ludoStatusUpdate, setLudoStatusUpdate] = useState('');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const handleWinningArray = (winPriceArray) => {
        console.log(winPriceArray);
        setWinPriceTableData(winPriceArray);
        setIsModalOpen(true);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [ludoStatus, setLudoStatus] = useState('');
    const [underScoreId, setUnderScoreId] = useState('');


    const handleOk = () => {
        setIsModalOpen2(false);
    };

    const HandlegameType = (e) => {
        setgameType(e.target.value);
    };

    const showModal2 = (status, under_id) => {
        setLudoStatus(status);
        setIsModalOpen2(true);
        setUnderScoreId(under_id);
    };

    const handleOk2 = () => {
        const data = {
            _id: underScoreId,
            status: ludoStatusUpdate
        };
        CreateContestAPI.LudoGameStatusChanged(data).then((res) => {
            if (res.status_code) {
                Notiflix.Notify.success(res.message);
                setIsModalOpen2(false);
                getLudoData();
            } else {
                Notiflix.Notify.failure(res.message);
                setIsLoading(false);
            }
        });
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
        setLudoStatus('');
    };

    return (
        <div className='wrapper'>

            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    {/* <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col-8'>
                                            <select
                                                value={gameType}
                                                className='offers_input'
                                                onChange={HandlegameType}
                                            >
                                                <option value='ludo'>Ludo</option>
                                                <option value='rummy'>Rummy</option>
                                            </select>
                                        </div>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-primary p-2'
                                        // onClick={submit}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <TopRoute name={'All Ludo Contest'} />
                        <div>
                            <select className='offer_checkboxxxxx' value={ludoContestStatus} onChange={handleAllChange(setLudoContestStatus)}>
                                <option value='all'>All Contest</option>
                                <option value='active'>Active Contest</option>
                            </select>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body '>
                                        <div className='div-liread-get'>
                                            <div className='col '>
                                                <div className='row'>
                                                    <div className='col-sm-12 col-lg-12'>
                                                        <div className='card'>
                                                            {isLoading ? (
                                                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                                    <div>
                                                                        <RotatingLines
                                                                            marginTop='160'
                                                                            marginBottom='160'
                                                                            strokeColor='#000000'
                                                                            strokeWidth='3'
                                                                            animationDuration='0.75'
                                                                            width='50'
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='card'>
                                                                    <div className='card-body'>
                                                                        <div>
                                                                            <div className='table-responsive'>
                                                                                <table className='table table-striped table-bordered'>
                                                                                    <thead>
                                                                                        <tr className='support_main_file_div'>
                                                                                            <th>
                                                                                                S.No.
                                                                                            </th>
                                                                                            <th>
                                                                                                Game
                                                                                                Type
                                                                                            </th>
                                                                                            <th>
                                                                                                Pool
                                                                                                Price
                                                                                            </th>
                                                                                            <th>
                                                                                                Entry
                                                                                                Price
                                                                                            </th>
                                                                                            <th>
                                                                                                Maximum
                                                                                                Spots
                                                                                            </th>
                                                                                            <th>
                                                                                                Minimum
                                                                                                Spots
                                                                                            </th>
                                                                                            <th>
                                                                                                Status
                                                                                            </th>
                                                                                            <th>
                                                                                                Winning
                                                                                                Ranks
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {ludoData &&
                                                                                            ludoData?.length >
                                                                                            0 &&
                                                                                            ludoData?.map(
                                                                                                (
                                                                                                    ludo,
                                                                                                    i
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <tr
                                                                                                            key={
                                                                                                                i
                                                                                                            }
                                                                                                        >
                                                                                                            <td>
                                                                                                                {
                                                                                                                    ++i
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    ludo?.gameType
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    ludo?.poolPrice
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    ludo?.entryPrice
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    ludo.max_spots
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {
                                                                                                                    ludo.min_spots
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {<button className='ludo-select-contest-button' onClick={() => showModal2(ludo?.status, ludo._id)}>{
                                                                                                                    ludo?.status ===
                                                                                                                        'active' ? (
                                                                                                                            <h4 className='ludo-select-active-contest'>Active</h4>
                                                                                                                        ) : (
                                                                                                                            <h4 className='ludo-select-inactive-contest'>Inactive</h4>
                                                                                                                        )
                                                                                                                }
                                                                                                                </button>}
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                {
                                                                                                                    <button
                                                                                                                        className='ludo-button-winrank'
                                                                                                                        onClick={() =>
                                                                                                                            handleWinningArray(
                                                                                                                                ludo?.winPrice
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        See
                                                                                                                        All
                                                                                                                    </button>
                                                                                                                }
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                }
                                                                                            )

                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {

                            <Modal title="Ranks and Winning Prices" open={isModalOpen} onOk={() => setIsModalOpen(false)} closeIcon={() => setIsModalOpen(false)}>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div>
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-bordered'>
                                                    <thead>
                                                        <tr className='support_main_file_div'>
                                                            <th>S.No.</th>
                                                            <th>Position</th>
                                                            <th>Win Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {winPriceTableData &&
                                                            winPriceTableData?.length > 0 &&
                                                            winPriceTableData?.map((win, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{++i}</td>
                                                                        <td>
                                                                            {win?.position === 1
                                                                                ? '🥇'
                                                                                : '🥈'}
                                                                        </td>
                                                                        <td>{win?.amount}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        }


                        {/* Change Status Modal */}
                        <Modal title="Change Contest Status" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
                            {
                                <select className='select-modal-button-ludo-contest' onChange={(e) => setLudoStatusUpdate(e.target.value)}>
                                    <option value={
                                        ludoStatus ===
                                            'active' ? (
                                                'active'
                                            ) : (
                                                'inactive'
                                            )
                                    }>{
                                            ludoStatus ===
                                                'active' ? (
                                                    <h3 className='active-ludo-status'>
                                                    Active
                                                    </h3>
                                                ) : (
                                                    <h3 className='inactive-ludo-status'>
                                                    Inactive
                                                    </h3>
                                                )
                                        }</option>
                                    <option value={
                                        ludoStatus ===
                                            'active' ? (
                                                'inactive'
                                            ) : (
                                                'active'
                                            )
                                    }>{
                                            ludoStatus ===
                                                'active' ? (
                                                    <h3 className='active-ludo-status'>
                                                    Inactive
                                                    </h3>
                                                ) : (
                                                    <h3 className='inactive-ludo-status'>
                                                    Active
                                                    </h3>
                                                )
                                        }</option >
                                    <option value='deleted'>Delete</option>
                                </select>
                            }
                        </Modal>


                    </div>
                </div>
            </div>
        </div>
    );
}
