import React, { useEffect, useState } from 'react';
import { authApi } from './api/authApi';
import Notiflix from 'notiflix';

export default function CreateUser() {
    const [password,setPassword] = useState('');
    const [user_name,setUsername] = useState('');
    const [roles,setRoles] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const submitHandler = (e)=>{
        e.preventDefault();
        const json ={
            roles,
            user_name,
            password
        };
        authApi.DashSignUp(json).then((res)=>{
            if(res.status_code){
                Notiflix.Notify.success(res.message);
                setPassword('');
                setRoles('');
                setUsername('');

            }

            else {
                Notiflix.Notify.failure(res.message);
            }
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Create User</h4>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>
                                                <form onSubmit={submitHandler}>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                                        <input required onChange={(e)=>setUsername(e.target.value)} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                                                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Password</label>
                                                        <input required onChange={(e)=>setPassword(e.target.value)} type="text" className="form-control" id="exampleInputPassword1" placeholder="Password"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Set Role</label>
                                                        <input required onChange={(e)=>setRoles(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                                                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                    </div>
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </form>
                                                    
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
