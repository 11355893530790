import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { SeriesAPI } from '../api/seriesApi';
import { CSVLink } from 'react-csv';
import { AccountManagerAPI } from '../api/AccountManagerAPI';

function WithdrawWithoutKYC() {
    const [dataArray,setArray]=useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        getData();
    }, []);

    const getData = ()=>{
        AccountManagerAPI.ReportWithdrawWithoutKYC().then((data)=>{
            setArray(data);
        });
    };
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                 

                {/* <div className="row"> */}
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                            

                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>Withdraw Without KYC</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Pan No</th>
                                                <th>Bank Account No</th>
                                                
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataArray &&
                                                        dataArray.length > 0 &&
                                                        dataArray.map((data, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>{++i}</td>
                                                                    <td>
                                                                        {data.user_id}
                                                                    </td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.email?'true':'false'}</td>
                                                                    <td>{data.phone ?'true':'false'}</td>
                                                                    <td>{data.pan_no ?'true':'false'}</td>
                                                                    <td>{data.bank_account_number ?'true':'false'}</td>
                                                                  
                                                                    
                                                                      
                                                                </tr>
                                                            );
                                                        })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div
          className="modal fade"
          id="exampleModalScrollable"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalScrollableTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalScrollableTitle">
                  Edit Series
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="name">Series Id :</label>
                    <input
                      type="text"
                      className="form-control"
                      value={seriesId}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Series Name :</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editSeries}
                      onChange={EditSeriesChange}
                      placeholder="Chennai Super King"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">From :</label>
                    <input
                      type="date"
                      className="form-control"
                      disabled
                      value={editSDate}
                      onChange={EditSDateChange}
                      id=""
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">To :</label>
                    <input
                      type="date"
                      className="form-control"
                      disabled
                      value={editEDate}
                      onChange={EditEDateChange}
                      id=""
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={EditSubmit}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div> */}
            </div>
        </div>
        // </div>
        // </div>
    );
}

export default WithdrawWithoutKYC;
