import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CouponAPI } from '../api/viewAllCoupon';

export default function EditOffersPage() {
    //title,min_amount,max_amount,offer_code,bonus_type,type,bonus,start_date,expire_date,max_used
    const location = useLocation();
    const [_id, set_id] = useState(location.state._id);
    const [title, setTitle] = useState(location.state.title);
    const [min_amount, setMinAmount] = useState(location.state.min_amount);
    const [max_amount, setMaxAmount] = useState(location.state.max_amount);
    const [offer_code, setOfferCode] = useState(location.state.offer_code);
    const [bonus_type, setBonusType] = useState(location.state.bonus_type);
    const [type, setType] = useState(location.state.type);
    const [bonus, setBonus] = useState(location.state.bonus);
    const [start_date, setStartDate] = useState(location.state.start_date);
    const [expire_date, setExpireDate] = useState(location.state.expire_date);
    const [max_used, setMaxUsed] = useState(location.state.max_used);
    const [tmp, setTmp] = useState(0);
    console.log(location.bonus_type);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const titleChange = (e) => {
        setTmp(1);
        setTitle(e.target.value);
    };
    const minAmountChange = (e) => {
        setMinAmount(e.target.value);
    };
    const maxAMountChange = (e) => {
        setMaxAmount(e.target.value);
    };
    const offerCodeChange = (e) => {
        setOfferCode(e.target.value);
    };
    const bonusChange = (e) => {
        setBonus(e.target.value);
    };
    const sDateChange = (e) => {
        setStartDate(e.target.value);
    };
    const eDateChange = (e) => {
        setExpireDate(e.target.value);
    };
    const maxUsedChange = (e) => {
        setMaxUsed(e.target.value);
    };
    const bonusTypeChange = (e) => {
        //alert(e.target.value);
        setBonusType(e.target.value);
    };
    const typeChange = (e) => {
        setType(e.target.value);
    };
    const Datasubmit = (e) => {
        e.preventDefault();
        {
            let editData = {
                _id: _id,
                title: title,
                min_amount: min_amount,
                max_amount: max_amount,
                offer_code: offer_code,
                bonus_type: bonus_type,
                type: type,
                bonus: bonus,
                start_date: start_date,
                expire_date: expire_date,
                max_used: max_used,
                is_deleted: false,
            };
            Notiflix.Notify.success(JSON.stringify(editData));
            CouponAPI.EditCoupon(editData).then((data) => {
                // console.log(data)
            });
        }
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'></div>
                                        <div className='col'></div>

                                        <div className='col'>
                                            <Link
                                                to={'/addoffers'}
                                                className='button btn btn-skyblue button-icon'
                                            >
                                                <i className='ml-2 ri-add-line'></i>Add offers
                                            </Link>
                                            <Link
                                                to={'/getoffers'}
                                                href='getoffers'
                                                className='btn btn-skyblue float-right'
                                            >
                                                <i className='ml-2 ri-eye-2-line'></i>View All Offer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Edit Offer</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Title :</label>
                                            <input
                                                value={title}
                                                onChange={titleChange}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Title'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Minimum Amount :</label>
                                            <input
                                                value={min_amount}
                                                onChange={minAmountChange}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter minimum amount'
                                                defaultValue='500'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Max Amount :</label>
                                            <input
                                                value={max_amount}
                                                onChange={maxAMountChange}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter max amount'
                                                defaultValue='super4'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Offer Code :</label>
                                            <input
                                                value={offer_code}
                                                onChange={offerCodeChange}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='101OFFER101'
                                                defaultValue='super4'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Bonus Type :</label>
                                            <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                <input
                                                    value='rupees'
                                                    onChange={bonusTypeChange}
                                                    type='radio'
                                                    id='customRadio6'
                                                    name='customRadio-1'
                                                    className='custom-control-input'
                                                    defaultChecked
                                                />
                                                &nbsp;
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='customRadio6'
                                                >
                                                    {' '}
                                                    Rupees{' '}
                                                </label>
                                            </div>
                                            <div className='custom-control custom-radio custom-control-inline'>
                                                <input
                                                    onChange={bonusTypeChange}
                                                    value='percentage'
                                                    type='radio'
                                                    id='customRadio7'
                                                    name='customRadio-1'
                                                    className='custom-control-input'
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='customRadio7'
                                                >
                                                    {' '}
                                                    Percentage{' '}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Type :</label>
                                            <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                <input
                                                    value={type}
                                                    onChange={typeChange}
                                                    type='radio'
                                                    id='customRadio8'
                                                    name='customRadio-2'
                                                    className='custom-control-input'
                                                    defaultChecked
                                                />
                                                &nbsp;
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='customRadio8'
                                                >
                                                    {' '}
                                                    Bonus{' '}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Bonus :</label>
                                            <input
                                                value={bonus}
                                                onChange={bonusChange}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Bonus'
                                                defaultValue='100'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='exampleInputdate1'>Start Date :</label>
                                            <input
                                                value={start_date}
                                                onChange={sDateChange}
                                                type='date'
                                                className='form-control'
                                                id='exampleInputdate1'
                                                defaultValue='2019-12-18'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='exampleInputdate'>Expire Date :</label>
                                            <input
                                                value={expire_date}
                                                onChange={eDateChange}
                                                type='date'
                                                className='form-control'
                                                id='exampleInputdate'
                                                defaultValue='2019-12-18'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Max Used :</label>
                                            <input
                                                value={max_used}
                                                onChange={maxUsedChange}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter max used time'
                                                defaultValue='2'
                                            />
                                        </div>
                                        <button
                                            onClick={Datasubmit}
                                            type='submit'
                                            className='btn btn-success'
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
