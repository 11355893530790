import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Input } from 'antd';
import { BannerAPI } from '../api/bannerAPI';
import Switch from '@mui/material/Switch';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import { SendToMobile } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { AiOutlineCheckCircle } from 'react-icons/ai';


const label = { inputProps: { 'aria-label': 'Switch demo' } };

function KycReportPage() {
    const [dataArray,setDataArray] =useState([]);
    const [status,setStatus] =useState('1');
    const [email,setEmail] =useState(false);
    const [phone,setPhone] =useState(false);
    const [pan,setPan] =useState(false);
    const [bank,setBank] =useState(false);
    const [aadhar,setAadhar] =useState(false);
    const [thisFilteredData, setThisFilteredData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    // const [totalLength, setTotalLength] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalEntires, setTotalEntries] = useState(0);
    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Name', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Mobile', key: 'phone' },
        { label: 'Pan Number', key: 'pan_number' },
        { label: 'Pan Name', key: 'pan_name' },
        { label: 'Bank Account', key: 'account_number' },
        { label: 'Aadhar', key: 'aadhaar_number' },
    ];
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
         
    }, []);

    useEffect(() => {
        Search();
        console.log(pageNumber);
    }, [pageNumber]);

    const statusChange =(e)=>{
        setStatus(e.target.value);
    };

    const Search =()=>{
        setDataArray([]);
        setThisFilteredData([]);
        // setIsLoading(true);
        if(!email && !phone && !pan && !bank && !aadhar){
            Notiflix.Notify.failure('Please Select Atleast 1 Field');
            return;
        }
        AccountManagerAPI.ReportOfKyc(status,email,phone,pan,bank,aadhar,10,pageNumber).then((res)=>{
            if(res.status_code){
                setTotalPages(res.total_page);
                setTotalEntries(res.total_length);
                setDataArray(res.data);
            }
        });
        // setIsLoading(false);

    };

    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const Reset=()=>{
        setStatus('1');
        setEmail(false);
        setPhone(false);
        setPan(false);
        setBank(false);
        setAadhar(false);
        setDataArray([]);
        setPageNumber(1);
        setTotalPages(0);
        setTotalEntries(0);
        
        

    };

    //  Fetch This ALL Filtered Data
    const handleThisFilteredData = () => {
        // setIsLoading(true);
        AccountManagerAPI.ReportOfKyc(
            status,email,phone,pan,bank,aadhar,totalEntires
        ).then((data) => {
            if (data.status_code) {
                console.log(data);
                setThisFilteredData(data.data);
            } else {
                Notiflix.Notify.warning(data.msg);
            }
            // setIsLoading(false);
        });
    };

    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>KYC Reports</h4>
                            </div>
                        </div>
                        
                        <>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-row'>
                                                <div className='col'>
                                                    <label htmlFor='name'>Verified Status</label>
                                                    <select
                                                        className='form-control mb-3'
                                                        onChange={statusChange}
                                                    >
                                                        <option selected value='1'>Verified</option>
                                                        <option value='2'>Non-Verified</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <div className='col text-center ml-2 mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>Email</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={email}
                                                            onChange={(e)=>setEmail(e.target.checked)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='col text-center mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>Mobile</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={phone}
                                                            onChange={(e)=>setPhone(e.target.checked)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='col text-center mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>Pan Card</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={pan}
                                                            onChange={(e)=>setPan(e.target.checked)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='col text-center mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>Bank</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={bank}
                                                            onChange={(e)=>setBank(e.target.checked)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>


                                                <div>
                                                    <div className='col text-center mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>Aadhar</label>
                                                        <input
                                                            type='checkbox'
                                                            checked={aadhar}
                                                            onChange={(e)=>setAadhar(e.target.checked)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
     
                                                <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        className='btn btn-primary'
                                                        type='button'
                                                        onClick={() => Search()}
                                                        disabled={isLoading ? true : false}
                                                    >
                                                Search
                                                    </button>
                                            &nbsp;
                                                    {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                    <input
                                                        type='reset'
                                                        onClick={() => Reset()}
                                                        className='btn btn-danger'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className='row'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    <div className='navbar-breadcrumb'>
                                        <h4 className='mb-0'>&nbsp;</h4>
                                    </div>

                                    {/* Export All Data */}
                                    <div className='div-series'>
                                     

                                        {/* Export All Filtered Data */}

                                        {thisFilteredData.length > 0 ? (
                                            <CSVLink
                                                filename='Filtered Kyc'
                                                data={thisFilteredData}
                                                headers={headers}
                                            >
                                                <button className='button-series' href='#'>
                                                Download This Filtered Data <AiOutlineCheckCircle />
                                                </button>
                                            </CSVLink>
                                        ) : (
                                            ''
                                        )}

                                        {/* {
                                    thisData.length > 0 || thisFilteredData.length > 0 ?
                                     (
                                        <button
                                            className='button-series'
                                            onClick={() => window.location.reload(true)}
                                        >
                                            <BsArrowClockwise />
                                        </button>
                                    ) : (
                                        ''
                                    )} */}
                                    </div>

                                    {/* Export Buttons */}
                                    <div className=''>
                                        <div className='btn-group m-2' role='group'>
                                            <button
                                                id='btnGroupDrop1'
                                                type='button'
                                                className='button-series-2'
                                                data-toggle='dropdown'
                                                aria-haspopup='true'
                                                aria-expanded='false'
                                            >
                                            Fetch - <BsFillArrowDownCircleFill />
                                            </button>
                                            <div
                                                className='dropdown-menu'
                                                aria-labelledby='btnGroupDrop1'
                                                style={{}}
                                            >
                                            
                                                <button
                                                    className='dropdown-item'
                                                    href='#'
                                                    onClick={handleThisFilteredData}
                                                >
                                                Fetch This Filtered Data
                                                </button>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table className='table  table-striped table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>User Id</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Pan Number</th>
                                                <th>Pan Name</th>
                                                <th>Bank Account </th>
                                                <th>Aadhar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataArray &&
                                                dataArray.length > 0 &&
                                                dataArray.map((name, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{++ind}</td>
                                                            <td>
                                                                <button className='users_link'>
                                                                    <Link
                                                                        className='users_linkk'
                                                                        to={
                                                                            '/get_user_details'
                                                                        }
                                                                        state={{
                                                                            userId: name.user_id,
                                                                        }}
                                                                        type='submit'
                                                                        // className='py-4'
                                                                    >
                                                                        {name.user_id}
                                                                    </Link>
                                                                </button>
                                                            </td>
                                                            <td>{name.name}</td>
                                                            <td>{name.email}</td>
                                                            <td>{name.phone}</td>
                                                            <td>{name.pan_number}</td>
                                                            <td>{name.pan_name}</td>
                                                            <td>{name.account_number}</td>
                                                            <td>{name.aadhaar_number}</td>
                                                            
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                            
                        </div>
                    </div>
                </div>  
                <div className='col-12 d-flex align-items-center'>
                    <label className='p-2' htmlFor='name'>
                                        Show
                    </label>
                    <select
                        // onChange={changePageSize}
                        className='form-control m-2 mb-3 col-2 d-flex align-items-center'
                    >
                        <option defaultValue={50}>50</option>
                    </select>
                    <nav aria-label='Page navigation example '>
                        <ul className='pagination'>
                            <li className='page-item' 
                                onClick={handlePrevious}
                            >
                                <a
                                    className='page-link'
                                    // href="#"
                                    aria-label='Previous'
                                >
                                    <span aria-hidden='true'>&laquo;</span>
                                    <span className='sr-only'>Previous</span>
                                </a>
                            </li>
                            <li
                                className='page-item active'
                                // onClick={() => {
                                //     HandlePaginate(item, i);
                                // }}
                            >
                                <button className='page-link'>{pageNumber}</button>
                            </li>

                            <li
                                className='page-item'
                                onClick={() => {
                                    handleNext();
                                }}
                            >
                                <a className='page-link' aria-label='Next'>
                                    <span aria-hidden='true'>&raquo;</span>
                                    <span className='sr-only'>Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className='col-6 d-flex align-items-center'>
                        <h5 className='d-flex p-2 mb-3 transaction_pagi_div'>out of <span className='transaction_pagi_box'>{totalPages}</span> Pages</h5>

                        <h5 className='d-flex p-2 mb-3 transaction_pagi_div'>
                                            and <span className='transaction_pagi_box'>{totalEntires}</span> users
                        </h5>
                    </div>

                    {/* <ScrollTop name={'Top'} /> */}
                </div>
            </div>
        </div>
    );
}

export default KycReportPage;
