import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QuizAPI } from '../api/quizApi';
import { imageURL } from '../api/apiBaseUrl';
import { CSVLink } from 'react-csv';

export default function ViewQuizContestCategoryPage() {
    const [quizList, setQuizList] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        GetQuiz();
    }, []);

    const headers = [
        { label: 'Category Name', key: 'QuizCatName' },
        { label: 'Sub Title', key: 'QuizSubTitle' },
        { label: 'Image', key: 'Image' },
        { label: 'Quiz Category Id', key: 'QuizCatId' },
    ];

    const GetQuiz = () => {
        QuizAPI.GetQuizCategory().then((data) => {
            if (data.Status) setQuizList(data.Data);
            console.log(data.Data);
        });
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'>
                                        {/* <h3 className="card-title mt-2">View All Quiz Category</h3> */}
                                    </div>

                                    <div className='col'>
                                        <Link
                                            to={'/creat_quiz_category'}
                                            className='mt-2 btn btn-primary float-right'
                                        >
                                            {' '}
                                            <i className='ri-add-line'></i> Add New Quiz Category
                                        </Link>
                                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                                            <div className='navbar-breadcrumb'>
                                                <h4 className='mb-0'>&nbsp;</h4>
                                            </div>
                                            <div className=''>
                                                <div className='btn-group m-2' role='group'>
                                                    <button
                                                        id='btnGroupDrop1'
                                                        type='button'
                                                        className='btn btn-primary dropdown-toggle'
                                                        data-toggle='dropdown'
                                                        aria-haspopup='true'
                                                        aria-expanded='false'
                                                    >
                                                        Export
                                                    </button>
                                                    <div
                                                        className='dropdown-menu'
                                                        aria-labelledby='btnGroupDrop1'
                                                        style={{}}
                                                    >
                                                        <button className='dropdown-item' href='#'>
                                                            Export All data
                                                        </button>
                                                        <CSVLink
                                                            filename='View Quiz Contest Category'
                                                            data={quizList}
                                                            headers={headers}
                                                        >
                                                            <button
                                                                className='dropdown-item'
                                                                href='#'
                                                            >
                                                                Export This Data
                                                            </button>
                                                        </CSVLink>
                                                        {/* <a className="dropdown-item" href="#">Delete</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>View All Quiz Category</h4>
                                </div>
                            </div>

                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Category Name</th>
                                                <th>Sub Title</th>
                                                <th>Image</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quizList.map((quiz, ind) => (
                                                <tr>
                                                    <td>{++ind}</td>
                                                    <td>{quiz.QuizCatName}</td>
                                                    <td>{quiz.QuizSubTitle}</td>
                                                    <td>
                                                        <img
                                                            src={imageURL + quiz.Image}
                                                            style={{ width: '80px' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue btn-sm'
                                                            data-toggle='modal'
                                                            data-target='#exampleModalScrollable'
                                                        >
                                                            <i className='ri-edit-line'></i>Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        {/* <tfoot>
                        <tr>
                            <th>S.No.</th>
                            <th>Category Name</th>
                            <th>Sub Title</th>
                            <th>Image</th>
                            <th>Action</th>
                        </tr>
                    </tfoot> */}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='modal fade'
                        id='exampleModalScrollable'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='exampleModalScrollableTitle'
                        aria-hidden='true'
                    >
                        <div className='modal-dialog modal-dialog-scrollable' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                        Edit Category
                                    </h5>
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='exampleInputText1'>
                                                Category Name{' '}
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='exampleInputText1'
                                                defaultValue='Test | Super4'
                                                placeholder='Enter Category Name'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='exampleInputText1'>Image </label>
                                            <div className='custom-file'>
                                                <input
                                                    type='file'
                                                    className='custom-file-input'
                                                    id='customFile'
                                                />
                                                <label
                                                    className='custom-file-label'
                                                    htmlFor='customFile'
                                                >
                                                    Choose file
                                                </label>
                                            </div>
                                        </div>

                                        {/* @*<button type="submit" className="btn btn-primary">Submit</button>*@ */}
                                    </form>
                                </div>
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        data-dismiss='modal'
                                    >
                                        Close
                                    </button>
                                    <button type='button' className='btn btn-primary'>
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
