import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function TopRoute({ name }) {

    const location = useLocation();

    return (
        <div>
            <div className='main-ref-ral-div'>
                <h1 className='coupon_h1-h1'>{name}</h1>
                <div className=''>
                    <NavLink
                        to={'/ludo-get'}
                        className={location.pathname === '/ludo-get' ? 'button-report-active' : 'button-report-referral'}
                    >
                        All  Contests
                    </NavLink>
                    <NavLink
                        to={'/ludo-create-contest'}
                        className={location.pathname === '/ludo-create-contest' ? 'button-report-active' : 'button-report-referral'}
                    >
                        Create Ludo Contest
                    </NavLink>
                    <NavLink
                        to={'/rummy-create-contest'}
                        className={location.pathname === '/rummy-create-contest' ? 'button-report-active' : 'button-report-referral'}
                    >
                        Create Rummy Contest
                    </NavLink>
                    <NavLink
                        to={'/crash-create-contest'}
                        className={location.pathname === '/crash-create-contest' ? 'button-report-active' : 'button-report-referral'} >
                        Create Crash Contest
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default TopRoute;
