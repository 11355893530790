import { BASEAPIS } from './BaseApis';
// import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const ReportAPI = {

    ReferReport: (status, startDate, endDate) => {
        return BASEAPIS.GETAPI('DirectAndInDirectUseageOfReferCode?status=' + status + '&start=' + startDate + '&end=' + endDate);
    },

    ReferReportDownload: (status, startDate, endDate) => {
        return BASEAPIS.GETAPI('directAndInDirectUseageOfReferCodeDownload?status=' + status + '&start=' + startDate + '&end=' + endDate);
    },

    ReferReportUsageStats: () => {
        return BASEAPIS.GETAPI('getRefCodeUsageStats');
    },

    ReferSecondReport: (refUserId, refUserTeamName, refUserPhone, refUserEmail, refUserRef) => {
        return BASEAPIS.GETAPI('referralDetailsOfSpecificUser?user_id=' + refUserId + '&team_name=' + refUserTeamName + '&email=' + refUserEmail + '&phone=' + refUserPhone + '&refCode=' + refUserRef);
    },

    GSTReportData: (startDate, endDate) => {
        return BASEAPIS.GETAPI('matchDataForGST?startDate=' + startDate + '&endDate=' + endDate);
    },

    GSTDATAAPI: (start, end) => {
        return BASEAPIS.GETAPI('gstData?start=' + start + '&end=' + end);
    },

    ModificationReport: (phoneNumber, emailId, teamName, userId) => {
        return BASEAPIS.GETAPI('getUserModificationDetail?phone=' + phoneNumber + '&user_id=' + userId + '&email=' + emailId + '&team_name=' + teamName);
    },

    BonusExpiry: (jsonData) => {
        return BASEAPIS.POSTAPI(jsonData, 'bonusExpiryReport');
    },

    
};
