import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

function ExcelDownload({ data, filename, headers }) {

    const [size, setSize] = useState('large');

    return (
        <div>
            {data.length > 0 ? (
                <CSVLink data={data} headers={headers} filename={filename || 'data.csv'}>
                    <Button type="primary" icon={<DownloadOutlined />} size={size}>
                        {filename}
                    </Button>
                </CSVLink>
            ) : (
                <p>No data available for download</p>
            )}
        </div>
    );
}

export default ExcelDownload;
