import { Link } from 'react-router-dom';

export default function EarningPage() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'></div>

                                        <div className='col'>
                                            <Link
                                                to={'/total_balance'}
                                                className='mt-2 btn btn-skyblue float-right'
                                            >
                                                {' '}
                                                <i className='ri-eye-2-line'></i> View Total Balance
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Total Join</th>
                                                    <th>Total Cancel Amount</th>
                                                    <th>Total Winning</th>
                                                    <th>Total Earned</th>
                                                    <th>Total Loss</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1.</td>
                                                    <td>Rs 55</td>
                                                    <td>Rs 10</td>
                                                    <td>Rs 50,000 </td>
                                                    <td>7000</td>
                                                    <td>2000</td>
                                                </tr>
                                                <tr>
                                                    <td>2.</td>
                                                    <td>Rs 55</td>
                                                    <td>Rs 10</td>
                                                    <td>Rs 50,000 </td>
                                                    <td>7000</td>
                                                    <td>2000</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Total Join</th>
                                                    <th>Total Cancel Amount</th>
                                                    <th>Total Winning</th>
                                                    <th>Total Earned</th>
                                                    <th>Total Loss</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
