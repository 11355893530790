import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const BonusAPI = {
    GetBonus: async (bonus, amount) => {
        //alert()
        return await fetch(baseURL + 'fetchBonusData?bonus=' + bonus + '&amount=' + amount, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    DeleteResponse: async (id) => {
        //alert()
        const data = {
            _id: id,
            is_deleted: true,
        };
        return await fetch(baseURL + 'editBonus', {
            method: 'POST',

            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)

                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    EditResponse: async (jsonbody) => {
        //alert()
        console.log(jsonbody);

        return await fetch(baseURL + 'editBonus', {
            method: 'POST',

            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)

                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    //addReferBonus
    AddBonus: async (jsonbody) => {
        //alert()
        console.log(jsonbody);

        return await fetch(baseURL + 'addReferBonus', {
            method: 'POST',

            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)

                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
