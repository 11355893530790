import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import { CSVLink } from 'react-csv';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RotatingLines } from 'react-loader-spinner';

export default function ActiveInactiveUserReport() {
    const [statusSeries, setStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dataArray, setArray] = useState([]);
    const [alldataArray, allsetArray] = useState([]);
    const [isDownload, setIsDownload] = useState(false);
    const [isDownloadClicked, setIsDownloadClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [userCount, setUserCount] = useState('');

    const SdateChange = (e) => {
        setStartDate(e.target.value);
    };
    const EdateChange = (e) => {
        setEndDate(e.target.value);
    };

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Email', key: 'email' },
        { label: 'Mobile', key: 'phone' },
    ];

    const Search = () => {
        setIsDownloadClicked(false);
        setIsDownload(false);
        let active;
        if (!statusSeries || !startDate || !endDate) {
            return Notiflix.Notify.failure('Please Select User Status');
        } else {
            statusSeries == 'active' ? (active = true) : (active = false);
            AccountManagerAPI.ActiveInactiveUsersReport(startDate, endDate, active).then((data) => {
                if (data.status_code) {
                    setUserCount(data.Active_user);
                    if (data.Active_user > 0) {
                        setIsClicked(true);
                        setIsDownload(true);
                    }

                } else {
                    Notiflix.Notify.failure(data.message);
                }
            });
        }
    };
    const AllDataDownload = () => {
        setIsLoading(true);
        let active;

        if (!statusSeries || !startDate || !endDate) {
            setIsLoading(false);
            return Notiflix.Notify.failure('error');
        } else {
            statusSeries == 'active' ? (active = true) : (active = false);
            AccountManagerAPI.ActiveInactiveUsersReportDownload(startDate, endDate, active).then(
                (data) => {
                    if (data.status_code) {
                        allsetArray(data.Active_user);
                        setIsDownloadClicked(true);
                        setIsDownload(false);
                        setIsLoading(false);
                        Notiflix.Notify.success(data.message);
                    } else {
                        Notiflix.Notify.failure(data.message);
                    }
                }
            );
        }
    };

    const Reset = () => {
        setIsDownloadClicked(false);
        setIsDownload(false);
        setStartDate('');
        setEndDate('');
        setArray([]);
        allsetArray([]);
        setStatus('');
        setIsClicked(false);
        setUserCount(false);

    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Active / Inactive</label>
                                            <select
                                                value={statusSeries}
                                                className='form-control mb-3'
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option selected value=''>
                                                    Select
                                                </option>
                                                <option value='active'>Active</option>
                                                <option value='inactive'>Inactive</option>
                                            </select>
                                        </div>

                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>From :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={startDate}
                                                    onChange={SdateChange}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>To :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={endDate}
                                                    onChange={EdateChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                className='btn btn-primary'
                                                type='button'
                                                onClick={() => Search()}
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                            <input
                                                type='reset'
                                                onClick={() => Reset()}
                                                className='btn btn-danger'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isDownload && !isLoading && (
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <button
                                    onClick={AllDataDownload}
                                    id='btnGroupDrop11'
                                    type='button'
                                    className='btn btn-success '
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Click to Download Data
                                </button>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                            </div>
                        </div>
                    )}

                    {isLoading && (
                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                            <div>
                                <RotatingLines
                                    marginTop='70'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}

                    {isDownloadClicked && (
                        <CSVLink
                            filename='Active/Inactive Users'
                            data={alldataArray}
                            headers={headers}
                        >
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <button
                                    // onClick={AllDataDownload}
                                    id='btnGroupDrop11'
                                    type='button'
                                    className='btn btn-success '
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Download Now
                                </button>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                            </div>
                        </CSVLink>
                    )}
                </>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Active Inactive users</h4>
                                </div>
                            </div>
                            {isClicked && <div className='col-md-3 '>
                                <div className='card card-block card-stretch card-height rtl-mode dashboard_card'>
                                    <div className='card-body'>
                                        <div className='top-block d-flex align-items-center justify-content-between mb-3'>
                                            <h3 className='wallet-text'>{userCount > 0 ? userCount : 0}</h3>
                                            <div className='bg-danger icon iq-icon-box-2 mr-2 rounded rtl-ml-2  rtl-mr-0'></div>
                                        </div>
                                        <h4>Users</h4>
                                    </div>
                                </div>
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
        // </div>
        // </div>
    );
}
