import './ViewAllUsers.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SupportAPI } from '../api/Support';
import Notiflix from 'notiflix';
import moment from 'moment';
import { Tooltip, Button, Modal, Image, Dropdown, Drawer, Spin, Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BsFillCaretUpFill, BsFillLightningChargeFill, BsFillCaretDownFill, BsFillPauseFill, BsMailbox2 } from 'react-icons/bs';
import { MdOutlineEdit } from 'react-icons/md';
import { FaRegEye } from 'react-icons/fa';
import ChatScreen from './ChatScreen';
import { AllUsersAPI } from '../api/allUsers';

export default function ViewlAllTicketList() {

    const supportCaseId = localStorage.getItem('name');

    const [ticketData, setTicketData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ticketStatus, setTicketStatus] = useState('');
    const [updateCaseId, setUpdateCaseId] = useState('');
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [finalTicketStatus, setFinalTicketStatus] = useState('');
    const [finalTicketStatusComment, setStatusComment] = useState('');
    const [emailStatusCaseNumber, setEmailStatusCaseNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    useEffect(() => {
        getAllCaseList();
    }, [isModalOpen2]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 64,
            }}
            spin
        />
    );

    const getAllCaseList = () => {
        setIsLoading(false);
        SupportAPI.getAllCases(
            // pageSize, pageNumber
        ).then((data) => {
            if (data.status_code) {
                console.log(data.data);
                console.log(data);
                setTicketData(data.data);
                // setTotalPages(data.totalPages);
                // setTotalLength(data.totalEntries);
                setCaseSource('');
                setEmailStatus('');
                setFilEmailID('');
                setFilPhoneNumber('');
                setFilCaseID('');
                setFilStatus('');
                setFilCaseType('');
                setFilPriority('');
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure('Something Went Wrong');
                setIsLoading(false);
            }
        });
    };

    const [filPriority, setFilPriority] = useState('');
    const [filCaseType, setFilCaseType] = useState('');
    const [filStatus, setFilStatus] = useState('');
    const [filCreatedBy, setFilCreatedBy] = useState('');
    const [filCaseID, setFilCaseID] = useState('');
    const [filPhoneNumber, setFilPhoneNumber] = useState('');
    const [filEmailID, setFilEmailID] = useState('');
    const [filEmailStatus, setEmailStatus] = useState('');
    const [filCaseSource, setCaseSource] = useState('');
    const [noSuchFilter, setNoSuchFilter] = useState(false);


    const Filter = () => {
        SupportAPI.filterAllCase(filEmailStatus, filCaseSource, filPriority, filCaseType, filStatus, filCreatedBy, filCaseID, filPhoneNumber, filEmailID).then((data) => {
            if (data.status_code) {
                console.log(data.data);
                setTicketData(data.data);
            } else {
                Notiflix.Notify.failure('Something Went Wrong');
                setNoSuchFilter(true);
            }
        });
    };

    const handleEmailRead = () => {
        const jsonData = {
            read: true,
            case_number: emailStatusCaseNumber,
        };
        if (emailStatusCaseNumber) {
            SupportAPI.updateEmailStatus(jsonData).then((data) => {
                if (data.status_code === true) {
                    console.log(data.message);
                    // setEmailStatusCaseNumber('');
                } else {
                    console.log(data.message);
                }
            });
        } else {
            '';
        }
    };

    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        return newDate;
    };

    const handlePriority = (e) => {
        if (e === 'Urgent') {
            return <div className='urgent-pipe-div'><p className='cases-urgent'>
                <BsFillLightningChargeFill /> Urgent</p></div>;
        } else if (e === 'High') {
            return <div className='urgent-pipe-div'><p className='cases-high'><BsFillCaretUpFill /> High</p></div>;
        } else if (e === 'Medium') {
            return <div className='urgent-pipe-div'><p className='cases-med'><BsFillPauseFill /> Med</p></div>;
        } else {
            return <div className='urgent-pipe-div'><p className='cases-low'><BsFillCaretDownFill /> Low</p></div>;
        }
    };

    const handleCaseType = (e) => {
        if (e === 'Deposit') {
            return <p >Deposit</p>;
        } else if (e === 'Withdraw') {
            return <p >Withdrawl</p>;
        } else if (e === 'Joining') {
            return <p>Joining</p>;
        } else if (e === 'Payment') {
            return <p>Payment</p>;
        } else if (e === 'Verification') {
            return <p>Verification</p>;
        } else if (e === 'Others') {
            return <p>Others</p>;
        }
        else {
            <h4 >Nothing</h4>;
        }
    };

    const handleCaseTypeDrawer = (e) => {
        if (e === 1) {
            return <span>Deposit</span>;
        } else if (e === 2) {
            return <span>Withdrawl</span>;
        } else if (e === 3) {
            return <span>Others</span>;
        } else {
            <span>Nothing</span>;
        }
    };


    const handleTicketStatus = (e) => {
        if (e === 'Open') {
            return <p className='allcases_status_open'>Open</p>;
        } else if (e === 'In Progress') {
            return <p className='allcases_status'>In Progress</p>;
        }
        else if (e === 'Re Open') {
            return <p className='allcases_status'>Re Open</p>;
        }
        else if (e === 'Reopened') {
            return <p className='allcases_status'>Re Open</p>;
        } else if (e === 'Closed') {
            return <p className='allcases_status_close'>Closed</p>;
        } else {
            'Others';
        }
    };

    const [modalCommentBody, setModalCommentBody] = useState([]);
    const [modalCommentSubject, setModalCommentSubject] = useState([]);
    const [modalCommentAttach, setModalCommentAttach] = useState([]);
    const [modalCommentCreatedBy, setModalCommentCreatedBy] = useState([]);
    const [modalCommentDate, setModalCommentDate] = useState([]);
    const [image, setImage] = useState([]);

    const EditUserDetail = (TUC, case_numb) => {
        const arr1 = [];
        const arr2 = [];
        const arr3 = [];
        const arr4 = [];
        const arr5 = [];
        setIsModalOpen(true);
        handleEmailRead();

        for (let i = 0; i < TUC.length; i++) {
            arr1.push(TUC[i].body);
            arr2.push(TUC[i].subject);
            arr3.push(TUC[i].attachments);
            arr4.push(TUC[i].created_by);
            arr5.push(TUC[i].updatedAt);
            setModalCommentBody(arr1);
            setModalCommentSubject(arr2);
            setModalCommentAttach(arr3);
            setModalCommentCreatedBy(arr4);
            setModalCommentDate(arr5);
            setImage([arr3]);
            setEmailStatusCaseNumber(case_numb);
        }
    };

    const handleCommentDate = (date) => {
        var dateNew = date?.slice(0, 10);
        return dateNew;
    };

    const handleCommentTime = (time) => {
        var newTime = moment(time).format('hh:mm:ss a');
        return newTime;
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk2 = () => {
        const jsonData = {
            case_number: updateCaseId,
            status: finalTicketStatus,
            // action: finalTicketStatusComment,
        };
        if (finalTicketStatus === '') {
            Notiflix.Notify.warning('No update has been made');
        } else {
            SupportAPI.upDateTicketStatusCases(jsonData).then((data) => {
                console.log(jsonData);
                if (data.status_code) {
                    Notiflix.Notify.success('Case Update Successfully');
                    setIsModalOpen2(false);
                    setFinalTicketStatus('');
                    setUpdateCaseId('');
                } else {
                    Notiflix.Notify.failure(data.message);
                }
            });
        }
    };

    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const [actionComment, setActionComment] = useState('');
    const [openModal4, setOpenModal4] = useState(false);

    const handleActionModal = (action) => {
        setActionComment(action);
        setOpenModal4(true);
    };

    const handleCloseModal4 = () => {
        setOpenModal4(false);
        setActionComment('');
    };

    const items = [
        {
            key: '1',
            value: 1,
            label: (
                <a>
                    Backend:
                </a>
            ),
        },
        {
            key: '2',
            value: 2,
            label: (
                <a>
                    Dashboard:
                </a>
            ),
        },
        {
            key: '3',
            value: 3,
            label: (
                <a >
                    App:
                </a>
            ),
        },
    ];

    const handleEmailClick = () => {

    };

    // Pagination
    const [totalEntires, setTotalEntries] = useState(1);
    const [totalPages, setTotalPages] = useState(50);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalLength, setTotalLength] = useState(1);

    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
        } else {
            return;
        }
    };

    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
        } else {
            return;
        }
    };

    // Pagination

    // Drawer

    const [open, setOpenDrawer] = useState(false);
    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const onClose = () => {
        setOpenDrawer(false);
        setEditCaseNumber('');
        setEditUserId('');
        setEditCaseType('');
        setEditCreatedBy('');
        setEditStatus('');
        setEditCaseSubject('');
    };

    // Edit Ticket Details
    const [editCaseNumber, setEditCaseNumber] = useState('Null');
    const [editUserId, setEditUserId] = useState('Null');
    const [editCaseType, setEditCaseType] = useState('Null');
    const [editStatus, setEditStatus] = useState('Null');
    const [editCreatedBy, setEditCreatedBy] = useState('Null');
    const [editCaseSubject, setEditCaseSubject] = useState([]);

    const [editCaseSubjectEdited, setEditCaseSubjectEdited] = useState('');
    const [editCaseOtherCaseSubjectEdited, setEditCaseOtherCaseSubjectEdited] = useState('');

    const handleDrawer = (case_number, user_id, case_type, status, created_by, subject) => {
        showDrawer();
        console.log(case_number, user_id, case_type, status, created_by, subject);
        setEditCaseNumber(case_number);
        setEditUserId(user_id);
        setEditCaseType(case_type);
        setEditCreatedBy(created_by);
        setEditStatus(status);
        setEditCaseSubject(subject);
    };

    const firstLetterUpper = (e) => {
        const letter = e?.charAt(0)?.toUpperCase() + e?.slice(1);
        return letter;
    };

    useEffect(() => {
        getAllCaseList();
    }, [updateCaseId]);

    const handleEditCases = () => {
        const jsonData = {
            case_number: editCaseNumber,
            Subject: editCaseSubjectEdited === '' ? editCaseSubject[0].subject : editCaseSubjectEdited,
            case_type: editCaseType,
            other_case_type: editCaseOtherCaseSubjectEdited,
            status: editCaseType,
            updated_by: supportCaseId,
        };
        if (editCaseSubjectEdited === '') {
            Notiflix.Notify.warning('No update has been made');
        } else {
            SupportAPI.editTicket(jsonData).then((data) => {
                console.log(jsonData);
                if (data.status_code === true) {
                    Notiflix.Notify.success('Case Update Successfully');
                    getAllCaseList();
                    setOpenDrawer(false);
                    // Set Everything to normal
                    setEditCaseNumber('');
                    setEditUserId('');
                    setEditCaseType('');
                    setEditCreatedBy('');
                    setEditStatus('');
                    setEditCaseSubject('');
                } else {
                    Notiflix.Notify.failure(data.message);
                }
            });
        }
    };

    const [chatScreenData, setChatScreenData] = useState([]);
    const [isModalOpenChat, setIsModalOpenChat] = useState(false);

    const handleOkChat = () => {
        setIsModalOpenChat(false);
    };
    const handleCancelChat = () => {
        setIsModalOpenChat(false);
    };

    const openModalChatScreen = (data) => {
        setChatScreenData(data);
        setIsModalOpenChat(true);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='offers_couponDiv'>
                            <h1 className='coupon_h1'>All Cases</h1>
                            {/* <div className='allcases-div'>
                                        <div >
                                            <BsMailbox2 className='bindu_green' /> : <h5>Seen</h5>
                                        </div>
                                        <div>
                                            <BsMailbox2 className='bindu_red' /> : <h5>UnSeen</h5>
                                        </div>
                                    </div> */}
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='col'>
                                                <label htmlFor='name'>Case ID</label>
                                                <input
                                                    type='text'
                                                    onChange={handleAllChange(setFilCaseID)}
                                                    className='offers_input'
                                                    placeholder='Search'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Phone Number</label>
                                                <input
                                                    type='text'
                                                    onChange={handleAllChange(setFilPhoneNumber)}
                                                    className='offers_input'
                                                    placeholder='Search'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Email ID</label>
                                                <input
                                                    onChange={handleAllChange(setFilEmailID)}
                                                    type='text'
                                                    className='offers_input'
                                                    placeholder='Search'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Source</label>
                                                <select
                                                    onChange={handleAllChange(setCaseSource)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='Email'>Email</option>
                                                    <option value='App'>App</option>
                                                    <option value='Phone'>Phone</option>
                                                </select>
                                            </div>

                                            {/* <div className='col'>
                                                <label htmlFor='name'>Email Status</label>
                                                <select
                                                    onChange={handleAllChange(setEmailStatus)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='1'>Seen</option>
                                                    <option value='2'>Not Seen</option>
                                                </select>
                                            </div> */}




                                            <div className='col'>
                                                <label htmlFor='name'>Priority</label>
                                                <select
                                                    onChange={handleAllChange(setFilPriority)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='Low'>Low</option>
                                                    <option value='Medium'>Medium</option>
                                                    <option value='High'>High</option>
                                                    <option value='Urgent'>Urgent</option>
                                                </select>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Case Type</label>
                                                <select
                                                    onChange={handleAllChange(setFilCaseType)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='Deposit'>Deposit</option>
                                                    <option value='Withdraw'>Withdrawl</option>
                                                    <option value='Joining'>Joining</option>
                                                    <option value='Payment'>Payment</option>
                                                    <option value='Verification'>Verification</option>
                                                    <option value='Others'>Others</option>
                                                </select>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Case Status</label>
                                                <select
                                                    onChange={handleAllChange(setFilStatus)}
                                                    className='offers_input'>
                                                    <option value=''>Select</option>
                                                    <option value='Open'>Open</option>
                                                    <option value='In Progress'>In Progress</option>
                                                    <option value='Re Open'>Re Open</option>
                                                    <option value='Closed'>Closed</option>
                                                </select>
                                            </div>


                                        </div>
                                        <div>
                                            <div className='form-row'>
                                                <div className='col'>
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        value="search"
                                                        href='#'
                                                        onClick={Filter}
                                                        type='button'
                                                        className='btn btn-primary'
                                                    >
                                                        Search
                                                    </button>
                                                    &nbsp;
                                                    <input
                                                        type='reset'
                                                        className='btn btn-danger'
                                                        onClick={getAllCaseList}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>

                                {
                                    ticketData.length === 0 ? <Result
                                        className='error-result-filter-cases'
                                        status="500"
                                        title="500"
                                        subTitle="Sorry, No Such Result Exist."
                                    /> :
                                        <div className='row'>
                                            <div className='col-sm-12 col-lg-12'>

                                                {
                                                    isLoading ? <Spin className='spinner_cases' indicator={antIcon} />
                                                        : <div className='card'>
                                                            <div className='card-body'>
                                                                <div
                                                                // className="table-editable" id="table"
                                                                >
                                                                    <div className='table-responsive'>
                                                                        <table className='table table-striped table-bordered'>
                                                                            <thead>
                                                                                <tr className='support_main_file_div'>
                                                                                    <th>S.No.</th>
                                                                                    <th>Create Date</th>
                                                                                    <th>Case ID</th>
                                                                                    <th>User ID</th>
                                                                                    <th>Details</th>
                                                                                    <th>Email</th>
                                                                                    <th>Phone</th>
                                                                                    <th>Priority</th>
                                                                                    <th>Case Type</th>
                                                                                    <th>Status</th>
                                                                                    <th>Created By</th>
                                                                                    <th>Updated By</th>
                                                                                    <th>Last Updated On</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    ticketData && ticketData.length > 0 && ticketData.map((ticket, i) => {
                                                                                        return (
                                                                                            <tr key={ticket._id}>
                                                                                                <td><div className='main_bindu_div'>
                                                                                                    {++i}
                                                                                                    {/* <Tooltip title="Edit" color='black'>
                                                                                                        <MdOutlineEdit className='md-outline-pencil' onClick={() => handleDrawer(ticket.case_number, ticket.user_id, ticket.case_type, ticket.status, ticket.comments[0].created_by, ticket.comments)} />
                                                                                                    </Tooltip> */}
                                                                                                </div>
                                                                                                </td>
                                                                                                <td>{handleDate(ticket.createdAt)}</td>
                                                                                                <td>{ticket.case_number}</td>
                                                                                                <td>{ticket.user_id}</td>
                                                                                                <td>
                                                                                                    {
                                                                                                        // ticket.source === 'App' ?
                                                                                                        <p
                                                                                                            onClick={() =>
                                                                                                                openModalChatScreen(ticket)
                                                                                                            }
                                                                                                            className='cases_pri_19'
                                                                                                        >
                                                                                                            Chat
                                                                                                        </p>
                                                                                                        // :
                                                                                                        // <p
                                                                                                        //     onClick={() =>
                                                                                                        //         EditUserDetail(
                                                                                                        //             ticket.comments,
                                                                                                        //             ticket.case_number
                                                                                                        //         )
                                                                                                        //     }
                                                                                                        //     className='cases_pri_19'
                                                                                                        // >
                                                                                                        //     Details
                                                                                                        // </p>
                                                                                                    }
                                                                                                </td>
                                                                                                <td>{ticket.email} </td>
                                                                                                <td>{ticket.phone}</td>
                                                                                                <td>{handlePriority(ticket.priority)}</td>
                                                                                                <td>{handleCaseType(ticket.case_type)}</td>
                                                                                                <td>
                                                                                                    <p onClick={() => {
                                                                                                        setIsModalOpen2(true);
                                                                                                        setTicketStatus(ticket.status);
                                                                                                        setUpdateCaseId(ticket.case_number);
                                                                                                    }} className='tickets_drop'>{handleTicketStatus(ticket.status)}</p>
                                                                                                </td>

                                                                                                <td>
                                                                                                    <div className='main_bindu_div'>
                                                                                                        {firstLetterUpper(ticket.comments[0].created_by)}
                                                                                                        {
                                                                                                            ticket.source === 'Email' ? <BsMailbox2 onClick={ticket.source === 'Email' ? handleEmailClick() : ''} className={ticket.read ? 'bindu_red' : 'bindu_green'} /> : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{

                                                                                                    ticket.comments.length > 1 ? ticket.comments?.slice(-1).map((com, i) => {
                                                                                                        return <p key={i}>{firstLetterUpper(com.created_by)}</p>;
                                                                                                    }) : 'NA'

                                                                                                }</td>

                                                                                                <td>{handleDate(ticket.updatedAt)}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                        </div>}

                                {/* Modal 1 */}
                                <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1500}>
                                    <div className='card-body'>

                                        <h1 className='ticket-info'>Ticket Information</h1>
                                        <div
                                            className="table-editable" id="table"
                                        >
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-bordered'>
                                                    <thead>
                                                        <tr className='support_main_file_div'>
                                                            <th>Created Date</th>
                                                            <th>Created Time</th>
                                                            <th>Subject</th>
                                                            <th>Body</th>
                                                            <th>Attachments</th>
                                                            <th>Created-By</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {modalCommentSubject.map((subject, i) => (
                                                            <tr key={i}>
                                                                <td>{handleCommentDate(modalCommentDate[i])}</td>
                                                                <td>{handleCommentTime(modalCommentDate[i])}</td>
                                                                <td>{firstLetterUpper(subject)}</td>
                                                                <td>{firstLetterUpper(modalCommentBody[i] ? modalCommentBody[i] : 'No Body')}</td>
                                                                <td>{modalCommentAttach[i].length > 0 ? modalCommentAttach[i].map((images, i) => {
                                                                    return (
                                                                        <Image
                                                                            key={i}
                                                                            width={50}
                                                                            src={images}
                                                                        />
                                                                    );
                                                                }) : 'No Images'}
                                                                </td>

                                                                <td><h2 className='modal-createdBy'>{firstLetterUpper(modalCommentCreatedBy[i])}</h2></td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </Modal>

                                {/* Modal 2 */}

                                <Modal
                                    width={600}
                                    open={isModalOpen2} onOk={handleOk2}
                                    onCancel={handleCancel2}>


                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-row'>

                                                <div className='col'>
                                                    <div>
                                                        <label>Update Status</label>
                                                        <select
                                                            className='form-control tickets_drop'
                                                            onChange={handleAllChange(setFinalTicketStatus)}
                                                        >
                                                            <option>Select</option>
                                                            <option value='Open'>Open</option>
                                                            <option value='In Progress'>In Progress</option>
                                                            <option value='Closed'>Closed</option>
                                                            <option value='Re Open'>Re Open</option>
                                                        </select>
                                                    </div>
                                                    {/* <div className='main-case-status-div'>
                                                        <label>Add Comments</label>
                                                        <textarea onChange={handleAllChange(setStatusComment)} className='tciket_case_div_statusssss' placeholder='Type Reason to change status' />
                                                    </div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal title=''
                                    width={500}
                                    open={openModal4} onOk={handleCloseModal4}
                                    onCancel={handleCloseModal4}>
                                    <div className=''>
                                        <h4 className='ticket_status_h4'>Reason to Change Status</h4>
                                    </div>
                                    <h4 className='tciket_case_div_statusssstsx' >{
                                        actionComment
                                    }</h4>
                                </Modal>

                                <Modal title="Chat Screen" open={isModalOpenChat} footer={null} width={800} height={800} onOk={handleOkChat} onCancel={handleCancelChat}>
                                    <ChatScreen ticketData={chatScreenData} updateTicketData={getAllCaseList} />
                                </Modal>

                                {/* Drawer */}

                                <Drawer title="Edit Ticket" size='large' placement='right' onClose={onClose} open={open}>
                                    <div>
                                        <h1>Edit Support Ticket</h1>

                                        <div className='ticket-drawer-cases-main-div'>

                                            <div className='ticket-drawer-cases'>
                                                <div className='ticket-drawer-cases-div-1'>
                                                    <h1>Case Number</h1>
                                                    <h2>{editCaseNumber}</h2>
                                                </div>

                                                <div className='ticket-drawer-cases-div-1'>
                                                    <h1>Case Type</h1>
                                                    <h2>{handleCaseTypeDrawer(editCaseType)}</h2>
                                                </div>

                                                <div className='ticket-drawer-cases-div-1'>
                                                    <h1>User Id</h1>
                                                    <h2 className='ticket-drawer-cases-5'>{editUserId}</h2>
                                                </div>

                                                <div className='ticket-drawer-cases-div-1'>
                                                    <h1>Created By</h1>
                                                    <h3>{editCreatedBy}</h3>
                                                </div>

                                                <div className='ticket-drawer-cases-div-3'>
                                                    <h1>Ticket Status</h1>
                                                    <h3 className='ticket-drawer-cases-div-1-text'>{handleTicketStatus(editStatus)}</h3>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='ticket-drawer-cases-div-1-container'>

                                                    <div className='ticket-drawer-cases-div-1'>
                                                        <h1>Ticket Subject</h1>
                                                        <textarea
                                                            onChange={(e) => setEditCaseSubjectEdited(e.target.value)}
                                                            type='text'
                                                            placeholder={editCaseSubject[0]?.subject}
                                                            className='input-drawer-edit-support' />
                                                    </div>

                                                    <div>
                                                        <h1>Other Case Type Subject</h1>
                                                        <textarea
                                                            disabled={editCaseType === 'Others' ? false : true} onChange={(e) => setEditCaseSubjectEdited(e.target.value)}
                                                            placeholder={editCaseSubject[0]?.body}
                                                            type='text'
                                                            className='input-drawer-edit-support-other' />
                                                    </div>

                                                    <div className='ticket-drawer-cases-div-1'>
                                                    </div>
                                                </div>

                                                <div className='ticket-drawer-cases-div-55'>
                                                    <div>
                                                        <h2 className='ticket-drawer-cases-div-5'>Ticket Created Date</h2>
                                                        <h2>{handleDate(editCaseSubject[0]?.createdAt)}</h2>
                                                    </div>
                                                    <button onClick={handleEditCases} className='ticket-drawer-cases-div-55-button'>Edit Ticket</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Drawer>
                                {/* Drawer */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >);
}
