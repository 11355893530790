import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { Spin, Result, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fundManager } from '../api/FundsApi';
import { Link } from 'react-router-dom';
import { TiTick } from 'react-icons/ti';


export default function Funds() {

    const [isLoading, setIsLoading] = useState(true);
    const [fundData, setFundData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fundTeamName, setFundTeamName] = useState('');
    const [fundOrderId, setFundOrderId] = useState('');

    const [orderID, setOrderID] = useState('');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 64,
            }}
            spin
        />
    );

    const fundsAPI = () => {
        fundManager.GETFunds().then((data) => {
            if (data.status_code) {
                setFundData(data.data);
                console.log(data.data);
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const handleModalOpenForOrderIDFunds = (fundTeamName, fundOrderId) => {
        setIsModalOpen(true);
        setFundTeamName(fundTeamName);
        setFundOrderId(fundOrderId);
    };

    useEffect(() => {
        fundsAPI();
    }, []);

    const handleDateTiem = (utcDateTimeString) => {
        const utcDateTime = new Date(utcDateTimeString);
        const utcOffsetMinutes = utcDateTime.getTimezoneOffset();
        const istOffsetMinutes = 330;
        const istDateTime = new Date(utcDateTime.getTime() + (utcOffsetMinutes + istOffsetMinutes) * 60000);
        const istDate = istDateTime.toLocaleDateString('en-IN');
        const istTime = istDateTime.toLocaleTimeString('en-IN');

        return `${istDate} - ${istTime}`;
    };

    const handleOrderIdSubmission = () => {
        const data = {
            'razorpay_order_id': fundOrderId,
        };
        fundManager.POSTORDERID(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success(data.message);
                setIsModalOpen(false);
            } else {
                Notiflix.Notify.failure(data.message);
                setIsModalOpen(false);
            }
        });
    };

    const [newPostOrderId, setNewPostOrderId] = useState('');
    const [isOrderError, setIsOrderError] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const verifyOrderId = () => {
        const arr = [];
        for (let i = 0; i < fundData.length; i++) {
            const trimmedOrderID = orderID.trim();
            arr.push(fundData[i].order_id);
            if (arr[i] === trimmedOrderID) {
                setIsOrderError(false);
                setNewPostOrderId(trimmedOrderID);
                return;
            } else {
                setIsOrderError(true);
                setNewPostOrderId('');
            }
        }
    };

    const sendOrderIdAfterVerification = () => {
        const data = {
            'razorpay_order_id': newPostOrderId,
        };
        fundManager.POSTORDERID(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success(data.message);
                setIsModalOpen(false);
                setNewPostOrderId('');
            } else {
                Notiflix.Notify.failure(data.message);
                setIsModalOpen(false);
            }
        });
    };

    const handleReset = () => {
        setNewPostOrderId('');
        setOrderID('');
        setIsOrderError(false);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='offers_head'>
                                        <div className='form-row'>
                                            <Link className='offer_button' to='/add-deduct-funds'>Add/Deduct Funds</Link>
                                        </div>
                                        <div className='form-row'>
                                            <Link className='offer_button' to='/funds'>Funds</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className='offers_couponDiv'>
                            <h1 className='coupon_h1'>Add Funds with Order ID</h1>
                        </div>

                    </form>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body order-id-div-div'>

                                    <div className='card-body'>
                                        <div className='hashed-funds-div'>
                                            <label className='offer_checkbox'>Email ID</label>
                                            <input
                                                value={orderID}
                                                onChange={handleAllChange(setOrderID)}
                                                className='offers_input_599'
                                                placeholder='Enter Order ID'
                                            />
                                            {isOrderError ? <h6 className='funds-error-ha'>Order Id does not match</h6> : ''}
                                        </div>

                                        <div className='funds-div-array'>

                                            {
                                                newPostOrderId && newPostOrderId.length > 0 ?
                                                    <div className='funds-error-verified-div'>
                                                        <div className='funds-error-verified-div-div'>
                                                            <TiTick />
                                                            <h5>Verified Order ID</h5>
                                                        </div>
                                                        <button onClick={sendOrderIdAfterVerification} className='funds-button-order-id-id'>Send ID</button>
                                                    </div>
                                                    :
                                                    <button onClick={verifyOrderId} className='funds-button-order-id'>Verify</button>
                                            }
                                            <button onClick={handleReset} className='funds-button-order-id-reset'>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>

                                {
                                    fundData.length === 0 ? <Result
                                        className='error-result-filter-cases'
                                        status="500"
                                        title="Sorry, No Such Result Exist."

                                    /> :
                                        <div className='row'>
                                            <div className='col-sm-12 col-lg-12'>

                                                {
                                                    isLoading ? <Spin className='spinner_cases' indicator={antIcon} />
                                                        : <div className='card'>
                                                            <div className='card-body'>
                                                                <div
                                                                >
                                                                    <div className='table-responsive'>
                                                                        <table className='table table-striped table-bordered'>
                                                                            <thead>
                                                                                <tr className='support_main_file_div'>
                                                                                    <th>S.No.</th>
                                                                                    <th>User ID</th>
                                                                                    <th>Team Name</th>
                                                                                    <th>Phone Number/ Email ID</th>
                                                                                    <th>Payment Date/Time</th>
                                                                                    <th>Order ID</th>
                                                                                    <th>Status</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Funds</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    fundData && fundData.length > 0 && fundData.map((fund, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>
                                                                                                    {++i}
                                                                                                </td>
                                                                                                <td>{fund.user_id}</td>
                                                                                                <td>{fund.teamName}</td>
                                                                                                <td>{fund.email === '' ? fund.phone : fund.email}</td>
                                                                                                <td>{handleDateTiem(fund.createdAt)}</td>
                                                                                                <td>{fund.order_id}</td>
                                                                                                <td>{fund.status}</td>
                                                                                                <td className='funds-api-amount'>{fund.amount}</td>
                                                                                                <td>
                                                                                                    {
                                                                                                        <button onClick={() => handleModalOpenForOrderIDFunds(fund.teamName, fund.order_id)} className='funds-button'>Send ID</button>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>}

                                {/* Modal*/}


                                <Modal
                                    closable={false}
                                    width={700}
                                    open={isModalOpen} footer={null}
                                >
                                    <div className='fund-modal-div'>



                                        <div className='table-responsive'>
                                            <table className='table table-striped table-bordered'>
                                                <thead>
                                                    <tr className='support_main_file_div'>
                                                        <th >Team Name</th>
                                                        <th >Order Id</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td className='modal-fund-td'>{fundTeamName}</td>
                                                        <td className='modal-fund-td'>{fundOrderId}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <h5>Are you sure you want to initiate payment ?</h5>

                                        <div className='fund-modal-div-div'>
                                            <button onClick={handleOrderIdSubmission} className='fund-add-order-button-no'>Yes</button>
                                            <button onClick={() => setIsModalOpen(false)} className='fund-add-order-button'>No</button>
                                        </div>
                                    </div>
                                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}
