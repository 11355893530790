import React, { useEffect, useState } from 'react';
import { AllUsersAPI } from '../api/allUsers';
import Notiflix from 'notiflix';

export default function RemoveUserScreen() {
    const [team, setTeam] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [user, setUser] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const Search =()=>{
        AllUsersAPI.FilterAllUsersInfo(
            50,
            1,
            '',
            email,
            mobile,
            '',
            '',
            '',
            '',
            ''

    
        ).then((data) => {
            if (data.status_code) {
                if(!data.msg){
                    Notiflix.Notify.success('User Deleted Successfully');
                    setMobile('');
                    setEmail('');
                }
                else if (data.msg){
                    Notiflix.Notify.failure(data.msg);
                }
                // console.log(data);
                // setUser(data.data);
                // console.log(data.data);
                
            } else {
                Notiflix.Notify.info(data.msg);

            }
            // setIsLoading(false);
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title m-2'>Remove User From App</h4>
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-12 col-lg-12'>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div className='form-row'>
                                                                {/* <div>
                                                                    <div className='col'>
                                                                        <label htmlFor='name'>
                                                                            Team Name
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            value={team}
                                                                            onChange={(e) =>
                                                                                setTeam(
                                                                                    e.target.value.toString()
                                                                                )
                                                                            }
                                                                            className='form-control'
                                                                            placeholder='Search By Name'
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                <div>
                                                                    <div className='col'>
                                                                        <label htmlFor='name'>
                                                                            Mobile Number
                                                                        </label>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            placeholder='Search By Mobile'
                                                                            value={mobile}
                                                                            onChange={(e) =>
                                                                                setMobile(
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='col'>
                                                                        <label htmlFor='name'>
                                                                            Email
                                                                        </label>
                                                                        <input
                                                                            type='email'
                                                                            className='form-control'
                                                                            placeholder='Search By Email'
                                                                            value={email}
                                                                            onChange={(e) =>
                                                                                setEmail(
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className='col'
                                                                    style={{
                                                                        'padding-top': '0.5pc',
                                                                    }}
                                                                >
                                                                    <br />
                                                                    <label htmlFor='name'></label>
                                                                    <button
                                                                        className='btn btn-primary'
                                                                        type='button'
                                                                        onClick={() => Search()}
                                                                    >
                                                                        Search
                                                                    </button>
                                                                    &nbsp;
                                                                    {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                                    <input
                                                                        type='reset'
                                                                        // onClick={() => Reset()}
                                                                        className='btn btn-danger'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
