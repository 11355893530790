import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { BannerAPI } from '../api/bannerAPI';

export default function ViewSideBanner() {
    const [image, setImage] = useState('');
    const [type, setType] = useState('');
    const [order, setOrder] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [max_count, setMax_count] = useState('');
    const [time_interval, setTime_interval] = useState('');
    const [start_time, setStart_time] = useState('');
    const [select_date, setSelect_date] = useState('');
    const [banner_id, setBanner_id] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const addBanner = () => {
        if (type == '' || type == null) Notiflix.Notify.failure('Type can\'t be blank');
        else if (image == '' || image == null) Notiflix.Notify.failure('Please choose image');
        else if (order == '' || order == null) Notiflix.Notify.failure('Order can\'t be blank');
        else {
            const data = new FormData();

            data.append('file', image);
            data.append('type', type);
            data.append('order', order);
            data.append('isDeleted', isDeleted);
            data.append('max_count', max_count);
            data.append('time_interval', time_interval);
            data.append('start_time', start_time);
            data.append('select_date', select_date);
            data.append('banner_id', banner_id);

            console.log(data);

            BannerAPI.AddBanner(data).then((res) => {
                if (res.status_code) {
                    Notiflix.Notify.success(res.message);
                } else {
                    Notiflix.Notify.failure(res.message);
                }
            });
        }
    };
    const imageChange = (e) => {
        e.preventDefault();
        setImage(e.target.files[0]);
    };

    const orderChange = (e) => {
        e.preventDefault();
        setType(e.target.value);
    };

    const bannerIdChange = (e) => {
        e.preventDefault();
        setBanner_id(e.target.value);
    };

    const isDeletedChange = (e) => {
        e.preventDefault();
        setIsDeleted(e.target.value);
    };

    const bannerTypeChange = (e) => {
        e.preventDefault();
        setOrder(e.target.value);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_side_banner'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View all Slider
                                    </Link>
                                    <a
                                        className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                        href='#'
                                    >
                                        <i className='ri-add-line m-0'></i> Add New Slider
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Create New Series</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Banner Type :</label>
                                            <select
                                                onChange={orderChange}
                                                className='form-control mb-3'
                                            >
                                                <option select=''>Open this select menu</option>
                                                <option value='Banner'>Banner</option>
                                                <option value='Web'>Web</option>
                                                <option value='App'>App</option>
                                            </select>
                                        </div>

                                        {/* const banner_ids = ['login', 'register', 'onboarding', 'fantasyHome'] */}
                                        {/* const banner_ids = ['App-2', 'App-3', 'App-1', 'App-4'] */}

                                        <div className='form-group'>
                                            <label htmlFor='name'>Banner Id</label>
                                            <select
                                                onChange={bannerIdChange}
                                                className='form-control mb-3'
                                            >
                                                {/*  */}
                                                <option value=''>Select</option>
                                                <option value='App-1'>Onboarding</option>
                                                <option value='App-2'>On Login Page</option>
                                                <option value='App-3'>Register</option>
                                                <option value='App-4'>Fantasy Home Page</option>
                                            </select>
                                        </div>


                                        <div className='form-group'>
                                            <label htmlFor='text'>Image</label>
                                            <div className='custom-file'>
                                                <input
                                                    type='file'
                                                    className='custom-file-input'
                                                    id='customFile'
                                                    onChange={(e) => imageChange(e)}
                                                    accept='image/png, image/jpeg'
                                                />
                                                <label
                                                    className='custom-file-label'
                                                    htmlFor='customFile'
                                                >
                                                    Choose file
                                                </label>
                                                {/* <button onClick={sendImage} type="submit" className="btn btn-primary">Update</button> */}
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Order :</label>
                                            <input
                                                onChange={bannerTypeChange}
                                                type='number'
                                                className='form-control'
                                                id='order'
                                                placeholder='Order'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='name'>Is Deleted</label>
                                            <select
                                                value={isDeleted}
                                                onChange={isDeletedChange}
                                                className='form-control mb-3'
                                            >
                                                <option value='false'>No</option>
                                                <option value='true'>Yes</option>
                                            </select>
                                        </div>

                                        <button
                                            onClick={addBanner}
                                            type='button'
                                            className='btn btn-primary'
                                        >
                                            Update Banners
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
