import { baseURL } from './apiBaseUrl';

const key = localStorage.getItem('jwtToken');

export const BankAPI = {
    GetBankInfo: async () => {
        //alert()
        return await fetch(baseURL + 'bankAccverificationReq', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    FilterBankInfo: async (status, entries, name, userId, email, phone, state) => {
        return await fetch(
            baseURL +
                'bankAccverificationReq?status=' +
                status +
                '&entries=' +
                entries +
                '&user_id=' +
                userId +
                '&name=' +
                name +
                '&email=' +
                email +
                '&mobile=' +
                phone +
                '&state=' +
                state,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                    'x-api-key': key,
                },
            }
        )
            .then((response) => response.json())
            .then((json) => {
                // console.log(baseURL+'bankAccverificationReq?name='+name+'&email='+email+'&phone='+phone)//notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    UpdateBankVerificationStatus: async (jsonbody) => {
        console.log(jsonbody);
        //alert()
        return await fetch(baseURL + 'updateBankVerificationStatus', {
            method: 'PUT',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetIfsc: async (Ifsc) => {
        return await fetch('https://ifsc.razorpay.com/'+Ifsc, {
            method: 'GET',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                // 'Content-Type': 'application/json; charset=utf-8',
                // 'x-api-key': key,
            },
        })
            .then((response) => response.json())
            .then((json) => {
      
                if (json != null) {
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },
    EditBankDetails: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'editBankDetails', {
            method: 'PUT',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => { 
                if (json != null) {
         
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

  
    RejectBankInfo: async (userId,state,mobile,email,startDate) => {
    //alert()
        return await fetch(baseURL + 'Bank-reject?status=Rejected&user_id='+userId+'&state='+state+'&mobile='+mobile+'&email='+email+'&start='+startDate, {
            method: 'GET',
            headers: {
            // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
        // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
            //notifyMessage("processing....")
            // notifyMessage("Processing....")
            //  console.log("api===="+json)
                if (json != null) {
                //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
