import Notiflix from 'notiflix';
import { useEffect, useLayoutEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { AllUsersAPI } from '../api/allUsers';
import './ViewAllUsers.css';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { BsFillArrowDownCircleFill } from 'react-icons/bs';
import { BsArrowClockwise } from 'react-icons/bs';
import ScrollTop from '../ScrollTop';

export default function UserManagerCase() {
    const [user, setUser] = useState([]);
    const [team_name, setTeam] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [user_id, setUserId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize, setPageSize] = useState(50);
    const [allStates, setAllStates] = useState([]);
    // const [state, setState] = useState('');
    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(10);
    const [thisData, setThisData] = useState([]);
    const [status, setStatus] = useState([]);
    const [thisFilteredData, setThisFilteredData] = useState([]);

    // Pagination
    const [totalEntires, setTotalEntries] = useState(1);
    const [paginate, setPaginate] = useState(10);
    const [activeIndex, setActiveIndex] = useState(0);
    const [entries, setEntries] = useState(50);
    const [totalPages, setTotalPages] = useState(100);
    const [nextPage, setNextpage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousPage, setPreviousPage] = useState(0);
    const [page, setPage] = useState([]);
    const [state, setUserState] = useState('');

    // Modal
    const [editId, setEditId] = useState('');
    const [editName, setEditName] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [nameChangeInput, setNameChangeInput] = useState('');
    const [emailChangeInput, setEmailChangeInput] = useState('');
    const [phoneChangeInput, setPhoneChangeInput] = useState('');
    const [userStatus, setUserStatus] = useState('');

    // Pagination--------------------
    const [pageNumber, setPageNumber] = useState(1);
    const [totalLength, setTotalLength] = useState(1);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        setIsLoading(true);
        Filter();
        getAllStates();
        Reset();
        // generatePagination(1, 10)
        console.log('first');
    }, []);

    useEffect(() => {
        Filter();
        console.log('hello');
    }, [pageNumber]);

    const getAllStates = () => {
        AllUsersAPI.GetAllStates().then((data) => {
            console.log(data);
            if (data.status_code) {
                setAllStates(data.states);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            // setIsLoading(false);
        });
    };

    //  Pagination
    const handleNext = () => {
        if (pageNumber + 1 <= totalPages) {
            setPageNumber(pageNumber + 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const handlePrevious = () => {
        if (pageNumber - 1 > 0) {
            setPageNumber(pageNumber - 1);
            // console.log(pageNumber)
        } else {
            return;
        }
    };

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Name', key: 'name' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Mobile No', key: 'phone' },
        { label: 'Email Id', key: 'email' },
        { label: 'Date and Time', key: 'date' },
        { label: 'Address', key: 'address' },
        { label: 'State', key: 'state' },
        { label: 'isphoneverify', key: 'isphoneverify' },
        { label: 'isBankverify', key: 'isBankverify' },
        { label: 'isEmailverify', key: 'isEmailverify' },
        { label: 'isPanverify', key: 'isPanverify' },
    ];

    const allUsersInfo = () => {
        setIsLoading(true);
        AllUsersAPI.GetAllUsersInfo(pageSize, pageNumber).then((data) => {
            if (data.status_code) {
                // var allUsers_i = JSON.parse(JSON.stringify(data));
                setUser(data.data);
                setTotalPages(data.total_page);
                // setTotalEntries(data.total_entries);
                setTotalLength(data.total_length);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            setIsLoading(false);
        });
    };

    // Fetch This ALL Data
    const handleFetchData = () => {
        setIsLoading(true);
        AllUsersAPI.GetAllUsersDownload().then((data) => {
            if (data.status_code) {
                // var allUsers_i = JSON.parse(JSON.stringify(data));
                setThisData(data.data);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    //  Fetch This ALL Filtered Data
    const handleThisFilteredData = (status) => {
        setIsLoading(true);
        AllUsersAPI.FilterAllUsersDownload(
            totalEntires,
            fromDate,
            toDate,
            state,
            status,
            pageSize,
            pageNumber
        ).then((data) => {
            if (data.status_code) {
                console.log(data);
                // setUser(data.data);
                setThisFilteredData(data.data);
            } else {
                Notiflix.Notify.warning(data.msg);
            }
            setIsLoading(false);
        });
    };

    const Filter = () => {
        setIsLoading(true);
        // console.log('hello')
        // e.preventDefault()
        // e.stopPropagation()
        AllUsersAPI.FilterAllUsersInfo(
            entries,
            pageNumber,
            team_name,
            email,
            mobile,
            user_id,
            fromDate,
            toDate,
            state,
            status
        ).then((data) => {
            if (data.status_code) {
                console.log(data);
                setUser(data.data);
                setTotalPages(data.total_page);
                setTotalEntries(data.total_length);
            } else {
                Notiflix.Notify.info(data.msg);

            }
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (totalEntires <= 50) {
            setPageNumber(1);
        } else {
            setPageNumber(pageNumber);
        }
    }, [Filter]);


    const Reset = () => {
        setTeam('');
        setEmail('');
        setMobile('');
        setUserId('', allUsersInfo());
        setFromDate('');
        setToDate('');
        setUserState('');
        setPageNumber(1);
        setTotalEntries(totalLength);
    };

    const getDate = (e) => {
        if (!e) {
            return '';
        } else {
            const time = moment(e).format('hh:mm:s a');
            const date = moment(e).format('DD/MM/YYYY');
            return `${date} | ${time}`;
        }
    };

    const changeStatus = (e) => {
        setStatus(e.target.value);
    };









    const handleTime = (e) => {
        var newTime = e?.slice(11, 18);
        var updateTime = moment(newTime, 'HH:mm:ss').format('hh:mm A');
        return updateTime;
    };

    const handleDate = (date) => {
        var dateNew = date?.slice(0, 10);
        return dateNew;
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='col'>
                                                <label htmlFor='name'>Status </label>
                                                <select
                                                    onChange={changeStatus}
                                                    className='form-control mb-3'
                                                >
                                                    <option value='all'> All </option>
                                                    <option value='true'>Blocked</option>
                                                    {/* <option value="2">Inactive User</option> */}
                                                    <option value='false'>Unblocked</option>
                                                </select>
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>User Id :</label>
                                                <input
                                                    onChange={(e) => setUserId(e.target.value)}
                                                    type='text'
                                                    value={user_id}
                                                    className='form-control'
                                                    placeholder='Search User ID'
                                                />
                                            </div>

                                            <div className='col'>
                                                <label htmlFor='name'>Team Name :</label>
                                                <input
                                                    onChange={(e) => setTeam(e.target.value)}
                                                    type='text'
                                                    value={team_name}
                                                    className='form-control'
                                                    placeholder='Search By Team Name'
                                                />
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='email'>Email Id :</label>
                                                <input
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type='text'
                                                    value={email}
                                                    className='form-control'
                                                    placeholder='Search By Email Id'
                                                />
                                            </div>



                                            <div className='d-flex flex-wrap'>
                                                <label htmlFor='mobile'>Mobile :</label>
                                                <input
                                                    onKeyDown={(evt) =>
                                                        ['e', 'E', '+', '-'].includes(evt.key) &&
                                                        evt.preventDefault()
                                                    }
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    type='number'
                                                    value={mobile}
                                                    className='form-control'
                                                    placeholder='Search By Mobile No.'
                                                />
                                            </div>



                                        </div>
                                        <div>
                                            <div className='form-row'>
                                                <div className='col'>
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        value="search"
                                                        href='#'
                                                        onClick={(e) => Filter(e)}
                                                        type='button'
                                                        className='btn btn-primary'
                                                        disabled={isLoading ? true : false}
                                                    >
                                                        Search
                                                    </button>
                                                    &nbsp;
                                                    <input
                                                        type='reset'
                                                        onClick={() => Reset()}
                                                        className='btn btn-danger'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>

                                {/* Export All Data */}
                                <div className='div-series'>
                                    {thisData.length > 0 ? (
                                        <CSVLink
                                            filename='View All Users'
                                            data={thisData}
                                            headers={headers}
                                        >
                                            <button className='button-series-1' href='#'>
                                                Download All Data <AiOutlineCheckCircle />
                                            </button>
                                        </CSVLink>
                                    ) : (
                                        ''
                                    )}

                                    {/* Export All Filtered Data */}

                                    {thisFilteredData.length > 0 ? (
                                        <CSVLink
                                            filename='Filtered Users'
                                            data={thisFilteredData}
                                            headers={headers}
                                        >
                                            <button className='button-series' href='#'>
                                                Download This Filtered Data <AiOutlineCheckCircle />
                                            </button>
                                        </CSVLink>
                                    ) : (
                                        ''
                                    )}
                                    {thisData.length > 0 || thisFilteredData.length > 0 ? (
                                        <button
                                            className='button-series'
                                            onClick={() => window.location.reload(true)}
                                        >
                                            <BsArrowClockwise />
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </div>

                                {/* Export Buttons */}
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='button-series-2'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Fetch - <BsFillArrowDownCircleFill />
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button
                                                className='dropdown-item'
                                                href='#'
                                                onClick={handleFetchData}
                                            >
                                                Fetch All data
                                            </button>
                                            <button
                                                className='dropdown-item'
                                                href='#'
                                                onClick={handleThisFilteredData}
                                            >
                                                Fetch This Filtered Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Users</h4>
                                    </div>
                                </div>
                                {isLoading && (
                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                        <div>
                                            <RotatingLines
                                                marginTop='70'
                                                strokeColor='#c1030d'
                                                strokeWidth='5'
                                                animationDuration='0.75'
                                                width='50'
                                                visible={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className='card-body'>
                                        <div
                                        // className="table-editable" id="table"
                                        >
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-bordered'>
                                                    <thead>
                                                        <tr className='users_link'>
                                                            <th>S.No.</th>
                                                            <th>User Id</th>
                                                            <th>Name</th>
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                            <th>Team Name</th>
                                                            <th>Mobile No.</th>
                                                            <th>Email Id</th>
                                                            {/* <th>Date</th> */}
                                                            {/* <th>Address</th> */}
                                                            <th>State</th>
                                                            <th>Verification</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {user &&
                                                            user.length > 0 &&
                                                            user.map((userInfo, i) => {
                                                                return (
                                                                    <tr key={userInfo._id}>
                                                                        <td>{++i}</td>
                                                                        <td className='users_linkk'>{userInfo.user_id}
                                                                        </td>
                                                                        <td>{userInfo.name}</td>
                                                                        <td>
                                                                            {handleDate(
                                                                                userInfo.date
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {handleTime(
                                                                                userInfo.date
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {userInfo.team_name}
                                                                        </td>
                                                                        <td>{userInfo.phone}</td>
                                                                        <td>{userInfo.email}</td>
                                                                        {/* <td>{userInfo.createdAt}</td> */}
                                                                        {/* <td>{userInfo.address}</td> */}
                                                                        <td>{userInfo.state}</td>
                                                                        <td>
                                                                            {userInfo.isphoneverify ? (
                                                                                <i
                                                                                    className='ri-smartphone-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-smartphone-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}
                                                                            {userInfo.isEmailverify ? (
                                                                                <i
                                                                                    className='ri-mail-check-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-mail-check-line green'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}

                                                                            {userInfo.isPanverify ? (
                                                                                <i
                                                                                    className='ri-bank-card-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-bank-card-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}

                                                                            {userInfo.isBankverify ? (
                                                                                <i
                                                                                    className='ri-bank-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#66FF99',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            ) : (
                                                                                <i
                                                                                    className='ri-bank-line red'
                                                                                    style={{
                                                                                        background:
                                                                                            '#FF0000',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                ></i>
                                                                            )}
                                                                            <br />
                                                                        </td>

                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='col-12 d-flex align-items-center'>
                                    <label className='p-2' htmlFor='name'>
                                        Show
                                    </label>
                                    <select
                                        // onChange={changePageSize}
                                        className='form-control m-2 mb-3 col-2 d-flex align-items-center'
                                    >
                                        <option defaultValue={50}>50</option>
                                    </select>
                                    <nav aria-label='Page navigation example '>
                                        <ul className='pagination'>
                                            <li className='page-item' onClick={handlePrevious}>
                                                <a
                                                    className='page-link'
                                                    // href="#"
                                                    aria-label='Previous'
                                                >
                                                    <span aria-hidden='true'>&laquo;</span>
                                                    <span className='sr-only'>Previous</span>
                                                </a>
                                            </li>
                                            <li
                                                className='page-item active'
                                            // onClick={() => {
                                            //     HandlePaginate(item, i);
                                            // }}
                                            >
                                                <button className='page-link'>{pageNumber}</button>
                                            </li>

                                            <li
                                                className='page-item'
                                                onClick={() => {
                                                    handleNext();
                                                }}
                                            >
                                                <a className='page-link' aria-label='Next'>
                                                    <span aria-hidden='true'>&raquo;</span>
                                                    <span className='sr-only'>Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className='col-6 d-flex align-items-center'>
                                        <h5 className='d-flex p-2 mb-3 transaction_pagi_div'>out of <span className='transaction_pagi_box'>{totalPages}</span> Pages</h5>

                                        <h5 className='d-flex p-2 mb-3 transaction_pagi_div'>
                                            and <span className='transaction_pagi_box'>{totalEntires}</span> users
                                        </h5>
                                    </div>

                                    <ScrollTop name={'Top'} />
                                </div>


                                {/* Edit Modale */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
