import { Link } from 'react-router-dom';

export default function EconomyTable() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Economy Rate Table</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Economy</th>
                                                        <th>Points</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Starting 11</td>
                                                        <td>4</td>
                                                        <td>
                                                            <button className='btn btn-primary btn-primary-fix btn-sm'>
                                                                <i className='ri-edit-line'></i>Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <td>Starting 11</td>
                                                        <td>4</td>
                                                        <td>
                                                            <button className='btn btn-primary btn-primary-fix btn-sm'>
                                                                <i className='ri-edit-line'></i>Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <td>Starting 11</td>
                                                        <td>4</td>
                                                        <td>
                                                            <button className='btn btn-primary btn-primary-fix btn-sm'>
                                                                <i className='ri-edit-line'></i>Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
