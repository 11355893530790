import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function ViewAllQuestionPage() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'></div>

                                    <div className='col'>
                                        <Link
                                            to={'/add_new_question'}
                                            className='mt-2 btn btn-primary float-right'
                                        >
                                            {' '}
                                            <i className='ri-add-line'></i> Add New Question
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Question</th>
                                                <th>Option A</th>
                                                <th>Option B</th>
                                                <th>Option C</th>
                                                <th>Option D</th>
                                                <th>Correct Answer</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                        data-toggle='modal'
                                                        data-target='#exampleModalScrollable'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2.</td>
                                                <td>
                                                    Though 'Samber' is popular across south India,
                                                    which state is most famous for Samber?
                                                </td>
                                                <td>Tamil Nadu</td>
                                                <td>Kerala</td>
                                                <td>Andhra Pradesh</td>
                                                <td>None of these</td>
                                                <td>Option A</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Walker cup is associate with which sport?</td>
                                                <td>Cricket</td>
                                                <td>Football</td>
                                                <td>Fencing</td>
                                                <td>Golf</td>
                                                <td>Option D</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>6.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>7.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>8.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>9.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>10.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>11.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                        data-toggle='modal'
                                                        data-target='#exampleModalScrollable'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>12.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                                <td>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                    >
                                                        <i className='ri-edit-line'></i> Edit
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >
                                                        <i className='ri-delete-bin-line'></i>{' '}
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='modal fade'
                        id='exampleModalScrollable'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='exampleModalScrollableTitle'
                        aria-hidden='true'
                    >
                        <div className='modal-dialog modal-dialog-scrollable' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                        Edit Team
                                    </h5>
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Add Question</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Match Name '
                                                defaultValue="Who was the India's first woman president?"
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Option A :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Option A'
                                                defaultValue='Indira Gandhi'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Option B :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Option B'
                                                defaultValue='Pratibha Patil'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Option C :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Option C'
                                                defaultValue='Sushma Swaraj'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Option D :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Option D'
                                                defaultValue='Mamta Banerjee'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Right Answer :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Right Answer'
                                                defaultValue='Option B'
                                            />
                                        </div>

                                        {/* @*<button type="submit" className="btn btn-primary">Submit</button>*@ */}
                                    </form>
                                </div>
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        data-dismiss='modal'
                                    >
                                        Close
                                    </button>
                                    <button type='button' className='btn btn-primary'>
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
