import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const SupportAPI = {


    CreateTicket: async (jsonbody) => {
        return await fetch(baseURL + 'createSupport', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
            body: jsonbody,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    getAllCaseStatusDashboard: () => {
        return BASEAPIS.GETAPI('getAllCaseStatus');
    },

    verifyUserDetail: (phoneNumber, emailId) => {
        return BASEAPIS.GETAPI('verifyUserInCase?email=' + emailId + '&phone=' + phoneNumber);
    },

    getAllCases: (pageNumber, pageSize) => {
        return BASEAPIS.GETAPI('getAllCaseList?pageNumber=' + pageNumber + '&entries=' + pageSize);
    },

    filterAllCase: (filEmailStatus, filCaseSource, filPriority, filCaseType, filStatus, filCreatedBy, filCaseID, filPhoneNumber, filEmailID) => {
        return BASEAPIS.GETAPI('getAllCaseList?priority=' + filPriority + '&case_type=' + filCaseType + '&status=' +
            filStatus + '&created_by=' + filCreatedBy + '&email=' + filEmailID + '&phone=' + filPhoneNumber +
            '&case_number=' + filCaseID + '&read=' + filEmailStatus + '&source=' + filCaseSource);
    },

    upDateTicketStatusCases: async (jsonbody) => {
        return await fetch(baseURL + 'updateCaseStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    editTicket: (jsonBody) => {
        return BASEAPIS.PUTAPI(jsonBody, 'updateSupport');
    },

    updateEmailStatus: (jsonBody) => {
        return BASEAPIS.PUTAPI(jsonBody, 'updateSupportReadField');
    },

};
