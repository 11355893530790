import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { QuizAPI } from '../api/quizApi';
import Notiflix from 'notiflix';

export default function CreatQuizCategoryPage() {
    const [catName, setCategory] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [catImage, setCatImage] = useState('');
    // const { register, handleSubmit }=useForm();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const CreateQuizCat = () => {
        if (catName == '' || catName == null) Notiflix.Notify.failure('Category name can\'t blank');
        else if (subTitle == '' || subTitle == null)
            Notiflix.Notify.failure('Sub Title can\'t blank');
        else if (catImage == '' || catImage == null) Notiflix.Notify.failure('Please choose image');
        else {
            const data = new FormData();
            data.append('QuizCatName', catName);
            data.append('QuizSubTitle', subTitle);
            data.append('Image', catImage);

            QuizAPI.CreateQuizCategory(data).then((dt) => {
                Notiflix.Notify.success(dt.Message);
                console.log(data);
            });
        }
    };

    // const ontSubmit = async (data) => {
    //     // alert(JSON.stringify(data))
    //     const formData = new FormData();
    //     formData.append("QuizCatName",data.Category);
    //     formData.append("QuizSubTitle",data.SubTitle)
    //     formData.append("Image", data.file[0]);

    //     const res = await fetch("http://65.1.132.117:5000/api/auth/CreateQuizCategory", {
    //         method: "POST",
    //         body: formData,
    //     }).then((res) => res.json());
    //     alert(JSON.stringify(`${res.Message}`));
    // };

    const CatNameChange = (e) => {
        setCategory(e.target.value);
    };
    const SubTitleChange = (e) => {
        setSubTitle(e.target.value);
    };
    const CatImageChange = (e) => {
        // if (e.target.files && e.target.files[0]) {
        //     let reader =new FileReader();
        //     reader.onload=(ev)=>{

        //         setCatImage(ev.target.result)
        //     };
        //     reader.readAsDataURL(e.target.files[0]);
        // }

        // alert(e.target.files[0])

        setCatImage(e.target.files[0]);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'></div>
                                    <div className='col'>
                                        <Link
                                            to={'/view_quiz_contest_category'}
                                            className='mt-2 btn btn-warning float-right'
                                        >
                                            {' '}
                                            <i className='ri-eye-line'></i> View All Quiz Category
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Add New Quiz Category</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form
                                // onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputText1'>Category Name </label>
                                        <input
                                            type='text'
                                            name='CategoryName'
                                            // {...register("Category")}
                                            onChange={CatNameChange}
                                            className='form-control'
                                            id='exampleInputText1'
                                            placeholder='Enter Category Name'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputText1'>Sub Title</label>
                                        <input
                                            type='text'
                                            name='SubTitle'
                                            // {...register("SubTitle")}
                                            onChange={SubTitleChange}
                                            className='form-control'
                                            id='exampleInputText1'
                                            placeholder='Enter Sub Title'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputText1'>Image </label>
                                        <div className='custom-file'>
                                            <input
                                                type='file'
                                                name='ImageCategory'
                                                onChange={(e) => CatImageChange(e)}
                                                className='custom-file-input'
                                                id='customFile'
                                                accept='image/png, image/jpeg'
                                            />
                                            {/* <input type="file" {...register("file")} className="custom-file-input" /> */}
                                            <label
                                                className='custom-file-label'
                                                htmlFor='customFile'
                                            >
                                                Choose file
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={CreateQuizCat}
                                    >
                                        Submit
                                    </button>
                                    {/* <input type="submit" className="btn btn-primary" /> */}
                                    &nbsp;
                                    <button type='submit' className='btn bg-danger'>
                                        Cancel
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
