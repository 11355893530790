import './ViewAllUsers.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createCoupon } from '../api/addCoupon';
import Notiflix from 'notiflix';
import moment from 'moment';
import { Tooltip, Spin } from 'antd';
import { RotatingLines } from 'react-loader-spinner';

export default function ViewAllUserPage() {

    const [couponData, setCouponData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editCouponId, setEditCouponId] = useState('');
    const [editCouponEndDate, setEditCouponEndDate] = useState('');
    const [newEditedDate, setNewEditedDate] = useState(editCouponEndDate);
    const [dateIs, setDateIs] = useState(false);
    const [couponTotalUsed, setCouponTotalUsed] = useState([]);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    useEffect(() => {
        getAllCouponCode();
        functionForCouponExpiry();
    }, []);

    const functionForCouponExpiry = () => {
        let today = new Date;
        console.log(today.getDate());

    };

    const getAllCouponCode = () => {
        createCoupon.GetAllCoupon().then((data) => {
            if (data.status_code) {
                setIsLoading(false);
                console.log(data.data);
                setCouponData(data.data.data);
                setCouponTotalUsed(data.data.coupon_count);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        return newDate;
    };

    const EditCoupon = (couponId, coupon_end_date) => {
        setEditCouponId(couponId);
        setEditCouponEndDate(coupon_end_date);
    };

    const handleClose = () => {
        setNewEditedDate('');
        setEditCouponId('');
    };

    const handleEditSubmit = () => {
        let data = {
            coupon_code: editCouponId,
            end_date: newEditedDate
        };
        console.log(data);
        if (newEditedDate === '') {
            Notiflix.Notify.failure('Empty Input');
        } else {
            createCoupon.ChangeCouponEndDate(data).then((data) => {
                Notiflix.Notify.success('End Date Updated Successfully');
                getAllCouponCode();
                setNewEditedDate('');
            });
        }
    };

    const handleCouponStatus = (coupon_name, coupon_status, coupon_endDate) => {
        const endDateCompare = moment(coupon_endDate).toDate();
        const dateNowCompare = moment(new Date()).toDate();

        console.log(endDateCompare, dateNowCompare);

        if (endDateCompare < dateNowCompare) {
            let data = {
                coupon_code: coupon_name,
                status: false,
            };
            console.log(data);
            createCoupon.ChangeCouponStatusUpdate(data).then((data) => {
                Notiflix.Notify.failure('End Date is Less than Current Date');
                getAllCouponCode();
            });
        } else {
            let data = {
                coupon_code: coupon_name,
                status: !coupon_status,
            };
            console.log(data);
            alert(`Are you sure you want to ${coupon_status === true ? 'DISABLE' : 'ACTIVATE'} the COUPON`);
            createCoupon.ChangeCouponStatusUpdate(data).then((data) => {
                Notiflix.Notify.success('Status Changed Successfully');
                getAllCouponCode();
            });
        }
    };

    const handleReset = () => {
        getAllCouponCode();
    };

    const handleCouponUsedTotal = (value) => {
        for (let i = 0; i < couponTotalUsed.length; i++) {
            const values = couponTotalUsed[i].split(':')[0].trim();
            if (value === values) {
                const finalValue = couponTotalUsed[i].split(':')[1].trim();
                return finalValue;
            }
        }
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='offers_head'>
                                        <div className='form-row'>
                                            <Link className='offer_button' to='/addoffers'>Create Coupons</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='col'>
                                                <label htmlFor='name'>Coupon Status </label>
                                                <select
                                                    className='form-control mb-3'
                                                >
                                                    <option value='all'>Live</option>
                                                    <option value='true'>Offline</option>
                                                </select>
                                            </div>
                                            {/* <div className='col'>
                                                <label htmlFor='name'>Coupon Title</label>
                                                <input
                                                    // onChange={(e) => setTeam(e.target.value)}
                                                    type='text'
                                                    // value={team_name}
                                                    className='form-control'
                                                    placeholder='Search By Coupon Title'
                                                />
                                            </div> */}
                                        </div>
                                        <div>
                                            <div className='form-row'>
                                                <div className='col'>
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        value="search"
                                                        href='#'
                                                        // onClick={(e) => Filter(e)}
                                                        type='button'
                                                        className='btn btn-primary'
                                                    // disabled={isLoading ? true : false}
                                                    >
                                                        Search
                                                    </button>
                                                    &nbsp;
                                                    <input
                                                        type='reset'
                                                        className='btn btn-danger'
                                                        onClick={handleReset}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        {/* <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                            </div>
                        </div> */}
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    {/* <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>View All Coupons</h4>
                                        </div>
                                    </div> */}
                                    {isLoading && (
                                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                            <div>
                                                <RotatingLines
                                                    marginTop='70'
                                                    strokeColor='#c1030d'
                                                    strokeWidth='5'
                                                    animationDuration='0.75'
                                                    width='50'
                                                    visible={true}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className='card-body'>
                                        <div
                                            className="table-editable" id="table"
                                        >
                                            <div className='table-responsive'>
                                                <table className='table  table-striped table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Coupon Code</th>
                                                            <th>Active</th>
                                                            <th>Min-Max</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Description</th>
                                                            <th>Overall Used</th>
                                                            <th>Max Limit Per User</th>
                                                            <th>Amount Type</th>
                                                            <th>Coupon Value</th>
                                                            <th>Status</th>
                                                            <th>Edit Coupon</th>

                                                        </tr>
                                                    </thead>
                                                    {!isLoading && <tbody>
                                                        {couponData.length > 0 && couponData.slice(0, 50).map((coupon, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{++i}</td>
                                                                    <td className='OFFERS_COUPON_H1'>{coupon.coupon_code}</td>
                                                                    <td>
                                                                        {
                                                                            coupon.status || dateIs ? <h6 className='offers_live'>Yes</h6> : <h6 className='offers_not_live'>No</h6>
                                                                        }
                                                                    </td>
                                                                    <td>{`₹${coupon.min_amount} - ₹${coupon.max_amount}`}</td>
                                                                    <td>{handleDate(coupon.start_date)}</td>
                                                                    <td>{handleDate(coupon.end_date)}</td>
                                                                    <td>{coupon.description}</td>
                                                                    <td>{handleCouponUsedTotal(coupon.coupon_code)}</td>
                                                                    <td>{coupon.max_limit_per_user}</td>
                                                                    <td>{coupon.amount_type === '1' ? 'Bonus' : 'Unutilized'}</td>
                                                                    <td>{coupon.coupon_value}</td>
                                                                    <td>
                                                                        {
                                                                            <Tooltip
                                                                                color='red'
                                                                                placement="top"
                                                                                title={moment(coupon.end_date).toDate() < moment(new Date()).toDate() ? 'Coupon Expired' : ''}>
                                                                                <button
                                                                                    className={coupon.status === true ? 'offers_status_disabled' : 'offers_status_active'}
                                                                                    onClick={() => handleCouponStatus(coupon.coupon_code, coupon.status, coupon.end_date)}>{coupon.status === true ? 'Disable' : 'Activate'}
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                    </td>
                                                                    <td>{<button
                                                                        title='Edit'
                                                                        type='button'
                                                                        onClick={() =>
                                                                            EditCoupon(
                                                                                coupon.coupon_code,
                                                                                coupon.end_date
                                                                            )
                                                                        }
                                                                        className='offer-edit-button'
                                                                        data-toggle='modal'
                                                                        data-target='#exampleModalScrollable'
                                                                    >
                                                                        Edit
                                                                    </button>}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Edit Modal */}

                        <div
                            className='modal fade'
                            id='exampleModalScrollable'
                            tabIndex='-1'
                            aria-labelledby='exampleModalScrollableTitle'
                            aria-hidden='true'
                        >
                            <div
                                className='modal-dialog modal-dialog-scrollable'
                                role='document'
                            >
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h5
                                            className='modal-title'
                                            id='exampleModalScrollableTitle'
                                        >
                                            Edit Users Detail
                                        </h5>
                                    </div>
                                    <div className='modal-body'>
                                        <form>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Coupon Name</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={editCouponId}
                                                    disabled
                                                />
                                            </div>
                                            <div className='form-group user_div'>
                                                <label htmlFor='name'>Coupon End Date</label>
                                                <p>Previous End Date:- <span className='offers_p'>{handleDate(editCouponEndDate)}</span></p>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={editCouponEndDate}
                                                    onChange={handleAllChange(setNewEditedDate)}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='modal-footer'>
                                        <button
                                            onClick={handleClose}
                                            type='button'
                                            className='btn btn-secondary'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={handleEditSubmit}
                                            data-dismiss='modal'
                                        >
                                            Save changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Edit Modale */}
                    </div>
                </div>
            </div>
        </div>);
}
