import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CreateContestAPI } from '../api/LudoAPI';
import { useDate } from '../Component/DateHook';
import { Modal } from 'antd';
import { RotatingLines } from 'react-loader-spinner';

function LudoContestReport() {

    useEffect(() => {
        allLudoMatchesList();
    }, []);

    const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 7));
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);

    const [contestMatchesData, setContestMatchesData] = useState([]);
    const [playerData, setPlayersData] = useState([]);

    const [matchStatus, setMatchStatus] = useState(3);

    const allLudoMatchesList = () => {
        setIsLoading(true);
        CreateContestAPI.ludoReportAllMatches(startDate, endDate, matchStatus).then((res) => {
            if (res.status_code) {
                setContestMatchesData(res.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                Notiflix.Notify.failure(res.message);
            }
        });
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOpenPlayers = (data) => {
        console.log(data);
        setIsModalOpen(true);
        setPlayersData(data);
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        allLudoMatchesList();
    };

    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>Ludo Contest Reports</h4>
                            </div>
                        </div>

                        <>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-row'>

                                                <div>
                                                    <div className='col text-center ml-2 mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>Start Date</label>
                                                        <input
                                                            type='date'
                                                            value={startDate}
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className='col text-center ml-2 mr-2 font-weight-bold'>
                                                        <label htmlFor='name'>End Date</label>
                                                        <input
                                                            type='date'
                                                            value={endDate}
                                                            onChange={(e) => setEndDate(e.target.value)}
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>


                                                <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        className='btn btn-primary'
                                                        type='button'
                                                        onClick={() => allLudoMatchesList()}
                                                    >
                                                        Search
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        type='reset'
                                                        onClick={handleReset}
                                                        className='btn btn-danger'
                                                    >Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                        <>
                            <h4 style={{ marginLeft: 16, fontWeight: 'bolder' }}>Total Matches:- {contestMatchesData.length}</h4>
                        </>

                        {
                            isLoading ? <div className='col-sm-12 col-lg-12'>
                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                    <RotatingLines
                                        marginTop='40'
                                        strokeColor='#c1030d'
                                        strokeWidth='5'
                                        animationDuration='0.75'
                                        width='50'
                                        visible={true}
                                    />
                                </div>
                            </div> :
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <span>
                                            <table className='table  table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Contest Id</th>
                                                        <th>Status</th>
                                                        <th>Created Date</th>
                                                        <th>Open Spots</th>
                                                        <th>Winners</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contestMatchesData &&
                                                        contestMatchesData.length > 0 &&
                                                        contestMatchesData.map((name, ind) => {
                                                            return (
                                                                <tr>
                                                                    <td>{++ind}</td>
                                                                    <td>{name.contestId}</td>
                                                                    <td style={name.status === 3 ? { backgroundColor: 'green', color: 'white' } : { backgroundColor: 'red', color: 'white' }}>{name.status === 3 ? 'Completed' : 'Cancelled'}</td>
                                                                    <td>{useDate(name.createdAt)}</td>
                                                                    <td>{name.openSpots}</td>
                                                                    <td>
                                                                        <button onClick={() => handleOpenPlayers(name.players)}>See</button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </span>
                                    </div>

                                    {
                                        contestMatchesData.length === 0 && <p style={{
                                            color: 'black', fontWeight: 'bold', alignItems: 'center', justifyContent: 'center', marginLeft: '40%'
                                        }}>No Data with this date found</p>
                                    }

                                </div>
                        }


                    </div>
                </div>


                <Modal width={800} title="All Participants" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <div className='table-responsive'>
                        <span>
                            <table className='table  table-striped table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>User Id</th>
                                        <th>Team Name</th>
                                        <th>Left the Match</th>
                                        <th>Transaction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {playerData &&
                                        playerData.length > 0 &&
                                        playerData.map((name, ind) => {
                                            return (
                                                <tr key={ind}>
                                                    <td>{name.position}</td>
                                                    <td>{name.userId}</td>
                                                    <td>{name.team_name}</td>
                                                    <td>{name.isDeleted ? 'YES' : 'NO'}</td>
                                                    <td><Link
                                                        to={'/ludoPlayerTransaction'}
                                                        state={{
                                                            matchId: name._id,
                                                            userId: name.userId,
                                                            teamName: name.team_name,
                                                            position: name.position
                                                        }}
                                                        type='submit'
                                                        className='btn btn-primary btn-sm'
                                                    >Transaction</Link></td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </span>
                    </div>
                </Modal>
            </div>
        </div >
    );
}

export default LudoContestReport;
