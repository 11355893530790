import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QuizAPI } from '../api/quizApi';
import Notiflix from 'notiflix';

export default function QuizContestRankPage() {
    const location = useLocation();

    const [TotalWinningAmount, setTotalWinningAmount] = useState(location.state.TotalWinningAmount);
    const [JoiningFees, setJoiningFees] = useState(location.state.JoiningFees);
    const [MaxSpots, setMaxSpots] = useState(location.state.MaxSpots);
    const [TotalWinners, setTotalWinners] = useState(location.state.TotalWinners);
    const [ContestCategoryName, setContestCategoryName] = useState(
        location.state.ContestCategoryName
    );
    const [Status, setStatus] = useState(location.state.Status);
    const [Bonus, setBonus] = useState(location.state.Bonus);
    const [IsGuranted, setIsGuranted] = useState(location.state.isGuranted);
    const [QuizContestId, setQuizContestId] = useState(location.state.QuizContestId);
    const [QuizCatId, setQuizCatId] = useState(location.state.QuizCatId);

    const [RankList, setRankList] = useState([]);
    const [rank, setRank] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        // alert(JSON.stringify(location.state))
        getContestRank();
    }, []);

    const getContestRank = () => {
        QuizAPI.GetQuizContestDetail(QuizContestId, QuizCatId).then((data) => {
            if (data.Status) {
                setRankList(data.Data[0].WinningRank);
                // alert(data.Data[0].WinningRank)
            }
        });
    };

    const rankChange = (e) => {
        setRank(e.target.value);
    };
    const amountChange = (e) => {
        setAmount(e.target.value);
    };
    const submitBtn = () => {
        if (rank == '') {
            Notiflix.Notify.failure('Rank can\'t be blank');
        } else if (amount == '') Notiflix.Notify.failure('Amount can\'t be blank');
        else {
            let contestDetail = {
                QuizContestId: QuizContestId,
                WinningRank: [
                    {
                        Rank: rank,
                        Amount: amount,
                    },
                ],
            };
            // alert(JSON.stringify(contestDetail))
            QuizAPI.CreateQuizContestDetail(contestDetail).then((data) => {
                Notiflix.Notify.success(data.Message);
                reset();
                getContestRank();
            });
        }
    };
    const reset = () => {
        setRank('');
        setAmount('');
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_all_quiz_contest'}
                                        className='mt-2 btn btn-skyblue float-right'
                                    >
                                        {' '}
                                        <i className='ri-arrow-left-line'></i> Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p>
                                                <strong>Contest Category</strong> :{' '}
                                                {ContestCategoryName}
                                            </p>
                                            <p>
                                                <strong> Winning Amount</strong> :{' '}
                                                {TotalWinningAmount}
                                            </p>
                                            <p>
                                                <strong>Entry Fees</strong> : {JoiningFees}
                                            </p>
                                            <p>
                                                <strong>Maximum users</strong> : {MaxSpots}
                                            </p>
                                            <p>
                                                <strong>Total Winners</strong> : {TotalWinners}
                                            </p>
                                        </div>

                                        <div className='col-md-6'>
                                            <p>
                                                <strong>Quiz Status</strong> : {Status}
                                            </p>
                                            <p>
                                                <strong>Guranted Contest</strong> :{' '}
                                                {IsGuranted.toString()}
                                            </p>
                                            <p>
                                                <strong>Bonus</strong> : {Bonus}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add Rank wise Amount</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Rank Position :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={rank}
                                                onChange={rankChange}
                                                id=''
                                                placeholder='5-10'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Price Amount in Rupees :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={amount}
                                                onChange={amountChange}
                                                defaultValue=''
                                                placeholder='Enter Price Amount in Rupees'
                                            />
                                        </div>
                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={submitBtn}
                                        >
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button
                                            type='button'
                                            className='btn bg-danger'
                                            onClick={reset}
                                        >
                                            Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Rank Position</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {RankList.map((item, ind) => (
                                                    <tr>
                                                        <td>{++ind}</td>
                                                        <td>{item.Rank}</td>
                                                        <td>{item.Amount}</td>
                                                        {/* <td>                                  
                                    <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i>Delete</button>
                                </td> */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
