import { Link } from 'react-router-dom';

export default function AddNewQuestionPage() {
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <Link
                                    to={'/view_all_question'}
                                    className='button btn btn-skyblue button-icon'
                                >
                                    <i className='ml-2 ri-add-line'></i>View All Question
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Add New Question</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Add Question</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Match Name '
                                            defaultValue="Who was the India's first woman president?"
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Option A :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Option A'
                                            defaultValue='Indira Gandhi'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Option B :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Option B'
                                            defaultValue='Pratibha Patil'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Option C :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Option C'
                                            defaultValue='Sushma Swaraj'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Option D :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Option D'
                                            defaultValue='Mamta Banerjee'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Right Answer :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Right Answer'
                                            defaultValue='Option B'
                                        />
                                    </div>

                                    <button type='submit' className='btn btn-primary'>
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
