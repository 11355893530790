import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const RoyaltyPointsApi = {
    UpdateRoyalPoint: async (id, point) => {
        //alert()
        return await fetch(baseURL + 'UpdateRoyalPoint?Id=' + id + '&PointBonus=' + point, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(json),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                //  alert("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetRoyalPoint: async () => {
        //alert()
        return await fetch(baseURL + 'GetRoyalPoint', {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    CreateRoyalPointCategory: async (jsonbody) => {
        //alert()
        //  console.log(JSON.stringify(jsonbody))
        return await fetch(baseURL + 'CreateRoyalPointCategory', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
