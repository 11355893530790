import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ContestAPI } from '../api/contestApi';
import Notiflix from 'notiflix';
import { SeriesAPI } from '../api/seriesApi';

export default function EditContestCategoryPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state ? location.state.id : '';
    const [catName, setCatName] = useState(location.state.CatName);
    const [subtitle, setSubtitle] = useState(location.state.subtitle);
    const [fantasyList, setFantasyList] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFantasy();
    }, []);

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
            console.log(fantasyList);
        });
    };

    const CategoryNameChange = (e) => {
        setCatName(e.target.value);
    };

    const CategorySubtitleChange = (e) => {
        setSubtitle(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            ContestCatId: id,
            ContestCategoryName: catName,
            SubTitle: subtitle,
        };

        ContestAPI.EditContestCategory(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Contest Edited successfully');
                navigate('/view_contest_category');
            } else {
                Notiflix.Notify.failure('Error Try again!!!');
                // navigate("/view_contest_category");
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <Link
                                    to={'/view_contest_category'}
                                    className='button btn btn-skyblue button-icon'
                                >
                                    <i className='ml-2 ri-eye-fill'></i> View All Contest Categories
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Edit Contest Category</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Select Fantasy :</label>
                                        <select className='form-control mb-3'>
                                            <option selected value=''>
                                                Open this select menu
                                            </option>
                                            {fantasyList.map((fan) => (
                                                <option value={fan.fantasy}>{fan.fantasy}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='text'>Contest Category Name :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id=''
                                            value={catName}
                                            onChange={CategoryNameChange}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='text'>Sub Title :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={subtitle}
                                            onChange={CategorySubtitleChange}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='text'>Contest Order :</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            defaultValue='1'
                                        />
                                    </div>
                                    {/* <div className="form-group">
                    <label htmlFor="text">Image</label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                  </div> */}
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                    &nbsp;
                                    <button type='submit' className='btn bg-danger'>
                                        Reset
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
