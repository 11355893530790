import React, { useState, useEffect } from 'react';
import { ReactDOM } from 'react-dom';
import { SeriesAPI } from '../api/seriesApi';
import { ScoreBoardAPI } from '../api/scoreBoardAPI';
import swal from 'sweetalert';
import Notiflix from 'notiflix';

export function CreateScoreBoard() {
    const [format, setFormat] = useState('');
    const [match_format, setMatchFormat] = useState('');
    const [starting11, setStarting11] = useState('');
    const [runs, setRuns] = useState('');
    const [four, setFour] = useState('');
    const [six, setSix] = useState('');
    const [duck, setDuck] = useState('');
    const [wicket, setWicket] = useState('');
    const [LBW_bonus, setLbw] = useState('');
    const [bowled_bonus, setBowled] = useState('');
    const [maiden_bonus, setMaiden] = useState('');
    const [maiden_over, setMaidenOver] = useState('');
    const [per_catch, setPer] = useState('');
    const [stumping, setStumping] = useState('');
    const [runout, setRunout] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFormat();
    }, []);

    const scoreBoardAdd = () => {
        //match_format,starting11,runs,four,six,duck,wicket,LBW_bonus,bowled_bonus,maiden_bonus,maiden_over,per_catch,stumping,runout

        // alert(document.getElementById('bowled').value)

        let scoreData = {
            match_format: match_format,
            starting11: starting11,
            runs: runs,
            four: four,
            six: six,
            duck: duck,
            wicket: wicket,
            LBW_bonus: LBW_bonus,
            bowled_bonus: bowled_bonus,
            maiden_bonus: maiden_bonus,
            maiden_over: maiden_over,
            per_catch: per_catch,
            stumping: stumping,
            runout: runout,
        };
        // alert(JSON.stringify(scoreData))
        ScoreBoardAPI.AddScoreBoard(scoreData).then((data) => {
            //   (JSON.stringify(data))
            // setData(data);
            console.log(data);
            Notiflix.Notify.success('Scoreboard Created Successfully');
        });
    };
    const GetFormat = () => {
        ScoreBoardAPI.FetchMatchFormat().then((data) => {
            console.log(data);
            if (data.status_code) setFormat(data.message);
        });
    };

    const startingChange = (e) => {
        setStarting11(e.target.value);
    };

    const matchChange = (e) => {
        setMatchFormat(e.target.value);
    };

    const runsChange = (e) => {
        setRuns(e.target.value);
    };

    const fourChange = (e) => {
        setFour(e.target.value);
    };

    const sixChange = (e) => {
        setSix(e.target.value);
    };

    const duckChange = (e) => {
        setDuck(e.target.value);
    };

    const wicketChange = (e) => {
        setWicket(e.target.value);
    };

    const lbwChange = (e) => {
        setLbw(e.target.value);
    };

    const bowledChange = (e) => {
        setBowled(e.target.value);
    };

    const maidenOverChange = (e) => {
        setMaidenOver(e.target.value);
    };

    const maidenBonusChange = (e) => {
        setMaiden(e.target.value);
    };

    const perCatchChange = (e) => {
        setPer(e.target.value);
    };

    const stumpingChange = (e) => {
        setStumping(e.target.value);
    };

    const runOutChange = (e) => {
        setRunout(e.target.value);
    };
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Create New Scoreboard</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form className='row'>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='name'>Match Format :</label>
                                        {/* <input id="match" value={match_format} onChange={matchChange} type="text" className="form-control" /> */}
                                        <select
                                            className='form-control mb-3'
                                            onChange={matchChange}
                                        >
                                            <option selected value=''>
                                                Select Match Format
                                            </option>
                                            {format &&
                                                format.length > 0 &&
                                                format.map((format) => (
                                                    <option value={format.format}>
                                                        {format.format}
                                                    </option>
                                                ))}
                                            {/* <option value="T10">T10</option>
                                            <option value="T20">T20</option>
                                            <option value="T50">T50</option> */}
                                            {/* {fantasyList.map((fan)=>(
                        <option value={fan.fantasy}>{fan.fantasy}</option>
                        ))} */}
                                        </select>
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Starting 11 :</label>
                                        <input
                                            id='starting'
                                            value={starting11}
                                            onChange={startingChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Runs :</label>
                                        <input
                                            value={runs}
                                            onChange={runsChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Four :</label>
                                        <input
                                            value={four}
                                            onChange={fourChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Six :</label>
                                        <input
                                            value={six}
                                            onChange={sixChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Duck :</label>
                                        <input
                                            value={duck}
                                            onChange={duckChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Wickets :</label>
                                        <input
                                            value={wicket}
                                            onChange={wicketChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>LBW Bonus :</label>
                                        <input
                                            value={LBW_bonus}
                                            onChange={lbwChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Maiden Overs :</label>
                                        <input
                                            value={maiden_over}
                                            onChange={maidenOverChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Maiden Bonus :</label>
                                        <input
                                            value={maiden_bonus}
                                            onChange={maidenBonusChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Per Catch :</label>
                                        <input
                                            value={per_catch}
                                            onChange={perCatchChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Stumping :</label>
                                        <input
                                            value={stumping}
                                            onChange={stumpingChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Runout :</label>
                                        <input
                                            value={runout}
                                            onChange={runOutChange}
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='form-group col-sm-3'>
                                        <label htmlFor='text'>Bowled Bonus :</label>
                                        <input
                                            value={bowled_bonus}
                                            onChange={bowledChange}
                                            id='bowled'
                                            type='text'
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-sm-12'>
                                        <button
                                            onClick={() => scoreBoardAdd()}
                                            type='button'
                                            className='btn btn-secondary'
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
