import { Image, Tooltip, Upload, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { IoIosImages } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';
import { SupportAPI } from '../api/Support';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Notiflix from 'notiflix';
import { AllUsersAPI } from '../api/allUsers';

const { Dragger } = Upload;

const ChatScreen = (props) => {
    const { ticketData, updateTicketData } = props;

    const [update, setUpdate] = useState([]);
    const [replyData, setReplyData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    console.log(replyData);
    console.log(ticketData);

    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        var newTime = date?.slice(11, 18);
        var updateTime = moment(newTime, 'HH:mm:ss').format('hh:mm A');
        return updateTime;
    };

    const chatContainerRef = useRef(null);

    useEffect(() => {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }, []);

    const returnCurrentDateTime = () => {
        var currentDate = new Date();
        var day = currentDate.getDate();
        var month = currentDate.getMonth() + 1;
        var year = currentDate.getFullYear();
        var hours = currentDate.getHours();
        var minutes = currentDate.getMinutes();
        var seconds = currentDate.getSeconds();

        if (day < 10) { day = '0' + day; }
        if (month < 10) { month = '0' + month; }
        if (hours < 10) { hours = '0' + hours; }
        if (minutes < 10) { minutes = '0' + minutes; }
        if (seconds < 10) { seconds = '0' + seconds; }

        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    };

    // Message Sent
    const sentMessage = async () => {
        setIsLoading(true);

        const sourceForReply = 'App';
        const RoleForReply = 'Admin';


        if (replyData.trim().length <= 0) {
            Notiflix.Notify.warning('Please type a message before sending.');
            setIsLoading(false);
        } else {
            try {
                const formData = new FormData();
                formData.append('user_id', ticketData.user_id);
                formData.append('case_number', ticketData.case_number);
                formData.append('body', replyData);
                formData.append('case_type', ticketData.case_type);
                formData.append('source', sourceForReply);
                formData.append('created_by', RoleForReply);

                // console.log(selectedImages);

                selectedImages.forEach((image) => {
                    formData.append('files', image.originFileObj);
                });

                const response = await SupportAPI.CreateTicket(formData);

                if (response.status_code) {
                    const newComment = {
                        user_id: ticketData.user_id,
                        case_number: ticketData.case_number,
                        body: replyData,
                        source: 'App',
                        created_by: 'Admin',
                        returnCurrentDateTime: selectedImages.map(image => image.originFileObj),
                        createdAt: returnCurrentDateTime(),
                    };

                    Notiflix.Notify.success('Message sent successfully.');

                    setReplyData('');
                    setSelectedImages([]);

                } else {
                    Notiflix.Notify.failure(response.message || 'Failed to send message.');
                }

            } catch (error) {
                console.error('Error sending message:', error);
                Notiflix.Notify.failure('An error occurred while sending the message.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const [usersDetailForChatScreen, setUsersDetailForChatScreen] = useState('');

    const userInfo = () => {
        AllUsersAPI.GetUserInfo(ticketData.user_id).then((res) => {
            if (res.status_code) {
                setUsersDetailForChatScreen(res.data[0]);
                console.log(res);
            } else {
                Notiflix.Notify.failure('No User Found');
            }
        });
    };

    useEffect(() => {
        userInfo();
    }, [ticketData]);

    const onImageChange = (info) => {
        const fileList = info.fileList.slice(0, 40);
        const fileUIDs = fileList.map(file => file.uid);
        setSelectedImages(fileList);
    };

    const handleCancelSelectedImages = () => {
        setSelectedImages([]);
    };

    return (
        <div style={{ width: '100%', maxWidth: '800px', margin: 'auto', marginTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>

                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <h5 style={{ fontWeight: 700, marginRight: '8px' }}>Ticket Last Updated at</h5>
                    <h5 style={{ fontWeight: 'bolder', color: 'green', backgroundColor: 'lightgreen', padding: '4px 8px', borderRadius: '6px' }}>
                        {`${handleDateTime(ticketData.updatedAt)} - ${handleDate(ticketData.updatedAt)}`}
                    </h5>
                </div> */}

            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 6, alignItems: 'center', marginBottom: 16 }}>
                <h1 style={{ fontSize: 24, fontWeight: '600', color: 'black' }}>Subject:</h1>
                <h1 style={{ fontSize: 24, fontWeight: '300', color: 'black' }}>{ticketData.subject}</h1>
            </div>

            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #C1C1C1', padding: 16, borderRadius: 6, marginBottom: 8
            }}>

                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 22
                }}>
                    <Tooltip title='Profile Image'>
                        <Image
                            style={{
                                borderRadius: 40, height: 48, width: 48
                            }}
                            width={42}
                            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        />
                    </Tooltip>
                    <Tooltip title='Team Name'>
                        <h6 style={{ fontWeight: 'bold', fontSize: 18 }}>{usersDetailForChatScreen?.team_name} - [{usersDetailForChatScreen?.phone}]</h6>
                    </Tooltip>
                </div>

                <div style={{ backgroundColor: '#FFB700', padding: 6, borderRadius: 4 }}>
                    <Tooltip title='Ticket Status'>
                        <h6 style={{ fontSize: 18, textAlign: 'center', color: 'black' }}>{ticketData.status}</h6>
                    </Tooltip>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{
                            backgroundColor: '#D3D3D3',
                            color: 'black',
                            padding: 6,
                            borderRadius: 4,
                            marginLeft: 10,
                            cursor: 'pointer',
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <h5 style={{ fontWeight: 700 }}>Case Number:</h5>
                                <h5 style={{ fontWeight: 900, color: 'darkblue' }}>{ticketData?.case_number}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Chat Screen Messages */}
            <div
                ref={chatContainerRef}
                style={{
                    height: '500px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '20px',
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                {/* Render chat messages here */}

                {update.length > 0 ?

                    update?.map((comment, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: comment.created_by === 'Admin' ? '70px' : '0',
                                marginBottom: '14px',
                                order: comment.createdAt,
                                alignItems: comment.created_by === 'Admin' ? 'flex-end' : 'flex-start',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: comment.created_by === 'Admin' ? '#E8E8E8' : '#FFE284',
                                    borderRadius: comment.created_by === 'Admin' ? '30px 0px 30px 30px' : '0px 30px 30px 30px',
                                    padding: 20,
                                    maxWidth: '70%',
                                }}
                            >
                                <p style={{ margin: 0, fontWeight: 600, fontSize: 24, color: 'black' }}>{comment.body}</p>
                                {comment.attachments && comment.attachments.length > 0 && (
                                    <div>

                                        {comment.attachments.map((attachment, attachmentIndex) => (
                                            <p key={attachmentIndex}>
                                                <Image src={attachment} width={100} alt={`Attachment ${attachmentIndex + 1}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            </p>
                                        ))}

                                    </div>
                                )}
                                <p style={{ fontSize: '12px', marginTop: '5px', alignSelf: 'flex-end' }}>{handleDate(comment.createdAt)}</p>
                            </div>
                        </div>
                    ))
                    :
                    ticketData?.comments.map((comment, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: comment.created_by === 'Admin' ? '70px' : '0',
                                marginBottom: '14px',
                                order: comment.createdAt,
                                alignItems: comment.created_by === 'Admin' ? 'flex-end' : 'flex-start',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: comment.created_by === 'Admin' ? '#E8E8E8' : '#FFE284',
                                    borderRadius: comment.created_by === 'Admin' ? '30px 0px 30px 30px' : '0px 30px 30px 30px',
                                    padding: 20,
                                    maxWidth: '70%',
                                }}
                            >
                                <p style={{ margin: 0, fontWeight: 600, fontSize: 24, color: 'black' }}>{comment.body}</p>
                                {comment.attachments && comment.attachments.length > 0 && (
                                    <div>

                                        {comment.attachments.map((attachment, attachmentIndex) => (
                                            <p key={attachmentIndex}>
                                                <Image src={attachment} width={100} alt={`Attachment ${attachmentIndex + 1}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                            </p>
                                        ))}

                                    </div>
                                )}
                                <p style={{ fontSize: '12px', marginTop: '5px', alignSelf: 'flex-end' }}>{handleDate(comment.createdAt)}</p>
                            </div>
                        </div>
                    ))}
            </div>

            <div style={{
                marginTop: 8, marginBottom: 8
            }}>
                {selectedImages.length > 0
                    ?
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8
                    }}>
                        <h6 style={{
                            fontWeight: 700, fontSize: 18, color: 'black',
                        }}>{selectedImages.length} {selectedImages.length > 1 ? 'Images' : 'Image'} Selected</h6>
                        <MdCancel size={28} onClick={handleCancelSelectedImages} style={{ cursor: 'pointer' }} />
                    </div>
                    :
                    ''
                }
            </div>

            {/* ChatScreen Messages */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14, alignItems: 'center', gap: 8 }}>
                <input
                    value={replyData}
                    onChange={(e) => setReplyData(e.target.value)}
                    placeholder='Write your message here'
                    style={{ flex: '1', height: '60px', borderRadius: '24px', padding: '14px', color: 'black', fontWeight: 500, fontSize: 18 }}
                />
                <Tooltip title='Send Images'>
                    <Dragger
                        showUploadList={false}
                        customRequest={() => { }}
                        onChange={onImageChange}
                        multiple
                        accept="image/*"
                    >
                        <IoIosImages style={{ fontSize: 26, alignItems: 'center' }} />
                    </Dragger>
                </Tooltip>
                <Tooltip title='Send Message'>
                    <button
                        disabled={isLoading ? true : false}
                        onClick={() => sentMessage()} style={{ padding: '17px', cursor: 'pointer', backgroundColor: 'black', color: 'white', borderRadius: '24px', border: 'none' }}>
                        {isLoading ?
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 12,
                                            color: 'white'
                                        }}
                                        spin
                                    />
                                }
                            />
                            : 'Send'}
                    </button>
                </Tooltip>
            </div>
        </div>
    );
};

export default ChatScreen;
