import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FetchAllPlayerAPI } from '../api/viewAllPlayerApi';
import { RotatingLines } from 'react-loader-spinner';

function ViewPlayerPage() {
    const [player, setPlayer] = useState([]);
    const [player_name, setPlayerName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const role = localStorage.getItem('role');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        playerInfo();
        // playerFilter();
        // editPlayer();
    }, []);
    const playerInfo = () => {
        FetchAllPlayerAPI.FetchAllPlayer().then((data) => {
            var playerInfo_i = JSON.parse(JSON.stringify(data));
            console.log(playerInfo_i.data);
            setPlayer(playerInfo_i.data);

            setIsLoading(false);
        });
    };
    // const editPlayer = () => {
    //     FetchAllPlayerAPI.EditPlayer().then(data => {
    //         var edit_info = JSON.parse(JSON.stringify(data))
    //         // console.log(edit_info)
    //         setPlayer(edit_info);
    //     })
    // }

    const playerFilter = () => {
        FetchAllPlayerAPI.FilterPlayer(player_name).then((data) => {
            var editInfo_i = JSON.parse(JSON.stringify(data));
            // console.log(editInfo_i.message.player_name);
            setPlayer(editInfo_i.message);
        });
    };
    const nameChange = (e) => {
        setPlayerName(e.target.value);
    };

    const Reset = () => {
        setPlayerName('');
        playerInfo();
    };
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Player Role</label>
                                                <select className='form-control mb-3'>
                                                    <option select=''>Select Role</option>
                                                    <option value='1'>Batman</option>
                                                    <option value='2'>wicket keeper</option>
                                                    <option value='4'>All Rounder</option>
                                                    <option value='3'>Bowler</option>
                                                </select>
                                            </div>
                                            <div>
                                                <div className='col'>
                                                    <label htmlFor='name'>Player Name </label>
                                                    <input
                                                        value={player_name}
                                                        onChange={nameChange}
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Search Players'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Team Name </label>
                                                <select className='form-control mb-3'>
                                                    <option select=''>Select Team name</option>
                                                    <option value='1'>Chennai Super King</option>
                                                    <option value='2'>Kolkata Knight Riders</option>
                                                    <option value='3'>Delhi Capitals</option>
                                                </select>
                                            </div>
                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button
                                                    onClick={() => playerFilter()}
                                                    type='button'
                                                    className='btn btn-primary'
                                                >
                                                    Search
                                                </button>
                                                &nbsp;
                                                <input
                                                    type='reset'
                                                    onClick={() => Reset()}
                                                    className='btn bg-danger'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    {isLoading && isLoading && (
                        <div className='col-sm-12 col-lg-12'>
                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                <RotatingLines
                                    marginTop='40'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}
                    {!isLoading && (
                        <div className='row'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    <div className='navbar-breadcrumb'>
                                        <h4 className='mb-0'>&nbsp;</h4>
                                    </div>
                                    <div className=''>
                                        <div className='btn-group m-2' role='group'>
                                            <button
                                                id='btnGroupDrop1'
                                                type='button'
                                                className='btn btn-primary dropdown-toggle'
                                                data-toggle='dropdown'
                                                aria-haspopup='true'
                                                aria-expanded='false'
                                            >
                                                Export
                                            </button>
                                            <div
                                                className='dropdown-menu'
                                                aria-labelledby='btnGroupDrop1'
                                                style={{}}
                                            >
                                                <button className='dropdown-item' href='#'>
                                                    Export All data
                                                </button>
                                                <button className='dropdown-item' href='#'>
                                                    Export This Data
                                                </button>
                                                {/* <a className="dropdown-item" href="#">Delete</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>View All Players</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable'>
                                            <div className='table-responsive'>
                                                <table
                                                    // id="datatable"
                                                    className='table  table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            {/* <th>Unique Id</th> */}
                                                            <th>Player Name</th>
                                                            <th>Player Key</th>
                                                            <th>Role</th>
                                                            <th>Credit</th>
                                                            <th>Image</th>
                                                            {role!=3 && role!=5 && <th>Action</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {player &&
                                                            player.length > 0 &&
                                                            player.map((player, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{++i}</td>
                                                                        {/* <td>R2346</td> */}
                                                                        <td>{player.short_name}</td>
                                                                        <td>{player.pid}</td>
                                                                        <td>
                                                                            {player.playing_role}
                                                                        </td>
                                                                        <td
                                                                            suppressContentEditableWarning='true'
                                                                            contentEditable='true'
                                                                        >
                                                                            {
                                                                                player.fantasy_player_rating
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <img
                                                                                src='/assets/images/cricket-team/ruturaj.jpg'
                                                                                style={{
                                                                                    borderRadius:
                                                                                        '5px',
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        {role!=3 && role!=5 && (
                                                                            <td>
                                                                                <Link
                                                                                    to={
                                                                                        '/edit_player'
                                                                                    }
                                                                                    state={{
                                                                                        pid: player.player_key,
                                                                                        credit: player.credit,
                                                                                        player_name:
                                                                                            player.player_name,
                                                                                        role: player.role,
                                                                                    }}
                                                                                    title='Edit'
                                                                                    className='btn btn-primary btn-sm'
                                                                                >
                                                                                    <i className='ri-edit-line'></i>
                                                                                </Link>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='modal fade'
                                id='exampleModalScrollable'
                                tabIndex='-1'
                                role='dialog'
                                aria-labelledby='exampleModalScrollableTitle'
                                aria-hidden='true'
                            >
                                <div
                                    className='modal-dialog modal-dialog-scrollable'
                                    role='document'
                                >
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h5
                                                className='modal-title'
                                                id='exampleModalScrollableTitle'
                                            >
                                                Edit Players
                                            </h5>
                                            <button
                                                type='button'
                                                className='close'
                                                data-dismiss='modal'
                                                aria-label='Close'
                                            >
                                                <span aria-hidden='true'>&times;</span>
                                            </button>
                                        </div>
                                        <div className='modal-body'>
                                            <form>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Player Name :</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Player Name'
                                                        defaultValue='Ruturaj Gaikwad'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Player Key :</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Player Key'
                                                        defaultValue='90363'
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Role :</label>
                                                    <select className='form-control mb-3'>
                                                        <option select=''>Select Role</option>
                                                        <option value='1'>Batman</option>
                                                        <option value='2'>wicket keeper</option>
                                                        <option value='3'>Bowler</option>
                                                    </select>
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Credit :</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder='Credit'
                                                        defaultValue='9'
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div className='modal-footer'>
                                            <button
                                                type='button'
                                                className='btn btn-secondary'
                                                data-dismiss='modal'
                                            >
                                                Close
                                            </button>
                                            <button type='button' className='btn btn-primary'>
                                                Save changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default ViewPlayerPage;
