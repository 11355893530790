import React, { useEffect, useState } from 'react';

export default function RoleMaster() {
    const data = ['Account', 'Manager', 'Developer', 'Account Manager'];
    const [roleName, setRoleName] = useState(data);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Role Master</h4>
                                    </div>
                                </div>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputEmail1'>Role </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='exampleInputEmail1'
                                            aria-describedby='emailHelp'
                                            placeholder='Enter email'
                                        />
                                    </div>

                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                </form>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col'>#</th>
                                                            <th scope='col'>Role Name</th>
                                                            <th scope='col'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope='row'>1</th>
                                                            <td>Account</td>
                                                            <td>
                                                                <button className='btn-success btn'>
                                                                    Edit
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th scope='row'>2</th>
                                                            <td>Manager</td>
                                                            <td>
                                                                <button className='btn-success btn'>
                                                                    Edit
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope='row'>3</th>
                                                            <td>Developer</td>
                                                            <td>
                                                                <button className='btn-success btn'>
                                                                    Edit
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th scope='row'>4</th>
                                                            <td>Account Manager</td>
                                                            <td>
                                                                <button className='btn-success btn'>
                                                                    Edit
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
