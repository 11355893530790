import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');
import { BASEAPIS } from './BaseApis';

export const UsersAPI = {

    GetFeedback: async (user_id, email, phone, team_name, startDate, endDate) => {
        //alert()
        return await fetch(baseURL + 'fetchAllFeedback?email=' + email + '&phone=' + phone + '&user_id=' + user_id +
            '&team_name=' + team_name + '&startDate=' + startDate + '&endDate=' + endDate, {
            method: 'GET',
            headers: {  
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    WalletReport: async () => {
        //alert()
        return await fetch(baseURL + 'userWalletReport', {
            method: 'GET',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    FilterFeedback: async (user_id, email, phone, team_name, startDate, endDate) => {
        //alert()
        return await fetch(baseURL + 'userWalletReport?team_name=' + team_name + '&email=' + email + '&phone=' + phone + '&user_id=' + user_id, {
            method: 'GET',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
    FilterWalletReport: async (user_id, email, phone, teamName) => {
        //alert()
        return await fetch(baseURL + 'userWalletReport?user_id=' + user_id + '&email=' + email + '&phone=' + phone + '&teamName=' + teamName, {
            method: 'GET',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },


    GetUserWalletDataByUserId: async (jsonbody) => {
        console.log(jsonbody);
        //alert()
        return await fetch(baseURL + 'getUserWalletDataByUserId', {
            method: 'POST',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody)
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },


    UpdateUserImage: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'updateImgFrommDashboard', {
            method: 'POST',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody)
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },
    UserTransactions: async (userId, entries, pageNumber, fromDate, toDate, itemSelection, lowOrHigh) => {
        return await fetch(baseURL + 'userTransaction?user_id=' + userId + '&entries=' + entries + '&pagenumber=' + pageNumber + '&start=' + fromDate + '&end=' + toDate + '&sortKey=' + itemSelection + '&sort=' + lowOrHigh, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            }
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    AllUserTransactions: async (userid) => {
        return await fetch(baseURL + 'userTransactionDownload?user_id=' + userid, {
            method: 'GET',
            headers: {  // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => { //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                }
                else
                    return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    GetUserTransactionInContest: (contest_id, user_id, matchId) => {
        console.log(contest_id, user_id, matchId);
        return BASEAPIS.GETAPI('getUserTransactionInContest?contest_id=' + contest_id + '&user_id=' + user_id + '&match_id=' + matchId);
    },

};