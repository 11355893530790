import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MatchAPI } from '../api/matchApi';
export default function UpdatePlayingXi() {

    const location = useLocation();
    const [matchId, setMatchId] = useState('');
    const [matches, setMatches] = useState([]);
    const [teamA, setTeamA] = useState([]);
    const [dummyA, setDummyA] = useState([]);
    const [dummyB, setDummyB] = useState([]);
    const [teamB, setTeamB] = useState([]);
    const [updatedXI, setUpdatedXI] = useState([]);
    const [ApiMatchId, setApiMatchId] = useState([]);
    const [cid, setCid] = useState([]);

    const [isLessThanEleven, setIsLessThanEleven] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetMatch();
    }, []);

    const GetMatch = () => {
        MatchAPI.FormatMatches().then((data) => {
            console.log(data);
            setMatches(data.message);

        });
    };

    const subtitleChange = (e) => {
        const obj = JSON.parse(e.target.value);
        setApiMatchId(obj.apiMatchId);
        setCid(obj.cid);
        setMatchId(obj.matchId);
        setDummyA('');
        setDummyB('');
        setTeamA('');
        setTeamB('');
        console.log('hello');
        const matchid = obj.matchId;
        // console.log(matchid)
        MatchAPI.GetPlayingXI(matchid).then((data) => {
            setUpdatedXI(data.message);
            console.log(data.message);

            const teamAdata = [];
            const teamBdata = [];
            const teamADummy = [];
            const teamBDummy = [];

            for (let i = 0; i < data.message[0].PlayerID.length; i++) {
                // console.log(data.message[0].MatchId);

                teamAdata.push({
                    PlayerID: data.message[0].PlayerID[i],
                    PlayerName: data.message[0].PlayerName[i],
                    PlayingRole: data.message[0].PlayingRole[i],
                    playerCredit: data.message[0].playerCredit[i],
                });
                teamADummy.push(false);
            }

            setMatchId(data.message[0].MatchId);

            for (let i = 0; i < data.message[1].PlayerID.length; i++) {
                teamBdata.push({
                    PlayerID: data.message[1].PlayerID[i],
                    PlayerName: data.message[1].PlayerName[i],
                    PlayingRole: data.message[1].PlayingRole[i],
                    playerCredit: data.message[1].playerCredit[i],
                });
                teamBDummy.push(false);
            }
            setDummyA(teamADummy);
            setDummyB(teamBDummy);
            setTeamA(teamAdata);
            setTeamB(teamBdata);
        });
    };

    const handleAChange = (e, index) => {
        const checked = e.target.checked;
        const myIndex = index - 1;
        // console.log(myIndex)
        const newArr = [...dummyA];
        newArr[myIndex] = checked;
        setDummyA((prev) => newArr);
        // console.log(dummyA)
    };

    const handleBChange = (e, index) => {
        const checked = e.target.checked;
        const myIndex = index - 1;
        // console.log(myIndex)
        const newArr = [...dummyB];
        newArr[myIndex] = checked;
        setDummyB((prev) => newArr);
        // console.log(dummyA)
    };

    const UpdatePlaying11 = () => {

        MatchAPI.UpdatePlaying11(ApiMatchId, cid, matchId).then(res => {
            if (res.status_code) {
                Notiflix.Notify.info(res.message);
            }
            else {
                Notiflix.Notify.failure(res.message);
            }
        });

    };

    const launch = () => {
        const myFinalA = [];
        const myFinalB = [];

        // console.log(teamA)

        for (let i = 0; i < teamA.length; i++) {
            if (dummyA[i] === true) {
                myFinalA.push(teamA[i]);
                console.log(myFinalA);
            }
        }

        for (let i = 0; i < teamB.length; i++) {
            if (dummyB[i] === true) {
                myFinalB.push(teamB[i]);
            }
        }
        //  console.log(myFinalA)
        //  console.log(myFinalB)

        const finalArray = [...myFinalA, ...myFinalB];

        //  console.log(finalArray)

        const finalFilter = finalArray.map((d) => d.PlayerID);

        //  console.log(finalFilter)

        if (finalArray.length > 22 || myFinalA.length > 11 || myFinalB.length > 11) {
            setIsLessThanEleven(true);
        } else {
            setIsLessThanEleven(false);
            const json = {
                matchId: matchId,
                playerId: finalFilter,
            };
            console.log(json);


            MatchAPI.Playing11Status(json).then((data) => {
                console.log(data);
                Notiflix.Notify.success('Sucessfully created Playing XI');
            });
        }
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>

                        <div>
                            {
                                isLessThanEleven
                                    ?
                                    <h4 style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: 6,
                                        padding: 12,
                                        marginBottom: 12,
                                        maxWidth: 'max-content'
                                    }}>Dont Select More than 11 players in one team</h4>
                                    :
                                    null
                            }
                        </div>

                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'>
                                        <label htmlFor='name'>Launch Linup</label>

                                        <select
                                            id='matchId'
                                            className='form-control mb-3'
                                            onChange={subtitleChange}
                                        >
                                            <option select=''>Select</option>
                                            {matches.map((fan, i) => (
                                                <option
                                                    key={i}
                                                    value={JSON.stringify(fan)}
                                                >{`${fan.matchName}   ||   ${fan.StartDateIST}}`}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='col'>
                                        <button
                                            className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0 mt-4 float-right'
                                            onClick={launch}
                                        >
                                            Launching Playing 11
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='col'></div>
                            <div className='col'></div>
                            <div className='col'></div>
                            <div className='col'>
                                <button
                                    className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                    onClick={UpdatePlaying11}
                                >
                                    Update
                                </button>
                                {/* <a
                                    className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0 float-right'
                                    href='#'
                                >
                                    Export <i className='ri-add-line m-0'></i>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <h4>Team A</h4>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Player Name</th>
                                                <th>Player Role</th>
                                                <th>Player Credit</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamA &&
                                                teamA.length > 0 &&
                                                teamA.map((player, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{++i}</td>

                                                            <td>{player.PlayerName}</td>
                                                            <td>{player.PlayingRole}</td>
                                                            <td>{player.playerCredit}</td>
                                                            <td>
                                                                <div className='checkbox'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='checkbox-input'
                                                                        id='checkbox1'
                                                                        onChange={(e) =>
                                                                            handleAChange(e, i)
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    <div className='col'></div>
                                    <div className='col'></div>
                                    <div className='col'></div>
                                    <div className='col'>
                                        <a
                                            className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                            href='#'
                                        >
                                            Update
                                        </a>
                                        <a
                                            className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0 float-right'
                                            href='#'
                                        >
                                            Export <i className='ri-add-line m-0'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card mt-3'>
                            <h4>Team B</h4>

                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Player Name</th>
                                                <th>Player Role</th>
                                                <th>Player Credit</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamB &&
                                                teamB.length > 0 &&
                                                teamB.map((player, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{++i}</td>

                                                            <td>{player.PlayerName}</td>
                                                            <td>{player.PlayingRole}</td>
                                                            <td>{player.playerCredit}</td>
                                                            <td>
                                                                <div className='checkbox'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='checkbox-input'
                                                                        id='checkbox1'
                                                                        onChange={(e) =>
                                                                            handleBChange(e, i)
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
