import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const AccountManagerAPI = {

    GetDailyReport: async (sDate, eDate) => {
        return BASEAPIS.GETAPI('getDailyReport?start_date=' + sDate + '&end_date=' + eDate,);
    },


    GetTDSReport: async (sDate, eDate) => {
        return BASEAPIS.GETAPI('getTdsReportforAccount?start=' + sDate + '&end=' + eDate);
    },

    userAccountDetail: async (user_id) => {
        return BASEAPIS.GETAPI('fetchUserAccountDetails?user_id=' + user_id,);
    },

    ReportOfKyc: async (status, email, phone, pan, bank, aadhar, entries, pageNumber) => {
        if (!email) {
            email = '';
        }

        if (!phone) {
            phone = '';
        }

        if (!pan) {
            pan = '';
        }

        if (!bank) {
            bank = '';
        }

        if (!aadhar) {
            aadhar = '';
        }

        if (!pageNumber) {
            pageNumber = 1;
        }

        return BASEAPIS.GETAPI('ReportOfKyc?status=' + status + '&email=' + email + '&phone=' + phone + '&pan_no=' + pan + '&aadhaar_number=' + aadhar + '&bank_account_number=' + bank + '&pagenumber=' + pageNumber + '&entries=' + entries);
    },

    filterWalletReport: async (userId, phoneNumber, email, teamName) => {
        return BASEAPIS.GETAPI('userWalletReport?user_id=' + userId + '&phone=' + phoneNumber + '&email=' + email + '&team_name=' + teamName);
    },

    AllWalletReportData: async () => {
        return BASEAPIS.GETAPI('getAllWalletSum');
    },

    allWalletData: async () => {
        return BASEAPIS.GETAPI('userWalletAllData');
    },

    unutilizedReport: async (days, pagenumber) => {
        return BASEAPIS.GETAPI('reportUnutilized?Days=' + days + '&entries=' + 50 + '&pagenumber=' + pagenumber);
    },

    allUnutilizedReport: async (days, pagenumber, totalEntries) => {
        return BASEAPIS.GETAPI('reportUnutilized?Days=' + days + '&entries=' + totalEntries + '&pagenumber=' + pagenumber);
    },

    reportUnutilizedAll: async () => {
        return BASEAPIS.GETAPI('reportUnutilizedAll');
    },

    ReportWithdrawWithoutKYC: async () => {
        return BASEAPIS.GETAPI('getWithdrawalUsersWithoutKYCApproved');
    },

    GetRegisteredUserCountByDate: async (date) => {
        return BASEAPIS.GETAPI('getRegisteredUserCountByDate?date=' + date);
    },

    ReferralDetailsOfSpecificUser: async (userID, phone, team, email) => {
        return BASEAPIS.GETAPI('referralDetailsOfSpecificUser?user_id=' + userID + '&phone=' + phone + '&team_name=' + team + '&email=' + email);
    },

    ActiveInactiveUsersReport: async (startDate, endDate, Active) => {
        return BASEAPIS.GETAPI('activeInactiveUsersReport?startDate=' + startDate + '&endDate=' + endDate + '&Active=' + Active);
    },

    ActiveInactiveUsersReportDownload: async (startDate, endDate, Active) => {
        return BASEAPIS.GETAPI('activeInactiveUsersReportDownload?startDate=' + startDate + '&endDate=' + endDate + '&Active=' + Active);
    },

    AllActiveUserWithDeposit: async (startDate) => {
        return BASEAPIS.GETAPI('getAllActiveUserWithDeposit?date=' + startDate);
    }
};
