import { useState } from 'react';
import { Link } from 'react-router-dom';
import './ViewAllUsers.css';
import Notiflix from 'notiflix';
import { SupportAPI } from '../api/Support';
import { ReportAPI } from '../api/ReportAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Tabs } from 'antd';
import ExcelDownload from '../Component/ExcelDownloadHook';
import { useDate, useDateAndTime, useTime } from '../Component/DateHook';
import { AllUsersAPI } from '../api/allUsers';


export default function BonusExpiry() {

    const [userId, setUserId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [bonusExpiryData, setBonusExpiryData] = useState([]);
    const [bonusGetBonus, setBonusGetBonus] = useState([]);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const bonusExpiryDataAPI = () => {

        const jsonData = {
            user_id: userId,
        };

        setIsLoading(true);
        if (userId.length > 0) {
            userInfo();
            ReportAPI.BonusExpiry(jsonData).then((data) => {
                if (data.status_code) {
                    Notiflix.Notify.success(data.message);
                    setBonusExpiryData(data.featchingBonusExpiryData);
                    setBonusGetBonus(data.getBonus);
                    setIsLoading(false);
                }
                else {
                    Notiflix.Notify.failure('No Changes in User Details');
                    setIsLoading(false);
                }
            });
        } else {
            Notiflix.Notify.warning('Please Fill any one Field');
            setIsLoading(false);
        }
    };

    const reset = () => {
        setUserId('');
        setBonusExpiryData([]);
        setBonusGetBonus([]);
        setIsLoading(false);
        setUsersDetail([]);
    };

    const processedExpireData = bonusExpiryData.map(item => ({
        user_id: item.user_id,
        description: item.description,
        amount: item.amount,
        point_type: item.point_type,
        createdAt: useDateAndTime(item.createdAt)
    }));

    const processedGetData = bonusGetBonus.map(item => ({
        user_id: item.user_id,
        description: item.description,
        amount: item.amount,
        point_type: item.point_type,
        createdAt: useDateAndTime(item.createdAt)
    }));

    const headers = ['user_id', 'description', 'amount', 'point_type', 'createdAt'];

    const onChange = () => {

    };

    const [usersDetail, setUsersDetail] = useState([]);

    const userInfo = () => {
        AllUsersAPI.GetUserInfo(userId).then((res) => {
            if (res.status_code) {
                setUsersDetail(res.data[0]);
                console.log(res.data[0]);
            } else {
                Notiflix.Notify.failure('No User Found');
            }
        });
    };

    const items = [
        {
            key: '1',
            label: 'Bonus Expiry Data',
            children:
                <div className='card'>
                    <div className='card-body'>
                        <div
                        >
                            <div className='table-responsive'>
                                <table className='table table-striped table-bordered'>
                                    <thead>
                                        <tr className='support_main_file_div'>
                                            <th>User Id</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Point Type</th>
                                            <th>Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bonusExpiryData?.length === 0 ?
                                                <div className='allMatches-div-res'>
                                                    <h1>Search for User</h1>
                                                </div>
                                                :
                                                bonusExpiryData?.map((bonus, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{bonus.user_id}</td>
                                                            <td>{useDate(bonus.createdAt)}</td>
                                                            <td>{useTime(bonus.createdAt)}</td>
                                                            <td>{bonus.description}</td>
                                                            <td>{bonus.amount}</td>
                                                            <td>{bonus.point_type}</td>
                                                            <td>{bonus.matchTransaction.bonus}</td>
                                                        </tr>
                                                    );
                                                })

                                        }
                                    </tbody>


                                </table>
                            </div>

                        </div>
                    </div>
                </div>
        },

        {
            key: '2',
            label: 'Getting Bonus Data',
            children:
                <div className='card'>
                    <div className='card-body'>
                        <div
                        >
                            <div className='table-responsive'>
                                <table className='table table-striped table-bordered'>
                                    <thead>
                                        <tr className='support_main_file_div'>
                                            <th>User Id</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Point Type</th>
                                            <th>Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bonusGetBonus?.length === 0 ?
                                                <div className='allMatches-div-res'>
                                                    <h1>Search for User</h1>
                                                </div>
                                                :
                                                bonusGetBonus?.map((bonus, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{bonus.user_id}</td>
                                                            <td>{useDate(bonus.createdAt)}</td>
                                                            <td>{useTime(bonus.createdAt)}</td>
                                                            <td>{bonus.description}</td>
                                                            <td>{bonus.amount}</td>
                                                            <td>{bonus.point_type}</td>
                                                            <td>{bonus.matchTransaction.bonus}</td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                    </tbody>


                                </table>
                            </div>

                        </div>
                    </div>
                </div>,
        },
    ];


    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='offers_couponDiv'>
                                <h1 className='coupon_h1'>Bonus Expiry Report</h1>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 34, alignItems: 'center' }}>
                                        <div>
                                            <label className='offer_checkbox'>User Id</label>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                                                <input value={userId} onChange={handleAllChange(setUserId)} className='offers_input' placeholder='Enter User ID' />

                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                                                    <button className='modification-button' onClick={bonusExpiryDataAPI}>Search User</button>
                                                    <button className='modification-button-reset' onClick={reset}>Reset</button>
                                                </div>

                                                {
                                                    bonusExpiryData.length || bonusGetBonus.length > 0
                                                        ?
                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center', maxWidth: 'max-content' }}>
                                                            <ExcelDownload className='download-data-csv-bonus' data={processedExpireData} filename={'Bonus Expiry Data'} headers={headers} />
                                                            <ExcelDownload className='download-data-csv-bonus' data={processedGetData} filename={'Getting Bonus Data'} headers={headers} />
                                                        </div>
                                                        :
                                                        ''
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {bonusExpiryData.length > 0 || bonusGetBonus.length > 0 ?
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center', marginLeft: '24px', marginBottom: 12 }}>
                                        <h1 style={{ fontSize: 16, backgroundColor: 'black', color: 'white', padding: 12, borderRadius: 8 }}>{usersDetail.team_name ? usersDetail.team_name : 'No Team Name'}</h1>
                                        <h1 style={{ fontSize: 16, backgroundColor: 'black', color: 'white', padding: 12, borderRadius: 8 }}>{usersDetail.phone ? usersDetail.phone : 'No Phone Number'}</h1>
                                        <h1 style={{ fontSize: 16, backgroundColor: 'black', color: 'white', padding: 12, borderRadius: 8 }}>{usersDetail.state ? usersDetail.state : 'No State Found'}</h1>
                                        <h1 style={{ fontSize: 16, backgroundColor: 'black', color: 'white', padding: 12, borderRadius: 8 }}>{usersDetail.email ? usersDetail.email : 'No Email Found'}</h1>
                                    </div>
                                    : null
                                }


                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        <div className='card'>
                                            <div className='row'>
                                                <div className='col-sm-12 col-lg-12'>
                                                    {
                                                        isLoading ? <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                            <div>
                                                                <RotatingLines
                                                                    marginTop='70'
                                                                    strokeColor='#c1030d'
                                                                    strokeWidth='5'
                                                                    animationDuration='0.75'
                                                                    width='50'
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        </div>
                                                            :
                                                            <Tabs style={{
                                                                marginLeft: 20
                                                            }} defaultActiveKey="1" items={items} onChange={onChange} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </div>
    );
}
