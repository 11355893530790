import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ContestReportAPI } from '../api/contestReportApi';
import { MatchAPI } from '../api/matchApi';

export default function ContestWiseReports() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    });
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-row'>
                                            <div className='col'>
                                                <label htmlFor='name'>Sport* :</label>
                                                <select className='form-control mb-3'>
                                                    <option select=''>Open this select menu</option>
                                                    <option value='1'>Sport 1</option>
                                                    <option value='2'>Sport 2</option>
                                                    <option value='3'>Sport 3</option>
                                                </select>
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Matches* :</label>
                                                <select className='form-control mb-3'>
                                                    <option select=''>Open this select menu</option>
                                                    <option value='1'>Matches 1</option>
                                                    <option value='2'>Matches 2</option>
                                                    <option value='3'>Matches 3</option>
                                                </select>
                                            </div>
                                            <div className='col'>
                                                <label htmlFor='name'>Contest* :</label>
                                                <select className='form-control mb-3'>
                                                    <option select=''>Open this select menu</option>
                                                    <option value='1'>Contest 1</option>
                                                    <option value='2'>Contest 2</option>
                                                    <option value='3'>Contest 3</option>
                                                </select>
                                            </div>

                                            <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                                <br />
                                                <label htmlFor='name'></label>
                                                <button type='submit' className='btn btn-primary'>
                                                    Search
                                                </button>
                                                &nbsp;
                                                {/* <button type="submit" className="btn bg-danger"><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                <input type='reset' className='btn btn-danger ' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_contest_category'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-add-line'></i>View Contest Category
                                    </Link>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Contest Wise Reports</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Match Name</th>
                                                    <th>Game Type</th>
                                                    <th>Match Date</th>
                                                    <th>Team Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>user@gmail.com</td>
                                                    <td>9876544320</td>
                                                    <td>Asia Cup</td>
                                                    <td>Cricket One day</td>
                                                    <td>25-Aug-2022</td>
                                                    <td>Rockers123</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>user@gmail.com</td>
                                                    <td>9876544320</td>
                                                    <td>Asia Cup</td>
                                                    <td>Cricket One day</td>
                                                    <td>25-Aug-2022</td>
                                                    <td>Rockers123</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>user@gmail.com</td>
                                                    <td>9876544320</td>
                                                    <td>Asia Cup</td>
                                                    <td>Cricket One day</td>
                                                    <td>25-Aug-2022</td>
                                                    <td>Rockers123</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>user@gmail.com</td>
                                                    <td>9876544320</td>
                                                    <td>Asia Cup</td>
                                                    <td>Cricket One day</td>
                                                    <td>25-Aug-2022</td>
                                                    <td>Rockers123</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>user@gmail.com</td>
                                                    <td>9876544320</td>
                                                    <td>Asia Cup</td>
                                                    <td>Cricket One day</td>
                                                    <td>25-Aug-2022</td>
                                                    <td>Rockers123</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>user@gmail.com</td>
                                                    <td>9876544320</td>
                                                    <td>Asia Cup</td>
                                                    <td>Cricket One day</td>
                                                    <td>25-Aug-2022</td>
                                                    <td>Rockers123</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
