import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContestAPI } from '../api/globalContestApi';
import { MatchAPI } from '../api/matchApi';
import { SeriesAPI } from '../api/seriesApi';
import { RotatingLines } from 'react-loader-spinner';

export default function CreateCustomContest() {
    const location = useLocation();
    // console.log(location)
    const [contest, setContest] = useState('');
    const [contest1, setContest1] = useState('');
    const [contest2, setContest2] = useState('');
    const [notLaunched, setNotLaunched] = useState('');
    const [fantasy, setFantasy] = useState([]);
    const [fan, setFan] = useState('cricket');
    const [data, setData] = useState([]);
    const [reset, setReset] = useState(false);
    const [checkArray, setCheckArray] = useState([]);
    const [checkCanceledArray, setCheckCanceledArray] = useState([]);
    // const [data, setData] = useState([]);
    const [color, setColor] = useState('');
    const [matchID, setMatchID] = useState(location.state ? location.state.matchId : '');
    const [cid, setCid] = useState(location.state ? location.state.cid : '');
    const [isLoading, setIsLoading] = useState(true);

    const [selectAll, setSelectAll] = useState(false);
    const [selectAllLaunched, setSelectAllLaunched] = useState(false);


    // Select All Items
    const handleSelectAllChange = () => {
        setSelectAll(prevSelectAll => !prevSelectAll);
        const updatedCheckArray = contest.map(() => !selectAll);
        setCheckArray(updatedCheckArray);
    };


    const handleChange = (e, i) => {
        const index = i - 1;
        const isChecked = e.target.checked;
        const newArr = [...checkArray];
        newArr[index] = isChecked;
        setCheckArray(newArr);
        const allItemsSelected = newArr.every((checked) => checked);
        setSelectAll(allItemsSelected);
    };

    const handleSelectAllLaunchedChange = () => {
        setSelectAllLaunched(prevSelectAll => !prevSelectAll);
        const updatedCheckArrayLaunched = contest1.map(() => !selectAllLaunched);
        setCheckCanceledArray(updatedCheckArrayLaunched);
    };

    // Select All Items

    const [match, setMatch] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetMatch();
        GetFantasy();
        // fetchContest();
    }, []);
    const GetMatch = () => {
        MatchAPI.FormatMatches().then((data) => {
            console.log('hello');
            console.log(data);
            handleData(data.message);
            setIsLoading(false);
        });
    };

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            console.log(data);
            if (data.status_code === true) {
                setFantasy(data.message);
                console.log(data.message);
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const fantasyChange = (e) => {
        setFan(e.target.value);
        // console.log(e.target.value)
    };

    const AddMultipleGlobalContestInMatch = () => {
        const json = { MatchId: matchID, cid: cid, Fantasy: fan };
        console.log(json);
        MatchAPI.AddMultipleGlobalContestInMatch(json).then((data) => {
            console.log(data);
            if (data.Status_code) {
                Notiflix.Notify.success(data.message);
            }
        });
    };
    const handleData = (data) => {
        console.log(data);
        setData(data);
    };



    const fetchContest = () => {
        console.log(checkArray.length);
        if (checkArray.length <= 0) {

            Notiflix.Notify.failure('Please Select some Contests');

            return;



        }
        else {

            const arr = [];
            const finalArr = [];
            // console.log(notLaunched);
            for (let i = 0; i < contest.length; i++) {
                if (checkArray[i] === true) {
                    arr.push(contest[i]);
                }
            }

            for (let i = 0; i < arr.length; i++) {
                finalArr.push({

                    ContestPoolId: arr[i]._id,

                });
            }

            console.log(finalArr);

            // console.log(finalArr);
            const json = {
                fantasy: arr[0].fantasy,
                matchId: matchID,
                contestArray: [...finalArr]
            };
            console.log(json);
            GlobalContestAPI.LaunchContest(json).then((res) => {
                console.log(res);
                setCheckArray();
                checkContest();


            });

        }

    };
    //   const matchIdChange=(e)=>{
    //     // setMatchName(e.target.value);
    //     setMatchId(e.target.value);
    // }

    const cancelContest = (contest) => {
        if (checkCanceledArray.length <= 0) {
            Notiflix.Notify.failure('Please Select some Contests');
            return;
        }
        
        // console.log(checkArray);
        const arr = [];
        const finalArr = [];
        // console.log(notLaunched);
        for (let i = 0; i < contest1.length; i++) {
            if (checkCanceledArray[i] === true) {
                arr.push(contest1[i]);
            }
        }

        for (let i = 0; i < arr.length; i++) {
            finalArr.push({

                ContestPoolId: arr[i].ContestPoolId,

            });
        }

        // console.log(finalArr);

        // console.log(finalArr);
        const json = {
            matchId: arr[0].MatchId,
            fantasy: arr[0].fantasy,
            contestArray: [...finalArr]
        };
        console.log(json);
        GlobalContestAPI.CancelContest(json).then((res) => {
            console.log(res);
            setCheckArray();
            checkContest();


        });


    };

    const idChange = (e) => {

        const selectedMatch = e.target.value;
        // console.log(data[0])
        const find = data.find((f) => f.matchId == selectedMatch);
        console.log(find.cid);

        console.log(find.matchId);

        setMatchID(find.matchId);
        setCid(find.cid);
    };

    const checkContest = () => {
        setCheckArray([]);
        setContest([]);
        setContest2([]);
        setNotLaunched([]);
        setContest1([]);

        const json = {
            fantasy: fan,
            MatchId: matchID
        };

        console.log(json);
        GlobalContestAPI.GetGlobalContest(json).then((res) => {
            if (res.status_code) {
                console.log(res);
                // const filterLaunched = res.data.filter(f => f.isLaunched === true && !f.isCancelled);
                // const filterNotLaunched = res.data.filter(f => f.isLaunched === false && f.isCancelled == false);
                // const filterCancel = res.data.filter(f => f.isLaunched === true && f.isCancelled == true);
                setContest(res.data);
                // setContest1(filterLaunched);
                // setContest2(filterCancel);
                // setNotLaunched(filterNotLaunched);
                // Notiflix.Notify.success(res.message);
            }

            else if (res.status_code === false) {
                Notiflix.Notify.failure(res.message);

            }
        });

        GlobalContestAPI.GetLaunchedContests(json).then((res) => {
            console.log('hello from get launched contest');
            if (res.status_code) {
                const filterLaunched = res.data.filter(f => f.isLaunched === true && f.isCancelled === false);
                const filterCancel = res.data.filter(f => f.isLaunched === true && f.isCancelled == true);
                console.log(filterLaunched);
                setContest1(filterLaunched);
                setContest2(filterCancel);
            }


        });

    };

    const handleCancelChange = (e, i) => {
        const index = i - 1;
        const checked = e.target.checked;
        const newArr = [...checkCanceledArray];
        newArr[index] = checked;
        setCheckCanceledArray(newArr);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>

                                            <label htmlFor='name'>Select Fantasy :</label>
                                            <select
                                                className='form-control mb-3'
                                                onChange={fantasyChange}
                                            >
                                                {fantasy.map((fan) => (
                                                    <option key={fan.fantasy} value={fan.fantasy}>
                                                        {fan.fantasy}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col'>

                                            <label htmlFor='name'>Match :</label>
                                            {location.state === null && (
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={(e) => idChange(e)}
                                                >
                                                    <option selected value=''>
                                                        Select
                                                    </option>
                                                    {data?.length > 0 && data.map((data) => (
                                                        <option
                                                            // selected={
                                                            //   matchID && data.matchId == matchID.matchId
                                                            // }
                                                            value={data.matchId}
                                                        >{`${data.matchName}   \u00A0\u00A0\  ${data.StartDateIST}`}</option>
                                                    ))}
                                                </select>
                                            )}
                                            {location.state && location.state.matchName && (
                                                <select className='form-control mb-3'>
                                                    <option
                                                        disabled
                                                        selected
                                                        value={location.state.matchName}
                                                    >
                                                        {`${location.state.matchName}  \u00A0\u00A0\  ${location.state.StartDateIST}`}
                                                    </option>
                                                </select>
                                            )}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <div className=''>

                                                <a>
                                                    <Link
                                                        to={'/customContestPage'}
                                                        state={{
                                                            match_Id: matchID
                                                        }}
                                                        className='btn bg-danger float-right'
                                                    >
                                                        <i className='ri-add-line'></i>Add New
                                                        Custom Contests
                                                    </Link>
                                                </a>
                                            </div>
                                            <div className=''>
                                                <a>
                                                    <button
                                                        onClick={() => fetchContest()}
                                                        className='btn bg-primary float-right mr-3'
                                                    >
                                                        <i className='ri-download-2-line'></i>Import
                                                        Global Contest
                                                    </button>
                                                </a>
                                            </div>

                                            <div className=''>
                                                <a>
                                                    <button
                                                        onClick={() => checkContest()}
                                                        className='btn bg-primary float-right mr-3'
                                                    >
                                                        <i className='ri-download-2-line'></i>Check Contest in Match
                                                    </button>
                                                </a>
                                            </div>
                                            <div className=''>
                                                <a>
                                                    <button
                                                        onClick={(e) => {
                                                            if (
                                                                window.confirm(
                                                                    'Are you sure to Cancel Contest?'
                                                                )
                                                                    ? cancelContest(

                                                                    )
                                                                    : e.preventDefault()
                                                            );
                                                        }}
                                                        className='btn bg-primary float-right mr-3'
                                                    >
                                                        <i className='ri-download-2-line'></i>Cancel Contest
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isLoading && (
                        <div className='col-sm-12 col-lg-12'>
                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                <RotatingLines
                                    marginTop='40'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}

                    <h6>
                        <input
                            style={{ marginRight: '12px', marginBottom: '16px' }}
                            type='checkbox'
                            className='checkbox-input'
                            id='selectAll'
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                        Select All
                    </h6>

                    {!isLoading && (
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Not Launched Contest</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table data-table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                Select
                                                            </td>
                                                            <th>S.No.</th>
                                                            <th>Contest Category</th>
                                                            <th>Win Type</th>
                                                            <th>Win Amount</th>
                                                            <th>Maximum Users</th>
                                                            {/* <th>Joined Users</th> */}
                                                            <th>Entry Fee</th>
                                                            <th>Confirmed</th>
                                                            <th>Multi Entry</th>
                                                            <th>Auto Repeat</th>
                                                            {/* <th>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contest &&
                                                            contest.length > 0 &&
                                                            contest.map((data, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            id={`checkbox${i}`}
                                                                            checked={checkArray[i] || false}
                                                                            onChange={(e) => handleChange(e, i)}
                                                                        />
                                                                    </td>
                                                                    <td>{++i}</td>
                                                                    <td>{data.contest_category}</td>
                                                                    <td>{data.contest_prize_card_type}</td>
                                                                    <td>{data.winning_amount}</td>
                                                                    <td>{data.maximum_user}</td>
                                                                    {/* <td>{data.MaxSpots - data.LeftSpots}</td> */}
                                                                    <td>{data.entry_fee}</td>
                                                                    <td>{data.is_confirmed ? '✓' : 'x'}</td>
                                                                    <td>{data.multi_entry ? '✓' : 'x'}</td>
                                                                    <td>{data.is_running ? '✓' : 'x'}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h6>
                                    <input
                                        style={{ marginRight: '12px', marginBottom: '16px' }}
                                        type='checkbox'
                                        className='checkbox-input'
                                        id='selectAllLaunched'
                                        checked={selectAllLaunched}
                                        onChange={handleSelectAllLaunchedChange}
                                    />
                                    Select All
                                </h6>

                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Launched Contest</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table data-table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Check</th>
                                                            <th>S.No.</th>
                                                            <th>Contest Category</th>
                                                            <th>Win Type</th>
                                                            <th>Win Amount</th>
                                                            <th>Maximum Users</th>
                                                            <th>Joined Users</th>
                                                            <th>Entry Fee</th>
                                                            <th>Confirmed</th>
                                                            <th>Multi Entry</th>
                                                            <th>Auto Repeat</th>
                                                            {/* <th>Action</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contest1 &&
                                                            contest1.length > 0 &&
                                                            contest1.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <input
                                                                                type='checkbox'
                                                                                className='checkbox-input'
                                                                                id={`checkbox1_${i}`}
                                                                                checked={checkCanceledArray[i] || false}
                                                                                onChange={(e) => handleCancelChange(e, i)}
                                                                            />
                                                                        </td>
                                                                        <td>{++i}</td>
                                                                        <td>
                                                                            {data.ContestCategoryName}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                data.contest_prize_card_type
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {data.TotalWinningAmount}
                                                                        </td>
                                                                        <td>{data.MaxSpots}</td>
                                                                        <td>{data.MaxSpots - data.LeftSpots}</td>
                                                                        <td>{data.JoiningFees}</td>

                                                                        <td>{data.IsConfirmed ? '✓' : 'x'}</td>
                                                                        <td>
                                                                            {data.IsMultiEntry ? '✓' : 'x'}
                                                                        </td>
                                                                        <td>{data.IsRunning ? '✓' : 'x'}</td>
                                                                        {/* <td>
                                                                            <button className='btn btn-primary'
                                                                                onClick={(e) => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            'Are you sure to delete?'
                                                                                        )
                                                                                            ? cancelContest(
                                                                                                data
                                                                                            )
                                                                                            : e.preventDefault()
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Cancel Contest
                                                                            </button>
                                                                        </td> */}

                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>Cancelled Contest</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table data-table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>

                                                            <th>S.No.</th>
                                                            <th>Contest Category</th>
                                                            <th>Win Type</th>
                                                            <th>Win Amount</th>
                                                            <th>Maximum Users</th>
                                                            <th>Joined Users</th>
                                                            <th>Entry Fee</th>
                                                            <th>Confirmed</th>
                                                            <th>Multi Entry</th>
                                                            <th>Auto Repeat</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {contest2 &&
                                                            contest2.length > 0 &&
                                                            contest2.map((data, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{++i}</td>
                                                                        <td>
                                                                            {data.ContestCategoryName}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                data.contest_prize_card_type
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {data.TotalWinningAmount}
                                                                        </td>
                                                                        <td>{data.MaxSpots}</td>
                                                                        <td>{data.MaxSpots - data.LeftSpots}</td>
                                                                        <td>{data.JoiningFees}</td>

                                                                        <td>{data.IsConfirmed ? '✓' : 'x'}</td>
                                                                        <td>
                                                                            {data.IsMultiEntry ? '✓' : 'x'}
                                                                        </td>
                                                                        <td>{data.IsRunning ? '✓' : 'x'}</td>

                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
