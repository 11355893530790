import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UsersAPI } from '../api/userApi';
import { Link } from 'react-router-dom';
import './ViewAllUsers.css';
import Notiflix from 'notiflix';
import { SupportAPI } from '../api/Support';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Image } from 'antd';

export default function SingleUserDetail() {
    const location = useLocation();
    const { verify_emailId, verify_phoneNumber } = location.state;

    const [dataArray, setArray] = useState([]);
    const [emailId, setEmailId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [userId, setUserId] = useState('');
    const [verifyError, setVerifyError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        verifyUserDetail();
    }, []);

    console.log('dataArray-----------', dataArray);

    const verifyUserDetail = () => {
        SupportAPI.verifyUserDetail(verify_phoneNumber, verify_emailId).then((data) => {
            if (data.status_code) {
                setVerifyError(false);
                setUserId(data.data[0].user_id);
                setTeamName(data.data[0].team_name);
                setEmailId(data.data[0].email);
                setPhoneNumber(data.data[0].phone);
            } else {
                setVerifyError(true);
            }
        });
    };

    const GetUserWalletDataByUserId = () => {
        const json = {
            user_id: userId,
        };
        UsersAPI.GetUserWalletDataByUserId(json).then((data) => {
            setLoading(true);
            if (data.status_code) {
                setArray(data.data);
                setLoading(false);
            }
        });
    };


    useEffect(() => {
        GetUserWalletDataByUserId();
    }, [userId]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 49,
            }}
            spin
        />
    );

    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='offers_couponDiv'>
                        <h4 className='coupon_h1'>View User Details</h4>
                    </div>

                    {loading ? (
                        <div className='something-loadery'>
                            <Spin indicator={antIcon} />
                        </div>
                    ) : (
                        <div className='card'>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table className='table table-striped table-bordered'>
                                        <thead className='cases_table_main'>
                                            <tr>
                                                <th>Image</th>
                                                <th>Username</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>Team Name</th>
                                                <th>State</th>
                                                <th>Pan Verification</th>
                                                <th>Pan Name</th>
                                                <th>Pan Number</th>
                                                <th>Bank Account Verification</th>
                                                <th>Bank Account No.</th>
                                                <th>Bank IFSC</th>
                                                <th>Bank Account Holder Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>
                                                    {dataArray[0]?.profile_image ? (
                                                        <Image
                                                            style={{ height: '50px', width: '50px' }}
                                                            width={300}
                                                            src={dataArray[0]?.profile_image}
                                                        />
                                                    ) : (
                                                        'No Image Found'
                                                    )}
                                                </td>
                                                <td>{dataArray[0]?.name === '' ? 'No Username Found' : dataArray[0]?.name}</td>
                                                <td>{dataArray[0]?.email}</td>
                                                <td>{dataArray[0]?.phone}</td>
                                                <td>{dataArray[0]?.team_name}</td>
                                                <td>{dataArray[0]?.state}</td>
                                                <td>{dataArray[0]?.account_details?.verification_status?.pan_no === true ? 'YES' : 'NO'}</td>
                                                <td>{dataArray[0]?.account_details?.pan_name === '' ? 'Pan Not Verified' : dataArray[0]?.account_details.pan_name}</td>
                                                <td>{dataArray[0]?.account_details?.pan_number === '' ? 'Pan Not Verified' : dataArray[0]?.account_details.pan_number}</td>
                                                <td>{dataArray[0]?.account_details?.verification_status?.bank_account_number === true ? 'YES' : 'NO'}</td>
                                                <td>{dataArray[0]?.account_details?.account_number === '' ? 'Account Not Verified' : dataArray[0]?.account_details.account_number}</td>
                                                <td>{dataArray[0]?.account_details?.ifsc_code === '' ? 'Account Not Verified' : dataArray[0]?.account_details.ifsc_code}</td>
                                                <td>{dataArray[0]?.account_details?.bank_name === '' ? 'Account Not Verified' : dataArray[0]?.account_details.bank_name}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body userDetailButtonDiv'>
                                    <div className='offers_head'>
                                        <div className='form-row'>
                                            <Link
                                                className='cases_button_199'
                                                to='/create_cases'
                                                state={{
                                                    finalPhoneNumber: verify_phoneNumber,
                                                    finalEmailId: verify_emailId,
                                                }}
                                            >
                                                Create Case
                                            </Link>
                                        </div>
                                    </div>

                                    <div className='offers_head'>
                                        <div className='form-row'>
                                            <Link
                                                className='cases_button'
                                                to='/support_user_transaction'
                                                state={{
                                                    userId: userId,
                                                }}
                                            >
                                                User Transaction
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
