import Notiflix from 'notiflix';
import { useState, useEffect } from 'react';
import { RotatingLines } from 'react-loader-spinner';

export default function GST_Report() {

    const [isLoading, setIsLoading] = useState(false);
    const [gstData, setGSTData] = useState([]);

    // useEffect(() => {
    //     gstMatchAPI();
    // }, []);

    // const gstMatchAPI = () => {
    //     (ReportAPI.matchDataGST(pageSize, pageNumber).then(data => {
    //         var pan_i = JSON.parse(JSON.stringify(data));
    //         if (data.status_code === true) {
    //             setPanVerify(pan_i.user);
    //             setIsLoading(false);
    //             setTotalPages(data?.total_page);
    //             setTotalLength(data?.total_length);
    //         } else {
    //             Notiflix.Notify.failure(data.msg);
    //         }
    //     }));
    // };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>GST Report</h4>
                                    </div>
                                </div>


                                {/* <div className='main-divv-main-divv'>
                                    <div>
                                        <select className='input-dropdown-handle-fuull-series' onChange={(e) => setFantansy(e.target.value)}>
                                            <option value='cricket' selected='selected'>
                                                Cricket
                                            </option>
                                            <option value='Prediction'>Prediction</option>
                                        </select>
                                    </div>

                                    <div>
                                        <button className='input-dropdown-handle-fuull-series-button' onClick={GetSeriesContestDetails}>Search</button>
                                    </div>
                                </div> */}

                                {/* GST Report*/}

                                <div className='card-body'>
                                    <div className='table-editable'>
                                        <div className='table-responsive'>
                                            <table
                                                className='table'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isLoading ? <div className='rotaing-lines-div-maiinm'>
                                                            <div>
                                                                <RotatingLines
                                                                    marginTop='160'
                                                                    marginBottom='160'
                                                                    strokeColor='#000000'
                                                                    strokeWidth='3'
                                                                    animationDuration='0.75'
                                                                    width='50'
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        </div> :
                                                            (
                                                                gstData &&
                                                                gstData.map((contest, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{++i}</td>
                                                                        </tr>
                                                                    );
                                                                }))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
