import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { Spin, Modal, Tabs } from 'antd';
import { fundManager } from '../api/FundsApi';
import { Link } from 'react-router-dom';
import { SupportAPI } from '../api/Support';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import WithdrawlFunds from './WithdrawlFunds';
import BonusFunds from './BonusAddFunds';
import RefundFunds from './RefundFunds';
import WinningFunds from './Winning-Funds';
import SuperCoinsFunds from './SuperCoinsFunds';

const dashUserName = localStorage.getItem('name');
const dashRole = localStorage.getItem('role');
const dashLoggedIn = localStorage.getItem('loggedin');

export default function DepositFunds() {

    useEffect(() => {
        getCouponsName();
    }, []);

    const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
    const [isModalOpenDeduct, setIsModalOpenDeduct] = useState(false);
    const [fundsUserId, setFundsUserId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [teamName, setTeamName] = useState('');
    const [tinyLoader, setTinyLoader] = useState(false);

    const [addFundMaxValidation, setAddFundMaxValidation] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    // Modal States
    const [addAmount, setAddAmount] = useState('');
    const [addMasterPassword, setAddMasterPassword] = useState('');
    const [addInternalComment, setAddInternalComment] = useState('');
    const [addTDS, setAddTDS] = useState('');
    const [addCoupon, setAddCoupon] = useState('');

    const [deductTDS, setDeductTDS] = useState('');
    const [deductInternalComment, setDeductInternalComment] = useState('');
    const [deductAmount, setDeductAmount] = useState('');
    const [deductType, setDeductType] = useState('');
    const [deductDescription, setDeductDescription] = useState('');
    const [deductMasterPassword, setDeductMasterPassword] = useState('');
    const [generateModal, setGenerateModal] = useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);

    const [failedDate, setFailedDate] = useState('');
    const [descriptionSelect, setDescriptionSelect] = useState('');

    // Generate Master Password States
    const [generateMasterPassword, setGenerateMasterpassword] = useState('');
    const [regenerateMasterPassword, setReGenerateMasterpassword] = useState('');
    const [generateUserName, setGenerateUserName] = useState('');

    const verifyUserDetail = () => {
        setTinyLoader(true);
        SupportAPI.verifyUserDetail(phoneNumber, emailId).then((data) => {
            if (data.status_code) {
                console.log(data.data);
                setFundsUserId(data.data[0].user_id);
                setTeamName(data.data[0].team_name);
                setEmailId(data.data[0].email);
                setPhoneNumber(data.data[0].phone);
                setTinyLoader(false);

            } else {
                Notiflix.Notify.failure('No Such User Exist');
                setTinyLoader(false);

            }
        });
    };

    const [getAllCoupons, setGetAllCoupons] = useState([]);

    const getCouponsName = () => {
        fundManager.GetAllCoupons().then((data) => {
            if (data.status_code) {
                console.log(data);
                setGetAllCoupons(data.data);
            } else {
                Notiflix.Notify.failure('No Coupons Found');
            }
        });
    };

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const AddFundsDashboardAPI = () => {
        const addType = 'deposit';
        const data = {
            'user_id': fundsUserId,
            'amount': parseFloat(addAmountUnutilized),
            'type': addType,
            'comment': addInternalComment,
            'gst': parseFloat(addAmountGST),
            'master_password': addMasterPassword,
            'user_name': dashLoggedIn,
            'failed_date': failedDate,
            'coupon_code': addCoupon,
        };

        const compareAmount = parseInt(addAmount);
        if (compareAmount > 10000) {
            setAddFundMaxValidation(true);
        } else {
            setAddFundMaxValidation(false);
            fundManager.PostAddFundDashboard(data).then((data) => {
                if (data.status_code) {
                    Notiflix.Notify.success(data.message);
                    setIsModalOpenAdd(false);
                    reset();
                } else {
                    Notiflix.Notify.failure(data.message);
                    setIsModalOpenAdd(false);
                }
            });
        }
    };

    const DeductFundsDashboardAPI = () => {
        const data = {
            'user_id': fundsUserId,
            'amount': parseFloat(deductAmount),
            'type': deductType,
            'comment': deductInternalComment,
            'tds': parseInt(deductTDS),
            'master_password': deductMasterPassword,
            'user_name': dashLoggedIn,
        };
        fundManager.PostDeductFundDashboard(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success(data.message);
                setIsModalOpenDeduct(false);
                reset();
            } else {
                setIsModalOpenDeduct(false);
                Notiflix.Notify.failure(data.message);
            }
        });
    };

    const reset = () => {
        setFundsUserId('');
        setTeamName('');
        setEmailId('');
        setPhoneNumber('');
        setAddAmount();
        setAddInternalComment('');
        setAddMasterPassword('');
        setAddTDS('');
    };

    const createMasterPasswordDashboard = () => {
        if (generateMasterPassword === regenerateMasterPassword && regenerateMasterPassword.length >= 8) {
            setIsPasswordMatch(false);
            const data = {
                'user_name': generateUserName,
                'master_password': regenerateMasterPassword,
            };
            fundManager.createMasterPassowrdForDashboard(data).then((data) => {
                if (data.status_code) {
                    Notiflix.Notify.success(data.message);
                    setIsModalOpenDeduct(false);
                    setGenerateMasterpassword('');
                    setReGenerateMasterpassword('');
                    setGenerateModal(false);
                } else {
                    setIsModalOpenDeduct(false);
                    Notiflix.Notify.failure(data.message);
                    setGenerateMasterpassword('');
                    setReGenerateMasterpassword('');
                    setGenerateModal(false);
                }
            });
        } else {
            setIsPasswordMatch(true);
        }
    };

    const [addAmountUnutilized, setAddAmountUnutilized] = useState('0');
    const [addAmountGST, setAddAmountGST] = useState('0');

    const calculate = (e) => {
        if (e === '') {
            setAddAmountUnutilized('');
            setAddAmountGST('');
        } else {
            let finalUnutilzed = (e / 128) * 100;
            let finalGST = e - finalUnutilzed;
            setAddAmountUnutilized(finalUnutilzed);
            setAddAmountGST(finalGST);
        }
    };

    useEffect(() => {
        if (addAmount) {
            calculate(addAmount);
        } else {
            setAddAmountUnutilized('0');
            setAddAmountGST('0');
        }
    }, [addAmount]);

    const { TabPane } = Tabs;
    function callback(key) {
        console.log(key);
    }



    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body cord-body-div-bc'>
                                    <div className='offers_head'>
                                        <div className='form-row'>
                                            <Link className='offer_button active' to='/add-deduct-funds'>Add/Deduct Funds</Link>
                                        </div>
                                        <div className='form-row'>
                                            <Link className='offer_button' to='/funds'>Funds</Link>
                                        </div>
                                        <div className='form-row'>
                                            <Link className='offer_button active' to='/Razpayout'>Razorpay X</Link>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            dashRole === '1' || dashRole === '2' ?
                                                <button onClick={() => setGenerateModal(true)} className='offer_button_generate'>Generate Master Password</button> : ''
                                        }
                                        <Link to='/allwalletlogs' className='offer_button_generate-log'>Wallet Logs</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card'>
                        <div className='card-body'>
                            <div className='form-row'>

                            </div>
                            <div className='offers_div'>
                                <div >
                                    <label className='offer_checkbox'>Phone Number</label>
                                    <input type='number' value={phoneNumber} required onChange={handleAllChange(setPhoneNumber)} className='offers_input' placeholder='Enter Phone Number' />
                                </div>

                                <div>
                                    <label className='offer_checkbox'>Email ID</label>
                                    <input value={emailId} onChange={handleAllChange(setEmailId)} className='offers_input' placeholder='Enter Email ID' />
                                </div>

                                <button onClick={verifyUserDetail} className='cases_verify_button'>Verify User</button>
                                <button onClick={reset} className='cases_verify_button_2'>Reset</button>

                            </div>
                        </div>
                    </div>


                    <div className='card'>
                        <div className='card-body'>
                            <div className='form-row'>

                            </div>
                            <div className='offers_div'>

                                <div >
                                    <label className='offer_checkbox'>Phone Number</label>
                                    <h1 className='offers_input_1'>Phone:- <span>{phoneNumber}</span></h1>
                                </div>

                                <div >
                                    <label className='offer_checkbox'>Email Id</label>
                                    <h1 className='offers_input_1'>Email:- <span>{emailId}</span></h1>
                                </div>

                                <div >
                                    <label className='offer_checkbox'>User ID</label>
                                    <h1 className='offers_input_1'>ID:- <span>{fundsUserId}</span></h1>
                                </div>

                                <div>
                                    <label className='offer_checkbox'>Team Name</label>
                                    <h1 className='offers_input_1'>Team-Name:- <span>{teamName}</span></h1>
                                </div>

                                <div>
                                    {tinyLoader ? <Spin className='cases_loader' size='large' /> : ''}
                                </div>

                            </div>
                        </div>
                    </div>

                    {
                        fundsUserId && fundsUserId.length > 0 ? <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='funds-adddeduct-div'>

                                            <div className='form-row'>
                                                <button onClick={() => setIsModalOpenAdd(true)} className='offer_button-2' >Add Funds</button>
                                            </div>

                                            <div className='form-row'>
                                                <button onClick={() => setIsModalOpenDeduct(true)} className='offer_button-3' >Deduct Funds</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            :
                            ''
                    }

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>

                                {/* Modal*/}
                                <Modal width={800} title='Add Funds' open={isModalOpenAdd} onCancel={() => setIsModalOpenAdd(false)} footer={null}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={callback}
                                        tabBarStyle={{ color: '#8D949C' }}
                                        style={{ width: '100%' }}
                                    >
                                        <TabPane tab="Deposit Funds"
                                            key="1"
                                        >
                                            <div className='modal-1-div-main'>
                                                <div className='main-div-for-funds-manager'>
                                                    <div className='modal-div-1'>
                                                        <h2>UserId</h2>
                                                        <h1>{fundsUserId}</h1>
                                                    </div>

                                                    <div className='modal-div-1'>
                                                        <h2>Name</h2>
                                                        <h1>{dashLoggedIn}</h1>
                                                    </div>
                                                </div>

                                                <div className='modal-div-2'>
                                                    <label className='offer_checkbox-2'>Enter Amount</label>
                                                    <input value={addAmount} onChange={handleAllChange(setAddAmount)} className='offers_input-2' placeholder='Type Amount Here' />
                                                </div>

                                                {addFundMaxValidation ?
                                                    <label className='offer_checkbox-error'>Amount Cannot Be More Than ₹ 10,000</label>
                                                    :
                                                    ''
                                                }

                                                <div className='modal-div-2'>
                                                    <label className='offer_checkbox-2'>Coupon Code</label>
                                                    <select value={addCoupon} onChange={handleAllChange(setAddCoupon)} className='offers_input-2'>
                                                        <option className='offers_select_option' value='' >Select</option>
                                                        {getAllCoupons?.map((coup, i) => {
                                                            return (
                                                                <option key={coup._id} className='offers_select_option' value={coup.coupon_code}>{coup.coupon_code}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>

                                                <div className='modal-div-2'>
                                                    <label className='offer_checkbox-2'>Failed Date</label>
                                                    <input value={failedDate} type='date' onChange={handleAllChange(setFailedDate)} className='offers_input-2' placeholder='Type TDS amount here' />
                                                </div>

                                                <div className='modal-div-2'>
                                                    <label className='offer_checkbox-2'>Internal Comment</label>
                                                    <textarea onChange={handleAllChange(setAddInternalComment)} className='offers_input-2' placeholder='Type Internal Comment' />
                                                </div>

                                                <div className='modal-div-2'>
                                                    <label htmlFor="password" className='offer_checkbox-2'>Enter Master Password</label>
                                                    <input value={addMasterPassword}
                                                        onChange={handleAllChange(setAddMasterPassword)}
                                                        autoComplete="off"
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        className='offers_input-2'
                                                        placeholder='Master Password Here' />
                                                    {passwordVisible ? (
                                                        <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                                    ) :
                                                        (
                                                            <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                                        )}
                                                </div>

                                                <div className='div-add-fund-div-multi'>
                                                    <button onClick={AddFundsDashboardAPI} className='modal-div-button'>Add Deposits</button>
                                                    <div className='div-add-fund-div-multi-h1'>
                                                        <h1 className='add-funds-api-number'>GST:<span className='span-amount-add-funds'>{addAmountGST}</span></h1>
                                                        <h1 className='add-funds-api-number'>Unutilized:<span className='span-amount-add-funds'>{addAmountUnutilized}</span></h1>
                                                    </div>
                                                </div>

                                            </div>
                                        </TabPane>

                                        <TabPane tab="Withdrawl Funds" key="2" >
                                            <WithdrawlFunds dashLoggedIn={dashLoggedIn} dashRole={dashRole} dashUserName={dashUserName} fundsUserId={fundsUserId} />
                                        </TabPane>

                                        <TabPane tab="Funds" key="3" >
                                            <BonusFunds dashLoggedIn={dashLoggedIn} dashRole={dashRole} dashUserName={dashUserName} fundsUserId={fundsUserId} />
                                        </TabPane>

                                        <TabPane tab="Cancel & Abandoned" key="4" >
                                            <RefundFunds dashLoggedIn={dashLoggedIn} dashRole={dashRole} dashUserName={dashUserName} fundsUserId={fundsUserId} />
                                        </TabPane>

                                        <TabPane tab="Winning Funds" key="5" >
                                            <WinningFunds dashLoggedIn={dashLoggedIn} dashRole={dashRole} dashUserName={dashUserName} fundsUserId={fundsUserId} />
                                        </TabPane>

                                        <TabPane tab="Super Coins" key="6" >
                                            <SuperCoinsFunds dashLoggedIn={dashLoggedIn} dashRole={dashRole} dashUserName={dashUserName} fundsUserId={fundsUserId} />
                                        </TabPane>

                                    </Tabs>
                                </Modal>

                                <Modal title='Deduct Funds' open={isModalOpenDeduct} onCancel={() => setIsModalOpenDeduct(false)} footer={null}>
                                    <div className='modal-1-div-main'>
                                        <div className='modal-div-1'>
                                            <h2>User Id</h2>
                                            <h1>{fundsUserId}</h1>
                                        </div>
                                        <div className='modal-div-1'>
                                            <h2>User Name</h2>
                                            <h1>{dashLoggedIn}</h1>
                                        </div>

                                        <div className='modal-div-2'>
                                            <label className='offer_checkbox-2'>Enter Amount</label>
                                            <input value={deductAmount} onChange={handleAllChange(setDeductAmount)} className='offers_input-2' placeholder='Type Amount Here' />
                                        </div>

                                        <div className='modal-div-2'>
                                            <label className='offer_checkbox-2'>Select Type</label>
                                            <select value={deductType} onChange={handleAllChange(setDeductType)} className='offers_input-2'>
                                                <option className='offers_select_option' value=''>Select</option>
                                                <option className='offers_select_option' value='unutilized'>Unutilized</option>
                                                <option className='offers_select_option' value='bonus'>Bonus</option>
                                                <option className='offers_select_option' value='winning'>Winnings</option>
                                            </select>
                                        </div>

                                        <div className='modal-div-2'>
                                            <label className='offer_checkbox-2'>Internal Comment</label>
                                            <textarea value={deductInternalComment} onChange={handleAllChange(setDeductInternalComment)} className='offers_input-2' placeholder='Type your comment here' />
                                        </div>

                                        <div className='modal-div-2'>
                                            <label className='offer_checkbox-2'>TDS</label>
                                            <input value={deductTDS} type='text' onChange={handleAllChange(setDeductTDS)} className='offers_input-2' placeholder='Type TDS amount here' />
                                        </div>

                                        <div className='modal-div-2'>
                                            <label htmlFor='password' className='offer_checkbox-2'>Enter Master Password</label>
                                            <input
                                                type={passwordVisible ? 'text' : 'password'}
                                                value={deductMasterPassword} onChange={handleAllChange(setDeductMasterPassword)} className='offers_input-2' placeholder='Master Password Here' />
                                            {passwordVisible2 ? (
                                                <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                            ) : (
                                                <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                            )}
                                        </div>
                                        <button onClick={DeductFundsDashboardAPI} className='modal-div-button-2'>Deduct Funds</button>
                                    </div>
                                </Modal>

                                <Modal title='' width={700} open={generateModal} onCancel={() => setGenerateModal(false)} footer={null}>
                                    <div className='master-password-div-main-div-main'>

                                        <div className='offer_checkbox-fund-div'>
                                            <h1 className='offer_checkbox'>Generating Master Password For </h1>
                                            <h1 className='offer_checkbox-fund'>{dashUserName}</h1>
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Your User Name</label>
                                            <input value={generateUserName} onChange={handleAllChange(setGenerateUserName)} autoComplete="off" className='offers_input-90' />
                                        </div>

                                        <div>
                                            <label htmlFor='password' className='offer_checkbox'>Set Master Password</label>
                                            <div className='master-pass-div-main'>
                                                <input type={passwordVisible ? 'text' : 'password'} value={generateMasterPassword} onChange={handleAllChange(setGenerateMasterpassword)} autoComplete="off" className='offers_input-90' />
                                                {passwordVisible2 ? (
                                                    <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                                ) : (
                                                    <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor='password' className='offer_checkbox'>Re-enter Master Password</label>
                                            <div className='master-pass-div-main'>
                                                <input type={passwordVisible ? 'text' : 'password'} value={regenerateMasterPassword} onChange={handleAllChange(setReGenerateMasterpassword)} autoComplete="off" className='offers_input-90' />
                                                {passwordVisible2 ? (
                                                    <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                                ) : (
                                                    <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                                                )}
                                            </div>
                                            {isPasswordMatch ?
                                                <label className='offer_checkbox-error'>Password does not match or Less than 8 Words</label>
                                                :
                                                ''
                                            }
                                        </div>
                                        <button onClick={createMasterPasswordDashboard} className='cases_verify_button'>Create</button>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}
