import React from 'react';
import { useEffect, useState } from 'react';
import { authApi } from '../api/authApi';
import { useNavigate, Link } from 'react-router-dom';
// import jwt from 'jwt-decode'
import swal from 'sweetalert';
import Notiflix from 'notiflix';
import Cookies from 'universal-cookie';

const LoginPage = (props) => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [password, setPassword] = useState();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        localStorage.removeItem('loggedin');
        localStorage.removeItem('jwtToken');
        props.topbar(false);
        localStorage.removeItem('role');
        localStorage.removeItem('name');
    }, []);

    const getName=(email)=>{
        const name = email.split('@');
        const myName = name[0];
        return myName;

    };

    const login = (e) => {
        e.preventDefault();

        authApi.Login(name, password).then((data) => {
            if (data.status_code) {
                localStorage.setItem('loggedin', name);
                localStorage.setItem('jwtToken', data.message);
                localStorage.setItem('role', data.role);
                const CallerName = getName(name);
                localStorage.setItem('name', CallerName);
                props.topbar(true);

                navigate('/');
            } else if (!data.status_code) {
                {
                    Notiflix.Notify.failure(data.message);
                }
            }
        });
    };
    return (
        <div className='wrapper'>
            <section className='login-content'>
                <div className='container h-100'>
                    <div className='row align-items-center justify-content-center h-100'>
                        <div className='col-12'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <h2 className='mb-2'>Sign In</h2>
                                    <p>
                                        To Keep connected with us please login with your personal
                                        info.
                                    </p>
                                    <form onSubmit={login}>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='floating-label form-group'>
                                                    <input
                                                        className='floating-input form-control'
                                                        type='text'
                                                        placeholder=' '
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                        }}
                                                    />
                                                    <label>Name</label>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className='floating-label form-group'>
                                                    <input
                                                        className='floating-input form-control'
                                                        type='password'
                                                        placeholder=' '
                                                        onChange={(e) => {
                                                            setPassword(e.target.value);
                                                        }}
                                                    />
                                                    <label>Password</label>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='custom-control custom-checkbox mb-3'>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id='customCheck1'
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customCheck1'
                                                    >
                                                        Remember Me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 rtl-left'>
                                                <a
                                                    href='change-password.html'
                                                    className='text-primary float-right'
                                                >
                                                    Forgot Password?
                                                </a>
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-primary'>
                                            Sign In
                                        </button>
                                        <p className='mt-3'>
                                            Create an Account{' '}
                                            <a href='sign-up.html' className='text-primary'>
                                                Sign Up
                                            </a>
                                        </p>
                                    </form>
                                </div>
                                <div className='col-lg-6 mb-lg-0 mb-4 mt-lg-0 mt-4'>
                                    <img
                                        src='assets/images/login/01.png'
                                        className='img-fluid w-80'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LoginPage;
