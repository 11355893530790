import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';

export const authApi = {

    SignUp : async (admin_name, password, role) => {
        const json = {
            admin_name: admin_name,
            password,
            role
        };
        return await BASEAPIS.POSTAPI(json, 'dasAuth');
    },

    Login : async (admin_name, password) => {
        const json = {
            user_name: admin_name,
            password,
        };
        return await BASEAPIS.POSTAPI(json, 'dashLogin');
    },



    DashSignUp : async (jsonbody) => {
        return await BASEAPIS.POSTAPI(jsonbody, 'dashSignUp');
    },
};
