import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function MasterTable() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    });
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Master Table Details:</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Role Name</th>
                                                <th>Menu</th>
                                                <th>Sub Menu</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Account</td>
                                                <td>Account</td>
                                                <td>P/L</td>
                                                <td>
                                                    <button
                                                        className='btn btn-primary btn-sm'
                                                        data-toggle='modal'
                                                        data-target='#exampleModalScrollable'
                                                    >
                                                        <i className='ri-edit-line'></i>Edit
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Account</td>
                                                <td>Account</td>
                                                <td>P/L</td>
                                                <td>
                                                    <button
                                                        className='btn btn-primary btn-sm'
                                                        data-toggle='modal'
                                                        data-target='#exampleModalScrollable'
                                                    >
                                                        <i className='ri-edit-line'></i>Edit
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Account</td>
                                                <td>Account</td>
                                                <td>P/L</td>
                                                <td>
                                                    <button
                                                        className='btn btn-primary btn-sm'
                                                        data-toggle='modal'
                                                        data-target='#exampleModalScrollable'
                                                    >
                                                        <i className='ri-edit-line'></i>Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Match Name</th>
                                                <th>Game Type</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
