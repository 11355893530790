import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function JoinUserListingPage() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'></div>
                                        <div className='col'>
                                            <Link
                                                to={'/view_all_quiz_contest'}
                                                className='mt-2 btn btn-skyblue float-right'
                                            >
                                                {' '}
                                                <i className='ri-arrow-left-line'></i> Back
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>IsJoin</th>
                                                    <th>IsRefund</th>
                                                    <th>Iswin</th>
                                                    <th>Userid</th>
                                                    <th>JoinDate</th>
                                                    <th>Refund Date</th>
                                                    <th>WinDate</th>
                                                    <th>UserEmail</th>
                                                    <th>Username</th>
                                                    <th>Team</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>2.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>3.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>4.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>5.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>6.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>7.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>8.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>9.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>10.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>11.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>12.</td>
                                                    <td>1</td>
                                                    <td>0</td>
                                                    <td>1</td>
                                                    <td>6525</td>
                                                    <td>2022-08-05 12:25:36</td>
                                                    <td>Not available</td>
                                                    <td>2022-08-05 03:00:01</td>
                                                    <td>sajalbansal71@gmailcom</td>
                                                    <td>Not available</td>
                                                    <td>7</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
