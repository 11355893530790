import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SeriesAPI } from '../api/seriesApi';
import { ContestAPI } from '../api/contestApi';
import swal from 'sweetalert';
import Notiflix from 'notiflix';

export default function CreateCategoryPage() {
    const [FantasyList, setFantasyList] = useState([]);
    const [Fantasy, setFantasy] = useState('');
    const navigate = useNavigate();
    const [catName, setCatName] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [order, setOrder] = useState('');

    useEffect(() => {
        GetFantasy();
    }, []);

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) {
                setFantasyList(data.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const fantasyChange = (e) => {
        // alert(e.target.value)
        setFantasy(e.target.value);
    };
    const catChange = (e) => {
        setCatName(e.target.value);
    };
    const subTitleChange = (e) => {
        setSubTitle(e.target.value);
    };
    const orderChange = (e) => {
        setOrder(e.target.value);
    };


    const submitbtn = () => {

        const data = {
            Fantasy: Fantasy,
            ContestCategoryName: catName,
            SubTitle: subTitle,
            ContestOrder: order,
        };
        ContestAPI.POSTAPI(data).then((dt) => {
            console.log(dt);
            swal(dt.Message);
            // refresh()
            navigate('/view_contest_category');
        });
    };

    const refresh = (e) => {
        e.preventDefault();
        // alert('hi')
        setFantasy('');
        setCatName('');
        setSubTitle('');
        setOrder('');
        // document.getElementById('customFile').value = "";
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_contest_category'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View All Contest
                                        Categories
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add New Contest Category</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form onSubmit={refresh}>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Fantasy :</label>
                                            <select
                                                className='form-control mb-3'
                                                onChange={fantasyChange}
                                            >
                                                <option selected value=''>
                                                    Open this select menu
                                                </option>
                                                {FantasyList.map((fan) => (
                                                    <option value={fan.fantasy}>
                                                        {fan.fantasy}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Contest Category Name :</label>
                                            <input
                                                type='text'
                                                value={catName}
                                                onChange={catChange}
                                                className='form-control'
                                                id=''
                                                placeholder='Enter category name'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Sub Title :</label>
                                            <input
                                                type='text'
                                                value={subTitle}
                                                onChange={subTitleChange}
                                                className='form-control'
                                                defaultValue=''
                                                placeholder='Enter subtitle'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Order :</label>
                                            <input
                                                type='text'
                                                value={order}
                                                onChange={orderChange}
                                                className='form-control'
                                                defaultValue=''
                                                placeholder='Enter order'
                                            />
                                        </div>


                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={submitbtn}
                                        >
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button type='submit' className='btn bg-danger'>
                                            Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
