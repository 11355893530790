import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ContestAPI } from '../api/contestApi';
import Notiflix from 'notiflix';

export default function ContestRankPage() {
    const location = useLocation();

    const [MatchId, setMatchId] = useState(location.state.MatchId);
    const [ContestPoolId, setContestPoolId] = useState(location.state.ContestPoolId);
    const [TotalWinningAmount, setTotalWinningAmount] = useState(location.state.TotalWinningAmount);
    const [JoiningFees, setJoiningFees] = useState(location.state.JoiningFees);
    const [MaxSpots, setMaxSpots] = useState(location.state.MaxSpots);
    const [TotalWinners, setTotalWinners] = useState(location.state.TotalWinners);
    const [ContestCategoryName, setContestCategoryName] = useState(
        location.state.ContestCategoryName
    );
    const [Status, setStatus] = useState(location.state.Status);
    const [RankList, setRankList] = useState([]);
    const [checkWinning, setCheckWinning] = useState(0);
    const [rank, setRank] = useState('');
    const [amount, setAmount] = useState([0]);

    useEffect(() => {
        // console.log(location.state)
        getContestRank();
    }, []);

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }

        e.key === 'e' && e.preventDefault();
    };

    const getContestRank = () => {
        ContestAPI.GetPoolContestWinning(MatchId, ContestPoolId).then((data) => {
            if (data.Status) {
                setRankList(data.Data[0].WinningRank);
                console.log(data.Data[0].WinningRank);
                const total = data.Data[0].WinningRank.reduce((accumulator, value) => {
                    return accumulator + value.Amount;
                }, 0);

                console.log(total);
            }
        });
    };

    const rankChange = (e) => {
        setRank(e.target.value);
    };
    const amountChange = (e) => {
        setAmount(e.target.value);
    };
    const submitBtn = () => {
        // const money = amount.reduce((prevValue,currentValue) => prevValue + currentValue.amount,setAmount);
        // console.log(i,'hi')
        // console.log(money,'hi')
        // console.log(money)
        // console.log(amount,'hi')
        if (rank == '') {
            Notiflix.Notify.failure('Rank can\'t be blank');
        } else if (rank > location.state.TotalWinners) {
            Notiflix.Notify.failure('Exceeding the winner number!!');
        } else if (amount > location.state.TotalWinningAmount) {
            Notiflix.Notify.failure('Exceeding the max amount');
        } else if (amount == '') Notiflix.Notify.failure('Amount can\'t be blank');
        else {
            let contestDetail = {
                ContestPoolId: ContestPoolId,
                WinningRank: [
                    {
                        Rank: rank,
                        Amount: amount,
                    },
                ],
            };
            ContestAPI.CreatePoolContestWinningRank(contestDetail).then((data) => {
                Notiflix.Notify.success(data.Message);
                reset();
                getContestRank();
            });
        }
    };
    const reset = () => {
        setRank('');
        setAmount('');
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/assign_contest'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View Match Contest
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p>
                                                <strong> Winning Amount</strong> :{' '}
                                                {TotalWinningAmount}
                                            </p>
                                            <p>
                                                <strong>Joining Fees</strong> : {JoiningFees}
                                            </p>
                                            <p>
                                                <strong>Maximum Spots</strong> : {MaxSpots}
                                            </p>
                                            {/* <p><strong>Multiple Entry</strong> : No</p> */}
                                            <p>
                                                <strong>Total Winner</strong> : {TotalWinners}
                                            </p>
                                        </div>

                                        <div className='col-md-6'>
                                            {/* <p><strong>Confirm Contest</strong> : No</p> */}
                                            {/* <p><strong>Is Bonus Allowed</strong> : No</p> */}
                                            <p>
                                                <strong>Status</strong> : {Status}
                                            </p>
                                            <p>
                                                <strong>Contest Category</strong> :{' '}
                                                {ContestCategoryName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add Rank wise Amount</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        {/* <div className="row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="name">Type of Contest:</label> <br />
                                    <div className="custom-control custom-radio custom-control-inline rtl-mr-0">
                                        <input type="radio" id="price" name="contest1" className="custom-control-input"/>
                                        <label className="custom-control-label" htmlFor="price"> Amount </label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="prize" name="contest1" className="custom-control-input"/>
                                        <label className="custom-control-label" htmlFor="prize"> Bonus</label>
                                    </div>
                                </div>
                            </div> */}
                                        <div className='form-group'>
                                            <label htmlFor='text'>Rank Position :</label>
                                            <input
                                                type='number'
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                onKeyDown={(evt) => {
                                                    preventMinus(evt);
                                                }}
                                                className='form-control'
                                                id=''
                                                value={rank}
                                                onChange={rankChange}
                                                placeholder='5-10'
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                <label htmlFor="text">Numbers of Winners :</label>
                                <input type="text" className="form-control" defaultValue="" placeholder="Enter Numbers of Winners"/>
                            </div> */}
                                        <div className='form-group'>
                                            <label htmlFor='text'>Price Amount in Rupees :</label>
                                            <input
                                                type='number'
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                onKeyDown={(evt) =>
                                                    evt.key === 'e' && evt.preventDefault()
                                                }
                                                className='form-control'
                                                value={amount}
                                                onChange={amountChange}
                                                defaultValue=''
                                                placeholder='Enter Price Amount in Rupees'
                                            />
                                        </div>
                                        <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={submitBtn}
                                        >
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button
                                            type='button'
                                            className='btn bg-danger'
                                            onClick={reset}
                                        >
                                            Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Rank Position</th>
                                                    <th>Amount</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {RankList.map((data, ind) => (
                                                    <tr>
                                                        <td>{++ind}</td>
                                                        <td>{data.Rank}</td>
                                                        <td>{data.Amount}</td>
                                                        {/* <td>                                  
                                    <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i>Delete</button>
                                </td> */}
                                                    </tr>
                                                ))}
                                                {/* <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp; </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>Total-1</td>
                                <td>&nbsp;</td>
                                <td>3000</td>
                                <td>&nbsp;
                                    
                                </td>
                            </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
