import { useEffect, useState } from 'react';
import { PanAPI } from '../api/verifyPanApi';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';
import './ViewAllUsers.css';
import { ReportAPI } from '../api/ReportAPI';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import ExcelDownload from '../Component/ExcelDownloadHook';

export default function GST() {

    const navigation = useNavigate();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [gstData, setGstData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const gstDataAPI = () => {
        setIsLoading(true);
        (ReportAPI.GSTReportData(startDate, endDate).then(data => {
            if (data.status_code === true) {
                setGstData(data.data);
                setIsLoading(false);
                Notiflix.Notify.success('Data Fetched Successfully');
            } else {
                Notiflix.Notify.failure(data.msg);
                setIsLoading(false);
            }
        }));
    };

    const handleReset = () => {
        setIsLoading(false);
        setGstData([]);
    };

    const headers = ['amount', 'winning', 'bonus', 'bonus', 'unutilized', 'match_id', 'description', 'createdAt', 'contest_id', 'JoiningFees', 'winningPrice', 'ContestCategoryName', 'totalTeam', 'platformFeePerUser', 'leagueSize'];

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>

                <div style={{
                    marginLeft: 15, marginBottom: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', gap: 10
                    }}>
                        <input className='offers_input-gstData' type='date' onChange={(e) => setStartDate(e.target.value)} />
                        <input className='offers_input-gstData' type='date' onChange={(e) => setEndDate(e.target.value)} />
                        <button className='button-gst-div' onClick={gstDataAPI}>Search</button>
                        <button className='button-gst-div-reset' onClick={handleReset}>Reset</button>
                        {
                            gstData ? <ExcelDownload className='download-data-csv-bonus' data={gstData} filename={'GST Data'} headers={headers} /> : ''
                        }
                    </div>
                    <div>
                        <Link style={{
                            backgroundColor: 'red', color: 'white', padding: 10, borderRadius: 8
                        }} to={'/match-gst'}>GST Data
                        </Link>

                        {/* <button style={{
                            backgroundColor: 'red', color: 'white', padding: 10, borderRadius: 8
                        }}>
                            <CSVLink>Download Data</CSVLink>
                        </button> */}
                    </div>

                </div>

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>Amount</th>
                                                        <th>Winning</th>
                                                        <th>Bonus</th>
                                                        <th>Unutilized</th>
                                                        <th>Match Id</th>
                                                        <th>Description</th>
                                                        <th>Date</th>
                                                        <th>Contest Id</th>
                                                        <th>Joining Fees</th>
                                                        <th>Winning Price</th>
                                                        <th>Contest Category Name</th>
                                                        <th>Total Teams</th>
                                                        <th>Platform Fee Per User</th>
                                                        <th>League Size</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isLoading ?
                                                            (
                                                                <div className='rotatinglines-loader'>
                                                                    <div className=''>
                                                                        <RotatingLines
                                                                            marginTop='40'
                                                                            strokeColor='#c1030d'
                                                                            strokeWidth='5'
                                                                            animationDuration='0.75'
                                                                            width='50'
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                            :
                                                            (
                                                                gstData.slice(0, 9).map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td>{item?.amount}</td>
                                                                                <td>{item?.winning}</td>
                                                                                <td>{item?.bonus}</td>
                                                                                <td>{item?.unutilized}</td>
                                                                                <td>{item?.match_id}</td>
                                                                                <td>{item?.description}</td>
                                                                                <td>{item?.createdAt}</td>
                                                                                <td>{item?.contest_id}</td>
                                                                                <td>{item?.JoiningFees}</td>
                                                                                <td>{item?.winningPrice}</td>
                                                                                <td>{item?.ContestCategoryName}</td>
                                                                                <td>{item?.totalTeam}</td>
                                                                                <td>{item?.platformFeePerUser}</td>
                                                                                <td>{item?.leagueSize}</td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                }))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {
                                    gstData.length <= 0 ?
                                        <h style={{
                                            textAlign: 'center', marginBottom: 40, marginTop: 40
                                        }}>Please Search With Dates Accordingly</h> : ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
