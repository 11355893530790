import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QuizAPI } from '../api/quizApi';
import { CSVLink } from 'react-csv';

export default function ViewAllQuizContestPage() {
    const [QuizcontestList, setQuizContestList] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        quizContest();
    }, []);

    const headers = [
        { label: 'Name of the Tournament', key: 'title' },
        { label: 'Start Date', key: 'datestart' },
        { label: 'End date', key: 'dateend' },
        { label: 'Series Status', key: 'series_status' },
        { label: 'Status', key: 'status' },
        { label: 'Series Id', key: 'seriesId' },
    ];

    const quizContest = () => {
        QuizAPI.GetQuizContest('upcoming').then((data) => {
            if (data.Status) setQuizContestList(data.Data);
            console.log(data.Data.Quizdata);
        });
    };
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'></div>
                                    <div className='col'>
                                        <Link
                                            to={'/create_quiz_contest'}
                                            className='mt-2 btn btn-skyblue float-right'
                                        >
                                            {' '}
                                            <i className='ri-add-line'></i> Add New Quiz Contest
                                        </Link>

                                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                                            <div className='navbar-breadcrumb'>
                                                <h4 className='mb-0'>&nbsp;</h4>
                                            </div>
                                            <div className=''>
                                                <div className='btn-group m-2' role='group'>
                                                    <button
                                                        id='btnGroupDrop1'
                                                        type='button'
                                                        className='btn btn-primary dropdown-toggle'
                                                        data-toggle='dropdown'
                                                        aria-haspopup='true'
                                                        aria-expanded='false'
                                                    >
                                                        Export
                                                    </button>
                                                    <div
                                                        className='dropdown-menu'
                                                        aria-labelledby='btnGroupDrop1'
                                                        style={{}}
                                                    >
                                                        <button className='dropdown-item' href='#'>
                                                            Export All data
                                                        </button>

                                                        <CSVLink
                                                            filename='View All Series'
                                                            data={QuizcontestList}
                                                            headers={headers}
                                                        >
                                                            <button
                                                                className='dropdown-item'
                                                                href='#'
                                                            >
                                                                Export This Data
                                                            </button>
                                                        </CSVLink>
                                                        {/* <a className="dropdown-item" href="#">Delete</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>View All Quiz Contests</h4>
                                </div>
                            </div>

                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Contest Category</th>
                                                <th>Entry Fee</th>
                                                <th>Winning Amt</th>
                                                <th>Max Users</th>
                                                <th>Total Winners</th>
                                                <th>B</th>
                                                <th>Guranteed</th>
                                                <th>Start Date</th>
                                                <th>Status</th>
                                                <th>Question Count</th>
                                                <th>Price Card</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {QuizcontestList.map((contest, ind) =>
                                                contest.Quizdata.map((quizCon, ind) => (
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>{contest.QuizCatName}</td>
                                                        <td>Rs. {quizCon.JoiningFees}</td>
                                                        <td>Rs. {quizCon.TotalWinningAmount}</td>
                                                        <td>{quizCon.MaxSpots}</td>
                                                        <td>{quizCon.TotalWinners}</td>
                                                        <td>
                                                            <i
                                                                style={{
                                                                    display:
                                                                        quizCon.Bonus == 0
                                                                            ? 'none'
                                                                            : 'block',
                                                                }}
                                                                className='ri-check-line'
                                                            ></i>{' '}
                                                            ({quizCon.Bonus})
                                                        </td>
                                                        <td>{quizCon.isGuranted.toString()}</td>
                                                        <td>{quizCon.DateAndTime}</td>
                                                        <td>{quizCon.QuizStatus}</td>
                                                        <td>{quizCon.QuestionCount}</td>
                                                        <td>
                                                            <Link
                                                                to='/quiz_contest_rank'
                                                                state={{
                                                                    QuizCatId: quizCon.QuizCatId,
                                                                    QuizContestId:
                                                                        quizCon.QuizContestId,
                                                                    TotalWinners:
                                                                        quizCon.TotalWinners,
                                                                    Status: quizCon.QuizStatus,
                                                                    ContestCategoryName:
                                                                        contest.QuizCatName,
                                                                    Bonus: quizCon.Bonus,
                                                                    isGuranted: quizCon.isGuranted,
                                                                    JoiningFees:
                                                                        quizCon.JoiningFees,
                                                                    TotalWinningAmount:
                                                                        quizCon.TotalWinningAmount,
                                                                    MaxSpots: quizCon.MaxSpots,
                                                                }}
                                                                className='btn btn-skyblue btn-sm'
                                                            >
                                                                <i className='ri-edit-line'></i>Add
                                                                Rank
                                                            </Link>
                                                        </td>
                                                        {/* <td>Not</td> */}
                                                        <td>
                                                            <Link
                                                                to={'/join_user_listing'}
                                                                className='btn btn-success btn-sm'
                                                            >
                                                                View Joined Users
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                            {/* <tr>
                            <td>1.</td>
                            <td>Amount</td>
                            <td>Rs. 5</td>
                            <td>Rs. 10</td>
                            <td>2</td>
                            <td><i className="ri-close-circle-line"></i></td>
                            <td>Amount</td>
                            <td>2022-08-05, 12:50:00</td>
                            <td>Not</td>
                            <td>Is Canceled</td>
                            <td><button type="submit" className="btn btn-skyblue btn-sm">View Question</button></td>
                            <td>
                                <button type="submit" className="btn btn-success btn-sm">View Joined Users</button>
                            </td>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td>Amount</td>
                            <td>Rs. 5</td>
                            <td>Rs. 10</td>
                            <td>2</td>
                            <td><i className="ri-close-circle-line"></i></td>
                            <td>Amount</td>
                            <td>2022-08-05, 12:50:00</td>
                            <td>Not</td>
                            <td>Is Canceled</td>
                            <td><button type="submit" className="btn btn-skyblue btn-sm">View Question</button></td>
                            <td>
                                <button type="submit" className="btn btn-success btn-sm">View Joined Users</button>
                            </td>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td>Amount</td>
                            <td>Rs. 5</td>
                            <td>Rs. 10</td>
                            <td>2</td>
                            <td><i className="ri-close-circle-line"></i></td>
                            <td>Amount</td>
                            <td>2022-08-05, 12:50:00</td>
                            <td>Not</td>
                            <td>Is Canceled</td>
                            <td><button type="submit" className="btn btn-skyblue btn-sm">View Question</button></td>
                            <td>
                                <button type="submit" className="btn btn-success btn-sm">View Joined Users</button>
                            </td>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td>Amount</td>
                            <td>Rs. 5</td>
                            <td>Rs. 10</td>
                            <td>2</td>
                            <td><i className="ri-close-circle-line"></i></td>
                            <td>Amount</td>
                            <td>2022-08-05, 12:50:00</td>
                            <td>Not</td>
                            <td>Is Canceled</td>
                            <td><button type="submit" className="btn btn-skyblue btn-sm">View Question</button></td>
                            <td>
                                <button type="submit" className="btn btn-success btn-sm">View Joined Users</button>
                            </td>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td>Amount</td>
                            <td>Rs. 5</td>
                            <td>Rs. 10</td>
                            <td>2</td>
                            <td><i className="ri-close-circle-line"></i></td>
                            <td>Amount</td>
                            <td>2022-08-05, 12:50:00</td>
                            <td>Not</td>
                            <td>Is Canceled</td>
                            <td><button type="submit" className="btn btn-skyblue btn-sm">View Question</button></td>
                            <td>
                                <button type="submit" className="btn btn-success btn-sm">View Joined Users</button>
                            </td>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td>Amount</td>
                            <td>Rs. 5</td>
                            <td>Rs. 10</td>
                            <td>2</td>
                            <td><i className="ri-close-circle-line"></i></td>
                            <td>Amount</td>
                            <td>2022-08-05, 12:50:00</td>
                            <td>Not</td>
                            <td>Is Canceled</td>
                            <td><button type="submit" className="btn btn-skyblue btn-sm">View Question</button></td>
                            <td>
                                <button type="submit" className="btn btn-success btn-sm">View Joined Users</button>
                            </td>
                        </tr> */}
                                        </tbody>
                                        {/* <tfoot>
                        <tr>
                            <th>S.No.</th>
                            <th>Contest Category</th>
                            <th>Entry Fee</th>
                            <th>Winning Amt</th>
                            <th>Max Users</th>
                            <th>B</th>
                            <th>C Type</th>
                            <th>Start Date</th>
                            <th>Price Card</th>
                            <th>Status</th>
                            <th>Question</th>
                            <th>Action</th>
                        </tr>
                    </tfoot> */}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
