import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MatchAPI } from '../api/matchApi';
import { SeriesAPI } from '../api/seriesApi';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';
import Pagination from '../Component/Pagination';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

export default function UpcomingMatchePage() {
    const [upcomingMatchList, setUpcomingMatch] = useState([]);
    const [match, setMatch] = useState('');
    const [fantasyList, setFantasyList] = useState([]);
    const [dateStart, setdateStart] = useState('');
    const [matchChangeData, setMatchChangeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const role = localStorage.getItem('role');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        getUpcoming();
        GetFantasy();
    }, []);

    const [isSpinning, setIsSpinning] = useState(false);

    const feedNewUpcoming = () => {
        setIsSpinning(true);
        MatchAPI.NewUpcoming().then((data) => {
            if (data.Status) {
                Notiflix.Notify.success(data.Message);
                setIsSpinning(false);
                console.log(data);
            } else {
                Notiflix.Notify.failure(data.msg);
                setIsSpinning(false);
            }
        });
        getUpcoming();
    };


    const launchMatch = (apiMatchID, match_id, cid) => {
        let launchData = {
            apiMatchId: apiMatchID,
            status: true,
        };

        MatchAPI.LaunchUpcoming(launchData).then((data) => {
            console.log(data);
            Notiflix.Notify.success(data.message);
        });

        let LaunchEleven = {
            api_match_id: apiMatchID,
            match_id: match_id,
            cid: cid,
        };

        MatchAPI.LaunchEleven(apiMatchID, match_id, cid).then((data) => {
            Notiflix.Notify.success(data.Message);
        });
    };

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
            console.log(fantasyList);
        });
    };

    const matchChange = (e) => {
        setMatchChangeData(e.target.value);
    };

    const handleReset = () => {
        setIsLoading(true);
        setMatchChangeData('');
        setdateStart('');
        setMatch('');
        getUpcoming();
    };


    const handleWeek = (e) => {
        let value = moment(e).format('dddd');
        console.log(value);
        return value;
    };

    // Pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [totalLength, setTotalLength] = useState(1);
    const [totalEntires, setTotalEntries] = useState(1);
    const [totalPages, setTotalPages] = useState(100);
    const [pageSize, setPageSize] = useState(100);

    const getUpcoming = () => {
        MatchAPI.GetUpcoming(dateStart, match, matchChangeData, pageSize, pageNumber).then((data) => {
            setUpcomingMatch(data.data);
            setTotalPages(data.total_page);
            setTotalLength(data.total_entries);
            setIsLoading(false);
            setTotalEntries(data.total_entries);
            setTotalEntries(data.total_length);
            console.log(data.data);
        });
    };

    const changePage = (data) => {
        setPageNumber(data);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor='name'>Match type</label>
                                        <select
                                            value={matchChangeData}
                                            className='form-control mb-3'
                                            onChange={matchChange}
                                        >
                                            <option value=''>All</option>
                                            <option value='T10'>T10</option>
                                            <option value='T20'>T20</option>
                                            <option value='ODI'>ODI</option>
                                            <option value='Test'>Test</option>
                                        </select>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='name'>Select Match</label>
                                        <input
                                            type='texmatch'
                                            value={match}
                                            onChange={(e) => {
                                                setMatch(e.target.value);
                                            }}
                                            className='form-control'
                                            placeholder='Search By Name'
                                        />
                                    </div>

                                    <div className='col'>
                                        <label htmlFor='exampleInputdate'>Select Date :</label>
                                        <input
                                            type='date'
                                            className='form-control'
                                            id='exampleInputdate'
                                            value={dateStart}
                                            onChange={(e) => {
                                                setdateStart(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                        <br />
                                        <label htmlFor='name'></label>
                                        <button
                                            onClick={() => {
                                                getUpcoming();
                                            }}
                                            className='btn btn-primary'
                                        >
                                            Search
                                        </button>
                                        &nbsp;
                                        <button
                                            onClick={() => handleReset()}
                                            className='btn bg-danger'
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            {
                                isSpinning ?
                                    <div className='cases_pri_59'>
                                        <Spin indicator={antIcon} /> </div>
                                    :
                                    <div className=''>
                                        <button
                                            type='button'
                                            className='cases_pri_59'
                                            onClick={feedNewUpcoming}
                                        >
                                            <i className='ri-download-2-line'></i> Import Match
                                        </button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                {
                    isLoading && (
                        <div className='col-sm-12 col-lg-12'>
                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                <RotatingLines
                                    marginTop='40'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )
                }

                {!isLoading && (
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Upcoming Matches</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Match</th>
                                                    <th>Match type</th>
                                                    <th>Contest Name</th>
                                                    <th>Match Time</th>
                                                    <th>Match Day</th>
                                                    <th>Squad Available</th>

                                                    {role != 3 && role != 5 && <th>Action</th>}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {upcomingMatchList &&
                                                    upcomingMatchList.length > 0 &&
                                                    upcomingMatchList.map((match, i) => (
                                                        <tr>
                                                            <td>
                                                                {++i}
                                                            </td>
                                                            <td>{match.matchName}</td>
                                                            <td>{match.matchType}</td>
                                                            <td>{match.title}</td>
                                                            <td>{match.StartDateIST}</td>
                                                            <td>
                                                                {handleWeek(match.StartDateIST)}
                                                            </td>

                                                            <td>
                                                                {match.preSquad === true
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </td>

                                                            {role != 3 && role != 5 && <td>
                                                                <Link
                                                                    to='/edit_existing_matches'
                                                                    state={{
                                                                        matchId: match.matchId,
                                                                        apiMatchId:
                                                                            match.apiMatchId,
                                                                        matchName: match.matchName,
                                                                        teamAname: match.teamAname,
                                                                        teamBname: match.teamBname,
                                                                        StartDateIST:
                                                                            match.StartDateIST,
                                                                        matchType: match.matchType,
                                                                    }}
                                                                    title='Edit'
                                                                    className='btn btn-primary btn-sm'
                                                                >
                                                                    <i className='ri-edit-line'></i>
                                                                </Link>
                                                                <Link
                                                                    to='/create_custom_contest'
                                                                    state={{
                                                                        matchId: match.matchId,
                                                                        matchName: match.matchName,
                                                                        StartDateIST:
                                                                            match.StartDateIST,
                                                                        cid: match.cid,
                                                                    }}
                                                                >
                                                                    {
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-success btn-sm mt-1'
                                                                            onClick={(e) => {
                                                                                if (
                                                                                    window.confirm(
                                                                                        'Are you sure to launch match?'
                                                                                    )
                                                                                        ? launchMatch(
                                                                                            match.apiMatchId,
                                                                                            match.matchId,
                                                                                            match.cid
                                                                                        )
                                                                                        : e.preventDefault()
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className='ri-edit-line'></i>
                                                                            Launch
                                                                        </button>
                                                                    }
                                                                </Link>
                                                            </td>}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>


                            </div>
                            <Pagination
                                className='pagination-css' totalEntries={totalEntires}
                                totalPages={totalPages} changePage={changePage}
                                pageNumber={pageNumber}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
