import React from 'react';
import { useEffect } from 'react';

const ForgotPassword = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='wrapper'>
            <section className='login-content'>
                <div className='container h-100'>
                    <div className='row align-items-center justify-content-center h-100'>
                        <div className='col-12'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <h2 className='mb-2'>Forgot Password</h2>
                                    <p>Help us reset your account's password.</p>
                                    <form>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='floating-label form-group'>
                                                    <input
                                                        className='floating-input form-control'
                                                        type='text'
                                                        placeholder=' '
                                                    />
                                                    <label>User ID</label>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className='floating-label form-group'>
                                                    <input
                                                        className='floating-input form-control'
                                                        type='password'
                                                        placeholder=' '
                                                    />
                                                    <label>Password</label>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='custom-control custom-checkbox mb-3'>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id='customCheck1'
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customCheck1'
                                                    >
                                                        Remember Me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 rtl-left'>
                                                <a
                                                    href='change-password.html'
                                                    className='text-primary float-right'
                                                >
                                                    Forgot Password?
                                                </a>
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-primary'>
                                            Submit
                                        </button>
                                        <p className='mt-3'>
                                            Create an Account{' '}
                                            <a href='sign-up.html' className='text-primary'>
                                                Sign Up
                                            </a>
                                        </p>
                                    </form>
                                </div>
                                <div className='col-lg-6 mb-lg-0 mb-4 mt-lg-0 mt-4'>
                                    <img
                                        src='assets/images/login/forgot3.jpg'
                                        className='img-fluid w-80'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForgotPassword;
