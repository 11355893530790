import { baseURL } from './apiBaseUrl';

export const UserActivityLogApi = {
    GetActivityLog: async (id, email, phone) => {
        console.log(id);
        // console.log(email)
        // console.log(phone)
        //alert()
        return await fetch(
            baseURL + 'getActivityLog?user_id=' + id + '&email=' + email + '&phone=' + phone,
            {
                method: 'GET',
                headers: {
                    // these could be different for your API call
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                // body: JSON.stringify(json),
            }
        )
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                //  alert("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
