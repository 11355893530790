import { Link } from 'react-router-dom';

export default function AddYoutuberPage() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <Link
                                                to={'/view_youtuber'}
                                                className='mt-2 btn btn-skyblue float-right'
                                            >
                                                <i className='ri-eye-2-line'></i>View All Youtuber
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add Youtuber</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Name :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Name'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Email :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Email'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Mobile :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Mobile no.'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Password :</label>
                                            <input
                                                type='password'
                                                className='form-control'
                                                id=''
                                                placeholder='Password'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='text'>Refer Code :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Refer code'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Percentage :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Percentage'
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor='text'>Contest Image</label>
                                            <div className='custom-file'>
                                                <input
                                                    type='file'
                                                    className='custom-file-input'
                                                    id='customFile'
                                                />
                                                <label
                                                    className='custom-file-label'
                                                    htmlFor='customFile'
                                                >
                                                    Choose file
                                                </label>
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-success'>
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
