import React, { useEffect, useState } from 'react';
import './ViewAllUsers.css';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import { AiOutlineDeleteRow, AiFillSwitcher, AiFillWallet } from 'react-icons/ai';
import { GiPodiumWinner, GiTwoCoins } from 'react-icons/gi';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

export default function WalletReport() {
    const [walletData, setWalletData] = useState([]);
    const [globalBonus, setGlobalBonus] = useState('');
    const [globalWinning, setGlobalWinning] = useState('');
    const [superCoins, setSuperCoins] = useState('');
    const [totalBalance, setTotalBalance] = useState('');
    const [totalUnutilized, setTotalUnutilized] = useState('');
    const [thisData, setThisData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [teamName, setTeamName] = useState('');
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [isclicked, setIsclicked] = useState(false);

    // Fetch initial wallet data when the component mounts
    useEffect(() => {
        fetchAllWalletData();
        // fetchWalletHeaders();
    }, []);

    const setterFunction = (setter) => (e) => {
        const { value } = e.target;
        setter(value);
    };

    const fetchAllWalletData = () => {
        setIsFilterShow(false);
        AccountManagerAPI.AllWalletReportData()
            .then((data) => {
                if (data.status_code) {
                    const walletSum = data.walletSum[0];
                    setTotalBalance(walletSum.totalBalance);
                    setTotalUnutilized(walletSum.totalUnutilized);
                    setGlobalBonus(walletSum.totalBonus);
                    setGlobalWinning(walletSum.totalWinning);
                    setSuperCoins(walletSum.totalRoyaltyPoint);
                } else {
                    Notiflix.Notify.failure(data.msg);
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                Notiflix.Notify.failure('Something Went Wrong');
            });
    };

    const fetchWalletHeaders = () => {
        AccountManagerAPI.allWalletData()
            .then((data) => {
                if (data.status_code) {
                    setThisData(data.data);
                    setIsLoading(false);
                } else {
                    Notiflix.Notify.failure('Something Went Wrong');
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                Notiflix.Notify.failure('Something Went Wrong');
            });
    };

    const filterWalletReport = () => {
        setIsFilterShow(true);
        setIsLoading(true);
        AccountManagerAPI.filterWalletReport(userId, phoneNumber, email, teamName)
            .then((res) => {
                if (res.status_code) {
                    const filteredWalletData = res.message[0];
                    setTotalBalance(filteredWalletData.total_balance);
                    setTotalUnutilized(filteredWalletData.unutilized);
                    setGlobalBonus(filteredWalletData.bonus);
                    setGlobalWinning(filteredWalletData.winning);
                    setSuperCoins(filteredWalletData.super_coin);
                    setWalletData(filteredWalletData);
                    setEmail(filteredWalletData.email);
                    setPhoneNumber(filteredWalletData.phone);
                    setUserID(filteredWalletData.user_id);
                    setTeamName(filteredWalletData.team_name);
                    setIsLoading(false);
                } else {
                    Notiflix.Notify.failure('Something Went Wrong');
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error('API Error:', error);
                Notiflix.Notify.failure('Something Went Wrong');
            });
    };

    const handleReset = () => {
        setPhoneNumber('');
        setEmail('');
        setTeamName('');
        setUserID('');
        fetchAllWalletData();
        setIsLoading(false);
        setIsclicked(false);
    };

    const buttonClicked = () => {
        setIsLoading(true);
        fetchWalletHeaders();
        setIsclicked(true);
    };

    const headers = [
        { label: 'User_ID', key: 'user_id' },
        { label: 'Team_Name', key: 'team_name' },
        { label: 'Email', key: 'email' },
        { label: 'Mobile', key: 'phone' },
        { label: 'Total_Bonus', key: 'bonus' },
        { label: 'Super_Coin_Balance', key: 'royalty_point' },
        { label: 'Total_Unutilized', key: 'unutilized' },
        { label: 'Total_Winning', key: 'winning' },
        { label: 'Total_Balance', key: 'total_balance' },
    ];

    return (
        <div className='dashboard_home'>
            <div className=''>
                <div className='content-page rtl-page'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    <div className='navbar-breadcrumb dashboard_div'>
                                        <h4 className='mb-0 dashboard_welcome'>Wallet Report</h4>
                                    </div>
                                </div>
                            </div>

                            {/* Inputs */}

                            <div className='card-body'>
                                <div className='table-responsive'>
                                    {/* <form> */}
                                    <div className='row'>
                                        <div className='col-sm-12 col-lg-12'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='d-flex flex-wrap'>
                                                        <div className='col'>
                                                            <label htmlFor='name'>User Id :</label>
                                                            <input
                                                                onChange={setterFunction(setUserID)}
                                                                type='text'
                                                                value={userId}
                                                                className='form-control wallet-input'
                                                                placeholder='Search User ID'
                                                            />
                                                        </div>

                                                        <div className='col'>
                                                            <label htmlFor='name'>Email :</label>
                                                            <input
                                                                onChange={setterFunction(setEmail)}
                                                                type='text'
                                                                value={email}
                                                                className='form-control wallet-input'
                                                                placeholder='Search Email'
                                                            />
                                                        </div>

                                                        <div className='col'>
                                                            <label htmlFor='name'>
                                                                Team Name :
                                                            </label>
                                                            <input
                                                                onChange={setterFunction(
                                                                    setTeamName
                                                                )}
                                                                type='text'
                                                                value={teamName}
                                                                className='form-control wallet-input'
                                                                placeholder='Search Team Name'
                                                            />
                                                        </div>

                                                        <div className='col'>
                                                            <label htmlFor='name'>Phone</label>
                                                            <input
                                                                onChange={setterFunction(
                                                                    setPhoneNumber
                                                                )}
                                                                type='number'
                                                                value={phoneNumber}
                                                                className='form-control wallet-input'
                                                                placeholder='Search Phone'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='form-row'>
                                                            <div className='col'>
                                                                <br />
                                                                <label htmlFor='name'></label>
                                                                <button
                                                                    onClick={() =>
                                                                        filterWalletReport()
                                                                    }
                                                                    type='button'
                                                                    className='btn btn-primary'
                                                                >
                                                                    Search
                                                                </button>
                                                                &nbsp;
                                                                <input
                                                                    type='reset'
                                                                    onClick={() => handleReset()}
                                                                    className='btn btn-danger'
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>

                            {/* INputs End */}
                            <div className='col-lg-11'>
                                <div>
                                    <div className='row'>
                                        <div className='col-md-3 '>
                                            <div className='card card-block card-stretch card-height rtl-mode dashboard_card'>
                                                <div className='card-body'>
                                                    <div className='top-block d-flex align-items-center justify-content-between mb-3'>
                                                        <h3 className='wallet-text'>
                                                            {Math.ceil(totalBalance)}
                                                        </h3>
                                                        <div className='bg-danger icon iq-icon-box-2 mr-2 rounded rtl-ml-2  rtl-mr-0'>
                                                            <AiFillSwitcher />
                                                        </div>
                                                    </div>
                                                    <h4>Total Balance</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 '>
                                            <div className='card card-block card-stretch card-height rtl-mode dashboard_card'>
                                                <div className='card-body'>
                                                    <div className='top-block d-flex align-items-center justify-content-between mb-3'>
                                                        <h3 className='wallet-text'>
                                                            {Math.ceil(totalUnutilized)}
                                                        </h3>
                                                        <div className='bg-danger icon iq-icon-box-2 mr-2 rounded rtl-ml-2  rtl-mr-0'>
                                                            <AiOutlineDeleteRow />
                                                        </div>
                                                    </div>
                                                    <h4>Unutilized</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 '>
                                            <div className='card card-block card-stretch card-height rtl-mode dashboard_card'>
                                                <div className='card-body'>
                                                    <div className='top-block d-flex align-items-center justify-content-between mb-3'>
                                                        <h3 className='wallet-text'>
                                                            {Math.ceil(globalWinning)}
                                                        </h3>
                                                        <div className='bg-danger icon iq-icon-box-2 mr-2 rounded rtl-ml-2  rtl-mr-0'>
                                                            <GiPodiumWinner />
                                                        </div>
                                                    </div>
                                                    <h4>Winnings</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 '>
                                            <div className='card card-block card-stretch card-height rtl-mode dashboard_card'>
                                                <div className='card-body'>
                                                    <div className='top-block d-flex align-items-center justify-content-between mb-3'>
                                                        <h3 className='wallet-text'>
                                                            {Math.ceil(globalBonus)}
                                                        </h3>
                                                        <div className='bg-danger icon iq-icon-box-2 mr-2 rounded rtl-ml-2  rtl-mr-0'>
                                                            <AiFillWallet />
                                                        </div>
                                                    </div>
                                                    <h4>Bonus</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 '>
                                            <div className='card card-block card-stretch card-height rtl-mode dashboard_card'>
                                                <div className='card-body'>
                                                    <div className='top-block d-flex align-items-center justify-content-between mb-3'>
                                                        <h3 className='wallet-text'>
                                                            {Math.ceil(superCoins)}
                                                        </h3>
                                                        <div className='bg-danger icon iq-icon-box-2 mr-2 rounded rtl-ml-2  rtl-mr-0'>
                                                            <GiTwoCoins />
                                                        </div>
                                                    </div>
                                                    <h4>Super Coins</h4>
                                                </div>
                                            </div>
                                        </div>

                                        {!isclicked && (
                                            <div className='wallet_div'>
                                                <button
                                                    className='wallet_button'
                                                    onClick={buttonClicked}
                                                >
                                                    Click to download
                                                </button>
                                            </div>
                                        )}

                                        {isLoading && (
                                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='70'
                                                        strokeColor='#c1030d'
                                                        strokeWidth='5'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {!isLoading && isclicked && (
                                            <div className='wallet_div'>
                                                <CSVLink
                                                    filename='Wallet Report'
                                                    data={thisData}
                                                    headers={headers}
                                                    className='wallet_button'
                                                >
                                                    <button className='wallet_button'>
                                                        Download All Data
                                                    </button>
                                                </CSVLink>

                                                {userId && userId.length > 0 ? (
                                                    <Link
                                                        className='wallet_button-2'
                                                        to={'/get_user_details'}
                                                        state={{
                                                            userId,
                                                        }}
                                                        type='submit'
                                                    >
                                                        See User Details
                                                    </Link>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* END */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
