import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const GlobalContestAPI = {
    // GetGlobalContest: async (fantasy) => {
    //     console.log(key);
    //     if (!fantasy) {
    //         return await fetch(baseURL + 'fetchAllGlobalContest?Fantasy=all', {
    //             method: 'GET',
    //             headers: {
    //                 // these could be different for your API call
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json; charset=utf-8',
    //                 'x-api-key': key,
    //                 'mai-hu':'motabhai007'
    //             },
    //             // body: JSON.stringify(jsonbody),*/
    //         })
    //             .then((response) => response.json())
    //             .then((json) => {
    //                 //notifyMessage("processing....")
    //                 // notifyMessage("Processing....")
    //                 //  console.log("api===="+json)
    //                 if (json != null) {
    //                     //  console.log("api===="+JSON.stringify(json))
    //                     return json;
    //                 } else return false;
    //             })
    //             .catch((err) => {
    //                 console.log('Some error occured, please retry');

    //                 console.log(err);
    //                 return false;
    //             });
    //     } else {
    //         return await fetch(baseURL + 'fetchAllGlobalContest?Fantasy=' + fantasy, {
    //             method: 'GET',
    //             headers: {
    //                 // these could be different for your API call
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json; charset=utf-8',
    //                 'x-api-key': key,
    //             },
    //             // body: JSON.stringify(jsonbody),*/
    //         })
    //             .then((response) => response.json())
    //             .then((json) => {
    //                 //notifyMessage("processing....")
    //                 // notifyMessage("Processing....")
    //                 //  console.log("api===="+json)
    //                 if (json != null) {
    //                     //  console.log("api===="+JSON.stringify(json))
    //                     return json;
    //                 } else return false;
    //             })
    //             .catch((err) => {
    //                 console.log('Some error occured, please retry');

    //                 console.log(err);
    //                 return false;
    //             });
    //     }
    //     //alert()
    // },

    CreateGlobalContest: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'CreateGlobalContest', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    createCustomContest: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'CreateCustomContest', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    DeleteMultipleGlobalContest: async (jsonbody) => {
        console.log(jsonbody);

        let data = {
            _id: jsonbody
        };
        return await fetch(baseURL + 'deleteMultipleGlobalContest', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    EditGlobalContest: async (jsonbody) => {
        return await fetch(baseURL + 'editGlobalContest', {
            method: 'PUT',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    CreateGlobalRank: async (jsonbody) => {
        console.log(jsonbody);
        //alert()
        return await fetch(baseURL + 'CreateGlobalRank', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetGlobalRank: async (id) => {
        console.log(id);
        //alert()
        return await fetch(baseURL + 'GetGlobalRank?Id=' + id, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    RemoveGlobalRank: async (id) => {
        console.log(id);
        //alert()
        return await fetch(baseURL + 'RemoveGlobalRank?_id=' + id, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    AddCustomContest: async (jsonbody) => {
        //alert()
        return await fetch(baseURL + 'AddCustomContest', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    AddCustomContestRank: async (jsonbody) => {
        console.log(jsonbody);
        //alert()
        return await fetch(baseURL + 'AddCustomContestRank', {
            method: 'POST',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetCustomContestRank: async (id) => {
        console.log(id);
        //alert()
        return await fetch(baseURL + 'GetCustomContestRank?Id=' + id, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'x-api-key': key,
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },

    GetGlobalContest: async (json) => {
        return await BASEAPIS.POSTAPI(json, 'getGlobalContest');
    },

    FetchAllGlobalContest: async (fantasy) => {
        if (!fantasy) {
            return await BASEAPIS.GETAPI('fetchAllGlobalContest?Fantasy=all');
        } else {
            return await BASEAPIS.GETAPI('fetchAllGlobalContest?Fantasy=' + fantasy,);

        }
        //alert()
    },


    CancelContest: async (json) => {
        return await BASEAPIS.POSTAPI(json, 'cancelContest');
    },

    LaunchContest: async (json) => {
        return await BASEAPIS.POSTAPI(json, 'launchContest');
    },

    GetLaunchedContests: async (json) => {
        return await BASEAPIS.POSTAPI(json, 'getLaunchedContests');
    },

};
