import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function PlayersSusbstitutePage() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    });
    return (
        <div>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_player'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-add-line'></i>View All Players
                                    </Link>
                                    &nbsp;
                                    <a
                                        className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                        href='#'
                                    >
                                        Export <i className='ri-add-line m-0'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Players Subsitute</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Unique Id</th>
                                                    <th>Player Name</th>
                                                    <th>Player Role</th>
                                                    <th>Player Credit</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        {/* <button type="submit" className="btn btn-skyblue">Edit</button>
                                    &nbsp; */}
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>R2345</td>
                                                    <td>Rohit Sharma</td>
                                                    <td>Batsman</td>
                                                    <td>9.5</td>

                                                    <td>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-skyblue'
                                                        >
                                                            Edit
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Inactive
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Unique Id</th>
                                                    <th>Player Name</th>
                                                    <th>Player Role</th>
                                                    <th>Player Credit</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
