import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { ServiceAPI } from '../api/serviceApi';
export default function ReferralsPage() {
    //primary_sms, secondary_sms
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const smsServiceSwitch = (e) => {
        e.preventDefault();
        //    console.log(e.target[0].value);
        //    console.log(e.target[1].value);
        let data = {
            primary_sms: e.target[0].value,
            secondary_sms: e.target[1].value,
        };
        ServiceAPI.SmsServiceSwitch(data).then((res) => {
            Notiflix.Notify.success(res.message);
        });
    };

    const emailServiceSwitch = (e) => {
        e.preventDefault();
        //    console.log(e.target[0].value);
        //    console.log(e.target[1].value);
        let data = {
            primary_email: e.target[0].value,
            secondary_email: e.target[1].value,
        };
        ServiceAPI.EmailServiceSwitch(data).then((res) => {
            Notiflix.Notify.success(res.message);
        });
    };

    const panServiceSwitch = (e) => {
        e.preventDefault();
        //    console.log(e.target[0].value);
        //    console.log(e.target[1].value);
        let data = {
            primary_pan: e.target[0].value,
            secondary_pan: e.target[1].value,
        };
        ServiceAPI.PanServiceSwitch(data).then((res) => {
            Notiflix.Notify.success(res.message);
        });
    };

    const bankServiceSwitch = (e) => {
        e.preventDefault();
        //    console.log(e.target[0].value);
        //    console.log(e.target[1].value);
        let data = {
            primary_bank: e.target[0].value,
            secondary_bank: e.target[1].value,
        };
        ServiceAPI.BankServiceSwitch(data).then((res) => {
            Notiflix.Notify.success(res.message);
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Services Switch</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form method='post' name='smsFrm' onSubmit={smsServiceSwitch}>
                                        <div className='row mb-4'>
                                            <div className='col-md-12 mb-3'>SMS:</div>
                                            <div className='col-md-3'>
                                                <div>Primary Provider</div>
                                                <select
                                                    id='primarySMS'
                                                    className='form-control mb-3'
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='cellonemedia'>
                                                        cellonemedia
                                                    </option>
                                                    <option value='msg91'>msg91</option>
                                                </select>
                                            </div>
                                            <div className='col-md-3'>
                                                <div>Secondary Provider</div>
                                                <select
                                                    id='secondarySMS'
                                                    className='form-control mb-3'
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='cellonemedia'>
                                                        cellonemedia
                                                    </option>
                                                    <option value='msg91'>msg91</option>
                                                </select>
                                            </div>
                                            <div className='col-md-12'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-skyblue btn-sm'
                                                    data-toggle='modal'
                                                    data-target='#exampleModalScrollable'
                                                >
                                                    <i className='la la-feather-alt'></i>Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <form method='post' name='smsFrm' onSubmit={emailServiceSwitch}>
                                        <div className='row mb-4'>
                                            <div className='col-md-12 mb-3'>E-MAIL:</div>
                                            <div className='col-md-3'>
                                                <div>Primary Provider</div>
                                                <select className='form-control mb-3'>
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='email-push'>email-push</option>
                                                </select>
                                            </div>
                                            <div className='col-md-3'>
                                                <div>Secondary Provider</div>
                                                <select
                                                    id='secondaryEMAIL'
                                                    className='form-control mb-3'
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='email-push'>email-push</option>
                                                </select>
                                            </div>
                                            <div className='col-md-12'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-skyblue btn-sm'
                                                    data-toggle='modal'
                                                    data-target='#exampleModalScrollable'
                                                >
                                                    <i className='la la-feather-alt'></i>Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <form method='post' name='smsFrm' onSubmit={panServiceSwitch}>
                                        <div className='row mb-4'>
                                            <div className='col-md-12 mb-3'>PAN VERFICATION:</div>
                                            <div className='col-md-3'>
                                                <div>Primary Provider</div>
                                                <select className='form-control mb-3'>
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='sandbox'>sandbox</option>
                                                </select>
                                            </div>
                                            <div className='col-md-3'>
                                                <div>Secondary Provider</div>
                                                <select
                                                    id='secondaryPAN'
                                                    className='form-control mb-3'
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='sandbox'>sandbox</option>
                                                </select>
                                            </div>
                                            <div className='col-md-12'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-skyblue btn-sm'
                                                    data-toggle='modal'
                                                    data-target='#exampleModalScrollable'
                                                >
                                                    <i className='la la-feather-alt'></i>Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <form method='post' name='smsFrm' onSubmit={bankServiceSwitch}>
                                        <div className='row mb-4'>
                                            <div className='col-md-12 mb-3'>BANK VERIFICATION:</div>
                                            <div className='col-md-3'>
                                                <div>Primary Provider</div>
                                                <select className='form-control mb-3'>
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='sandbox'>sandbox</option>
                                                </select>
                                            </div>
                                            <div className='col-md-3'>
                                                <div>Secondary Provider</div>
                                                <select
                                                    id='secondaryBANK'
                                                    className='form-control mb-3'
                                                >
                                                    <option selected value=''>
                                                        Open this select menu
                                                    </option>
                                                    <option value='sandbox'>sandbox</option>
                                                </select>
                                            </div>
                                            <div className='col-md-12'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-skyblue btn-sm'
                                                    data-toggle='modal'
                                                    data-target='#exampleModalScrollable'
                                                >
                                                    <i className='la la-feather-alt'></i>Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
