import React, { useState, useEffect } from 'react';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';

function UserAccountDetail() {
    const [user_id, setUserId] = useState('');
    const [inputData, setInputData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const userAccountDetail = () => {
        AccountManagerAPI.userAccountDetail(user_id).then((data) => {
            if (data.status_code === true) {
                setInputData(data);
                console.log(data);
            } else {
                Notiflix.Notify.failure(data.msg);
                setIsLoading(false);
            }
        });
    };

    const handleInput = (e) => {
        if (user_id === '' || user_id === undefined) {
            Notiflix.Notify.failure('Invalid User ID');
        } else {
            setUserId(e.target.value);
        }
    };

    const handleUserID = (e) => {
        setIsLoading(true);
        userAccountDetail();
        setIsLoading(false);
        // setIDData(e.target.value)
    };

    // const handleReset = (e) => {
    //     setInputData('')
    //     setIDData('')
    // }

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>User Id</label>
                                                <input
                                                    type='text'
                                                    className='form-control '
                                                    placeholder='Search By User Id'
                                                    onChange={handleInput}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {/* <div className="col">
                                                <label htmlFor="name">Team Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search By Team Name"


                                                />
                                            </div> */}
                                        </div>
                                        <div>
                                            {/* <div className="col">
                                                <label htmlFor="name">Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search By Email"

                                                />
                                            </div> */}
                                        </div>

                                        <div className='col' style={{ paddingTop: '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                className='btn btn-primary'
                                                type='button'
                                                onClick={handleUserID}
                                            >
                                                Search
                                            </button>

                                            <button
                                                type='reset'
                                                className='btn bg-danger'

                                                // onClick={handleReset}
                                            >
                                                <i className='ri-creative-commons-sa-line'></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                {/* <div className="row"> */}
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                            {/* <Link to={"/create_series"} className="button btn btn-skyblue button-icon">
        <i className="ml-2 ri-add-line"></i>Create New Series
        </Link> */}

                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    {/* <div className="btn-group m-2" role="group">
                                        <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Export
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="btnGroupDrop1" style={{}}>
                                            <button className="dropdown-item" href="#">Export All data</button>
                                            <CSVLink filename='View All Series' data={dataArray} headers={headers}>

                                            <button onClick={handleExport} className="dropdown-item" href="#">Export This Data</button>
                                            </CSVLink>
                                            
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Map items in the UI  */}
            {isLoading && (
                <div className='col-sm-12 col-lg-12'>
                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                        <RotatingLines
                            marginTop='40'
                            strokeColor='#c1030d'
                            strokeWidth='5'
                            animationDuration='0.75'
                            width='50'
                            visible={true}
                        />
                    </div>
                </div>
            )}

            {!isLoading && (
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>User Account Detail</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-editable' id='table'>
                                <div className='table-responsive'>
                                    <table
                                        // id="datatable"
                                        className='table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Contact Id</th>
                                                <th>User Name</th>
                                                <th>Team Name</th>
                                                <th>Fund Account Id</th>
                                                <th>Payout Id</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inputData &&
                                                inputData.length > 0 &&
                                                inputData.map((accountData, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{++index}</td>
                                                            <td>{accountData.contact_id}</td>
                                                            <td>{accountData.name}</td>
                                                            <td>{accountData.team_name}</td>
                                                            <td>{accountData.fund_account_id}</td>
                                                            <td>{accountData.payout_id}</td>
                                                            <td>{accountData.phone}</td>
                                                            <td>{accountData.email}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserAccountDetail;
