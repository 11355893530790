import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GlobalContestAPI } from '../api/globalContestApi';
import Notiflix from 'notiflix';

export default function AddPriceCardPage() {
    const [startPosition, setStartPosition] = useState();
    const [priceAmount, setPriceAmount] = useState();
    const [type, setType] = useState();
    const [globalRank, setGlobalRank] = useState([]);
    const [WinnerPosition, setWinnerPosition] = useState('');
    const [GrandAmount, setGrandAmount] = useState('');

    const location = useLocation();
    console.log(location.state);
    const {
        _id,
        winning_amount,
        is_confirmed,
        entry_fee,
        is_bonus,
        maximum_user,
        contest_category,
        is_multiple_entry,
        is_running,
    } = location.state;
    console.log(location);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetGlobalRank(_id);

        // console.log(_id)
    }, []);

    const CreateGlobalRank = (data) => {
        // console.log(data)
        GlobalContestAPI.CreateGlobalRank(data).then((data) => {
            if (data.Status) {
                Notiflix.Notify.success(data.Message);
                GetGlobalRank(_id);
                console.log(data);
            } else if (!data.Status) {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const submitHandler = (e) => {
        e.preventDefault();

        const json = {
            _id: _id,
            WinningRank: [
                {
                    PriceType: type,
                    Winner: Number(startPosition),
                    TotalAmount: Number(priceAmount),
                },
            ],
        };

        console.log(json);

        CreateGlobalRank(json);
    };

    const AmountChange = (e) => {
        setType(e.target.value);
    };

    const GetGlobalRank = (data) => {
        GlobalContestAPI.GetGlobalRank(data).then((data) => {
            if (data.Status) {
                setGlobalRank(data.Data.WinningRank);
                setWinnerPosition(data.WinnerPosition);
                setGrandAmount(data.GrandAmount);
                console.log(data);
            } else if (!data.Status) {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const RemoveGlobalRank = () => {
        GlobalContestAPI.RemoveGlobalRank(_id).then((data) => {
            if (data.Status) {
                GetGlobalRank(_id);
            } else if (!data.Status) {
                console.log('no');
            }
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    {/* <Link
                    to={"/global_contests"}
                    className="button btn btn-skyblue button-icon"
                  >
                    <i className="ml-2 ri-eye-fill"></i> View All Global Contest
                  </Link> */}

                                    <Link
                                        to={'/global_contests'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-arrow-left-fill'></i>Go Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <p>
                                                <strong> Winning Amount</strong> : {winning_amount}
                                            </p>
                                            <p>
                                                <strong>Entry Fees</strong> : {entry_fee}
                                            </p>
                                            <p>
                                                <strong>Maximum users</strong> : {maximum_user}
                                            </p>
                                            <p>
                                                <strong>Multiple Entry</strong> :{' '}
                                                {is_multiple_entry ? 'yes' : 'no'}
                                            </p>
                                            <p>
                                                <strong>Is Running</strong> :{' '}
                                                {is_running ? 'yes' : 'no'}
                                            </p>
                                        </div>

                                        <div className='col-md-6'>
                                            <p>
                                                <strong>Confirm Contest</strong> :{' '}
                                                {is_confirmed ? 'yes' : 'no'}
                                            </p>
                                            <p>
                                                <strong>Is Bonus Allowed</strong> :{' '}
                                                {is_bonus ? 'yes' : 'no'}
                                            </p>
                                            <p>
                                                <strong>Contest Category</strong> :{' '}
                                                {contest_category}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add Price Card</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group col-md-5'>
                                            <label htmlFor='name'>Type of Contest:</label>
                                            <br />
                                            <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                <input
                                                    type='radio'
                                                    id='customRadio6'
                                                    name='customRadio-1'
                                                    className='custom-control-input'
                                                    value='Amount'
                                                    onChange={AmountChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='customRadio6'
                                                >
                                                    {' '}
                                                    Amount{' '}
                                                </label>
                                            </div>
                                            <div className='custom-control custom-radio custom-control-inline'>
                                                <input
                                                    type='radio'
                                                    id='customRadio7'
                                                    name='customRadio-1'
                                                    className='custom-control-input'
                                                    value='Prize'
                                                    onChange={AmountChange}
                                                />
                                                <label
                                                    className='custom-control-label'
                                                    htmlFor='customRadio7'
                                                >
                                                    {' '}
                                                    Prize{' '}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Start Position </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                id=''
                                                placeholder='1'
                                                value={WinnerPosition}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Winner : </label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                id=''
                                                placeholder='Enter Winner'
                                                value={startPosition}
                                                onChange={(e) => {
                                                    setStartPosition(e.target.value);
                                                }}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                <label htmlFor="text">Numbers of Winners :</label>
                                <input type="text" className="form-control" defaultValue="" placeholder="Enter Numbers of Winners"/>
                            </div> */}
                                        <div className='form-group'>
                                            <label htmlFor='text'>Price Amount in Rupees :</label>
                                            <input
                                                type='number'
                                                className='form-control'
                                                defaultValue=''
                                                placeholder='Enter Price Amount in Rupees'
                                                value={priceAmount}
                                                onChange={(e) => {
                                                    setPriceAmount(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <button
                                            onClick={submitHandler}
                                            type='submit'
                                            className='btn btn-primary'
                                        >
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button type='submit' className='btn bg-danger'>
                                            Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Price Card Type</th>
                                                    <th>Min Position</th>
                                                    <th>Max Position</th>
                                                    <th>Winning Users</th>
                                                    <th>Each Winner Amount</th>
                                                    <th>Total Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {globalRank.map((rank, i, globalRank) => {
                                                    return (
                                                        <tr>
                                                            <td>{++i}</td>
                                                            <td>{rank.PriceType}</td>
                                                            <td>{rank.MinPosition}</td>
                                                            <td>{rank.MaxPosition}</td>
                                                            <td>{rank.WinningUser}</td>
                                                            <td>{rank.EachWinAmount}</td>
                                                            <td>
                                                                {i === globalRank.length
                                                                    ? GrandAmount
                                                                    : ''}
                                                            </td>
                                                            <td>
                                                                {/* <button onClick={RemoveGlobalRank}
                                    type="submit"
                                    className="btn btn-primary btn-sm"
                                  >
                                    <i className="ri-delete-bin-line"></i>Delete
                                  </button> */}

                                                                {i === globalRank.length ? (
                                                                    <button
                                                                        onClick={RemoveGlobalRank}
                                                                        type='submit'
                                                                        className='btn btn-primary btn-sm'
                                                                    >
                                                                        <i className='ri-delete-bin-line'></i>
                                                                        Delete
                                                                    </button>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
