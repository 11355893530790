import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UsersAPI } from '../api/userApi';
import { Image } from 'antd';
import Notiflix from 'notiflix';
import { useCompressHook } from '../Component/ImageCompressHook';


function GetUserDetails() {
    const location = useLocation();
    const userId = location.state.userId;
    const [transactionData, setTransactionData] = useState([]);

    useEffect(() => {
        getUserWalletDataByUserId();
    }, []);

    const getUserWalletDataByUserId = () => {
        const json = {
            user_id: userId,
        };

        UsersAPI.GetUserWalletDataByUserId(json).then((data) => {
            if (data.status_code) {
                setTransactionData(data.data[0]);
                console.log(data.data);
            }
        });
    };

    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        } else {
            setImage(null);
        }
    };

    const handleUpdateUserProfileImage = () => {

        if (!(image instanceof File)) {
            console.error('Selected image is not a valid file object.');
            return;
        }

        useCompressHook(image, 0.8)
            .then((compressedImage) => {
                const updateId = {
                    user_id: userId,
                    img_url: compressedImage
                };

                if (!Object.keys(compressedImage).length) {
                    Notiflix.Notify.failure('No Image is there');
                } else {
                    UsersAPI.UpdateUserImage(updateId).then((data) => {
                        if (data.status_code) {
                            Notiflix.Notify.success(data.message);
                            handleReset();
                            getUserWalletDataByUserId();
                        } else {
                            Notiflix.Notify.failure(data.message);
                        }
                    });
                }
            })
            .catch((error) => {
                console.error('Error compressing image:', error);
            });
    };


    const handleReset = () => {
        setImage(null);
    };


    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>User Details</h4>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <span>
                                <table className='table  table-striped table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>User Id</th>
                                            <th>Team Name</th>
                                            <th>D.O.B</th>
                                            <th>State</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Blocked Account</th>
                                            <th>Pan Card Name {transactionData?.account_details?.verification_status.pan_no ? '✔️' : '❌'}</th>
                                            <th>Pan Card Number</th>
                                            <th>Adhaar Number {transactionData?.account_details?.verification_status.aadhaar_number ? '✔️' : '❌'}</th>
                                            <th>Bank Number</th>
                                            <th>Bank Name</th>
                                            <th>Bank IFSC Code</th>
                                            <th>Bank Branch</th>
                                            <th>Profile Image</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <Suspense fallback='Loading'>
                                            <tr>
                                                <td>{transactionData?.user_id}</td>
                                                <td>{transactionData?.team_name}</td>
                                                <td>{transactionData?.date_of_birth}</td>
                                                <td>{transactionData?.state}</td>
                                                <td>{transactionData?.phone}</td>
                                                <td>{transactionData?.email}</td>
                                                <td>{transactionData?.blocked_account}</td>
                                                <td>{transactionData?.account_details?.pan_name}</td>
                                                <td>{transactionData?.account_details?.pan_number}</td>
                                                <td>{transactionData?.account_details?.aadhaar_number}</td>
                                                <td>{transactionData?.account_details?.account_number}</td>
                                                <td>{transactionData?.account_details?.bank_name}</td>
                                                <td>{transactionData?.account_details?.ifsc_code}</td>
                                                <td>{transactionData?.account_details?.branch}</td>
                                                <td>
                                                    <Image
                                                        width={200}
                                                        src={transactionData?.profile_image}
                                                    />
                                                </td>
                                            </tr>
                                        </Suspense>
                                    </tbody>
                                </table>
                            </span>
                        </div>


                    </div>
                </div>

                <div>
                    <label htmlFor="profile-picture-upload" style={{ backgroundColor: 'black', color: 'white', padding: 12, borderRadius: 4, border: 'none', marginTop: 12, marginLeft: 18, cursor: 'pointer', display: 'inline-block' }}>
                        Update User Profile Picture
                        <input type="file" id="profile-picture-upload" accept="image/*" style={{ display: 'none' }} onChange={handleImageChange} />
                    </label>
                    {image && (
                        <div style={{ marginLeft: 18 }}>
                            <h2 style={{ fontSize: 24, color: 'black' }}>Preview:</h2>
                            <img src={URL.createObjectURL(image)} alt="Profile Preview" style={{ maxWidth: '400px', maxHeight: '400px' }} />
                        </div>
                    )}

                    {image && (
                        <div style={{ marginLeft: 18, marginTop: 10, gap: 12 }}>
                            <button style={{ backgroundColor: 'white', border: '1px solid black', borderRadius: 4, color: 'black' }} onClick={handleUpdateUserProfileImage}>
                                Update
                            </button>

                            <button style={{ backgroundColor: 'white', border: '1px solid black', borderRadius: 4, color: 'black' }} onClick={handleReset}>Reset</button>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}

export default GetUserDetails;
