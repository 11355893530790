import './ViewAllUsers.css';
import { useState } from 'react';
import Notiflix from 'notiflix';
import { fundManager } from '../api/FundsApi';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Radio } from 'antd';

export default function RefundFunds({ dashLoggedIn, fundsUserId }) {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [bonusMasterPassword, setBonusMasterPassword] = useState('');
    const [bonusInternalComment, setBonusInternalComment] = useState('');
    const [bonusFailedDate, setBonusFailedDate] = useState('');
    const [cancelBonus, setCancelBonus] = useState('');
    const [cancelWinning, setCancelWinning] = useState('');
    const [cancelUnutilized, setCancelUnutilized] = useState('');
    const [finalMatchName, setFinalMatchName] = useState('');
    const [cancelFantasy, setCancelFantasy] = useState('prediction');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [allMatches, setAllMatches] = useState([]);
    const [showAllMatches, setShowAllMatches] = useState(false);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const callMatchNames = () => {
        fundManager.CancelMatchNames(startDate, endDate).then((data) => {
            if (data.status_code) {
                setShowAllMatches(true);
                setAllMatches(data.data);
                Notiflix.Notify.success('Matches Fetched Successfully');
            } else {
                Notiflix.Notify.failure('Please Provide Start & End Date');
            }
        });
    };

    const handleCancelFunds = () => {
        const mainType = 'cancel';
        const data = {
            type: mainType,
            user_id: fundsUserId,
            master_password: bonusMasterPassword,
            comment: bonusInternalComment,
            failed_date: bonusFailedDate,
            user_name: dashLoggedIn,
            bonus: cancelBonus,
            winning: cancelWinning,
            unutilized: cancelUnutilized,
            matchName: finalMatchName,
            fantasy: cancelFantasy,
        };

        fundManager.CancelAbandonedFunds(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Successful');
                reset();
            } else {
                Notiflix.Notify.failure(data.meassage);
            }
        });
    };

    const reset = () => {
        setBonusInternalComment('');
        setBonusFailedDate('');
        setBonusMasterPassword('');
        setCancelBonus('');
        setCancelWinning('');
        setCancelUnutilized('');
        setStartDate('');
        setEndDate('');
        setCancelFantasy('');
        setFinalMatchName('');
    };

    const handleTwoReset = () => {
        setShowAllMatches(false);
        setAllMatches([]);
        setStartDate('');
        setEndDate('');
        setFinalMatchName('');
    };

    return (
        <div className='modal-1-div-main'>
            <div className='main-div-for-funds-manager'>
                <div className='modal-div-1'>
                    <h2>UserId</h2>
                    <h1>{fundsUserId}</h1>
                </div>
                <div className='modal-div-1'>
                    <h2>Name</h2>
                    <h1>{dashLoggedIn}</h1>
                </div>
            </div>

            <div>
                <div className='main-div-for-funds-manager'>
                    <div className='modal-div-2'>
                        <h2 className='weight-padding'>Bonus</h2>
                        <input value={cancelBonus} onChange={handleAllChange(setCancelBonus)} className='offers_input-29' placeholder='Bonus Here' />
                    </div>
                    <div className='modal-div-2'>
                        <h2 className='weight-padding'>Unutilize</h2>
                        <input value={cancelUnutilized} onChange={handleAllChange(setCancelUnutilized)} className='offers_input-29' placeholder='Unutilize Here' />
                    </div>
                </div>

                <div className='main-div-for-funds-manager'>
                    <div className='modal-div-2'>
                        <h2 className='weight-padding'>Winnings</h2>
                        <input value={cancelWinning} onChange={handleAllChange(setCancelWinning)} className='offers_input-29' placeholder='Winning Here' />
                    </div>
                    <div className='modal-div-2'>
                        <label className='offer_checkbox-2'>Fantasy</label>
                        <div className='radio-cancel-fantasy'>
                            <Radio.Group onChange={handleAllChange(setCancelFantasy)} defaultValue="prediction" buttonStyle="solid">
                                <Radio.Button value="prediction">Score</Radio.Button>
                                <Radio.Button value="cricket">Cricket</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </div>

                {showAllMatches ?
                    <div className='modal-div-29'>
                        <label className='offer_checkbox-2'>Select Match</label>
                        <div className='radio-cancel-fantasy'>
                            <select className='offers_input-999' value={finalMatchName} onChange={(e) => setFinalMatchName(e.target.value)}>
                                <option value=''>Select</option>
                                {allMatches.map((match, i) => (
                                    <option key={i} value={match.shortTitle}>
                                        {match.shortTitle}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button onClick={handleTwoReset} className='search-matches'>Reset</button>
                    </div> :
                    <div className='modal-div-29'>
                        <label className='offer_checkbox-2'>Date</label>
                        <div className='radio-cancel-fantasy'>
                            <input type='date' value={startDate} className='offers_input-999' onChange={handleAllChange(setStartDate)} />
                            <input type='date' value={endDate} className='offers_input-999' onChange={handleAllChange(setEndDate)} />
                        </div>
                        <button onClick={callMatchNames} className='search-matches'>Search</button>
                        <button onClick={handleTwoReset} className='search-matches'>Reset</button>
                    </div>
                }

                <div className='modal-div-2'>
                    <label className='offer_checkbox-2'>Failed Date</label>
                    <input value={bonusFailedDate} type='date' onChange={handleAllChange(setBonusFailedDate)} className='offers_input-2' placeholder='Type TDS amount here' />
                </div>

                <div className='modal-div-2'>
                    <label className='offer_checkbox-2'>Internal Comment</label>
                    <textarea value={bonusInternalComment} onChange={handleAllChange(setBonusInternalComment)} className='offers_input-2' placeholder='Type Internal Comment' />
                </div>

                <div className='modal-div-2'>
                    <label htmlFor="password" className='offer_checkbox-2'>Enter Master Password</label>
                    <input value={bonusMasterPassword}
                        onChange={handleAllChange(setBonusMasterPassword)}
                        autoComplete="off"
                        type={passwordVisible ? 'text' : 'password'}
                        className='offers_input-2'
                        placeholder='Master Password Here' />
                    {passwordVisible ?
                        <EyeInvisibleOutlined className='add-funds-eyes' onClick={() => setPasswordVisible(!passwordVisible)} /> :
                        <EyeOutlined className='add-funds-eyes' onClick={() => setPasswordVisible(!passwordVisible)} />}
                </div>

                <div className='div-add-fund-div-multi'>
                    <button onClick={handleCancelFunds} className='modal-div-button-withdraw'>Add Funds</button>
                    <div className='div-add-fund-div-multi-h1'>
                        <h1 className='add-funds-api-number'>Refund Match cancel</h1>
                        <h1 className='span-amount-add-fundss'>{finalMatchName}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}
