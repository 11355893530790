import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Notiflix from 'notiflix';
import { BannerAPI } from '../api/bannerAPI';

export default function EditSecondSideBanner() {
    const location = useLocation();
    const [_id, setBannerId] = useState(location.state._id);
    // console.log(location.state._id);
    const [order, setOrder] = useState(location.state.order);
    // console.log(location.state.order);
    const [type, setType] = useState(location.state.type);
    // console.log(location.state.type);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const typeChange = (e) => {
        setType(e.target.value);
    };

    const orderChange = (e) => {
        setOrder(e.target.value);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        let editData = {
            _id: _id,
            type: type,
            order: order,
        };
        Notiflix.Notify.success('Edit Done!!');
        BannerAPI.EditBanner(editData).then((data) => {
            // console.log(data)
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_side_banner'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View all Slider
                                    </Link>
                                    <a
                                        className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                        href='#'
                                    >
                                        <i className='ri-add-line m-0'></i> Add New Slider
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Create New Series</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Banner Type :</label>
                                            <select
                                                onChange={typeChange}
                                                value={type}
                                                className='form-control mb-3'
                                            >
                                                <option select=''>Open this select menu</option>
                                                <option value='Banner'>Banner</option>
                                                <option value='Web'>Web</option>
                                                <option value='App'>App</option>
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Order :</label>
                                            <input
                                                value={order}
                                                onChange={orderChange}
                                                type='number'
                                                className='form-control'
                                                id='order'
                                                placeholder='Order'
                                            />
                                        </div>

                                        <button
                                            onClick={submitHandler}
                                            type='button'
                                            className='btn btn-primary'
                                        >
                                            Update
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
