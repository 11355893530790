import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SeriesAPI } from '../api/seriesApi';
import { RotatingLines } from 'react-loader-spinner';

export default function AllContests() {
    const location = useLocation();
    const matchId = location.state.matchId;
    const APiMatchId = location.state.APIMATCHID;
    console.log(matchId);
    console.log(APiMatchId);
    const [fantasy, setFantansy] = useState('cricket');
    const [seriesContestDetails, setSeriesContestDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        GetSeriesContestDetails();
    }, []);

    const GetSeriesContestDetails = () => {
        setIsLoading(true);
        SeriesAPI.ViewSeriesContestDetails(matchId, fantasy).then((data) => {
            console.log(data);
            if (data.status_code) {
                setSeriesContestDetails(data.data);
                console.log(data);
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const handleDecimal = (number) => {
        if (number === undefined || number === null) {
            return '';
        } else {
            let result = Math?.round(number * 100) / 100;
            return result;
        }
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Contests</h4>
                                    </div>
                                </div>

                                {/* View All Contest - Dropdown */}

                                <div className='main-divv-main-divv'>
                                    <div>
                                        <select className='input-dropdown-handle-fuull-series' onChange={(e) => setFantansy(e.target.value)}>
                                            <option value='cricket' selected='selected'>
                                                Cricket
                                            </option>
                                            <option value='Prediction'>Prediction</option>
                                        </select>
                                    </div>

                                    <div>
                                        <button className='input-dropdown-handle-fuull-series-button' onClick={GetSeriesContestDetails}>Search</button>
                                    </div>
                                </div>

                                {/* View All Contest */}

                                <div className='card-body'>
                                    <h1 className='contest-fully-SUCCESS'>Contests Successfully Started</h1>
                                    <div className='table-editable'>
                                        <div className='table-responsive'>
                                            <table
                                                className='table'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Contest Category</th>
                                                        <th>Joining Fees</th>
                                                        <th>Contest Size</th>
                                                        <th>Joined Users</th>
                                                        <th>Total Recieved</th>
                                                        <th>Unutilized</th>
                                                        <th>Bonus</th>
                                                        <th>Winning</th>
                                                        <th>Discount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isLoading ? <div className='rotaing-lines-div-maiinm'>
                                                            <div>
                                                                <RotatingLines
                                                                    marginTop='160'
                                                                    marginBottom='160'
                                                                    strokeColor='#000000'
                                                                    strokeWidth='3'
                                                                    animationDuration='0.75'
                                                                    width='50'
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        </div> :
                                                            (
                                                                seriesContestDetails &&
                                                                seriesContestDetails
                                                                    .filter(
                                                                        (contest) =>
                                                                            (contest.joined_user > 0 && contest.isCancelled === false) &&
                                                                            contest.ContestCategoryName !== 'Private'
                                                                    )
                                                                    .map((contest, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{++i}</td>
                                                                                <td>{contest.ContestCategoryName}</td>
                                                                                <td>{contest.JoiningFees}</td>
                                                                                <td>{contest.contest_size}</td>
                                                                                <td>{contest.joined_user}</td>
                                                                                <td>{handleDecimal(contest?.amount[0]?.total_recived)}</td>
                                                                                <td>{handleDecimal(contest?.amount[0]?.unutilized)}</td>
                                                                                <td>{handleDecimal(contest?.amount[0]?.bonus)}</td>
                                                                                <td>{handleDecimal(contest?.amount[0]?.winning)}</td>
                                                                                <td>0</td>
                                                                                <td>
                                                                                    <Link
                                                                                        to={'/all_users'}
                                                                                        state={{
                                                                                            MatchId:
                                                                                                matchId,
                                                                                            ContestPoolId:
                                                                                                contest.ContestPoolId,
                                                                                            APiMatchId:
                                                                                                APiMatchId,
                                                                                        }}
                                                                                        type='submit'
                                                                                        className='btn btn-primary btn-sm'
                                                                                    >
                                                                                        View Users
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {/* If JoinedUsers is Equal to zero */}

                                <div className='card-body'>

                                    <h1 className='contest-cancelled-failedrt'>
                                        Contest Cancelled
                                    </h1>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                className='table data-table'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Contest Category</th>
                                                        <th>Joining Fees</th>
                                                        <th>Contest Size</th>
                                                        <th>Joined Users</th>
                                                        <th>Total Received</th>
                                                        <th>Unutilized</th>
                                                        <th>Bonus</th>
                                                        <th>Winning</th>
                                                        <th>Discount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        isLoading ? (
                                                            <div className='rotaing-lines-div-maiinm'>
                                                                <div>
                                                                    <RotatingLines
                                                                        marginTop='160'
                                                                        marginBottom='160'
                                                                        strokeColor='#000000'
                                                                        strokeWidth='3'
                                                                        animationDuration='0.75'
                                                                        width='50'
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            seriesContestDetails &&
                                                            seriesContestDetails
                                                                .filter(
                                                                    (contest) =>
                                                                        contest.isCancelled === true &&
                                                                        contest.ContestCategoryName !== 'Private'
                                                                )
                                                                .map((contest, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{++i}</td>
                                                                            <td>{contest.ContestCategoryName}</td>
                                                                            <td>{contest.JoiningFees}</td>
                                                                            <td>{contest.contest_size}</td>
                                                                            <td>{contest.joined_user}</td>
                                                                            <td>{handleDecimal(contest?.amount[0]?.total_recived)}</td>
                                                                            <td>{handleDecimal(contest?.amount[0]?.unutilized)}</td>
                                                                            <td>{handleDecimal(contest?.amount[0]?.bonus)}</td>
                                                                            <td>{handleDecimal(contest?.amount[0]?.winning)}</td>
                                                                            <td>0</td>
                                                                            <td>
                                                                                <Link
                                                                                    to={'/all_users'}
                                                                                    state={{
                                                                                        MatchId:
                                                                                            matchId,
                                                                                        ContestPoolId:
                                                                                            contest.ContestPoolId,
                                                                                        APiMatchId:
                                                                                            APiMatchId,
                                                                                    }}
                                                                                    type='submit'
                                                                                    className='btn btn-primary btn-sm'
                                                                                >View Users</Link>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
