import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { NotificationAPI } from '../api/NotificationApi';
import TextArea from './Structure/TextArea';
import EmojiPicker from 'emoji-picker-react';
import './ViewAllUsers.css';


export default function PushNotificationPage() {
    const [title, setTitle] = useState('');
    // const [body, setBody] = useState('');
    const [textAreaValue, setTextAreaValue] = useState('');

    const onEmojiClick = (event, emojiObject) => {
        const textAreaElement = document.getElementById('text-area');
        setTextAreaValue(
            textAreaValue.slice(0, textAreaElement.selectionStart) +
            emojiObject.emoji +
            textAreaValue.slice(textAreaElement.selectionEnd)
        );
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const SendNotification = (e) => {
        // console.log(body)
        // if (e.keyCode == 13) {
        //     e.preventDefault();
        //     return false;
        // }
        e.preventDefault();

        if (!textAreaValue || !title) {
            Notiflix.Notify.failure('Fill all Details');
            return;
        }

        const json = {
            body: textAreaValue,
            title: title,
        };

        NotificationAPI.FCMNotification(json).then((data) => {
            Notiflix.Notify.success('Notification Sent Successfully');
            console.log(data);
        });
        console.log(json);

        setTitle('');
        setTextAreaValue('');
    };

    const handleReset = () => {
        setTitle('');
        setTextAreaValue('');

    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Send Notification</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Select Users :</label>
                                        <select className='form-control mb-3'>
                                            {/* <option select="">Open this select menu</option> */}
                                            <option value='all'>All Users</option>
                                            {/* <option value="2">Specific Users</option> */}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='text'>Notification Title :</label>
                                        <input
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            type='text'
                                            className='form-control text-break'
                                            id='textarea'
                                            placeholder='Enter Notification Title Here'
                                        />
                                    </div>
                                    <div className='notification_div'>
                                        <label htmlFor='text'>Enter Message :</label>
                                        <TextArea text={textAreaValue} setText={setTextAreaValue} className='notification_textarea' />
                                        <br />
                                        <EmojiPicker onEmojiClick={onEmojiClick} />

                                    </div>
                                    <button
                                        type='submit'
                                        onClick={SendNotification}
                                        className='m-r-10 btn btn-primary notific_button'
                                    >
                                        Send Notification
                                    </button>
                                    <button type='button' className='btn btn-success' onClick={handleReset}>
                                        Reset
                                    </button>
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
