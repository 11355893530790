import './ViewAllUsers.css';
import { useState } from 'react';
import Notiflix from 'notiflix';
import { fundManager } from '../api/FundsApi';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

export default function WithdrawlFunds({ dashLoggedIn, fundsUserId }) {

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    // Modal States
    const [addWithdrawlAmount, setAddWithdrawlAmount] = useState('');
    const [addMasterPassword, setAddMasterPassword] = useState('');
    const [addInternalComment, setAddInternalComment] = useState('');
    const [addTDS, setAddTDS] = useState('');

    const [failedDate, setFailedDate] = useState('');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const AddWithdrawl = () => {
        const addType = 'withdraw';
        const data = {
            'user_id': fundsUserId,
            'amount': parseFloat(addWithdrawlAmount),
            'type': addType,
            'comment': addInternalComment,
            'gst': parseFloat(addTDS),
            'master_password': addMasterPassword,
            'user_name': dashLoggedIn,
            'failed_date': failedDate,
        };

        fundManager.addFundsWithdrawl(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Withdrawl Added Succesfully');
                reset();
            } else {
                Notiflix.Notify.failure('Something Went Wrong, Check Comlete Filled Details');
            }
        });

    };

    const reset = () => {
        setAddInternalComment('');
        setAddMasterPassword('');
        setAddTDS('');
    };

    return (
        <div className='modal-1-div-main'>
            <div className='main-div-for-funds-manager'>
                <div className='modal-div-1'>
                    <h2>UserId</h2>
                    <h1>{fundsUserId}</h1>
                </div>

                <div className='modal-div-1'>
                    <h2>Name</h2>
                    <h1>{dashLoggedIn}</h1>
                </div>
            </div>

            <div className='modal-div-2'>
                <label className='offer_checkbox-2'>Enter Amount</label>
                <input value={addWithdrawlAmount} onChange={handleAllChange(setAddWithdrawlAmount)} className='offers_input-2' placeholder='Type Amount Here' />
            </div>

            <div className='modal-div-2'>
                <label className='offer_checkbox-2'>Enter TDS Amount</label>
                <input value={addTDS} onChange={handleAllChange(setAddTDS)} className='offers_input-2' placeholder='Type TDS Here' />
            </div>

            <div className='modal-div-2'>
                <label className='offer_checkbox-2'>Failed Date</label>
                <input value={failedDate} type='date' onChange={handleAllChange(setFailedDate)} className='offers_input-2' placeholder='Type TDS amount here' />
            </div>

            <div className='modal-div-2'>
                <label className='offer_checkbox-2'>Internal Comment</label>
                <textarea onChange={handleAllChange(setAddInternalComment)} className='offers_input-2' placeholder='Type Internal Comment' />
            </div>

            <div className='modal-div-2'>
                <label htmlFor="password" className='offer_checkbox-2'>Enter Master Password</label>
                <input value={addMasterPassword}
                    onChange={handleAllChange(setAddMasterPassword)}
                    autoComplete="off"
                    type={passwordVisible ? 'text' : 'password'}
                    className='offers_input-2'
                    placeholder='Master Password Here' />
                {passwordVisible ? (
                    <EyeInvisibleOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                ) :
                    (
                        <EyeOutlined className='add-funds-eyes' onClick={togglePasswordVisibility} />
                    )}
            </div>

            <div className='div-add-fund-div-multi'>
                <button onClick={AddWithdrawl} className='modal-div-button-withdraw'>Add Withdrawl</button>
                <div className='div-add-fund-div-multi-h1'>
                    <h1 className='add-funds-api-number'>Remember to add</h1>
                    <h1 className='span-amount-add-fundss'>TDS</h1>
                </div>
            </div>

        </div>
    );
}
