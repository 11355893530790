import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { ReportAPI } from '../api/ReportAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import moment from 'moment';


export default function IndividualRefReport() {

    const [referSecondUserData, setReferSecondUserData] = useState([]);
    const [isSecondLoading, setIsSecondLoading] = useState(false);

    const [refUserId, setRefUserId] = useState('');
    const [refUserTeamName, setRefUserTeamName] = useState('');
    const [refUserPhone, setRefUserPhone] = useState('');
    const [refUserEmail, setRefUserEmail] = useState('');
    const [refUserRef, setRefUserRef] = useState('');
    const [isRender, setIsRender] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [reffralData, setReffralData] = useState([]);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const getReffralSecondData = async () => {
        setIsSecondLoading(true);

        try {
            const response = await ReportAPI.ReferSecondReport(refUserId, refUserTeamName, refUserPhone, refUserEmail, refUserRef);
            if (response.status_code) {
                setIsSecondLoading(false);
                setReferSecondUserData(response.document1);
                setReffralData(response.document1.referredUser_id);
                setIsRender(true);
                console.log(response.document1.referredUser_id);
            } else {
                Notiflix.Notify.failure(response.message);
                setIsSecondLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSecondReset = () => {
        setRefUserEmail('');
        setRefUserPhone('');
        setRefUserId('');
        setRefUserTeamName('');
        setRefUserRef('');
        setIsRender(false);
        setReferSecondUserData([]);
    };

    // Modal

    const showModal = (referredUsers) => {
        setReffralData(referredUsers);
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleDate = (date) => {
        const newDate = moment(date).format('DD/MM/YYYY');
        return newDate;
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div>
                        {/* Link */}
                        <div className='main-ref-ral-div'>
                            <h1 className='coupon_h1-h1'>Individual User Report</h1>
                            <div className=''>
                                <Link to={'/refer-report'} className='button-report-referral'>Referral Report</Link>
                                <Link to={'/indi-refer-report'} className='button-report-referral'>Individual User Report</Link>
                                <Link to={'/ref-stats'} className='button-report-referral'>User Report Stats</Link>
                            </div>
                        </div>
                        {/* Link */}
                    </div>
                    {/* New Section */}
                    <div className='row'>
                        <div className='indi-ref-report-div'>
                            <div className='card-body'>
                                <div className='form-row'>

                                </div>
                                <div className='offers_div'>
                                    <div >
                                        <label className='offer_checkbox'>Phone Number</label>
                                        <input type='number' value={refUserPhone} required onChange={handleAllChange(setRefUserPhone)} className='offers_input' placeholder='Enter Phone Number' />
                                    </div>
                                    <div>
                                        <label className='offer_checkbox'>Email ID</label>
                                        <input value={refUserEmail} onChange={handleAllChange(setRefUserEmail)} className='offers_input' placeholder='Enter Email ID' />
                                    </div>

                                    <div>
                                        <label className='offer_checkbox'>Team Name</label>
                                        <input value={refUserTeamName} onChange={handleAllChange(setRefUserTeamName)} className='offers_input' placeholder='Enter Team Name' />
                                    </div>

                                    <div>
                                        <label className='offer_checkbox'>User Id</label>
                                        <input value={refUserId} onChange={handleAllChange(setRefUserId)} className='offers_input' placeholder='Enter User Id' />
                                    </div>

                                    <div>
                                        <label className='offer_checkbox'>Refer Code</label>
                                        <input value={refUserRef} onChange={handleAllChange(setRefUserRef)} className='offers_input' placeholder='Enter Refer Code' />
                                    </div>
                                    <div className='cases_button_div' >

                                        <button
                                            onClick={getReffralSecondData}
                                            className='cases_verify_button_234'>
                                            Search
                                        </button>
                                        <button
                                            onClick={handleSecondReset}
                                            className='cases_verify_button_222'>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        {isSecondLoading ? (
                                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='160'
                                                        marginBottom='160'
                                                        strokeColor='#000000'
                                                        strokeWidth='3'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {referSecondUserData?.length > 0 ? (
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div>
                                                                <div className='table-responsive'>
                                                                    <table className='table table-striped table-bordered'>
                                                                        <thead>
                                                                            <tr className='support_main_file_div'>
                                                                                <th>User ID</th>
                                                                                <th>Team Name</th>
                                                                                <th>Email</th>
                                                                                <th>Phone</th>
                                                                                <th>Refer Code</th>
                                                                                <th>Refer Code From</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {referSecondUserData.map((refer, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{refer.user_id}</td>
                                                                                    <td>{refer.team_name}</td>
                                                                                    <td>{refer.email}</td>
                                                                                    <td>{refer.phone}</td>
                                                                                    <td>{refer.refCode}</td>
                                                                                    <td>{refer.referCodeOf === '' ? 'Not Used' : refer.referCodeOf}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            className='button-reffer-div-from'
                                                                                            onClick={() => showModal(refer.referredUser_id)}
                                                                                        >
                                                                                            View Refferals
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="ref-indi-h4">Search User.</div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <Modal
                        footer={null}
                        width={400}
                        open={isModalOpen}
                        onCancel={handleCancel}
                    >
                        <div className='card-body'>
                            <h1 className='ticket-info'>Referral Information</h1>
                            <div className="table-editable" id="table">
                                <div className='table-responsive'>
                                    <table className='table table-striped table-bordered'>
                                        <thead>
                                            <tr className='support_main_file_div'>
                                                <th>User ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reffralData?.length > 0 ?
                                                    reffralData?.map((details, i) => (
                                                        <tr key={i}>
                                                            <td>{details.user_id}</td>
                                                        </tr>
                                                    ))
                                                    : <h4 className='text-same-reffer-h4'>No User Id Found</h4>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
            </div >
        </div >);
}
