import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { MatchAPI } from '../api/matchApi';

export default function RescheduleMatchPage() {
    const [rescheduleMatch, setRescheduleMatch] = useState([]);
    const [editRescheduleMatch, setEditRescheduleMatch] = useState('');
    const [editChangeDate, setEditChangeDate] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        GetReschedule();
    }, []);

    const headers = [
        { label: 'Match Name', key: 'matchName' },
        { label: 'Type', key: 'matchType' },
        { label: 'Team 1', key: 'teamAname' },
        { label: 'Team 2', key: 'teamBname' },
        { label: 'Date / Time', key: 'StartDateIST' },
        { label: 'Change Time', key: 'changeTime' },
        { label: 'Status', key: 'matchStatusStr' },
    ];

    const GetReschedule = () => {
        MatchAPI.RescheduleMatch().then((data) => {
            if (data.status_code) setRescheduleMatch(data.message);
            console.log(data.message);
        });
    };

    const EditReschedule = (data) => {
        // console.log(data)
        setId(data._id);
        setEditRescheduleMatch(data);
    };

    const EditSubmitHandler = () => {
        // console.log(editChangeDate)
        const json = {
            _id: id,
            changeTime: editChangeDate,
        };

        console.log(json);

        MatchAPI.EditRescheduleMatch(json).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('success');
                GetReschedule();
            } else {
                Notiflix.Notify.failure('failure');
            }
        });
    };

    const dateChange = (e) => {
        // console.log(e.target.value)
        setEditChangeDate(e.target.value);
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <form>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Match Name</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Match Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Matches Status</label>
                                            <select className='form-control mb-3'>
                                                <option select=''>Open this select menu</option>
                                                <option value='1'>Cancelled</option>
                                                <option value='2'>Delayed </option>
                                            </select>
                                        </div>
                                        <div className='col'>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button type='submit' className='mt-2 btn btn-primary'>
                                                Search
                                            </button>
                                            &nbsp;
                                            {/* <button type="submit" className="mt-2 btn bg-danger"><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                            <input type='reset' className='mt-2 btn btn-primary' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                            <div className='btn-group m-2' role='group'>
                                <button
                                    id='btnGroupDrop1'
                                    type='button'
                                    className='btn btn-primary dropdown-toggle'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Export
                                </button>
                                <div
                                    className='dropdown-menu'
                                    aria-labelledby='btnGroupDrop1'
                                    style={{}}
                                >
                                    <button className='dropdown-item' href='#'>
                                        Export All data
                                    </button>
                                    <CSVLink
                                        filename='Reschedule Match'
                                        data={rescheduleMatch}
                                        headers={headers}
                                    >
                                        <button className='dropdown-item' href='#'>
                                            Export This Data
                                        </button>
                                    </CSVLink>
                                    {/* <a className="dropdown-item" href="#">Delete</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Matches</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Match Name</th>
                                                <th>Type</th>
                                                <th>Team 1</th>
                                                <th>Team 2</th>
                                                <th>Date / Time</th>
                                                <th>Change Time</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rescheduleMatch.map((match, i) => {
                                                return (
                                                    <tr>
                                                        <td>{++i}</td>
                                                        <td>{match.matchName}</td>
                                                        <td>{match.matchType}</td>
                                                        <td>{match.teamAname}</td>
                                                        <td>{match.teamBname}</td>
                                                        <td>{match.StartDateIST}</td>
                                                        <td>
                                                            {match.changeTime
                                                                ? match.changeTime
                                                                : match.StartDateIST}
                                                        </td>
                                                        <td>{match.matchStatusStr}</td>

                                                        <td>
                                                            <button
                                                                onClick={() => {
                                                                    EditReschedule(match);
                                                                }}
                                                                type='button'
                                                                data-toggle='modal'
                                                                data-target='#exampleModalScrollable'
                                                                className='btn btn-skyblue btn-sm'
                                                            >
                                                                <i className='ri-edit-line'></i>{' '}
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='modal fade'
                        id='exampleModalScrollable'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='exampleModalScrollableTitle'
                        aria-hidden='true'
                    >
                        <div className='modal-dialog modal-dialog-scrollable' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                        Reschedule Match
                                    </h5>
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Type :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='T20'
                                                defaultValue='T20'
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Team 1 :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Team Short Name'
                                                defaultValue={editRescheduleMatch.teamAname}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Team 2:</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Team Key'
                                                defaultValue={editRescheduleMatch.teamBname}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Date Time :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder=' '
                                                defaultValue={editRescheduleMatch.StartDateIST}
                                                disabled
                                            />
                                        </div>
                                        {/* <div className="form-group">
                      <label htmlFor="name">Status :</label>
                      <select className="form-control mb-3">
                        <option select="">Select</option>
                        <option value 
                        ="1">Cancelled</option>
                        <option value="2">Delayed</option>
                      </select>
                    </div> */}
                                        <div className='form-group'>
                                            <label htmlFor='name'>Change Date Time :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder=' '
                                                defaultValue={editRescheduleMatch.StartDateIST}
                                                // value={editChangeDate}
                                                onChange={(e) => dateChange(e)}
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        data-dismiss='modal'
                                    >
                                        Close
                                    </button>
                                    &nbsp;
                                    <button
                                        onClick={EditSubmitHandler}
                                        data-dismiss='modal'
                                        type='button'
                                        className='btn btn-primary'
                                    >
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
