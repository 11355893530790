import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MatchAPI } from '../api/matchApi';
import './ViewAllUsers.css';


export default function UpdatePlayingXi() {
    const [matchId, setMatchId] = useState('');
    const [matches, setMatches] = useState([]);
    const [teamA, setTeamA] = useState([]);
    const [dummyA, setDummyA] = useState([]);
    const [dummyB, setDummyB] = useState([]);
    const [teamB, setTeamB] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetMatch();
    }, []);



    const GetMatch = () => {
        MatchAPI.FormatMatches().then((data) => {
            console.log(data);
            setMatches(data.message);
        });
    };




    const subtitleChange = (e) => {
        setDummyA('');
        setDummyB('');
        setTeamA('');
        setTeamB('');
        console.log('hello');
        const matchid = e.target.value;
        MatchAPI.GetPlayingXI(matchid).then((data) => {
            console.log(data.message);

            const teamAdata = [];
            const teamBdata = [];
            const teamADummy = [];
            const teamBDummy = [];

            for (let i = 0; i < data.message[0].PlayerID.length; i++) {

                teamAdata.push({
                    PlayerID: data.message[0].PlayerID[i],
                    PlayerName: data.message[0].PlayerName[i],
                    PlayingRole: data.message[0].PlayingRole[i],
                    playerCredit: data.message[0].playerCredit[i],
                });
                teamADummy.push(false);
            }

            setMatchId(data.message[0].MatchId);

            for (let i = 0; i < data.message[1].PlayerID.length; i++) {
                teamBdata.push({
                    PlayerID: data.message[1].PlayerID[i],
                    PlayerName: data.message[1].PlayerName[i],
                    PlayingRole: data.message[1].PlayingRole[i],
                    playerCredit: data.message[1].playerCredit[i],
                });
                teamBDummy.push(false);
            }
            setDummyA(teamADummy);
            setDummyB(teamBDummy);
            setTeamA(teamAdata);
            setTeamB(teamBdata);
        });
    };

    const handleAChange = (e, index) => {
        const checked = e.target.checked;
        const myIndex = index - 1;
        // console.log(myIndex)
        const newArr = [...dummyA];
        newArr[myIndex] = checked;
        setDummyA((prev) => newArr);
    };

    const handleBChange = (e, index) => {
        const checked = e.target.checked;
        const myIndex = index - 1;
        // console.log(myIndex)
        const newArr = [...dummyB];
        newArr[myIndex] = checked;
        setDummyB((prev) => newArr);
    };

    const handleInsertImpact = () => {
        const myFinalA = [];
        const myFinalB = [];

        for (let i = 0; i < teamA.length; i++) {
            if (dummyA[i] === true) {
                myFinalA.push(teamA[i]);
                console.log(myFinalA);
            }
        }

        for (let i = 0; i < teamB.length; i++) {
            if (dummyB[i] === true) {
                myFinalB.push(teamB[i]);
            }
        }

        const finalArray = [...myFinalA, ...myFinalB];
        const finalFilter = finalArray.map((d) => d.PlayerID);

        if (finalArray.length < 8 || finalArray.length > 8) {
            Notiflix.Notify.failure('Please Select Only 8 Players');
        } else {
            const json = {
                match_id: matchId,
                playerArray: finalFilter,
            };

            console.log(json);

            MatchAPI.ImpactPlayer(json).then((data) => {
                console.log(data);
                Notiflix.Notify.success('Sucessfully Updated Impact Players');
            });
        }

        // if (finalArray.length === 8) {
        //     MatchAPI.ImpactPlayer(matchId, finalFilter).then((data) => {
        //         console.log(data)
        //         if (data.status_code) {
        //             Notiflix.Notify.success("Impact Players Successfully");
        //         } else {
        //             Notiflix.Notify.failure('Something Went Wrong');
        //         }
        //     });
        // } else {
        //     Notiflix.Notify.failure('Please Select Max 8 Players');
        // }
    };


    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'>
                                        <label htmlFor='name'>Choose Match</label>
                                        <select
                                            id='matchId'
                                            className='form-control mb-3'
                                            onChange={subtitleChange}
                                        >
                                            <option select=''>Select</option>
                                            {matches.map((fan) => (
                                                <option
                                                    value={fan.matchId}
                                                >{`${fan.matchName}   ||   ${fan.StartDateIST}}`}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* <div className='col'>
                                        <button
                                            className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0 mt-4 float-right'
                                            onClick={launch}
                                        >
                                            Launching Playing 11
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>

                            {/* <div className='col'>
                                <a
                                    className='button btn btn-info ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                    href='#'
                                >
                                    Update
                                </a>
                                <a
                                    className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0 float-right'
                                    href='#'
                                >
                                    Export <i className='ri-add-line m-0'></i>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='impact_div'>
                    <div className='col-sm-12 col-lg-12 '>
                        <div className=''>
                            <h4 className="impact_h4">Team A</h4>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Player Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamA &&
                                                teamA.length > 0 &&
                                                teamA.map((player, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{++i}</td>

                                                            <td>{player.PlayerName}</td>
                                                            <td>
                                                                <div className='checkbox'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='checkbox-input'
                                                                        id='checkbox1'
                                                                        onChange={(e) =>
                                                                            handleAChange(e, i)
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>

                                </div>
                            </div>
                        </div>

                        <div className='card mt-3'>
                            <h4 className="impact_h4">Team B</h4>

                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Player Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamB &&
                                                teamB.length > 0 &&
                                                teamB.map((player, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i++}</td>
                                                            <td>{player.PlayerName}</td>

                                                            <td>
                                                                <div className='checkbox'>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='checkbox-input'
                                                                        id='checkbox1'
                                                                        onChange={(e) =>
                                                                            handleBChange(e, i)
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <button className='impact-button-series' onClick={handleInsertImpact}>Update Impact Player List</button>

                    </div>
                </div>
            </div>
        </div>
    );
}
