import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import { CSVLink } from 'react-csv';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RotatingLines } from 'react-loader-spinner';

export default function ActiveUserWithDeposit() {
    const [statusSeries, setStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dataArray, setArray] = useState([]);
    const [alldataArray, allsetArray] = useState([]);
    const [isDownload, setIsDownload] = useState(false);
    const [isDownloadClicked, setIsDownloadClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const SdateChange = (e) => {
        setStartDate(e.target.value);
    };
    const EdateChange = (e) => {
        setEndDate(e.target.value);
    };

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Email', key: 'email' },
        { label: 'Mobile', key: 'phone' },
        { label: 'Amount', key: 'amount' },
        { label: 'Txn Time', key: 'createdAt' },
    ];

    const Search = () => {
        setIsDownloadClicked(false);
        setIsDownload(false);
        let active;
        if (!startDate) {
            return Notiflix.Notify.failure('error');
        } else {
            AccountManagerAPI.AllActiveUserWithDeposit(startDate).then((data) => {
                if (data.status_code) {
                    setArray(data.data);
                    setIsDownload(true);
                } else {
                    Notiflix.Notify.failure('error');
                }
            });
        }
    };
    const AllDataDownload = () => {
        setIsLoading(true);

        if (!startDate) {
            setIsLoading(false);
            return Notiflix.Notify.failure('error');
        } else {
            // AccountManagerAPI.AllActiveUserWithDeposit(startDate).then((data) => {
            //     if (data.status_code) {
            allsetArray(dataArray);
            setIsDownloadClicked(true);
            setIsDownload(false);
            setIsLoading(false);

            //     }
            //     else {
            //         Notiflix.Notify.failure('error');
            //     }
            // });
        }
    };

    const Reset = () => {
        setIsDownloadClicked(false);
        setIsDownload(false);
        setStartDate('');
        setEndDate('');
        setArray([]);
        allsetArray([]);
        setStatus('');
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>From :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={startDate}
                                                    onChange={SdateChange}
                                                />
                                            </div>
                                        </div>

                                        <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                className='btn btn-primary'
                                                type='button'
                                                onClick={() => Search()}
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                            <input
                                                type='reset'
                                                onClick={() => Reset()}
                                                className='btn btn-danger'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isDownload && !isLoading && (
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <button
                                    onClick={AllDataDownload}
                                    id='btnGroupDrop11'
                                    type='button'
                                    className='btn btn-success '
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Click to Download Data
                                </button>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                            </div>
                        </div>
                    )}

                    {isLoading && (
                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                            <div>
                                <RotatingLines
                                    marginTop='70'
                                    strokeColor='#c1030d'
                                    strokeWidth='5'
                                    animationDuration='0.75'
                                    width='50'
                                    visible={true}
                                />
                            </div>
                        </div>
                    )}

                    {isDownloadClicked && (
                        <CSVLink
                            filename='Active users with deposit'
                            data={alldataArray}
                            headers={headers}
                        >
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <button
                                    // onClick={AllDataDownload}
                                    id='btnGroupDrop11'
                                    type='button'
                                    className='btn btn-success '
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    Download Now
                                </button>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                            </div>
                        </CSVLink>
                    )}
                </>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Active Inactive users</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <span>
                                        <table className='table  table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>User Id</th>
                                                    <th>Team Name</th>
                                                    <th>Email </th>
                                                    <th>Phone</th>
                                                    <th>Amount</th>
                                                    <th>txn time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataArray &&
                                                    dataArray.length > 0 &&
                                                    dataArray.map((name, ind) => {
                                                        return (
                                                            <tr key={ind}>
                                                                <td>{++ind}</td>
                                                                <td>{name.user_id}</td>
                                                                <td>{name.team_name}</td>
                                                                <td>{name.email}</td>
                                                                <td>{name.phone}</td>
                                                                <td>{name.amount}</td>
                                                                <td>{name.createdAt}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='total_entries_div_classy'>
                        <Pagination
                            totalEntires={totalEntires}
                            totalPages={totalPages}
                            changePage={changePage}
                            pageNumber={pageNumber}
                        />
                        <h4>
                            {totalEntires}
                        </h4>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
        // </div>
        // </div>
    );
}
