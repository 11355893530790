import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { PrvtContest } from '../api/PvtContestAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useLocation } from 'react-router-dom';
import { TbCricket } from 'react-icons/tb';
import { Result } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

export default function PrivateContestInfo() {

    const location = useLocation();
    const contestPoolId = location.state.poolId;
    const prvCont_matchId = location.state.PVTmatchId;
    const prvCont_MatchName = location.state.matchName;
    const prvt_userId = location.state.PVTuserId;
    const prvt_matchStatus = location.state.matchStatus;
    const userId = location.state.userId;

    const [teamInfo, setTeamInfo] = useState([]);
    const [fantasy, setFantasy] = useState(location.state.fantasy);
    const [isLoading, setIsLoading] = useState(false);
    const [matchStatus, setMatchStatus] = useState('Not Yet');

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    useEffect(() => {
        getAllMatchesDataInfo();
        console.log(fantasy);
    }, []);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 64,
            }}
            spin
        />
    );

    const getAllMatchesDataInfo = () => {
        setIsLoading(true);
        PrvtContest.GetAllUserMatchesInfo(contestPoolId, prvCont_matchId,fantasy).then((data) => {
            if (data.status_code === true) {
                setIsLoading(false);
                setTeamInfo(data.data);
                setMatchStatus(data.matchStatus);
                console.log(data.data);
                Notiflix.Notify.success('Successfully Fetched');
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const handleTeamRole = (captain, viceCaptain) => {
        if (captain) {
            return (
                <h6>Captain</h6>
            );
        } else if (viceCaptain) {
            return (
                <h6>Vice Captain</h6>
            );
        } else {
            return (
                <h6>Player</h6>
            );
        }
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <form>
                        <div className='offers_couponDiv-div'>
                            <TbCricket className='dashboard-icon' />
                            <h1 className='coupon_h1-h1'>{prvCont_MatchName}</h1>
                            <h4 className='offers-div-main-private-comnt'>{prvt_matchStatus}</h4>
                        </div>

                    </form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        {
                                            isLoading ? <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='70'
                                                        strokeColor='#c1030d'
                                                        strokeWidth='5'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                                : (
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div
                                                            >
                                                                <div className='table-responsive'>
                                                                    <table className='table table-striped table-bordered'>
                                                                        <thead>
                                                                            <tr className='support_main_file_div'>
                                                                                <th>Rank</th>
                                                                                <th>Team Name</th>
                                                                                <th>User Id</th>
                                                                                <th>Winning Price</th>
                                                                                <th>Team Detail</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                teamInfo.length === 0 ?
                                                                                    <Result
                                                                                        icon={<SmileOutlined />}
                                                                                        title="No Private Contest Was Created"

                                                                                    /> : teamInfo.map((team, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>{team.rank}</td>
                                                                                                <td>{team.team_name}</td>
                                                                                                {team.user_id===userId? <td className='offers-div-main-private-comnt'>{team.user_id}</td>:<td>{team.user_id}</td>}
                                                                                                
                                                                                                <td>{team.WinPrice}</td>
                                                                                                <td><button className='some_898989898988'>
                                                                                                    <Link
                                                                                                        className='some_898989898988'
                                                                                                        to={
                                                                                                            '/prvt-contest-team-info'
                                                                                                        }
                                                                                                        state={{
                                                                                                            PVTuserId: prvt_userId,
                                                                                                            PVTmatchId: prvCont_matchId,
                                                                                                            matchName: prvCont_MatchName,
                                                                                                            matchStatus: prvt_matchStatus,
                                                                                                            poolId: contestPoolId,
                                                                                                            fantasy:fantasy,
                                                                                                        }}
                                                                                                        type='submit'
                                                                                                    >
                                                                                                        Check Team
                                                                                                    </Link>
                                                                                                </button></td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >);
}
