import { json, Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MatchAPI } from '../api/matchApi';
import { SeriesAPI } from '../api/seriesApi';
import Notiflix from 'notiflix';

export default function EditExistingMatchesPage() {
    const location = useLocation();
    const navigate = useNavigate();
    // alert(JSON.stringify(location.state.StartDateIST))
    const [matchId, setMatchId] = useState(location.state.matchId);
    const [apiMatchId, setApiMatchId] = useState(location.state.apiMatchId);
    const [matchName, setMatchName] = useState(location.state.matchName);
    const [dateStart, setdateStart] = useState(location.state.StartDateIST);
    const [teamA, setTeamA] = useState(location.state.teamAname);
    const [teamB, setTeamB] = useState(location.state.teamBname);
    const [series, setSeries] = useState('');
    const [format, setFormat] = useState(location.state.matchType);

    console.log(location.state.matchType);

    const [tmp, setTmp] = useState(0);
    // const [matchFormat,setMatchFormat]=useState(location.state.matchType);
    const [formatList, setFormatList] = useState([]);
    const [seriesList, setSeriesList] = useState([]);

    useEffect(() => {
        GetMatchFormat();
        GetSeries();
    }, []);

    const GetMatchFormat = () => {
        // alert("hi")
        MatchAPI.GetMatchFormat().then((data) => {
            setFormatList(data.message);
        });
    };

    const GetSeries = () => {
        SeriesAPI.GetSeries().then((data) => {
            setSeriesList(data.Data);
        });
    };

    const matchNameChange = (e) => {
        setMatchName(e.target.value);
    };

    const dateChange = (e) => {
        setTmp(1);
        setdateStart(e.target.value);
    };
    const teamAChange = (e) => {
        setTeamA(e.target.value);
    };
    const teamBChange = (e) => {
        setTeamB(e.target.value);
    };
    const seriesChange = (e) => {
        setSeries(e.target.value);
    };
    const formatChange = (e) => {
        setFormat(e.target.value);
    };
    // const formatChange=(e)=>{
    //     setMatchFormat(e.target.value)
    // }
    const Datasubmit = () => {
        if (tmp == 1) {
            let editData = {
                matchId: matchId,
                apiMatchId: apiMatchId,
                team1: teamA,
                team2: teamB,
                matchStartDate: dateStart.split('T')[0] + ' ' + dateStart.split('T')[1] + ':00',
                series: series,
                matchType: format,
            };
            // alert(JSON.stringify(editData))
            MatchAPI.EditUpcomingMatch(editData).then((data) => {
                Notiflix.Notify.success(data.meassage);

                navigate('/upcoming_matches');
            });
        } else {
            let editData = {
                matchId: matchId,
                apiMatchId: apiMatchId,
                team1: teamA,
                team2: teamB,
                matchStartDate: dateStart,
                series: series,
                matchType: format,
            };
            // alert(JSON.stringify(editData))
            MatchAPI.EditUpcomingMatch(editData).then((data) => {
                Notiflix.Notify.success(data.meassage);
                console.log(data.meassage);
                navigate('/upcoming_matches');
            });
        }
    };

    const handleReset = () => {
        setdateStart('');
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <div className='d-flex align-items-center justify-content-between welcome-content'>
                            <div className='navbar-breadcrumb'>
                                <h4 className='mb-0'>&nbsp;</h4>
                            </div>
                            <div className=''>
                                <Link
                                    to={'/upcoming_matches'}
                                    className='button btn btn-skyblue button-icon'
                                >
                                    <i className='ml-2 ri-add-line'></i>View All Upcoming Matches
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>Edit Existing Match</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <form>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Match Name :</label>
                                        <input
                                            type='text'
                                            value={matchName}
                                            disabled
                                            onChange={matchNameChange}
                                            className='form-control'
                                            placeholder='Match Name '
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Team 1 :</label>
                                        <input
                                            type='text'
                                            value={teamA}
                                            disabled
                                            //   onChange={teamAChange}

                                            className='form-control'
                                            placeholder='Team'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Team 2 :</label>
                                        <input
                                            type='text'
                                            value={teamB}
                                            //   onChange={teamBChange}
                                            disabled
                                            className='form-control'
                                            placeholder='Team'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='exampleInputdatetime'>
                                            Match Start Date
                                        </label>
                                        <input
                                            type='datetime-local'
                                            value={dateStart}
                                            onChange={dateChange}
                                            className='form-control'
                                            id='exampleInputdatetime'
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor='name'>Match Format :</label>
                                        <select
                                            className='form-control mb-3'
                                            onChange={formatChange}
                                        >
                                            <option value=''>{format}</option>

                                            {/* <option>{formatList[1]}</option>
                        <option>{formatList[13]}</option>                         */}
                                            {formatList.map((ford) => (
                                                <option value={ford.format}>{ford.format}</option>
                                            ))}
                                            {/* <option value="T50">T50</option>
                        <option value="T20">T20</option>
                        <option value="odi">odi</option> */}
                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                    <label htmlFor="name">Select Series :</label>
                    <select className="form-control mb-3">
                      <option selected value="">
                        Open this select menu
                      </option>
                      {seriesList.map((fan) => (
                        <option value={fan.title}>{fan.title}</option>
                      ))}
                    </select>
                  </div> */}
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={Datasubmit}
                                    >
                                        Submit
                                    </button>
                                    &nbsp;
                                    <button
                                        type='button'
                                        className='btn bg-danger'
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
