import { BASEAPIS } from './BaseApis';
 
export const NotificationAPI = {

    FCMNotification: async (jsonbody) => {
        return await BASEAPIS.POSTAPI(jsonbody,'fcmNotification');
    },
      
    FCMCustomNotification: async (jsonbody, key) => {
        return await fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            // headers: {
            //     // these could be different for your API call
            //     Authorization:
            //         'key=' + apiKey,
            //     'Content-Type': 'application/json; charset=utf-8',
            // },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                // console.log(json,'data')
                if (json != null) {
                    //    console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
        // }
    },
};
