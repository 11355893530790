import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import moment from 'moment';
import { ReportAPI } from '../api/ReportAPI';
import { RotatingLines } from 'react-loader-spinner';
import { CSVLink } from 'react-csv';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { BsFillFileArrowDownFill } from 'react-icons/bs';

export default function ReferReportData() {
    const [referUserData, setReferUserData] = useState([]);
    const [referStatus, setReferStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [downloadData, setDownloadData] = useState([]);
    const [totalUsersStatus, setTotalStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showError, setShowError] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    useEffect(() => {
        getReffralData();
    }, [referStatus]);


    const getReffralData = () => {
        setIsLoading(true);
        setDownloadData('');
        setDownloadData('');
        ReportAPI.ReferReport(referStatus, startDate, endDate).then((data) => {
            if (data.status_code) {
                setIsLoading(false);
                // setReferUserData(data.data);
                // setDownloadData(677);
                // setReferUserData(data.data);
                // setDownloadData(677);
                setTotalStatus(data.length);
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const getAllReffralData = () => {
        setIsButtonLoading(true);
        ReportAPI.ReferReportDownload(referStatus, startDate, endDate).then((data) => {
            if (data.status_code) {
                // setIsLoading(false);
                setIsButtonLoading(false);

                // setReferUserData(data.data);
                setDownloadData(data.data);
                // setTotalStatus(data.length);
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const headers = [
        { label: 'User Id', key: 'user_id' },
        { label: 'Team Name', key: 'team_name' },
        { label: 'Mobile No', key: 'phone' },
        { label: 'Email Id', key: 'email' },
        { label: 'Referral Code', key: 'refCode' },
    ];

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
                color: 'white',
            }}
            spin
        />
    );

    const referUserSelect = (status) => {
        if (status === 'DIRECT') {
            return <h2>Direct</h2>;
        } else if (status === 'INDIRECT') {
            return <h2>Referral</h2>;
        } else {
            return <h2>All</h2>;
        }
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        getReffralData();
        setTotalStatus('');
        setReferStatus('');
        setDownloadData('');
        setDownloadData('');
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div>
                        <div className='main-ref-ral-div'>
                            <h1 className='coupon_h1-h1'>Referrals Report</h1>

                            <div className=''>
                                <Link to={'/refer-report'} className='button-report-referral'>
                                    Referral Report
                                </Link>
                                <Link to={'/indi-refer-report'} className='button-report-referral'>
                                    Individual User Report
                                </Link>
                                <Link to={'/ref-stats'} className='button-report-referral'>
                                    User Report Stats
                                </Link>
                                
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body '>
                                        <div className='div-liread-get'>
                                            <div className='col '>
                                                <label htmlFor='name'>Status</label>
                                                <div className='download-refer-div'>
                                                    <select
                                                        onChange={handleAllChange(setReferStatus)}
                                                        type='text'
                                                        className='offers_input_98998'
                                                        placeholder='Search'
                                                    >
                                                        <option value=''>All</option>
                                                        <option value='DIRECT'>Direct</option>
                                                        <option value='INDIRECT'>Referral</option>
                                                    </select>

                                                    {(totalUsersStatus.length > 0 && !isButtonLoading)  ? (
                                                        ''
                                                    ) : (
                                                        <button
                                                            onClick={getAllReffralData}
                                                            className='userTransaction_button'
                                                            type='button'
                                                        >
                                                            <div className='div-tranx-div'>
                                                                Load Refer Data
                                                                {isButtonLoading && (
                                                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                                        <div>
                                                                            <RotatingLines
                                                                                strokeColor='#FFFFFF'
                                                                                strokeWidth='2'
                                                                                animationDuration='0.75'
                                                                                width='20'
                                                                                visible={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </button>
                                                    )}

                                                    {downloadData?.length > 0 && !isButtonLoading ? (
                                                        <button
                                                            className='userTransaction_button'
                                                            type='button'
                                                        >
                                                            <CSVLink
                                                                className='userTransaction_csv'
                                                                filename={'Referal Report'}
                                                                data={downloadData}
                                                                headers={headers}
                                                            >
                                                                {' '}
                                                                Download All Refer Report
                                                                <BsFillFileArrowDownFill className='bs_fillname' />
                                                            </CSVLink>
                                                        </button>
                                                    ) : (
                                                        ''
                                                    )}

                                                    {/* {
                                                        
                                                        downloadData.length>0  ?
                                                            <CSVLink
                                                                filename='Referral Report'
                                                                data={downloadData}
                                                                headers={headers}
                                                            >
                                                                <button disabled={isLoading ? true : false} className='button-series-9999'>
                                                                    <div className='div-spin-antd-div'>
                                                                        Download
                                                                        {isLoading ?
                                                                            <div className='div-spin-antd'>
                                                                                <Spin indicator={antIcon} />
                                                                            </div> :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </button>
                                                            </CSVLink> : ''
                                                    } */}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Numbers Rows */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body '>
                                    <div className=''>
                                        <div className='div-entreal-slim'>
                                            <div className='div-entreal-slim'>
                                                {/* Start Date or End Date */}

                                                <label htmlFor='name'>Start Date</label>
                                                <div className='download-refer-div'>
                                                    <input
                                                        onChange={handleAllChange(setStartDate)}
                                                        type='date'
                                                        className='offers_input_98998100'
                                                    />
                                                </div>

                                                <label htmlFor='name'>End Date</label>
                                                <div className='div-flex-justify-end'>
                                                    <div className='download-refer-div'>
                                                        <input
                                                            onChange={handleAllChange(setEndDate)}
                                                            type='date'
                                                            className='offers_input_98998100'
                                                        />
                                                    </div>

                                                    <button
                                                        onClick={getReffralData}
                                                        className='offers_input_98998100100'
                                                    >
                                                        Search
                                                    </button>

                                                    <button
                                                        onClick={handleReset}
                                                        className='offers_input_98998100101'
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>

                                            <hr className='litreal-hr' />

                                            {isLoading ? (
                                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                    <div>
                                                        <RotatingLines
                                                            marginTop='160'
                                                            marginBottom='160'
                                                            strokeColor='#000000'
                                                            strokeWidth='3'
                                                            animationDuration='0.75'
                                                            width='50'
                                                            visible={true}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='div-main-reffar-maiin-main'>
                                                    <div className='div-main-divv-maiin'>
                                                        <div> </div>
                                                        <div className='main-div-string-literal'>
                                                            {'Total '}
                                                            {referUserSelect(referStatus)}
                                                            {' Users'}
                                                        </div>
                                                    </div>
                                                    <h1 className='main-div-litreal-h1'>
                                                        {totalUsersStatus}
                                                    </h1>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
