import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function TotalBalancePage() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    });
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'></div>

                                        <div className='col'>
                                            <Link
                                                to={'/earning'}
                                                className='mt-2 btn btn-skyblue float-right'
                                            >
                                                {' '}
                                                <i className='ri-eye-2-line'></i> View Earnings
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Total Balance</th>
                                                    <th>Used Balance</th>
                                                    <th>Unutilized Balance</th>
                                                    <th>Total joining</th>
                                                    <th>Total Winning</th>
                                                    <th>Total joining – Total winning</th>
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                            <tfoot></tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
