import React from 'react';

const SerialNumber = ({ currentPage, entriesPerPage, activeIndex }) => {
    const calculateSerialNumber = (currentPage, entriesPerPage, activeIndex) => {
        return (currentPage - 1) * entriesPerPage + activeIndex;
    };

    return <span>
        {
            calculateSerialNumber(currentPage, entriesPerPage, activeIndex)
        }
    </span>;
};

export default SerialNumber;
