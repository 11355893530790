import { Link } from 'react-router-dom';

export default function ViewYoutuberPage() {
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Youtuber Name :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Youtuber Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Email :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Email id'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Mobile :</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Mobile no.'
                                            />
                                        </div>

                                        <div className='col'>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button type='submit' className='btn btn-primary'>
                                                Submit
                                            </button>
                                            &nbsp;
                                            <button type='submit' className='btn bg-danger'>
                                                <i className='ri-creative-commons-sa-line'></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <a className='button btn btn-danger button-icon' href='#'>
                                        <i className='ml-2 ri-add-line'></i>Select
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Password</th>
                                                    <th>Refer Code</th>
                                                    <th>Total Earned</th>
                                                    <th>Today's Earned</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Imran</td>
                                                    <td>imran@albatrossmedia.com</td>
                                                    <td>9716734521</td>
                                                    <td>Hello123#</td>
                                                    <td>IF2022</td>
                                                    <td>9774.95</td>
                                                    <td>0</td>
                                                    <td>
                                                        <Link
                                                            to={'/editoffers'}
                                                            className='btn btn-success btn-sm'
                                                        >
                                                            <i className='ri-edit-line'></i>Edit
                                                        </Link>
                                                        &nbsp;
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>
                                                            Delete
                                                        </button>
                                                        <span style={{ color: '#14a409' }}>
                                                            dectucted Money
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Imran</td>
                                                    <td>imran@albatrossmedia.com</td>
                                                    <td>9716734521</td>
                                                    <td>Hello123#</td>
                                                    <td>IF2022</td>
                                                    <td>9774.95</td>
                                                    <td>0</td>
                                                    <td>
                                                        <a
                                                            href='editoffers'
                                                            className='btn btn-success btn-sm'
                                                        >
                                                            <i className='ri-edit-line'></i>Edit
                                                        </a>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>
                                                            Delete
                                                        </button>
                                                        <span style={{ color: '#14a409' }}>
                                                            dectucted Money
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Imran</td>
                                                    <td>imran@albatrossmedia.com</td>
                                                    <td>9716734521</td>
                                                    <td>Hello123#</td>
                                                    <td>IF2022</td>
                                                    <td>9774.95</td>
                                                    <td>0</td>
                                                    <td>
                                                        <a
                                                            href='editoffers'
                                                            className='btn btn-success btn-sm'
                                                        >
                                                            <i className='ri-edit-line'></i>Edit
                                                        </a>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>
                                                            Delete
                                                        </button>
                                                        <span style={{ color: '#14a409' }}>
                                                            dectucted Money
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Imran</td>
                                                    <td>imran@albatrossmedia.com</td>
                                                    <td>9716734521</td>
                                                    <td>Hello123#</td>
                                                    <td>IF2022</td>
                                                    <td>9774.95</td>
                                                    <td>0</td>
                                                    <td>
                                                        <a
                                                            href='editoffers'
                                                            className='btn btn-success btn-sm'
                                                        >
                                                            <i className='ri-edit-line'></i>Edit
                                                        </a>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btn-sm'
                                                        >
                                                            <i className='ri-delete-bin-line'></i>
                                                            Delete
                                                        </button>
                                                        <span style={{ color: '#14a409' }}>
                                                            dectucted Money
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Password</th>
                                                    <th>Refer Code</th>
                                                    <th>Total Earned</th>
                                                    <th>Today's Earned</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
