import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SeriesAPI } from '../api/seriesApi';
import { RotatingLines } from 'react-loader-spinner';

export default function SeriesMatchDetail() {
    const location = useLocation();
    // console.log(location)
    const seriesCID = location.state.seriesCID;
    // const {apiMatchId} = location.state;
    // const [matchId, setMatchId] = useState(apiMatchId);
    // console.log(cid);
    const [raw, setRaw] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchFullDetail();
    }, []);

    // let data = {
    //     cid: label,
    // };

    const fetchFullDetail = () => {
        SeriesAPI.GetMatchDetail(seriesCID).then((data) => {
            console.log(data.Data);
            setRaw(data.data);
            console.log(data);
        });

        setIsLoading(false);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Series Match Details</h4>
                                    </div>
                                </div>

                                {isLoading && (
                                    <div className='col-sm-12 col-lg-12'>
                                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                            <RotatingLines
                                                marginTop='40'
                                                strokeColor='#c1030d'
                                                strokeWidth='5'
                                                animationDuration='0.75'
                                                width='50'
                                                visible={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table className='table table-striped table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Match Name</th>
                                                            <th>StartDate</th>
                                                            <th>Team A</th>
                                                            <th>Team B</th>
                                                            <th>Match Status</th>
                                                            <th>Match Result</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {raw?.length > 0 ? (
                                                            raw.map((data, i) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{++i}</td>
                                                                        <td>{data.matchName}</td>
                                                                        <td>{data.StartDateIST}</td>
                                                                        <td>{data.teamAname}</td>
                                                                        <td>{data.teamBname}</td>
                                                                        <td>{data.launchStatus === true ? 'YES' : 'NO'}</td>
                                                                        <td>{data.isResult === true ? 'YES' : 'NO'}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <div className='col-sm-12 col-lg-12 mt-4 ml-10'>
                                                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient mr-10'>
                                                                    <h3>No Data Found</h3>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
