import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AdminWalletAPI } from '../api/adminWalletAPI';

export default function SearchAdminWallet() {
    const [walletData, setWalletData] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [userid, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const [amount_type, setAmountType] = useState('');
    const [description, setDescription] = useState('');
    const [master_password, setMasterPassword] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        // FetchAdminWalletUserData()
    }, []);

    const FetchAdminWalletUserData = () => {
        AdminWalletAPI.FetchAdminWalletUserData(teamName, userid, email).then((data) => {
            setWalletData(data.data);
            console.log(data.data);
        });
    };

    const reset = () => {
        setWalletData([]);
        setUserId('');
        setTeamName('');
        setEmail('');
    };
    // const updateWallet = (e) => {
    // //   e.preventDefault();
    //     console.log(e.target[0].value);

    //     //   let data = {
    //     //       user_id:
    //     //   }
    // }
    const getTransactionPassword = () => {
        // document.getElementById('add-money-1').style.display = "none";
        // document.getElementById('add-money-2').style.display = "block";
    };
    const addMoneyReset = () => {
        // document.getElementById('add-money-1').style.display = "block";
        // document.getElementById('add-money-2').style.display = "none";
    };
    const setTransactionType = (transaction_type) => {
        document.getElementById('transaction-type').value = transaction_type;
    };
    const AddAdminFund = (e) => {
        e.preventDefault();
        // console.log('error');
        console.log(e.target[0].value);
        console.log(e.target[1].value);
        console.log(e.target[2].value);
        console.log(e.target[3].value);
        console.log(e.target[4].value);
        let launchData = {
            user_id: e.target[0].value,
            amount: e.target[1].value,
            amount_type: e.target[2].value,
            description: e.target[3].value,
            master_password: e.target[4].value,
        };

        if (e.target[5].value == 'add') {
            AdminWalletAPI.AddAdminFund(launchData).then((data) => {
                Notiflix.Notify.success(data.message);
            });
        } else if (e.target[5].value == 'deduct') {
            AdminWalletAPI.DeductAdminFund(launchData).then((data) => {
                Notiflix.Notify.success(data.message);
            });
        }

        // alert(JSON.stringify(launchData))
    };

    // const amountChange = (e) => {
    //     console.log(e.target.value)
    //     setAmount(e.target.value);
    // }

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-4 col-lg-12 '>
                            <div className='card'>
                                <div className='card-body'>
                                    <a>
                                        <Link
                                            to='/admin_wallet'
                                            className='btn btn-m bg-danger float-right'
                                        >
                                            <i className='ri-add-line'></i>View List
                                        </Link>
                                    </a>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='phone'> Team Name: </label> &nbsp;
                                            <input
                                                type='text'
                                                className='form-control-sm'
                                                id='phone'
                                                placeholder='Search by Team Name'
                                                value={teamName}
                                                onChange={(e) => setTeamName(e.target.value)}
                                            />
                                            &nbsp;
                                            <label htmlFor='phone'> User ID: </label> &nbsp;
                                            <input
                                                type='text'
                                                className='form-control-sm'
                                                id='phone'
                                                placeholder='Search by User ID'
                                                value={userid}
                                                onChange={(e) => setUserId(e.target.value)}
                                            />
                                            &nbsp;
                                            <label htmlFor='phone'> Email: </label> &nbsp;
                                            <input
                                                type='email'
                                                className='form-control-sm'
                                                id='phone'
                                                placeholder='Search by Email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                        <br />
                                        <label htmlFor='name'></label>
                                        <button
                                            type='submit'
                                            className='btn btn-m btn-primary'
                                            onClick={FetchAdminWalletUserData}
                                        >
                                            Search
                                        </button>
                                        &nbsp;
                                        <button
                                            onClick={reset}
                                            type='submit'
                                            className='btn btn-m bg-danger'
                                        >
                                            <i className='ri-creative-commons-sa-line'></i>Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Starts from here */}
                    <div
                        className='modal fade'
                        id='exampleModalScrollable'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='exampleModalScrollableTitle'
                        aria-hidden='true'
                    >
                        <div className='modal-dialog modal-dialog-scrollable' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                        Add Money
                                    </h5>
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body p-4'>
                                    <form id='add-money-1' onSubmit={(e) => AddAdminFund(e)}>
                                        <div className='form-group'>
                                            <label htmlFor='user-id'>User ID :</label>
                                            <input
                                                id='user_id'
                                                type='text'
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Amount :</label>
                                            <input
                                                id='amount'
                                                type='number'
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Select Amount Type :</label>
                                            <select id='amount_type' className='form-control mb-3'>
                                                <option select=''>Select</option>
                                                <option value='addFund'>Add Fund</option>
                                                <option value='Bonus'>Bonus</option>
                                                <option value='Winning'>Winning</option>
                                            </select>
                                        </div>
                                        <label>Description</label>
                                        <textarea
                                            className='form-control form-control-sm'
                                            name='comment'
                                            rows='5'
                                            cols='58'
                                            id='description'
                                        ></textarea>

                                        {/* <div className="form-group">
                    <button type="button" data-toggle="modal" data-target="#exampleModalScrollable2" className="btn btn-primary mt-2" onClick={() => getTransactionPassword()}>Next</button>
                    </div> */}

                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Enter your Master Password :
                                            </label>
                                            <input
                                                id='master_password'
                                                type='passworf'
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            {/* <input type="text" id="transaction-type"/> */}
                                            <button className='btn btn-primary mt-2'>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="card">

                                
                            </div>
                        </div>
                    </div> */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Admin Wallet Details</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>User ID</th>
                                                        <th>Team</th>
                                                        <th>User Name</th>
                                                        <th>Email</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {walletData.map((data, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{++i}</td>
                                                                <td>{data.user_id}</td>
                                                                <td>{data.team_name}</td>
                                                                <td>{data.name}</td>
                                                                <td>{data.email}</td>
                                                                <td>
                                                                    <button
                                                                        onClick={() => {
                                                                            addMoneyReset();
                                                                            setTransactionType(
                                                                                'add'
                                                                            );
                                                                        }}
                                                                        className='btn btn-primary btn-primary-fix btn-sm mr-2'
                                                                        data-toggle='modal'
                                                                        data-target='#exampleModalScrollable'
                                                                    >
                                                                        <i className='ri-edit-line'></i>
                                                                        Add Money
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            addMoneyReset();
                                                                            setTransactionType(
                                                                                'deduct'
                                                                            );
                                                                        }}
                                                                        className='btn btn-danger btn-sm'
                                                                        data-toggle='modal'
                                                                        data-target='#exampleModalScrollable'
                                                                    >
                                                                        <i
                                                                            className='la fa fa-minus-circle mr-2'
                                                                            aria-hidden='true'
                                                                        ></i>
                                                                        Deduct
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
