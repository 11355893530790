import { BASEAPIS } from './BaseApis';
import { baseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');

export const BannerAPI = {

    AddBanner: async (jsonbody) => {
        return await fetch(baseURL + 'storeBanner', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
            body: jsonbody,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },


    FetchBanner: () => {
        return BASEAPIS.GETAPI('getBanner');
    },

    editDashboardBanner: (formData) => {
        return BASEAPIS.POSTAPI(formData, 'bannerEditForDashboard');
    },

    EditBanner: () => {
        return BASEAPIS.POSTAPI('editBanner');
    },

    DeleteBanner: (_id) => {
        const data = {
            _id: _id,
            isdeleted: true,
        };
        return BASEAPIS.POSTAPI(data, 'editBanner');
    },

    CreateBannerPopUp: (data) => {
        return BASEAPIS.POSTAPI(data, 'bannerCreate');
    },

    GetBanner: () => {
        return BASEAPIS.GETAPI('getBanner');
    },

    DeleteBannerPopUp: (json) => {
        return BASEAPIS.DELETEAPI(json, 'bannerDelete');
    },

    UpdateBannerStatus: (json) => {
        return BASEAPIS.PUTAPI(json, 'updateBannerStatus');
    },
};
