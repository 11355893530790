import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { SeriesAPI } from '../api/seriesApi';
import { ContestAPI } from '../api/contestApi';
import { GlobalContestAPI } from '../api/globalContestApi';
import Notiflix from 'notiflix';

export default function EditGlobalContestPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        contest_amount_type,
        contest_category,
        contest_prize_card_type,
        entry_fee,
        fantasy,
        is_bonus,
        is_confirmed,
        is_multiple_entry,
        is_running,
        maximum_user,
        offer_entry_fee,
        offered_entry,
        order,
        type_of_contest,
        winning_amount,
        _id,
        bonus,
        multi_entry,
    } = location.state;
    const [categoryList, setCategoryList] = useState([]);
    const [fantasyList, setFantasyList] = useState([]);
    const [amountType, setAmountType] = useState(contest_amount_type);
    const [priceCardType, setPriceCardType] = useState();
    const [contestType, setContestType] = useState(type_of_contest);
    const [myfantasy, setFantasy] = useState();
    const [category, setCategory] = useState(contest_category);
    const [entryFee, setEntryFee] = useState(entry_fee);
    const [offerEntryFee, setOfferEntryFee] = useState(offer_entry_fee);
    const [offeredEntry, setOfferedEntry] = useState(offered_entry);
    const [winningAmount, setWinningAmount] = useState(winning_amount);
    const [maxUsers, setMaxUsers] = useState(maximum_user);
    const [orders, setOrders] = useState(order);
    const [multi, setMulti] = useState(multi_entry);
    const [isRunning, setIsRunning] = useState(is_running);
    const [isBonus, setIsBonus] = useState(is_bonus);
    const [Bonus, setBonus] = useState(bonus);
    const [IsConfirm, setIsConfirm] = useState(is_confirmed);
    const [IsMultiEntry, setMultiEntry] = useState(is_multiple_entry);
    const [UserMaxJoin, setUserMaxJoin] = useState(multi_entry);
    // const [BonusPrice,setBonusPrice]=useState(bonus);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFantasy();
        // GetCategory();
        console.log(isRunning);

        console.log(IsConfirm);
    }, []);

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
            console.log(fantasyList);
        });
    };
    const MultientryChange = () => {
        setMultiEntry(document.getElementById('checkbox1').checked);
    };

    const MultiChange = (e) => {
        setMulti(e.target.value);
    };

    const IsBonusChange = () => {
        setIsBonus((prev) => !prev);
    };

    const BonusChange = (e) => {
        console.log(e.target.value);
        setBonus(e.target.value);
    };

    const submitChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!IsMultiEntry) {
            setMulti(0);
        }

        if (!isBonus) {
            setBonus(0);
        }

        if (entryFee === undefined || offerEntryFee === undefined || offeredEntry === undefined) {
            Notiflix.Notify.failure('Please Fill all the Values');
            return;
        }

        // if(entryFee*maxUsers<winningAmount){
        //   Notiflix.Notify.failure("Decrease Winning Amount or increase Entry fee or Maximum Users")
        //   return

        // }

        // const IsRunning= e.target[16].checked

        // const IsBonusAllowed= e.target[17].checked

        const ChangeConfirmStatus = e.target[18].checked;

        const data = {
            _id: _id,
            fantasy: myfantasy,
            contest_amount_type: amountType,
            contest_prize_card_type: priceCardType,
            contest_category: category,
            entry_fee: entryFee,
            offer_entry_fee: offerEntryFee,
            offered_entry: offeredEntry,
            type_of_contest: contestType,
            winning_amount: winningAmount,
            maximum_user: maxUsers,
            order: orders,
            is_multiple_entry: IsMultiEntry,
            is_confirmed: IsConfirm,
            is_running: isRunning,
            is_bonus: isBonus,
            bonus: Bonus,
            multi_entry: UserMaxJoin,
        };

        EditGlobalContest(data);
    };

    const EditGlobalContest = (data) => {
        console.log(data);
        GlobalContestAPI.EditGlobalContest(data).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Done');
                navigate('/global_contests');
            } else {
                Notiflix.Notify(data.msg);
            }
        });
    };

    const MultiEntryChange = () => {
        setMultiEntry((prev) => !prev);
    };

    const IsConfirmedChange = (e) => {
        console.log(e.target.checked);

        setIsConfirm(e.target.checked);
    };

    const IsBonusAllowedChange = () => {
        setIsBonus((prev) => !prev);
    };

    const IsRunningChange = () => {
        setIsRunning((prev) => !prev);
        // console.log(isRunning)
    };

    const AmountTypeChange = (e) => {
        setAmountType(e.target.value);
    };
    const PriceCardTypeChange = (e) => {
        setPriceCardType(e.target.value);
    };

    const ContestTypeChange = (e) => {
        setContestType(e.target.value);
    };

    const GetCategory = (cat) => {
        ContestAPI.GetContestCategory(cat).then((data) => {
            setCategoryList(data.data);
            console.log(data);
        });
    };

    const fantasyChange = (e) => {
        setFantasy(e.target.value);
        GetCategory(e.target.value);
    };

    const CategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const OfferEntryFeeChange = (e) => {
        setOfferEntryFee(e.target.value);
    };

    const OfferedEntryChange = (e) => {
        setOfferedEntry(e.target.value);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/global_contests'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View All Global Contest
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Global Contest</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form
                                        onSubmit={(e) => {
                                            submitChange(e);
                                        }}
                                    >
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='name'>Select Fantasy :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={fantasyChange}
                                                >
                                                    <option selected value=''>
                                                        Select
                                                    </option>
                                                    {fantasyList.map((fan) => (
                                                        <option value={fan.fantasy}>
                                                            {fan.fantasy}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-5'>
                                                <label htmlFor='name'>Contest Amount Type:</label>
                                                <br />
                                                <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio6'
                                                        name='customRadio-1'
                                                        checked={contest_amount_type == 'Amount Based Contest'}
                                                        className='custom-control-input'
                                                        value='Amount Based Contest'
                                                        onChange={AmountTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio6'
                                                    >
                                                        {' '}
                                                        Amount Based Contest{' '}
                                                    </label>
                                                </div>
                                                <div className='custom-control custom-radio custom-control-inline'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio7'
                                                        name='customRadio-1'
                                                        className='custom-control-input'
                                                        checked={contest_amount_type == 'Percentage Based Contest'}
                                                        value='Percentage Based Contest'
                                                        onChange={AmountTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio7'
                                                    >
                                                        
                                                        Percentage Based Contest
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6'>
                                                <label htmlFor='name'>
                                                    Contest PriceCard Type:
                                                </label>
                                                <br />
                                                <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio8'
                                                        name='customRadio-2'
                                                        className='custom-control-input'
                                                        checked={contest_prize_card_type === 'Amount Based Price Card'}
                                                        value='Amount Based Price Card'
                                                        onChange={PriceCardTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio8'
                                                    >
                                                        {' '}
                                                        Amount Based Price Card{' '}
                                                    </label>
                                                </div>
                                                <div className='custom-control custom-radio custom-control-inline'>
                                                    <input
                                                        type='radio'
                                                        id='customRadio9'
                                                        name='customRadio-2'
                                                        className='custom-control-input'
                                                        checked={contest_prize_card_type === 'Percentage based Pricecard'}
                                                        value='Percentage based Pricecard'
                                                        onChange={PriceCardTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='customRadio9'
                                                    >
                                                        {' '}
                                                        Percentage based Pricecard
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='text'>Contest Category :</label>
                                                <select
                                                    className='form-control mb-3'
                                                    onChange={CategoryChange}
                                                >
                                                    <option select=''>
                                                        Select Contest Category
                                                    </option>
                                                    {categoryList?.map((category) => (
                                                        <option
                                                            value={category.ContestCategoryName}
                                                        >
                                                            {category.ContestCategoryName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Entry Fee :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Entry Fee'
                                                    value={entryFee}
                                                    onChange={(e) => {
                                                        setEntryFee(e.target.value);
                                                    }}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Offer Entry Fee :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Offer Entry Fee'
                                                    value={offerEntryFee}
                                                    onChange={OfferEntryFeeChange}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Offered Entry :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Offered Entry'
                                                    value={offeredEntry}
                                                    onChange={OfferedEntryChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <label htmlFor='name'>Type of Contest:</label>{' '}
                                                <br />
                                                <div className='custom-control custom-radio custom-control-inline rtl-mr-0'>
                                                    <input
                                                        type='radio'
                                                        id='price'
                                                        name='contest1'
                                                        className='custom-control-input'
                                                        checked={type_of_contest === 'Price'}
                                                        value='Price'
                                                        onChange={ContestTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='price'
                                                    >
                                                        {' '}
                                                        Price{' '}
                                                    </label>
                                                </div>
                                                <div className='custom-control custom-radio custom-control-inline'>
                                                    <input
                                                        type='radio'
                                                        id='prize'
                                                        name='contest1'
                                                        className='custom-control-input'
                                                        checked={type_of_contest === 'Prize'}
                                                        value='Prize'
                                                        onChange={ContestTypeChange}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='prize'
                                                    >
                                                        {' '}
                                                        Prize
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Winning Amount:</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Winning Amount'
                                                    value={winningAmount}
                                                    onChange={(e) => {
                                                        setWinningAmount(e.target.value);
                                                    }}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>
                                                    Maximum Number of Users:
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter Maximum Number of Users'
                                                    value={maxUsers}
                                                    onChange={(e) => {
                                                        setMaxUsers(e.target.value);
                                                    }}
                                                />
                                            </div>

                                            <div className='form-group col-md-4'>
                                                <label htmlFor='text'>Orders :</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    defaultValue=''
                                                    placeholder='Enter orders'
                                                    value={orders}
                                                    onChange={(e) => {
                                                        setOrders(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-12'>
                                                <div className='form-group'>
                                                    <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                        <input
                                                            type='checkbox'
                                                            className='custom-control-input'
                                                            id='checkbox1'
                                                            checked={IsMultiEntry}
                                                            onChange={MultientryChange}
                                                        />
                                                        <label
                                                            className='custom-control-label'
                                                            htmlFor='checkbox1'
                                                        >
                                                            Multi Entry
                                                        </label>
                                                    </div>

                                                    <input
                                                        type='number'
                                                        className='form-control my-3'
                                                        value={UserMaxJoin}
                                                        onChange={(e) => {
                                                            setUserMaxJoin(Number(e.target.value));
                                                        }}
                                                        style={{
                                                            display:
                                                                IsMultiEntry == false
                                                                    ? 'none'
                                                                    : 'flex',
                                                        }}
                                                        placeholder='User Max Number of Team Join'
                                                        defaultValue=''
                                                    />
                                                </div>

                                                <div className='form-group'>
                                                    <div className='custom-control custom-checkbox custom-control-inline mr-3'>
                                                        <input
                                                            type='checkbox'
                                                            className='custom-control-input'
                                                            id='checkbox2'
                                                            checked={IsConfirm}
                                                            onChange={(e) => IsConfirmedChange(e)}
                                                            value={IsConfirm}
                                                        />
                                                        <label
                                                            className='custom-control-label'
                                                            htmlFor='checkbox2'
                                                        >
                                                            Is Confirmed
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='custom-control custom-checkbox custom-control-inline mr-3'>
                                                    <input
                                                        type='checkbox'
                                                        className='custom-control-input'
                                                        id='checkbox3'
                                                        checked={isRunning}
                                                        onChange={IsRunningChange}
                                                        value={isRunning}
                                                    />
                                                    <label
                                                        className='custom-control-label'
                                                        htmlFor='checkbox3'
                                                    >
                                                        Is Running
                                                    </label>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='custom-control custom-checkbox custom-control-inline mr-3 rtl-mr-0'>
                                                        <input
                                                            type='checkbox'
                                                            className='custom-control-input '
                                                            id='checkbox4'
                                                            checked={isBonus}
                                                            onChange={IsBonusChange}
                                                        />
                                                        <label
                                                            className='custom-control-label'
                                                            htmlFor='checkbox4'
                                                        >
                                                            Is Bonus
                                                        </label>
                                                    </div>

                                                    <input
                                                        type='number'
                                                        className='form-control my-3'
                                                        value={Bonus}
                                                        onChange={(e) => BonusChange(e)}
                                                        style={{
                                                            display:
                                                                isBonus == false ? 'none' : 'flex',
                                                        }}
                                                        placeholder='Bonus Percentage'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-primary'>
                                            Submit
                                        </button>
                                        &nbsp;
                                        <button type='submit' className='btn bg-danger'>
                                            Reset
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
