import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { UsersAPI } from '../api/userApi';
import moment from 'moment';

export default function ViewTransactionPage() {

    const location = useLocation();
    const contest_id = location.state.contestPoolId;
    const matchId = location.state.matchId;
    const user_id = location.state.userId;

    console.log(contest_id, matchId, user_id);

    //   const userId = location.state.userId;
    const [transactionData, setTransactionData] = useState([]);

    useEffect(() => {
        getViewTransaction();
    }, []);

    // const dateToIst = (date) => {
    //     var dt = new Date(date).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
    //     return dt;
    // };

    // console.log(dt)

    const getViewTransaction = () => {
        UsersAPI.GetUserTransactionInContest(contest_id, user_id, matchId).then((res) => {
            console.log(res);
            if (res.status_code) {
                setTransactionData(res.data);
            } else {
                Notiflix.Notify.failure(res.msg);
            }
        });
    };

    const handleDecimalAfter = (number) => {
        if (number === undefined) {
            return '';
        } else {
            let result = Math?.round(number * 100) / 100;
            return result;
        }
    };

    const handleDate = (date) => {
        var dateNew = date?.slice(0, 10);
        return dateNew;
    };

    const handleTime = (e) => {
        var newTime = e?.slice(11, 18);
        var updateTime = moment(newTime, 'HH:mm:ss').format('hh:mm A');
        return updateTime;
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>User Transactions</h4>
                                    </div>
                                </div>

                                <div className='card'>
                                    <div className='table-responsive'>
                                        <span>
                                            <table className='table  table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>S.no</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Add Bonus</th>
                                                        <th>Add Unutilized</th>
                                                        <th>Add Winnings</th>
                                                        <th>Consumed Bonus</th>
                                                        <th>Consumed Unutilized</th>
                                                        <th>Consumed Winnings</th>
                                                        <th>Available Bonus: Total</th>
                                                        <th>Available Unutilized: Total</th>
                                                        <th>Available Winnings: Total</th>
                                                        <th>Total Available balance</th>
                                                        <th>Net Winning</th>
                                                        <th>Amount</th>
                                                        <th>Point Type</th>
                                                        <th>Transaction Type</th>
                                                        <th>TDS</th>
                                                        <th>Super Coins</th>
                                                        <th>Joining Fee</th>
                                                        <th>Transaction Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactionData &&
                                                        transactionData.length > 0 &&
                                                        transactionData.map((trans, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{++index}</td>
                                                                    <td>{handleDate(trans.createdAt)}</td>
                                                                    <td>{handleTime(trans.createdAt)}</td>
                                                                    <td>{(trans.point_type === 'SIGN') || (trans.point_type === 'BONUS' && trans.transaction_type === 'add') || (trans.point_type === 'REFUND' && trans.transaction_type === 'add') || (trans.point_type === 'REVERSE' && trans.transaction_type === 'add') || (trans.point_type === 'PROMO' && trans.transaction_type === 'add') ? handleDecimalAfter(trans?.bonus) : ''}</td>
                                                                    <td>{(trans.point_type === 'RAZ' && trans.transaction_type === 'add') || (trans.point_type === 'REFUND' && trans.transaction_type === 'add') || (trans.point_type === 'REVERSE' && trans.transaction_type === 'add') || (trans.point_type === 'PROMO' && trans.transaction_type === 'add') ? handleDecimalAfter(trans?.unutilized) : ''}</td>
                                                                    <td>{(trans.point_type === 'WIN' && trans.transaction_type === 'add') || (trans.point_type === 'REFUND' && trans.transaction_type === 'add') || (trans.point_type === 'REVERSE' && trans.transaction_type === 'add') || (trans.point_type === 'PROMO' && trans.transaction_type === 'add') ? handleDecimalAfter(trans.winning) : ''}</td>
                                                                    <td>{(trans.point_type === 'JOIN' && trans.transaction_type === 'deduct') || (trans.point_type === 'BONUS' && trans.transaction_type === 'deduct') || (trans.point_type === 'REFUND' && trans.transaction_type === 'deduct') || (trans.point_type === 'REVERSE' && trans.transaction_type === 'deduct') ? handleDecimalAfter(trans.bonus) : ''}</td>
                                                                    <td>{(trans.point_type === 'JOIN' && trans.transaction_type === 'deduct') || (trans.point_type === 'REFUND' && trans.transaction_type === 'deduct') || (trans.point_type === 'REVERSE' && trans.transaction_type === 'deduct') ? handleDecimalAfter(trans.unutilized) : ''}</td>
                                                                    <td>{(trans.point_type === 'JOIN' && trans.transaction_type === 'deduct') || (trans.point_type === 'WITHDRAW' && trans.transaction_type === 'deduct') || (trans.point_type === 'WIN' && trans.point_type === 'deduct') || (trans.point_type === 'REFUND' && trans.transaction_type === 'deduct') || (trans.point_type === 'REVERSE' && trans.transaction_type === 'deduct') ? handleDecimalAfter(trans.winning) : ''}</td>
                                                                    <td>{handleDecimalAfter(trans.new_balance?.bonus)}</td>
                                                                    <td>{handleDecimalAfter(trans.new_balance?.unutilized)}</td>
                                                                    <td>{handleDecimalAfter(trans.new_balance?.winning)}</td>
                                                                    <td>{handleDecimalAfter(trans?.available_balance)}</td>
                                                                    <td>{handleDecimalAfter(trans.new_balance?.net_winning)}</td>
                                                                    <td>{handleDecimalAfter(trans?.amount)}</td>
                                                                    <td>{trans?.point_type}</td>
                                                                    <td>{trans?.transaction_type}</td>
                                                                    <td>{handleDecimalAfter(trans?.gst_amount)}</td>
                                                                    <td>{trans?.super_coins}</td>
                                                                    <td>{trans.point_type === 'JOIN' && trans.transaction_type === 'deduct' ? trans?.amount : ''}</td>
                                                                    <td>{trans.description}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
