import { useEffect, useState } from 'react';
import { AccountManagerAPI } from '../api/AccountManagerAPI';

export default function ViewTDS() {
    const [data, setData] = useState('');
    const [mName, setName] = useState('');
    const [eDate, setDate] = useState('');
    const [sDate, setSdate] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        getDailyReport();
    }, []);

    const getDailyReport = () => {
        // console.log('hi')
        AccountManagerAPI.GetTDSReport(mName, sDate, eDate, email, mobile).then((data) => {
            setData(data.data);
            //   console.log(data.data)
        });
    };

    const nameChange = (e) => {
        setName(e.target.value);
    };

    const eDateChange = (e) => {
        setDate(e.target.value);
    };
    const sDateChange = (e) => {
        setSdate(e.target.value);
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
    };

    const mobileChange = (e) => {
        setMobile(e.target.value);
    };
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Match Name :</label>
                                            <input
                                                onChange={nameChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Youtuber Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='exampleInputdate'>Start Date :</label>
                                            <input
                                                onChange={sDateChange}
                                                type='date'
                                                className='form-control'
                                                id='exampleInputdate'
                                                defaultValue='2019-12-18'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='exampleInputdate'>End Date :</label>
                                            <input
                                                onChange={eDateChange}
                                                type='date'
                                                className='form-control'
                                                id='exampleInputdate'
                                                defaultValue='2019-12-18'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Email :</label>
                                            <input
                                                onChange={emailChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Email id'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Mobile :</label>
                                            <input
                                                onChange={mobileChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Mobile no.'
                                            />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col mt-2'>
                                            <button
                                                onClick={() =>
                                                    getDailyReport(
                                                        mName,
                                                        sDate,
                                                        eDate,
                                                        email,
                                                        mobile
                                                    )
                                                }
                                                type='submit'
                                                className='btn btn-primary'
                                            >
                                                Submit
                                            </button>
                                            &nbsp;
                                            <button type='submit' className='btn bg-danger'>
                                                <i className='ri-creative-commons-sa-line'></i>Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className=''>
                                    <a
                                        className='button btn btn-danger button-icon float-right'
                                        href='#'
                                    >
                                        <i className='ml-2 ri-add-line'></i>Export
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Match</th>
                                                    <th>Match Key</th>
                                                    <th>Email</th>
                                                    <th>Name</th>
                                                    <th>PAN Number</th>
                                                    <th>Match Date</th>
                                                    <th>Deduct</th>
                                                    <th>Total TDS Amount</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.length > 0 &&
                                                    data.map((data, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{++i}</td>
                                                                <td>{data.match}</td>
                                                                <td>{data.match_key}</td>
                                                                <td>{data.email}</td>
                                                                <td>{data.name}</td>
                                                                <td>{data.pan_number}</td>
                                                                <td>{data.match_date}</td>
                                                                <td>{data.deduct}</td>
                                                                <td>{data.total_tds_amount}</td>
                                                                <td>{data.amount}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
