import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
export default function MenuMaster() {
    //primary_sms, secondary_sms
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Master Controller</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form method='post' name='smsFrm'>
                                        <div className='row mb-4'>
                                            <div className='col-md-12 mb-3'>Menu Master:</div>
                                            <div className='col-md-3'>
                                                <select className='form-control mb-3'>
                                                    <option value='Account Manager'>
                                                        Account Manager
                                                    </option>
                                                    <option value='Coupon Manager'>
                                                        Coupon Manager
                                                    </option>
                                                    <option value='Verification Manager'>
                                                        Verification Manager
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='col-md-12 mb-3'>Sub Menu:</div>
                                            <div className='col-md-3'>
                                                <select className='form-control mb-3'>
                                                    <option value='Earning'>Earning</option>
                                                    <option value='View Coupon'>View Coupon</option>
                                                    <option value='View All Pan'>
                                                        View All Pan
                                                    </option>
                                                </select>
                                            </div>
                                            <div className='col-md-12 mb-3'>Role:</div>
                                            <div className='col-md-3'>
                                                <select className='form-control mb-3'>
                                                    <option value='Admin'>Admin</option>
                                                    <option value='Node js'>Node js</option>
                                                    <option value='React js'>React js</option>
                                                </select>
                                            </div>

                                            <div className='col-md-12'>
                                                <Link to='/master_table'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-skyblue btn-sm'
                                                        data-toggle='modal'
                                                        data-target='#exampleModalScrollable'
                                                    >
                                                        <i className='la la-feather-alt'></i>Save
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
