import './ViewAllUsers.css';
import { useState } from 'react';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import { fundManager } from '../api/FundsApi';
import { Link } from 'react-router-dom';

export default function FirstTimeDepositReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [totalFirstDepositAmount, setTotalFirstDepositAmount] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const getAllDepositsData = () => {
        if (startDate === '' || endDate === '') {
            Notiflix.Notify.failure('Please Fill Both The Date');
        } else {
            setIsLoading(true);
            fundManager.FirstTimeDepositAPI(startDate, endDate).then((res) => {
                if (res.status_code) {
                    setIsLoading(false);
                    setTotalFirstDepositAmount(res);
                } else {
                    Notiflix.Notify.failure(res.message);
                    setIsLoading(false);
                }
            });
        }
    };

    const handleReset = () => {
        console.log('Resetting state...');
        setStartDate('');
        setEndDate('');
        setIsLoading(false);
        setTotalFirstDepositAmount({});
        console.log('State reset:', startDate, endDate, isLoading, totalFirstDepositAmount);
    };



    return (
        <div className="wrapper">
            <div className="content-page rtl-page">
                <div className="container-fluid">
                    <div>
                        <div className="div-proper-deposit">
                            <h1 className="coupon_h1-h1-h1">First Time Deposit Report</h1>
                        </div>
                        <div className="div-main-for-deposit-side-2">
                            <div className="div-main-for-deposit-side">
                                <div className="">
                                    <input
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        type="date"
                                        className="button-report-referrals"
                                    />
                                    <input
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        type="date"
                                        className="button-report-referrals"
                                    />
                                </div>
                                <button onClick={getAllDepositsData} className="button-report-referral">
                                    Search
                                </button>
                                <button onClick={handleReset} className="button-report-referral-2">
                                    Reset
                                </button>
                            </div>
                            <div>
                                <Link to="/deposit-report" className="button-report-referral-2">
                                    Deposit Report
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body ">
                                        <div className="div-liread-get">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="display-sections">
                                                                    <div className="display-first-section">
                                                                        <h1 className="display-sections-h1">
                                                                            Total User with first time deposit:
                                                                        </h1>
                                                                        <h2 className="display-sections-h2">
                                                                            {isLoading ? (
                                                                                <div className="px-2 h-60 d-flex align-items-center justify-content-center bg-gradient">
                                                                                    <RotatingLines
                                                                                        marginTop="160"
                                                                                        marginBottom="160"
                                                                                        strokeColor="#000000"
                                                                                        strokeWidth="3"
                                                                                        animationDuration="0.75"
                                                                                        width="35"
                                                                                        visible={true}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                totalFirstDepositAmount.totalUser || 'Please Search'
                                                                            )}
                                                                        </h2>
                                                                    </div>
                                                                    <div className="display-second-section">
                                                                        <h1 className="display-sections-h1">
                                                                            Total Amount with first time deposit:
                                                                        </h1>
                                                                        <h2 className="display-sections-h2">
                                                                            {isLoading ? (
                                                                                <div className="px-2 h-60 d-flex align-items-center justify-content-center bg-gradient">
                                                                                    <RotatingLines
                                                                                        marginTop="160"
                                                                                        marginBottom="160"
                                                                                        strokeColor="#000000"
                                                                                        strokeWidth="3"
                                                                                        animationDuration="0.75"
                                                                                        width="35"
                                                                                        visible={true}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                totalFirstDepositAmount.totalAmount
                                                                                    ? `₹ ${totalFirstDepositAmount.totalAmount}`
                                                                                    : 'Please Search'
                                                                            )}
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
