import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { AddReferralAPI } from '../api/referralApi';

export default function ViewReferrals() {
    const [referrals, setRefarrals] = useState([]);
    const [_id, setId] = useState('');
    const [welcome_bonus, setWelcomeBonus] = useState('');
    const [referral_bonus_used_user, setUsedBonus] = useState('');
    const [refer_user_bonus, setUserBonus] = useState('');
    const [referral_max_amount, setMaxAmount] = useState('');
    const [Bonus, setBonus] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
        getReferral();
    }, []);

    const getReferral = () => {
        AddReferralAPI.GetReferralInfo().then((data) => {
            // console.log(data.message);
            // console.log(data.status_code)
            if (data.status_code === true) {
                setRefarrals(data.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
            // if(data.status_code === true){
            //     swal(data.message);
            // }
        });
    };

    const editHandler = (e) => {
        e.preventDefault();
        let data = {
            _id: _id,
            welcome_bonus: welcome_bonus,
            referral_bonus_used_user: referral_bonus_used_user,
            refer_user_bonus: refer_user_bonus,
            referral_max_amount: referral_max_amount,
            Bonus: Bonus,
        };
        AddReferralAPI.EditReferral(data).then((data) => {
            // console.log(object)(data)
            Notiflix.Notify.success('Coupon Edit successfully!!!!');
            // console.log(data)
            getReferral('');
        });
    };

    const deleteHandler = (_id) => {
        // console.log(_id);
        AddReferralAPI.DeleteReferral(_id).then((data) => {
            // console.log(data)
            Notiflix.Notify.success('Coupon Deleted!!');
            getReferral();
        });
    };
    const bonusChange = (e) => {
        setWelcomeBonus(e.target.value);
        // console.log(e.target.value);
    };

    const referBonus = (e) => {
        setUsedBonus(e.target.value);
    };

    const usedRefer = (e) => {
        setUserBonus(e.target.value);
    };

    const maxRefer = (e) => {
        setMaxAmount(e.target.value);
    };
    const bonusPer = (e) => {
        setBonus(e.target.value);
    };

    const EditView = (
        _id,
        welcome_bonus,
        referral_bonus_used_user,
        refer_user_bonus,
        Bonus,
        referral_max_amount
    ) => {
        setId(_id);
        setWelcomeBonus(welcome_bonus);
        setUsedBonus(referral_bonus_used_user);
        setUserBonus(refer_user_bonus);
        setBonus(Bonus);
        setMaxAmount(referral_max_amount);
    };
    //   "_id": "6399a06c43d2966a1d6f1d5b",
    // "welcome_bonus": "700",
    // "referral_bonus_used_user": "300",
    // "refer_user_bonus": "100",
    // "Bonus": "50",
    // "referral_max_amount": ""
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>View Referrals</h4>
                                        </div>
                                    </div>
                                    {/* Modal Starts From Here */}

                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Welcome Bonus</th>
                                                    <th>Bonus Used User</th>
                                                    <th>Refer User Bonus</th>
                                                    <th>Bonus( % )</th>
                                                    <th>Referral Max Amount</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {referrals &&
                                                    referrals.length > 0 &&
                                                    referrals.map((data, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{++i}</td>
                                                                <td>{data.welcome_bonus}</td>
                                                                <td>
                                                                    {data.referral_bonus_used_user}
                                                                </td>
                                                                <td>{data.refer_user_bonus}</td>
                                                                <td>{data.Bonus}</td>
                                                                <td>{data.referral_max_amount}</td>
                                                                <td>Active</td>
                                                                <td>
                                                                    <button
                                                                        onClick={() =>
                                                                            EditView(
                                                                                data._id,
                                                                                data.welcome_bonus,
                                                                                data.referral_bonus_used_user,
                                                                                data.refer_user_bonus,
                                                                                data.Bonus,
                                                                                data.referral_max_amount
                                                                            )
                                                                        }
                                                                        data-toggle='modal'
                                                                        data-target='#exampleModalScrollable'
                                                                        className='btn btn-success btn-sm'
                                                                    >
                                                                        <i className='ri-edit-line'></i>
                                                                        Edit
                                                                    </button>
                                                                    &nbsp;
                                                                    <button
                                                                        onClick={(e) => {
                                                                            if (
                                                                                window.confirm(
                                                                                    'Are you sure to delete?'
                                                                                )
                                                                                    ? deleteHandler(
                                                                                        data._id
                                                                                    )
                                                                                    : e.preventDefault()
                                                                            );
                                                                        }}
                                                                        type='submit'
                                                                        className='btn btn-primary btn-sm'
                                                                    >
                                                                        <i className='ri-delete-bin-line'></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='modal fade'
                            id='exampleModalScrollable'
                            tabIndex='-1'
                            role='dialog'
                            aria-labelledby='exampleModalScrollableTitle'
                            aria-hidden='true'
                        >
                            <div className='modal-dialog modal-dialog-scrollable' role='document'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h5
                                            className='modal-title'
                                            id='exampleModalScrollableTitle'
                                        >
                                            Edit Referrals
                                        </h5>
                                        <button
                                            type='button'
                                            className='close'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            <span aria-hidden='true'>&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <form>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Welcome Bonus :</label>
                                                <input
                                                    value={_id}
                                                    type='hidden'
                                                    className='form-control'
                                                />
                                                <input
                                                    onWheelCapture={(e) => {
                                                        e.target.blur();
                                                    }}
                                                    min='0'
                                                    onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                    value={welcome_bonus}
                                                    onChange={bonusChange}
                                                    type='number'
                                                    className='form-control'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Bonus Used User :</label>
                                                <input
                                                    onWheelCapture={(e) => {
                                                        e.target.blur();
                                                    }}
                                                    min='0'
                                                    onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                    value={referral_bonus_used_user}
                                                    onChange={referBonus}
                                                    type='number'
                                                    className='form-control'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Refer User Bonus :</label>
                                                <input
                                                    onWheelCapture={(e) => {
                                                        e.target.blur();
                                                    }}
                                                    min='0'
                                                    onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                    onChange={usedRefer}
                                                    value={refer_user_bonus}
                                                    type='number'
                                                    className='form-control'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Bonus( % ) :</label>
                                                <input
                                                    onWheelCapture={(e) => {
                                                        e.target.blur();
                                                    }}
                                                    min='0'
                                                    onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                    onChange={bonusPer}
                                                    value={Bonus}
                                                    type='number'
                                                    className='form-control'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Referral Max Amount :</label>
                                                <input
                                                    onWheelCapture={(e) => {
                                                        e.target.blur();
                                                    }}
                                                    min='0'
                                                    onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                    onChange={maxRefer}
                                                    value={referral_max_amount}
                                                    type='number'
                                                    className='form-control'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary mt-2'
                                                    data-dismiss='modal'
                                                    onClick={editHandler}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-secondary'
                                            data-dismiss='modal'
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
