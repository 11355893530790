import Notiflix from 'notiflix';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { AddReferralAPI } from '../api/referralApi';

export default function ReferralsPage() {
    const firstRef = useRef(null);
    const secondRef = useRef(null);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const handleChange = (event) => {
        event.preventDefault();
        // welcome_bonus,referral_bonus_used_user,refer_user_bonus,Bonus,referral_max_amount
        var welcome_bonus = event.target[0].value;
        var referral_bonus_used_user = event.target[1].value;
        var refer_user_bonus = event.target[2].value;
        var Bonus = event.target[3].value;
        var referral_max_amount = event.target[4].value;
        console.log(referral_max_amount);
        AddReferralAPI.AddReferral(
            welcome_bonus,
            referral_bonus_used_user,
            refer_user_bonus,
            Bonus,
            referral_max_amount
        ).then((response) => {
            console.log(response);
            if (response.status_code === true) {
                Notiflix.Notify.success(response.message);
            }
            //form reset
            // Reset();
            event.target.reset();
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'></div>
                                        <div className='col'>
                                            <Link
                                                to={'/referrals_users'}
                                                className='mt-2 btn btn-skyblue float-right'
                                            >
                                                {' '}
                                                <i className='ri-eye-2-line'></i> Referrals Users
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>
                                            Add Referrals Amount for users
                                        </h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form onSubmit={handleChange}>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Welcome Bonus{' '}
                                                <i
                                                    className='ri-information-line'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='User getting bonus after using refers code or without refers code.'
                                                ></i>
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                min='0'
                                                onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                ref={firstRef}
                                                type='number'
                                                className='form-control'
                                                placeholder='Welcome bonus'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Referral bonus Used user{' '}
                                                <i
                                                    className='ri-information-line'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Referred user will get this bonus after using the refer code'
                                                ></i>
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                min='0'
                                                onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                ref={secondRef}
                                                type='number'
                                                className='form-control'
                                                placeholder='Referral bunus'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Refer User Bonus{' '}
                                                <i
                                                    className='ri-information-line'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='The bonus user will get after the refer code is used by another user.'
                                                ></i>
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                min='0'
                                                onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                type='number'
                                                className='form-control'
                                                placeholder='Refer bonus for user'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Bonus (%){' '}
                                                <i
                                                    className='ri-information-line'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Bonus (%) received by user after the referred user join'
                                                ></i>
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                min='0'
                                                onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                type='number'
                                                className='form-control'
                                                placeholder='Percentage of bonus'
                                                defaultValue=''
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>
                                                Referral Max Amount{' '}
                                                <i
                                                    className='ri-information-line'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Maximum bonus amount in one time (Pool join) receive by user '
                                                ></i>
                                            </label>
                                            <input
                                                onWheelCapture={(e) => {
                                                    e.target.blur();
                                                }}
                                                min='0'
                                                onKeyPress='return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))'
                                                type='number'
                                                className='form-control'
                                                placeholder='Maximum bonus amount'
                                                defaultValue=''
                                            />
                                        </div>

                                        <button type='submit' className='btn btn-primary'>
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
