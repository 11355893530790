import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { SeriesAPI } from '../api/seriesApi';

export default function MatchDetailsPage() {
    const [detail, setDetail] = useState([]);
    const location = useLocation();
    // console.log(location)
    const { cid } = location.state;
    // const {apiMatchId} = location.state ;
    // const [matchId, setMatchId] = useState(apiMatchId);
    // console.log(cid);
    const [label, setLabel] = useState(cid);
    const [raw, setRaw] = useState([]);
    console.log(cid);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        fetchFullDetail();
    }, []);

    let data = {
        cid: label,
    };
    const fetchFullDetail = () => {
        // console.log(data)
        SeriesAPI.GetMatchDetail(data).then((data) => {
            // console.log(data.Data);
            setRaw(data.Data);
            console.log(data.Data);
        });
    };

    const AbandanMatch = (myData) => {
        const json = {
            matchId: myData.matchId,
            apiMatchId: myData.apiMatchId,
        };

        SeriesAPI.ResultAbandoned(json).then((data) => {
            swal(data.message);
        });
    };

    const launchWinner = (apiid, matchid) => {
        // console.log(apiid,matchid)

        SeriesAPI.MatchResultDone(matchid, apiid).then((data) => {
            swal(data.Message);
        });

        fetchFullDetail();
    };

    const launchPredictionWinner = (apiid, matchid) => {
        // console.log(apiid,matchid)

        SeriesAPI.PredictionResultDone(matchid, apiid).then((data) => {
            swal(data.Message);
        });
        fetchFullDetail();
    };
    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Series Listing - All Series</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Match Name</th>
                                                        <th>Match Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {raw &&
                                                        raw.length > 0 &&
                                                        raw.map((data, i) => {
                                                            // {console.log(data.matchId,data.apiMatchId)}
                                                            return (
                                                                <tr>
                                                                    <td>{++i}</td>
                                                                    <td>
                                                                        <Link
                                                                            to={
                                                                                '/match_player_points'
                                                                            }
                                                                            state={data}
                                                                        >
                                                                            {data.matchName}
                                                                        </Link>
                                                                        <br />
                                                                        {data.StartDateIST}
                                                                        <br />
                                                                        Match Status :{' '}
                                                                        {data.matchStatusStr}
                                                                    </td>
                                                                    <td>
                                                                        {data.matchStatus === 4 &&
                                                                            data.PredictionResult ===
                                                                                false &&
                                                                            data.isResult ===
                                                                                false && (
                                                                            <div
                                                                                type='button'
                                                                                className='mt-2 btn btn-danger btn-sm mr-2'
                                                                            >
                                                                                    This Match is
                                                                                    Cancelled
                                                                            </div>
                                                                        )}
                                                                        {data.matchStatus !== 4 &&
                                                                            data.isResult ===
                                                                                false && (
                                                                            <button
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            'Are you sure to Declare Winner?'
                                                                                        )
                                                                                            ? launchWinner(
                                                                                                data.apiMatchId,
                                                                                                data.matchId
                                                                                            )
                                                                                            : e.preventDefault()
                                                                                    );
                                                                                }}
                                                                                type='button'
                                                                                className='mt-2 btn btn-success btn-sm'
                                                                            >
                                                                                <i className='ri-eye-2-line'></i>{' '}
                                                                                    Declare Winner
                                                                            </button>
                                                                        )}
                                                                        {data.matchStatus !== 4 &&
                                                                            data.isResult && (
                                                                            <div
                                                                                type='button'
                                                                                className='mt-2 btn danger btn-sm mr-2 s-90'
                                                                            >
                                                                                <h4>
                                                                                        Winner
                                                                                        Declared
                                                                                </h4>
                                                                            </div>
                                                                        )}
                                                                        {data.matchStatus !== 4 &&
                                                                            data.isResult ===
                                                                                false &&
                                                                            data.PredictionResult && (
                                                                            <div
                                                                                type='button'
                                                                                className='mt-2 btn btn-danger btn-sm mr-2'
                                                                            >
                                                                                    This Match is
                                                                                    Cancelled
                                                                            </div>
                                                                        )}
                                                                        {/* {data.matchStatus===2 && <button type="button" className="mt-2 btn btn-danger btn-sm mr-2" onClick={(e) => {if(window.confirm('Are you sure to Abandon the Match?')?AbandanMatch(data):e.preventDefault());}}>Cancel Match</button>}  */}
                                                                        {/* <Link to={"/cancelled_matches"}>IsCanceled</Link><br /> */}
                                                                        {/* <button>onClick={(e) => {if(window.confirm('Are you sure to launch match?')?'launchMatch(match.apiMatchId, match.matchId)':e.preventDefault());}}</button> */}
                                                                        {/* {data.matchStatusStr=='Completed' && <button onClick={(e) => {if(window.confirm('Are you sure to Declare Winner?')?launchWinner(data.apiMatchId, data.matchId):e.preventDefault());}} type="button" className="mt-2 btn btn-success btn-sm"><i className="ri-eye-2-line"></i> Declare Winner</button>} */}
                                                                        &nbsp;
                                                                        {data.matchStatus !== 4 &&
                                                                            data.matchStatusStr ==
                                                                                'Completed' && (
                                                                            <button
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            'Are you sure to Declare Prediction Winner?'
                                                                                        )
                                                                                            ? launchPredictionWinner(
                                                                                                data.apiMatchId,
                                                                                                data.matchId
                                                                                            )
                                                                                            : e.preventDefault()
                                                                                    );
                                                                                }}
                                                                                type='button'
                                                                                className='mt-2 btn btn-success btn-sm'
                                                                            >
                                                                                <i className='ri-eye-2-line'></i>
                                                                                    Declare
                                                                                    Prediction
                                                                                    Winner
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}

                                                    {/* <tr>
                                    <td>1</td>
                                    <td>
                                        <Link to={"/match_detail"}>India Tour of Zimbabwe</Link>
                                        <br />
                                        Wednesday, 24-Aug-2022 5:30:00 pm<br />
                                        Match Status : Winnerdeclared
                                    </td>
                                    <td>
                                       Winner Declared <br />
                                        <button type="button" className="mt-2 btn btn-success"><i className="ri-eye-2-line"></i> View Winners</button>
                                    </td>
                                </tr> */}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Match Name</th>
                                                        <th>Match Status</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
