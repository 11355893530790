import { useEffect, useState } from 'react';
import './ViewAllUsers.css';
import { AccountManagerAPI } from '../api/AccountManagerAPI';
import { CSVLink } from 'react-csv';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import Pagination from '../Component/Pagination';

export default function UnutilizedReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(true);
    const [clicked, setClicked] = useState(false);
    const [data, setData] = useState([]);
    const [days, setDays] = useState('');
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    // pagination
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        // unutilizedReport();
        // allUnutilized();
    }, []);

    useEffect(() => {
        unutilizedReport();
    }, [pageNumber]);

    const unutilizedReport = () => {
        // setPageNumber(1);

        if (days || !days == '') {
            setIsButton(true);
            setClicked(false);
            setIsLoading(true);

            AccountManagerAPI.unutilizedReport(days, pageNumber).then((res) => {
                if (res.status_code) {
                    setData(res.data);
                    console.log(res);
                    setTotalEntries(res.total_length);
                    setTotalPages(res.totalPages);
                    // Notiflix.Notify.success('Yo');
                    setIsLoading(false);
                    setClicked(true);
                } else {
                    Notiflix.Notify.failure(res.message);
                    setIsLoading(false);
                }
            });
        }
    };

    const headers = [
        { label: 'User_ID', key: 'user_id' },
        { label: 'Team_Name', key: 'team_name' },
        { label: 'Email', key: 'email' },
        { label: 'Mobile', key: 'phone' },
        { label: 'Total_Bonus', key: 'bonus' },
        { label: 'Super_Coin_Balance', key: 'royalty_point' },
        { label: 'Total_Unutilized', key: 'unutilized' },
        { label: 'Total_Winning', key: 'winning' },
        { label: 'Total_Balance', key: 'total_balance' },
    ];

    const allUnutilized = () => {
        setIsButton(false);

        AccountManagerAPI.allUnutilizedReport(days, 1, totalEntries).then((res) => {
            if (res.status_code) {
                setFilteredData(res.data);
                console.log(res);
                // setTotalEntries(res.total_length);
                // setTotalPages(res.totalPages);
                // Notiflix.Notify.success('Yo');
                setIsLoading(false);
            } else {
                Notiflix.Notify.failure('Something Went Wrong');
            }
        });
    };

    const setterFunction = (setter) => (e) => {
        const { value } = e.target;
        setter(value);
    };

    const handleReset = () => {
        setDays('');
        unutilizedReport();
        setPageNumber((prev) => 1);
        setIsLoading(false);
        setIsButton(true);
        setClicked(false);
    };

    const changePage = (data) => {
        setPageNumber(data);
    };

    return (
        <div className='dashboard_home'>
            <div className=''>
                <div className='content-page rtl-page'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 mb-3'>
                                <div className='d-flex align-items-center justify-content-between welcome-content'>
                                    <div className='navbar-breadcrumb dashboard_div'>
                                        <h4 className='mb-0 dashboard_welcome'>
                                            Unutilized Report
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            {/* END */}
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    {/* <form> */}
                                    <div className='row'>
                                        <div className='col-sm-12 col-lg-12'>
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='d-flex flex-wrap'>
                                                        <div className='col'>
                                                            <label htmlFor='name'>
                                                                Search for Days
                                                            </label>
                                                            <div className='UT_div'>
                                                                <input
                                                                    onChange={setterFunction(
                                                                        setDays
                                                                    )}
                                                                    type='number'
                                                                    value={days}
                                                                    className='form-control UT_input'
                                                                    placeholder='Search Days'
                                                                />
                                                                {clicked && isButton && (
                                                                    <button
                                                                        className='btn btn-primary'
                                                                        onClick={allUnutilized}
                                                                    >
                                                                        Click to Download this Data
                                                                    </button>
                                                                )}

                                                                {!isButton && clicked && (
                                                                    <CSVLink
                                                                        filename='Unutilized Report'
                                                                        data={filteredData}
                                                                        headers={headers}
                                                                        className='UT_button'
                                                                    >
                                                                        <button className='UT_button'>
                                                                            Download Filtered All
                                                                            Data
                                                                        </button>
                                                                    </CSVLink>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className=''>
                                                            <div className='col'>
                                                                <br />
                                                                <label htmlFor='name'></label>
                                                                <button
                                                                    value='search'
                                                                    onClick={unutilizedReport}
                                                                    type='button'
                                                                    className='btn btn-primary'
                                                                >
                                                                    Search
                                                                </button>
                                                                &nbsp;
                                                                <input
                                                                    type='reset'
                                                                    onClick={() => handleReset()}
                                                                    className='btn btn-danger'
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>

                            <div className='col-lg-11'>
                                <div>
                                    <div className='row'>
                                        {/* <div className='wallet_div'>
                                            <CSVLink
                                                filename='Wallet Report'
                                                // data={}
                                                // headers={}
                                                className='wallet_button'>
                                                <button className='wallet_button'>Download All Data</button>
                                            </CSVLink>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/*  */}

                            <div className='card-body'>
                                {isLoading && (
                                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                        <div>
                                            <RotatingLines
                                                marginTop='70'
                                                strokeColor='#c1030d'
                                                strokeWidth='5'
                                                animationDuration='0.75'
                                                width='50'
                                                visible={true}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!isLoading && (
                                    <div className='table-responsive'>
                                        <span>
                                            <table className='table  table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>User Id</th>
                                                        <th>Team Name</th>
                                                        <th>Email</th>
                                                        <th>Mobile</th>
                                                        <th>Total Bonus</th>
                                                        <th>Super Coins Balance</th>
                                                        <th>Total Unutilized</th>
                                                        <th>Total Winning</th>
                                                        <th>Total Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data &&
                                                        data.length > 0 &&
                                                        data.map((UTData, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{++i}</td>
                                                                    <td>{UTData.user_id}</td>
                                                                    <td>{UTData.team_name}</td>
                                                                    <td>{UTData.email}</td>
                                                                    <td>{UTData?.mobile}</td>
                                                                    <td>
                                                                        {Math.ceil(UTData.bonus)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.ceil(
                                                                            UTData.royalty_point
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {Math.ceil(
                                                                            UTData.unutilized
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {Math.ceil(UTData.winning)}
                                                                    </td>
                                                                    <td>
                                                                        {Math.ceil(
                                                                            UTData.total_balance
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Pagination
                        totalEntries={totalEntries}
                        totalPages={totalPages}
                        changePage={changePage}
                        pageNumber={pageNumber}
                    />
                </div>
            </div>
        </div>
    );
}
