import './ViewAllUsers.css';
import { useState, useEffect } from 'react';
import Notiflix from 'notiflix';
import { RotatingLines } from 'react-loader-spinner';
import { TeamAPI } from '../api/teamApi';
import { Checkbox } from 'antd';



export default function DeleteExtraPlayer() {

    const [isSecondLoading, setIsSecondLoading] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [deleteTeamId, setDeleteTeamId] = useState('');
    const [deleteMatchId, setDeleteMatchId] = useState('');
    const [errorShow, setErrorShow] = useState(false);

    // Delete Function States
    const [listOfExtraPlayersTeam, setListOfExtraPlayersTeam] = useState([]);
    const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);

    useEffect(() => {
        const finalPlayerInTeam = listOfExtraPlayersTeam.length - selectedPlayerIds.length;
        setTotalPlayersInTheTeam(finalPlayerInTeam);
    }, [listOfExtraPlayersTeam, selectedPlayerIds]);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const [totalPlayersInTheTeam, setTotalPlayersInTheTeam] = useState(() => {
        return listOfExtraPlayersTeam.length;
    });

    const handleCheckboxChange = (playerId) => {
        setSelectedPlayerIds(prevSelectedPlayerIds => {
            const updatedSelectedPlayerIds = prevSelectedPlayerIds.includes(playerId)
                ? prevSelectedPlayerIds.filter(_id => _id !== playerId)
                : [...prevSelectedPlayerIds, playerId];
            return updatedSelectedPlayerIds;
        });
    };

    // GET TEAMS API
    const handleGetTeamsWithExtraPlayers = async () => {
        if (!deleteUserId || !deleteMatchId || !deleteTeamId) {
            setErrorShow(true);
            return;
        }
        setErrorShow(false);
        setIsSecondLoading(true);
        try {
            const dataArr = {
                user_id: deleteUserId,
                teamId: parseInt(deleteTeamId),
                MatchId: deleteMatchId
            };
            const response = await TeamAPI.DeletePlayerFromTeam(dataArr);
            if (response.status_code) {
                setIsSecondLoading(false);
                setListOfExtraPlayersTeam(response.getTeamData);
                console.log(listOfExtraPlayersTeam);
                checkDuplicates(response.getTeamData);
            } else {
                Notiflix.Notify.failure(response.message);
                setIsSecondLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorShow(false);
            setIsSecondLoading(false);
        }
    };

    const [duplicatePlayer, setDuplicatePlayer] = useState([]);
    const checkDuplicates = (newPlayerArray) => {
        const duplicates = newPlayerArray.reduce((acc, player, index, array) => {
            if (array.findIndex(item => item.PlayerName === player.PlayerName) !== index && !acc.includes(player.PlayerName)) {
                acc.push(player.PlayerName);
            }
            return acc;
        }, []);
        setDuplicatePlayer(duplicates);
        console.log(duplicatePlayer);
    };

    const handleDeleteCompleteTeam = async () => {
        const data = {
            user_id: deleteUserId,
            teamId: parseInt(deleteTeamId),
            MatchId: deleteMatchId,
        };

        const res = await TeamAPI.DeletePlayerCompletely(data);
        if (res.status_code) {
            Notiflix.Notify.success('User Team Deleted Successfully');
            handleSecondReset();
        } else {
            Notiflix.Notify.failure('Something Went Wrong');
            setIsSecondLoading(false);
        }
    };

    const handleMultipleDelete = async () => {
        const confirmed = window.confirm('Are you sure you want to Delete');
        if (confirmed) {
            // captain vicecaptain kaa filter
            const selectedPlayerIdsSet = new Set(selectedPlayerIds);
            const nonDeletePlayers = listOfExtraPlayersTeam.filter(player => !selectedPlayerIdsSet.has(player._id));
            const numCaptains = nonDeletePlayers.filter(player => player.IsCaptain).length;
            const numViceCaptains = nonDeletePlayers.filter(player => player.IsViceCaptain).length;

            // role kaa filter
            const rolesCount = {
                'wk': 0,
                'bat': 0,
                'all': 0,
                'bowl': 0
            };

            nonDeletePlayers.forEach(player => {
                rolesCount[player.PlayingRole]++;
            });

            const rolesArray = Object.values(rolesCount);
            const rolesSum = rolesArray.reduce((total, count) => total + count, 0);

            // Player check duplicate kaa filter
            const uniquePlayerNamesSet = new Set();
            const duplicatePlayerNames = nonDeletePlayers.filter(player => {
                if (uniquePlayerNamesSet.has(player.PlayerName)) {
                    return true;
                }
                uniquePlayerNamesSet.add(player.PlayerName);
                return false;
            });

            if (rolesSum !== 11) {
                return Notiflix.Notify.failure('There must be 11 players in the team.');
            } else if (numCaptains !== 1 || numViceCaptains !== 1) {
                return Notiflix.Notify.failure('There must be exactly one captain and one vice captain in the team.');
            } else if (rolesArray.includes(0)) {
                return Notiflix.Notify.failure('There must be one player for each role (Wicket Keeper, Bowler, Batsman, and All Rounder) in the team.');
            } else if (duplicatePlayerNames.length > 0) {
                return Notiflix.Notify.failure('There are players with duplicate names in the team. Each player must have a unique name.');
            } else {

                const data = {
                    user_id: deleteUserId,
                    teamId: parseInt(deleteTeamId),
                    MatchId: deleteMatchId,
                    _id: selectedPlayerIds,
                };

                try {
                    const response = await TeamAPI.DeletePlayer(data);
                    if (response.status_code === true) {
                        Notiflix.Notify.success(response.message);
                        // console.log(response);
                        handleSecondReset();
                        handleGetTeamsWithExtraPlayers();
                    } else {
                        Notiflix.Notify.failure(response.message);
                        setIsSecondLoading(false);
                    }
                } catch (error) {
                    console.error(error);
                    Notiflix.Notify.failure('Error occurred while deleting players.');
                }
            }
        } else {
            Notiflix.Notify.warning('No Player Updated');
        }
    };

    const handleSecondReset = () => {
        setDeleteUserId('');
        setDeleteMatchId('');
        setDeleteTeamId('');
        setErrorShow(false);
        setIsSecondLoading(false);
        setListOfExtraPlayersTeam([]);
    };

    const handleDuplicateStyles = (playerName, id) => {
        const uniquePlayersSet = new Set();
        const isDuplicate = listOfExtraPlayersTeam.some(player => {
            if (player.PlayerName === playerName && player._id !== id) {
                uniquePlayersSet.add(player._id);
                return true;
            }
            return false;
        });

        return (
            <div className='playerName-div'>
                <h5 className={`playerName-duplicate ${isDuplicate ? 'duplicate-color' : ''}`}>
                    {playerName}
                </h5>
                {isDuplicate && <h6 className='playerName-duplicate-yes'>Duplicate</h6>}
            </div>
        );
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                {/* Total Users In The Team */}

                {
                    listOfExtraPlayersTeam.length > 0 &&
                    <div className='sticky-div'>
                        <h1 className='sticky-div-h1'>Total Players In The Team : </h1>
                        <h1 className='sticky-div-h1-h1'>{totalPlayersInTheTeam}</h1>
                    </div>
                }

                {/*  */}
                <div className='container-fluid'>
                    <div className='offers_couponDiv'>
                        <h1 className='coupon_h1'>Delete Extra Players From Team</h1>
                    </div>
                    {/* New Section */}
                    <div className='row'>
                        <div className='indi-ref-report-div'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    {errorShow && (
                                        <h1 className='provide-details-extra'>
                                            Please Provide All Details
                                        </h1>
                                    )}
                                </div>
                                <div className='offers_div'>
                                    <div >
                                        <label className='offer_checkbox'>User Id</label>
                                        <input type='number' value={deleteUserId} required onChange={handleAllChange(setDeleteUserId)} className='offers_input' placeholder='Enter User Id' />
                                    </div>
                                    <div>
                                        <label className='offer_checkbox'>Match Id</label>
                                        <input value={deleteMatchId} onChange={handleAllChange(setDeleteMatchId)} className='offers_input' placeholder='Enter Match Id' />
                                    </div>

                                    <div>
                                        <label className='offer_checkbox'>Team Id</label>
                                        <input value={deleteTeamId} onChange={handleAllChange(setDeleteTeamId)} className='offers_input' placeholder='Enter Team Id' />
                                    </div>

                                    <div className='dif-for-delete-player'>
                                        <div className='cases_button_div' >

                                            <button
                                                onClick={handleGetTeamsWithExtraPlayers}
                                                className='cases_verify_button_234'>
                                                Search
                                            </button>
                                            <button
                                                onClick={handleSecondReset}
                                                className='cases_verify_button_222'>
                                                Reset
                                            </button>
                                        </div>

                                        <div>
                                            {listOfExtraPlayersTeam.length > 0 ?
                                                <button onClick={handleDeleteCompleteTeam} className='cases_verify_button_22234'>
                                                    Delete This Team
                                                </button> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/*  */}
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        {isSecondLoading ? (
                                            <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='160'
                                                        marginBottom='160'
                                                        strokeColor='#000000'
                                                        strokeWidth='3'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {listOfExtraPlayersTeam?.length > 0 ? (
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div>
                                                                <div className='table-responsive'>
                                                                    <table className='table table-striped table-bordered'>
                                                                        <thead>
                                                                            <tr className='support_main_file_div'>
                                                                                <th>Select</th>
                                                                                <th>User ID</th>
                                                                                <th>Player Name</th>
                                                                                <th>Playing Role</th>
                                                                                <th>is Captain</th>
                                                                                <th>is Vice Captain</th>
                                                                                <th>Ratings</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {listOfExtraPlayersTeam.map((refer, i) => (
                                                                                <tr key={refer._id}>
                                                                                    <td>
                                                                                        <Checkbox
                                                                                            onChange={() => handleCheckboxChange(refer._id)}
                                                                                            checked={selectedPlayerIds.includes(refer._id)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>{refer.UserID}</td>
                                                                                    <td>{handleDuplicateStyles(refer.PlayerName, refer._id)}</td>
                                                                                    <td>{refer.PlayingRole}</td>
                                                                                    <td>{refer.IsCaptain === true ? '✔️' : ''}</td>
                                                                                    <td>{refer.IsViceCaptain === true ? '✔️' : ''}</td>
                                                                                    <td>{refer.CreditPoint}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                                {/* <div className='deleteDuplicateDivMain'> */}
                                                                {selectedPlayerIds.length > 0 && (
                                                                    <button
                                                                        className='cases_verify_button_2223434'
                                                                        onClick={handleMultipleDelete}>
                                                                        Delete Selected Players
                                                                    </button>
                                                                )}
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="ref-indi-h4">Search User.</div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
