import { useState } from 'react';
import { useEffect } from 'react';
import { UsersAPI } from '../api/userApi';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';

export default function ViewAllFeedbackPage() {
    const [feedback, setFeedback] = useState();
    const [user_id, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [team_name, setTeamName] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        feedbackDetail();
        handleReset();
    }, []);

    const feedbackDetail = () => {
        setIsLoading(true);
        UsersAPI.GetFeedback(user_id, email, phone, team_name, startDate, endDate).then((data) => {
            var feedData_i = JSON.parse(JSON.stringify(data));
            console.log(feedData_i.feedbackData);
            setFeedback(feedData_i.feedbackData);
            setIsLoading(false);
        });
    };
    // , team_name, startDate, endDate
    const filterFeedback = () => {
        setIsLoading(true);
        feedbackDetail();
    };

    const userChange = (e) => {
        setUserId(e.target.value);
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
    };

    const teamChange = (e) => {
        setTeamName(e.target.value);
    };

    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };

    // useEffect(() => {
    //   console.log('hello')
    //   filterFeedback()

    // }, [email,endDate,startDate,phone,team_name,user_id])

    const handleReset = () => {
        setEmail('');
        setEndDate('');
        setStartDate('');
        setPhone('');
        setTeamName('');
        setUserId('');
        feedbackDetail();
    };

    const handleDate = (e) => {
        if (!e) {
            return '';
        } else {
            const time = moment(e).format('hh:mm:s a');
            const date = moment(e).format('DD/MM/YYYY');
            return `${date} | ${time}`;
        }
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Team Name :</label>
                                            <input
                                                value={team_name}
                                                onChange={teamChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Team Name'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Email Id :</label>
                                            <input
                                                value={email}
                                                onChange={emailChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By Email Id'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>Mobile :</label>
                                            <input
                                                onKeyDown={(evt) =>
                                                    ['e', 'E', '+', '-'].includes(evt.key) &&
                                                    evt.preventDefault()
                                                }
                                                value={phone}
                                                onChange={phoneChange}
                                                type='number'
                                                min='0'
                                                className='form-control'
                                                placeholder='Search By Mobile No.'
                                            />
                                        </div>
                                        <div className='col'>
                                            <label htmlFor='name'>User Id :</label>
                                            <input
                                                value={user_id}
                                                onChange={userChange}
                                                type='text'
                                                className='form-control'
                                                placeholder='Search By User Id'
                                            />
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>From :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={startDate}
                                                    onChange={handleStartDate}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>To :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={endDate}
                                                    onChange={handleEndDate}
                                                />
                                            </div>
                                        </div>
                                        <div className='col' style={{ paddingTop: '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                onClick={(e) => filterFeedback(e)}
                                                type='submit'
                                                className='btn btn-primary'
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            <button
                                                type='reset'
                                                className='btn bg-danger'
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                    <RotatingLines
                                        marginTop='40'
                                        strokeColor='#c1030d'
                                        strokeWidth='5'
                                        animationDuration='0.75'
                                        width='50'
                                        visible={true}
                                    />
                                </div>
                            </div>
                        )}

                        {!isLoading && (
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <div className='header-title'>
                                            <h4 className='card-title'>View All Feedback</h4>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id='datatable'
                                                    className='table data-table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <td>S.No</td>
                                                            <th>User Id</th>
                                                            <th>Team Name</th>
                                                            <th>Email</th>
                                                            <th>Mobile No.</th>
                                                            <th>Date</th>
                                                            <th>Feedback</th>
                                                            <th>Rating</th>
                                                            <th>Feedback Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {feedback &&
                                                            feedback.length > 0 &&
                                                            feedback.map((feed, i) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{++i}</td>
                                                                            <td>{feed.user_id}</td>
                                                                            <td>
                                                                                {feed.team_name}
                                                                            </td>
                                                                            <td>{feed.email}</td>
                                                                            <td>{feed.phone}</td>
                                                                            <td>
                                                                                {handleDate(
                                                                                    feed.createdAt
                                                                                )}
                                                                            </td>
                                                                            <td>{feed.feedback}</td>
                                                                            <td>{feed.rating}</td>
                                                                            <td>
                                                                                {feed.feedbackValue}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
