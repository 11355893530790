import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createCoupon } from '../api/addCoupon';
import './ViewAllUsers.css';
import { RiCoupon4Line } from 'react-icons/ri';
import moment from 'moment';
import { Modal, Result } from 'antd';


export default function AddOfferPage() {

    const [couponTitle, setCouponTitle] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [description, setDescription] = useState('');
    const [minAmount, setMinAmount] = useState('');
    const [maxAmount, setMaxAmount] = useState('');
    const [uptoAmount, setUptoAmount] = useState('');
    const [couponType, setCouponType] = useState('');
    const [couponValue, setCouponValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [amountType, setAmountType] = useState('');
    const [maxLimitPerUser, setMaxLimitPerUser] = useState('');
    const [termsAndConditions, seTermsAndConditions] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [error, setError] = useState(false);
    const [couponCreated, setCouponCreated] = useState(false);
    const [duplicateCouponError, setDuplicateCouponError] = useState(false);

    const [dateError, setDateError] = useState(false);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const handleEndDateError = () => {
        const todayDate = moment();
        const newEndDate = moment(endDate, 'YYYY-MM-DD'); // Format end date as 'YYYY-MM-DD'

        if (newEndDate.isSameOrBefore(todayDate, 'day')) {
            setDateError(true);
        } else {
            setDateError(false);
        }
    };


    useEffect(() => {
        handleEndDateError();
    }, [endDate]);


    const handleCreateCoupon = () => {
        const jsonBody = {
            coupon_code: couponCode,
            title: couponTitle,
            description: description,
            terms_and_conditions: termsAndConditions,
            status: true,
            min_amount: minAmount,
            max_amount: maxAmount,
            coupon_type: couponType,
            coupon_value: couponValue,
            start_date: startDate,
            end_date: endDate,
            max_limit: maxLimit,
            amount_type: amountType,
            max_limit_per_user: maxLimitPerUser,
        };

        if (couponCode === '' ||
            amountType === '' ||
            couponType === '' ||
            couponValue === '' ||
            startDate === '' ||
            endDate === '' ||
            minAmount === '' ||
            maxAmount === '' ||
            termsAndConditions === ''
        ) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 4000);
        } else {
            createCoupon.AddCoupon(jsonBody).then((data) => {
                console.log(data);
                if (data.status_code) {
                    setError(false);
                    // setCouponCreated(true);
                    showModal(true);
                } else {
                    setDuplicateCouponError(true);
                    setTimeout(() => {
                        setDuplicateCouponError(false);
                    }, 4000);
                }
            });
        }

    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };




    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <Link className='offer_button' to='/getoffers'>View All Coupons
                                            <RiCoupon4Line className='offers_icon' /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Coupon Title */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='offers_couponDiv'>
                                <h1 className='coupon_h1'>Create Coupon</h1>

                                {/* SWITCH */}
                                <div className='div_props_me'>
                                    {/* <Tag color="success">Coupon is Live</Tag> */}
                                    {/* <Tag color="warning">Coupon is not Live</Tag> */}
                                </div>
                                {/* SWITCH */}
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                    </div>
                                    <div className='offers_div'>
                                        <div >
                                            <label className='offer_checkbox'>Type Coupon Title</label>
                                            <input onChange={handleAllChange(setCouponTitle)} className='offers_input' placeholder='Add Coupon Title' />
                                        </div>
                                        <div >
                                            <label className='offer_checkbox'>Create Coupon Code</label>
                                            <input required onChange={handleAllChange(setCouponCode)} className='offers_input' placeholder='Add Coupon Code' />
                                        </div>
                                        <div>
                                            <label className='offer_checkbox'>Select Coupon Category</label>
                                            <select className='offers_select' onChange={handleAllChange(setCouponType)}>
                                                <option className='offers_select_option'>Select</option>
                                                <option className='offers_select_option' value='1' >Fixed (Rupees)</option>
                                                <option className='offers_select_option' value='2' >Percentage</option>
                                                <option className='offers_select_option' value='3' >Both</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label className='offer_checkbox'>Select Coupon Type</label>
                                            <select onChange={handleAllChange(setAmountType)} className='offers_select'>
                                                <option>Select</option>
                                                <option value="1" className='offers_select_option'>Bonus</option>
                                                <option value="2" className='offers_select_option'>Unutilized</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label className='offer_checkbox'>Type Value</label>
                                            <input required onChange={handleAllChange(setCouponValue)} className='offers_input' text='text' placeholder='Value' />
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                    </div>
                                    <div className='offers_div'>
                                        <div>
                                            <label className='offer_checkbox'>Coupon Description</label>
                                            <textarea onChange={handleAllChange(setDescription)} className='offers_DESCRIPTION' placeholder='Add Description' />
                                        </div>
                                        <div className='description_div_side'>
                                            <label className='offer_checkbox'>Terms & Conditions</label>
                                            <textarea onChange={handleAllChange(seTermsAndConditions)} type='text' className='offers_DESCRIPTION' placeholder='Add T&C' />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Min-Max, Type, Value */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                    </div>
                                    <div className='offers_div'>
                                        <div>
                                            <label className='offer_checkbox'>Min. Amount</label>
                                            <input required onChange={handleAllChange(setMinAmount)} text='text' className='offers_input' placeholder='Minimum Amount' />
                                        </div>
                                        <div>
                                            <label className='offer_checkbox'>Max. Amount</label>
                                            <input required onChange={handleAllChange(setMaxAmount)} text='text' className='offers_input' placeholder='Maximum Amount' />
                                        </div>
                                        <div >
                                            <label className='offer_checkbox'>Maximum limit Per User</label>
                                            <input onChange={handleAllChange(setMaxLimitPerUser)} type='text' className='offers_input' placeholder='Coupon ( Per user )' />
                                        </div>
                                        {
                                            couponType === '3' ?
                                                <div >
                                                    <label className='offer_checkbox'>Upto Amount</label>
                                                    <input onChange={handleAllChange(setUptoAmount)} type='text' className='offers_input' placeholder='Set Upto Amount' />
                                                </div> : ''
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Date & Expiry Date */}

                    {
                        dateError ? <h5 className='offers_endDate_error'>Please Input Greater Date</h5> : ''
                    }

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                    </div>
                                    <div className='offers_div'>
                                        <div >
                                            <label className='offer_checkbox'>Start Date</label>
                                            <input required onChange={handleAllChange(setStartDate)} type='date' className='offers_input' />
                                        </div>
                                        <div >

                                            <label className='offer_checkbox'>Expiry date</label>
                                            <input required onChange={handleAllChange(setEndDate)} type='date' className='offers_input' value={dateError ? '' : endDate} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Button */}

                    <div className='offers_button_div' >
                        <button onClick={handleCreateCoupon} className='offers_button'>Create Coupon</button>
                    </div>

                    <div className='offers_head'>

                        {
                            error ? <h5 className='toast'>Please Fill All The Necessary Details</h5> : ''
                        }
                        {
                            couponCreated ? <h5 className='toast-true'>Coupon Created Successfully</h5> : ''
                        }
                        {
                            duplicateCouponError ? <h5 className='toast-warning'>Duplicate Coupon Error</h5> : ''
                        }
                    </div>
                </div>

                {/* Modal */}

                <Modal closable={false} open={isModalOpen} footer={null}>
                    <div className='ticket_mod'>
                        <Result
                            status="success"
                            title="Successfully Created Coupon"
                            subTitle={<h1>{couponCode}</h1>} />
                        <Link className='offers_button_9090' to='/getoffers'>View All Coupons</Link>
                    </div>
                </Modal>
            </div>
        </div>
    );
}
