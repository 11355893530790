import { baseURL } from './apiBaseUrl';

export const ContestReportAPI = {
    ContestWiseReport: async (matchId) => {
        //alert()
        return await fetch(baseURL + 'ContestWiseReport?MatchId=' + matchId, {
            method: 'GET',
            headers: {
                // these could be different for your API call
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            },
            // body: JSON.stringify(jsonbody),*/
        })
            .then((response) => response.json())
            .then((json) => {
                //notifyMessage("processing....")
                // notifyMessage("Processing....")
                //  console.log("api===="+json)
                if (json != null) {
                    //  console.log("api===="+JSON.stringify(json))
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');

                console.log(err);
                return false;
            });
    },
};
