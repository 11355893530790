import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { imageURL } from '../api/apiBaseUrl';
import { BannerAPI } from '../api/bannerAPI';
import Notiflix from 'notiflix';
import { Image, Modal } from 'antd';
import { useDate } from '../Component/DateHook';

export default function ViewSideBanner() {
    const [view, setView] = useState();
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
        viewBanner();
    }, []);

    const viewBanner = () => {
        BannerAPI.FetchBanner().then((data) => {
            var fetch_i = JSON.parse(JSON.stringify(data));
            setView(fetch_i.data);
        });
    };

    const [edit_bannerId, setEdit_bannerId] = useState('');
    const [edit_bannerStatus, setEdit_bannerStatus] = useState(false);

    const handleEditBanners = async (_id, isDeleted) => {
        setEdit_bannerId(_id);
        setEdit_bannerStatus(isDeleted);
        setIsModalOpen(true);
    };

    const handleModalEditStatus = async () => {
        const formData = {
            '_id': edit_bannerId,
            'newStatus': edit_bannerStatus ? false : true
        };
        await BannerAPI.editDashboardBanner(formData).then((res) => {
            if (res.status_code) {
                Notiflix.Notify.success(res.message);
                viewBanner();
                setEdit_bannerId('');
                setEdit_bannerStatus('');
                setIsModalOpen(false);
            }
            else {
                Notiflix.Notify.failure(res.message);
                setIsModalOpen(false);
            }
        });
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <Link
                                                to={'/edit_side_banner'}
                                                className='mt-2 btn btn-success float-right'
                                            >
                                                <i className='ri-add-line m-0'></i>Add SideBanner
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>View All Side Banner</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>S.No.</th>
                                                        <th>Type</th>
                                                        <th>Image</th>
                                                        <th>Order</th>
                                                        <th>Created Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {view &&
                                                        view?.length > 0 &&
                                                        view?.map((data, i) => {
                                                            return (
                                                                <>
                                                                    <tr key={i}>
                                                                        <td>{++i}</td>
                                                                        <td>{data.type}</td>
                                                                        <td>
                                                                            <Image
                                                                                width={100}
                                                                                src={data.image}
                                                                            />
                                                                        </td>
                                                                        <td>{data.order}</td>
                                                                        <td>{useDate(data.createdAt)}</td>
                                                                        <td>{data.isDeleted ? <p style={{ color: 'red', fontWeight: '700' }}>Not Active</p> : <p style={{ color: 'green', fontWeight: '700' }}>Active</p>}</td>
                                                                        <td>
                                                                            {
                                                                                <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                                                                                    <button style={{ paddingHorizontal: 12, paddingVertical: 8, backgroundColor: 'black', color: 'white', borderRadius: 6 }}
                                                                                        onClick={() => handleEditBanners(data._id, data.isDeleted)}>Edit</button>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <Modal title={`Status: ${edit_bannerStatus}`} open={isModalOpen} footer={null} width={450} onCancel={handleCancel}>
                                    <div>
                                        <h2>Are you sure you want to {edit_bannerStatus ? 'Active' : 'Delete'} this Banner</h2>
                                        <button onClick={handleModalEditStatus} style={{ backgroundColor: 'purple', color: 'white', borderRadius: 6, padding: 16, border: 'none', marginTop: 12 }}>{edit_bannerStatus ? 'Active' : 'Delete'}</button>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
