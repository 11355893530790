import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { StrikeRateAPI } from '../api/strikeRateAPI';

export default function StrikeRate() {
    const [strike, setStrike] = useState([]);
    const [editId, setEditId] = useState('');
    const [editData, setEditData] = useState([]);
    const [editStrike, setEditStrike] = useState([]);
    const [editPoints, setEditPoints] = useState([]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        GetFormat();
    }, []);

    const GetFormat = () => {
        StrikeRateAPI.FetchStrikeRate().then((data) => {
            //   console.log(data.data);
            if (data.status_code === true) {
                setStrike(data.data);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const EditSubmit = () => {};

    const EditChange = (data) => {
        console.log(data);
        setEditId(data._id);
        setEditData([data]);
        setEditStrike(data.strike_rate);
        setEditPoints(data.points);
    };

    const pointChange = (data) => {
        const oldData = [...editData];

        setEditData((prev) => [...prev]);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Strike Rate Table</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table-editable' id='table'>
                                        <div className='table-responsive'>
                                            <table
                                                id='datatable'
                                                className='table data-table table-striped table-bordered'
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Strike Rate</th>
                                                        <th>Points</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {strike &&
                                                        strike.length > 0 &&
                                                        strike.map((data) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {data.strike_rate.map(
                                                                            (strike, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>
                                                                                            {strike}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {data.points.map(
                                                                            (point, i) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>
                                                                                            {point}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            title='Edit'
                                                                            type='button'
                                                                            onClick={() =>
                                                                                EditChange(data)
                                                                            }
                                                                            className='btn btn-sm btn-skyblue'
                                                                            data-toggle='modal'
                                                                            data-target='#exampleModalScrollable'
                                                                        >
                                                                            <i className='ri-edit-line'></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className='modal fade'
                id='exampleModalScrollable'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalScrollableTitle'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-scrollable' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalScrollableTitle'>
                                Edit Strike Rate & Points
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className='card-body'>
                                <div className='table-editable' id='table'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Strike Rate</th>
                                                    <th>Points</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {editData &&
                                                    editData.length > 0 &&
                                                    editData.map((data) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {data.strike_rate.map(
                                                                        (strike, i) => {
                                                                            return (
                                                                                <div className='form-group'>
                                                                                    <label htmlFor='name'>
                                                                                        Strike{' '}
                                                                                        {i + 1}
                                                                                    </label>
                                                                                    <input
                                                                                        type='number'
                                                                                        className='form-control'
                                                                                        value={
                                                                                            strike
                                                                                        }
                                                                                        id=''
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {data.points.map((point, i) => {
                                                                        return (
                                                                            <div className='form-group'>
                                                                                <label htmlFor='name'>
                                                                                    Point {i + 1}
                                                                                </label>
                                                                                <input
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    value={point}
                                                                                    onChange={() => {
                                                                                        pointChange(
                                                                                            data,
                                                                                            i
                                                                                        );
                                                                                    }}
                                                                                    id=''
                                                                                />
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                data-dismiss='modal'
                            >
                                Close
                            </button>
                            <button
                                data-dismiss='modal'
                                type='button'
                                className='btn btn-primary'
                                onClick={EditSubmit}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
