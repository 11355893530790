import React, { useEffect, useState } from 'react';
import './ViewAllUsers.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd';
import { ContestAPI } from '../api/contestApi';
import { GlobalContestAPI } from '../api/globalContestApi';
import Notiflix from 'notiflix';

export default function AddNewCustomContest() {
    const [matchIdSelected, setMatchId] = useState('');
    const [seletedFantasy, setSeletedFantasy] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [isMultiEntry, setMultiEntry] = useState(false);
    const [isBonusContest, setBonusContest] = useState(false);
    const [isCustom, setIsCustom] = useState(false);
    const [bonusAmount, setBonusAmount] = useState(0);
    const [multiEntryPerson, setMultiEntryPerson] = useState(0);
    const [entryFees, setEntryFees] = useState('');
    const [offerEntry, setOfferEntry] = useState('');
    const [offeredEntry, setOfferedEntry] = useState('');
    const [winningAmount, setWinningAmount] = useState('');
    const [order, setOrder] = useState('');
    const [contestAmountType, setContestAmountType] = useState('');
    const [contestPriceCardType, setContestPriceCardType] = useState('');
    const [contestType, setContestType] = useState('');
    const [maxUsers, setMaximumUsers] = useState('');
    const [selectedContestCategory, setSelectedContestCategory] = useState('');
    const [isFlexible, setIsFlexible] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setMatchId(location?.state?.match_Id);
    }, [location?.state?.match_Id]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const data = await ContestAPI.GetContestCategory();
                setCategoryList(data.data);
            } catch (error) {
                console.error('Error fetching category:', error);
            }
        };
        fetchCategory();
    }, []);

    const handleGlobalContest = async () => {
        const dataToSend = {
            'is_global': false,
            'contest_category_id': selectedContestCategory.id,
            'fantasy': seletedFantasy,
            'contest_amount_type': contestAmountType,
            'contest_prize_card_type': contestPriceCardType,
            'contest_category': selectedContestCategory.name,
            'entry_fee': parseInt(entryFees),
            'offer_entry_fee': parseInt(offerEntry),
            'offered_entry': parseInt(offeredEntry),
            'type_of_contest': contestType,
            'winning_amount': parseInt(winningAmount),
            'maximum_user': parseInt(maxUsers),
            'order': parseInt(order),
            'is_multiple_entry': isMultiEntry,
            'multi_entry': multiEntryPerson,
            'is_confirmed': isConfirmed,
            'is_running': isRunning,
            'is_bonus': isBonusContest,
            'bonus': bonusAmount,
            'total_winner': 0,
            'isFlexible': isFlexible
        };

        await GlobalContestAPI.CreateGlobalContest(dataToSend).then((response) => {
            if (response.status_code) {
                Notiflix.Notify.success(response.message);
                navigate('/add_pricecard', { state: response.data });
            } else {
                Notiflix.Notify.failure(response.message);
            }
        });
    };

    const handleCustomeContest = async () => {
        const dataToSend = {
            'is_global': false,
            'MatchId': matchIdSelected,
            'contest_category_id': selectedContestCategory.id,
            'fantasy': seletedFantasy,
            'contest_amount_type': contestAmountType,
            'contest_prize_card_type': contestPriceCardType,
            'contest_category': selectedContestCategory.name,
            'entry_fee': parseInt(entryFees),
            'offer_entry_fee': parseInt(offerEntry),
            'offered_entry': parseInt(offeredEntry),
            'type_of_contest': contestType,
            'winning_amount': parseInt(winningAmount),
            'maximum_user': parseInt(maxUsers),
            'order': parseInt(order),
            'is_multiple_entry': isMultiEntry,
            'multi_entry': multiEntryPerson,
            'is_confirmed': isConfirmed,
            'is_running': isRunning,
            'is_bonus': isBonusContest,
            'bonus': bonusAmount,
            'total_winner': 0,
            'isFlexible': isFlexible
        };

        await GlobalContestAPI.createCustomContest(dataToSend).then((response) => {
            if (response.status_code) {
                Notiflix.Notify.success(response.message);
                navigate('/add_pricecard', { state: response.data });
            } else {
                Notiflix.Notify.failure(response.message);
            }
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 18 }}>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 28, backgroundColor: 'white', paddingLeft: 22, paddingRight: 22, paddingTop: 22, paddingBottom: 22, borderRadius: 10 }}>
                        <h1 style={{ fontSize: 28, fontWeight: '700' }}>Create Global Contest</h1>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 28, backgroundColor: 'white', paddingLeft: 22, paddingRight: 22, paddingTop: 22, paddingBottom: 22, borderRadius: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Select Fantasy</h3>
                            <select style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} value={seletedFantasy} onChange={(e) => setSeletedFantasy(e.target.value)}>
                                <option value=''>Select</option>
                                <option value='cricket'>Cricket</option>
                                <option value='prediction'>Prediction</option>
                            </select>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Type of Contest</h3>
                            <select value={contestType} onChange={(e) => setContestType(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} >
                                <option value=''>Select</option>
                                <option value='Price'>Price</option>
                                <option value='Prize'>Prize</option>
                            </select>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Contest Amount Type</h3>
                            <select value={contestAmountType} onChange={(e) => setContestAmountType(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} >
                                <option value=''>Select</option>
                                <option value='Amount Based Contest'>Amount Based</option>
                                <option value='Percentage Based Contest'>Percentage Based</option>
                            </select >
                        </div >

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Contest Price Card Type</h3>
                            <select
                                value={contestPriceCardType}
                                onChange={(e) => setContestPriceCardType(e.target.value)}
                                style={{
                                    padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none'
                                }} >
                                < option value='' > Select</option>
                                <option value='Amount Based Price Card'>Amount Based</option>
                                <option value='Percentage based Pricecard'>Percentage Based</option>
                            </select>
                        </div >

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Contest Category</h3>
                            <select style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} value={selectedContestCategory.name} onChange={(e) => {
                                const selectedOption = categoryList.find(category => category.ContestCategoryName === e.target.value);
                                setSelectedContestCategory({ id: selectedOption?.ContestCatId || '', name: e.target.value });
                            }}>
                                <option value=''>Select</option>
                                {categoryList.map((category, index) => (
                                    <option key={index} value={category.ContestCategoryName}>{category.ContestCategoryName}</option>
                                ))}
                            </select>
                        </div >
                    </div >

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 24, backgroundColor: 'white', paddingLeft: 22, paddingRight: 22, paddingTop: 22, paddingBottom: 22, borderRadius: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Entry Fees</h3>
                            <input onChange={(e) => setEntryFees(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='type entry fees' />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Offer Entry</h3>
                            <input onChange={(e) => setOfferEntry(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='type offer entry' />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Offered Entry</h3>
                            <input onChange={(e) => setOfferedEntry(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='type offered entry' />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Winning Amount</h3>
                            <input onChange={(e) => setWinningAmount(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='type winning amount' />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Maximum Number of Users</h3>
                            <input onChange={(e) => setMaximumUsers(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='type max no. of users' />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 24, backgroundColor: 'white', paddingLeft: 22, paddingRight: 22, paddingTop: 22, paddingBottom: 22, borderRadius: 10 }}>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Order</h3>
                            <input onChange={(e) => setOrder(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='type ordering' />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Is Confirmed</h3>
                            <Checkbox checked={isConfirmed} onChange={(e) => setIsConfirmed(e.target.checked)}>Confirmed</Checkbox>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Is Running</h3>
                            <Checkbox checked={isRunning} onChange={(e) => setIsRunning(e.target.checked)}>Running</Checkbox>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Multi Entry</h3>
                            <Checkbox checked={isMultiEntry} onChange={(e) => setMultiEntry(e.target.checked)}>Multi Entry</Checkbox>
                            {isMultiEntry && <input onChange={(e) => setMultiEntryPerson(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='Type Maximum entry per user' />}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Bonus Contest</h3>
                            <Checkbox checked={isBonusContest} onChange={(e) => setBonusContest(e.target.checked)}>Bonus Contest</Checkbox>
                            {isBonusContest && <input onChange={(e) => setBonusAmount(e.target.value)} style={{ padding: 14, borderRadius: 8, backgroundColor: '#E9E9E9', color: 'black', border: 'none' }} placeholder='Type Maximum entry per user' />}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>Custom Contest</h3>
                            <Checkbox checked={isCustom} onChange={(e) => setIsCustom(e.target.checked)}>Custom Contest</Checkbox>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <h3 style={{ fontSize: 18, fontWeight: '500' }}>is Flexible</h3>
                            <Checkbox checked={isFlexible} onChange={(e) => setIsFlexible(e.target.checked)}>Flexible Contest</Checkbox>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 24, backgroundColor: 'white', paddingLeft: 22, paddingRight: 22, paddingTop: 22, paddingBottom: 22, borderRadius: 10 }}>
                        {isCustom ?
                            <button onClick={handleCustomeContest} style={{ backgroundColor: 'red', color: 'white', borderRadius: 8, padding: 18, border: 'none' }}>Create Custom Contest</button>
                            :
                            <button onClick={handleGlobalContest} style={{ backgroundColor: 'orangered', color: 'white', borderRadius: 8, padding: 18, border: 'none' }}>Create Global Contest</button>
                        }
                    </div>
                </div >

            </div >
        </div >
    );
}
