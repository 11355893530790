import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GlobalContestAPI } from '../api/globalContestApi';
import Notiflix from 'notiflix';
import { UserActivityLogApi } from '../api/ActivityLogApi';

export default function UserActivityLogPage() {
    const [data, setData] = useState([]);
    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    //   const [userId, setUserId] = useState('');
    const [phone, setPhone] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        UserActivityLog();
        // console.log('hello')
    }, []);

    const UserActivityLog = () => {
        UserActivityLogApi.GetActivityLog(id, email, phone).then((data) => {
            if (data.status_code) {
                setData(data.data);
                console.log(data);
            } else if (!data.status_code) {
                console.log('no');
            }
        });
    };

    const handleSearch = () => {
        UserActivityLog();
    };

    const Reset = () => {
        setId('');
        setEmail('');
        setPhone('');

        UserActivityLog();
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='container-fluid'>
                        <form>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-row '>
                                                <div>
                                                    <div className='col'>
                                                        <label htmlFor='name'>UserId</label>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Search By UserId'
                                                            value={id}
                                                            onChange={(e) => {
                                                                setId(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='col'>
                                                        <label htmlFor='name'>Phone</label>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            placeholder='Search By Phone'
                                                            value={phone}
                                                            onChange={(e) => {
                                                                setPhone(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='col'>
                                                        <label htmlFor='name'> Email</label>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Search By Email'
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className='col'
                                                    style={{ 'padding-top': '0.5pc' }}
                                                >
                                                    <br />
                                                    <label htmlFor='name'></label>
                                                    <button
                                                        onClick={handleSearch}
                                                        className='btn btn-primary'
                                                        type='button'
                                                    >
                                                        Search
                                                    </button>
                                                    &nbsp;
                                                    {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                                    <button
                                                        onClick={Reset}
                                                        className='btn btn-primary'
                                                        type='button'
                                                    >
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Log</th>
                                                    <th>UserId</th>
                                                    <th>Description</th>
                                                    <th>createdAt</th>
                                                    <th>updatedAt</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((data, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{++i}</td>
                                                            <td>{data.log}</td>
                                                            <td>{data.user_id}</td>
                                                            <td>{data.description}</td>
                                                            <td>{data.createdAt}</td>
                                                            <td>{data.updatedAt}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
