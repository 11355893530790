import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { BannerAPI } from '../api/bannerAPI';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function BannerPage() {
    const [image, setImage] = useState('');
    const [type, setType] = useState('');
    const [maxCount, setMaxCount] = useState('');
    const [TimeInterval, setTimeInterval] = useState('');
    const [StartTime, setStartTime] = useState('');
    const [EndTime, setEndTime] = useState('');
    const [SelectDate, setSelectDate] = useState('');
    const [active, setActive] = useState(false);
    // const [order, setOrder] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const addBanner = () => {
        console.log(type);

        // e.preventDefault();
        if (type == '' || type == null) {
            Notiflix.Notify.failure('Type can\'t be blank');
            return;
        }

        else if (image == '' || image == null || TimeInterval == '' || TimeInterval == null || maxCount == '' || maxCount == null || StartTime == '' || StartTime == null || EndTime == '' || EndTime == null || SelectDate == '' || SelectDate == null) {
            Notiflix.Notify.failure('Please Fill all Values');
            return;
        }
        if (type == 'Pop-up Banner') {
            // console.log('hgeloooo');
            // const data = new FormData();
            // data.append('Image', image);
            // data.append('type', type);
            // data.append('max_count', maxCount);
            // data.append('time_interval', TimeInterval);
            // data.append('start_time', StartTime);
            // data.append('end_time', EndTime);
            // data.append('select_date', SelectDate);
            // data.append('active', active);
            const data = {
                // 'image': image,
                // 'time_interval': TimeInterval,
                // 'start_time': StartTime,
                // 'end_time': EndTime,
                // 'select_date': SelectDate,
                // 'active': active,
                // 'max_count': maxCount

                'image': image,
                'active': active,
                'time_interval': TimeInterval,
                'start_time': StartTime,
                'end_time': EndTime,
                'select_date': SelectDate,
                'max_count': maxCount,
            };
            BannerAPI.CreateBannerPopUp(data, 'bannerCreate').then((res) => {
                console.log(data);
                if (res.status_code) {
                    setType('');
                    setMaxCount('');
                    setTimeInterval('');
                    setStartTime('');
                    setEndTime('');
                    setSelectDate('');
                    setImage('');
                    Notiflix.Notify.success(res.message);
                }
            });
        }


        // else if (image == '' || image == null) Notiflix.Notify.failure('Please choose image');
        // else if (order == '' || order == null) Notiflix.Notify.failure('Order can\'t be blank');
        // else {
        //     const data = new FormData();
        //     data.append('Image', image);
        //     data.append('type', type);
        //     data.append('order', order);
        //     BannerAPI.AddBanner(data).then((res) => {
        //         // swal('Banner Added');
        //         Notiflix.Notify.success('Banner Added');
        //         // console.log(data)
        //     });
        // }
    };
    const imageChange = (e) => {
        e.preventDefault();
        setImage(e.target.files[0]);
    };

    const orderChange = (e) => {
        e.preventDefault();
        setType(e.target.value);
        console.log(e.target.value);
    };

    // const bannerTypeChange = (e) => {
    //     e.preventDefault();
    //     setOrder(e.target.value);
    // };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <Link
                                        to={'/view_pop_up_banners'}
                                        className='button btn btn-skyblue button-icon mr-2'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View all Pop-Up Banners
                                    </Link>
                                    <Link
                                        to={'/view_side_banner'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-eye-fill'></i> View all Slider
                                    </Link>
                                    <a
                                        className='button btn btn-primary ml-2 button-icon rounded-small rtl-mr-2 rtl-ml-0'
                                        href='#'
                                    >
                                        <i className='ri-add-line m-0'></i> Add New Slider
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Create New Banner</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group col-9'>
                                            <label htmlFor='name'>Banner Type :</label>
                                            <select
                                                value={type}
                                                onChange={orderChange}
                                                className='form-control mb-3'
                                            >
                                                <option select=''>Open this select menu</option>
                                                <option value='Pop-up Banner'>Pop-Up Banner</option>
                                                <option value='Banner'>Banner</option>
                                                <option value='Web'>Web</option>
                                                <option value='App'>App</option>
                                            </select>
                                        </div>

                                        <div className='form-group col-12 d-flex'>

                                            <div className='form-group col-2'>


                                                <label htmlFor='text'>Image Link</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='order'
                                                    value={image}
                                                    onChange={(e) => setImage(e.target.value)}
                                                    placeholder='Image Link'
                                                />

                                            </div>

                                            {/* <div className='form-group col-2'>
                                                <label htmlFor='text'>Image</label>
                                                <div className='custom-file'>
                                                    <input
                                                        type='file'
                                                        className='custom-file-input'
                                                        id='customFile'
                                                        onChange={(e) => imageChange(e)}
                                                        accept='image/png, image/jpeg'
                                                    />
                                                    <label
                                                        className='custom-file-label'
                                                        htmlFor='customFile'
                                                    >
                                                    Choose file
                                                    </label>
                                                    <button onClick={sendImage} type="submit" className="btn btn-primary">Update</button>
                                                </div>
                                            </div> */}

                                            <div className='form-group col-2'>
                                                <label htmlFor='text'>Max Count </label>
                                                <input
                                                    // onChange={}
                                                    type='number'
                                                    className='form-control'
                                                    value={maxCount}
                                                    onChange={(e) => setMaxCount(e.target.value)}
                                                    id='order'
                                                    placeholder='Max Count'
                                                />
                                            </div>

                                            <div className='form-group col-2 d-flex justify-content-center align-items-center mt-4'>
                                                <label htmlFor='text'>Active </label>
                                                <Switch {...label} disabled />
                                            </div>

                                            <div className='form-group col-2'>


                                                <label htmlFor='text'>Time Interval </label>
                                                <input
                                                    // onChange={}
                                                    type='number'
                                                    step='1'
                                                    className='form-control'
                                                    value={TimeInterval}
                                                    id='order'
                                                    onChange={(e) => setTimeInterval(e.target.value)}
                                                    placeholder='Time Interval'
                                                />

                                            </div>


                                        </div>
                                        <div className='form-group col-12 d-flex'>


                                            <div className='form-group col-2'>


                                                <label htmlFor='text'>Start Time</label>
                                                <input
                                                    // onChange={}
                                                    type='time'
                                                    className='form-control'
                                                    value={StartTime}
                                                    onChange={(e) => setStartTime(e.target.value)}
                                                    id='order'
                                                    placeholder='Start Time'
                                                />

                                            </div>


                                            <div className='form-group col-2'>


                                                <label htmlFor='text'>End Time</label>
                                                <input
                                                    // onChange={}
                                                    type='time'
                                                    className='form-control'
                                                    value={EndTime}
                                                    onChange={(e) => setEndTime(e.target.value)}
                                                    id='order'
                                                    placeholder='End Time'
                                                />

                                            </div>

                                            <div className='form-group col-2'>


                                                <label htmlFor='text'>Select Date</label>
                                                <input
                                                    // onChange={}
                                                    type='date'
                                                    className='form-control'
                                                    value={SelectDate}
                                                    onChange={(e) => setSelectDate(e.target.value)}
                                                    id='order'
                                                    placeholder='Time Interval'
                                                />

                                            </div>
                                        </div>

                                        <button
                                            onClick={addBanner}
                                            type='button'
                                            className='btn btn-primary'
                                        >
                                            Create Banner
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
