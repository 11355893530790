import { BASEAPIRUMMY } from './BaseApiRummy';
import { BASEAPISLUDO } from './BaseApisLudo';
import { baseURLRummy, crashBaseURL } from './apiBaseUrl';
const key = localStorage.getItem('jwtToken');


export const CreateContestAPI = {
    CreateContest: (json) => {
        return BASEAPISLUDO.POSTAPI(json, 'createGame');
    },

    // RummyContest: (json) => {
    //     return BASEAPIRUMMY.POSTAPI(json, 'rumming');
    // },

    RummyContest: async (jsonbody) => {
        return await fetch(baseURLRummy + 'rumming', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    CreateCrashContest: async (jsonbody) => {
        return await fetch(crashBaseURL + 'createGame', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'x-api-key': key,
                'mai-hu': 'motaBhai007'
            },
            body: JSON.stringify(jsonbody),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json != null) {
                    return json;
                } else return false;
            })
            .catch((err) => {
                console.log('Some error occured, please retry');
                console.log(err);
                return false;
            });
    },

    GetLudoGame: (gameType, status) => {
        return BASEAPISLUDO.GETAPI('getContest?gameType=' + gameType + '&status=' + status);
    },

    LudoGameStatusChanged: (statusData) => {
        return BASEAPISLUDO.POSTAPI(statusData, 'updateLudocontestStatus');
    },

    ludoReportAllMatches: (startDate, endDate, matchStatus) => {
        return BASEAPISLUDO.GETAPI('allLudoMatchesList?startDate=' + startDate + '&endDate=' + endDate + '&status=' + matchStatus);
    },

    ludoUserTransaction: (matchId, userId) => {
        return BASEAPISLUDO.GETAPI('joinedUserLudoDetails?matchId=' + matchId + '&userId=' + userId);
    }
};
