import './ViewAllUsers.css';
import { useEffect, useState } from 'react';
import Notiflix from 'notiflix';
import { LoadingOutlined } from '@ant-design/icons';
import { PrvtContest } from '../api/PvtContestAPI';
import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

export default function PrivateContest() {
    const [allMatches, setAllMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [matchName, setMatchName] = useState('');
    const [matchStartDate, setMatchStartDate] = useState('');

    useEffect(() => {
        // getAllMatchesFilteredData();
    }, []);

    const getAllMatchesFilteredData = () => {
        setIsLoading(true);

        const requestMatchName = localStorage.getItem('pvtName') || '';
        const requestMatchStartDate = localStorage.getItem('pvtDate') || '';

        PrvtContest.GetAllFilteredMatches(requestMatchName, requestMatchStartDate).then((data) => {
            if (data.status_code === true) {
                setIsLoading(false);
                setAllMatches(data.data);
                Notiflix.Notify.success('Successfully Fetched');
            } else {
                Notiflix.Notify.failure(data.message);
                setIsLoading(false);
            }
        });
    };

    const handleReset = () => {
        setMatchName('');
        setMatchStartDate('');
        localStorage.removeItem('pvtName');
        localStorage.removeItem('pvtDate');
        getAllMatchesFilteredData();
    };

    const handleSearch = () => {
        localStorage.setItem('pvtName', matchName);
        localStorage.setItem('pvtDate', matchStartDate);
        getAllMatchesFilteredData();
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div>
                        <div className='offers_couponDiv'>
                            <h1 className='coupon_h1'>Private Contest</h1>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-12'>
                                <div className='card'>
                                    <div className='card-body '>
                                        <div className='d-flex'>
                                            <div className='div-trnx-div-main-h'>
                                                {/* Match Name */}
                                                <div className='prvt-contest-div'>
                                                    <div className='prvt-contest-div-div'>
                                                        <label style={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>Match Name</label>
                                                        <input
                                                            className='offers_input_9899888'
                                                            onChange={(e) => setMatchName(e.target.value)}
                                                            value={matchName}
                                                            type='text'
                                                            placeholder='Search Match Name'
                                                        />
                                                    </div>
                                                </div>
                                                {/* Match Start Date */}
                                                <div className='prvt-contest-div'>
                                                    <div className='prvt-contest-div-div'>
                                                        <label style={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>Match Start Date</label>
                                                        <input
                                                            className='offers_input_9899888'
                                                            onChange={(e) => setMatchStartDate(e.target.value)}
                                                            value={matchStartDate}
                                                            type='date'
                                                            placeholder='Search Start Date'
                                                        />
                                                    </div>
                                                </div>

                                                <div className='prvt-contest-div-divv'>
                                                    <button className='offers_input_989988' onClick={handleSearch}>
                                                        Search
                                                    </button>
                                                </div>
                                                <div className='prvt-contest-div-divv'>
                                                    <button className='offers_input_989988' onClick={handleReset}>
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='d-flex'>
                                            <div className='col '>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='row'>
                                    <div className='col-sm-12 col-lg-12'>
                                        {
                                            isLoading ? <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                                <div>
                                                    <RotatingLines
                                                        marginTop='70'
                                                        strokeColor='#c1030d'
                                                        strokeWidth='5'
                                                        animationDuration='0.75'
                                                        width='50'
                                                        visible={true}
                                                    />
                                                </div>
                                            </div>
                                                : <div className='card'>
                                                    <div className='card-body'>
                                                        <div
                                                        >
                                                            <div className='table-responsive'>
                                                                <table className='table table-striped table-bordered'>
                                                                    <thead>
                                                                        <tr className='support_main_file_div'>
                                                                            <th>S.No.</th>
                                                                            <th>Match Name</th>
                                                                            <th>Match ID</th>
                                                                            <th>Start Date</th>
                                                                            <th>Match Status</th>
                                                                            <th></th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            allMatches.length === 0 ?
                                                                                <div className='allMatches-div-res'>
                                                                                    <h1>No Matches Found</h1>
                                                                                </div>
                                                                                :
                                                                                allMatches.map((matches, i) => {
                                                                                    return (
                                                                                        <tr key={i}>
                                                                                            <td>{++i}</td>
                                                                                            <td>{matches.matchName}</td>
                                                                                            <td>{matches.matchId}</td>
                                                                                            <td>{matches.StartDateIST}</td>
                                                                                            <td>{matches.matchStatus}</td>
                                                                                            <td>
                                                                                                <button className='some_8989898989'>
                                                                                                    <Link
                                                                                                        className='some_8989898989'
                                                                                                        to={
                                                                                                            '/private-contest-detail'
                                                                                                        }
                                                                                                        state={{
                                                                                                            matchId: matches.matchId,
                                                                                                            matchName: matches.matchName,
                                                                                                            matchStatus: matches.matchStatus
                                                                                                        }}
                                                                                                        type='submit'
                                                                                                    // className='py-4'
                                                                                                    >
                                                                                                        Private Contest
                                                                                                    </Link>
                                                                                                </button></td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >);
}
