import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { BonusAPI } from '../api/bonusApi';

export default function PointsPage() {
    const [bonus, setBonus] = useState('');
    const [bonus_type, setBonusType] = useState('');
    const [amount, setAmount] = useState('');
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/chart-custom2.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const addBonus = () => {
        // e.preventDefault();
        let data = {
            bonus_type: bonus_type,
            amount: amount,
            is_deleted: false,
        };
        BonusAPI.AddBonus(data).then((data) => {
            if (data.status_code) setBonus(data.message);
            Notiflix.Notify.success('Bonus Added Successfully!!');
        });
    };

    const bonusChangeHandler = (e) => {
        setBonusType(e.target.value);
    };

    const amountChangeHandler = (e) => {
        setAmount(e.target.value);
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Add Bonus Points</h4>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <form>
                                        <div className='form-group'>
                                            <label htmlFor='text'>Bonus Points :</label>
                                            <input
                                                value={amount}
                                                onChange={amountChangeHandler}
                                                type='text'
                                                className='form-control'
                                                id=''
                                                placeholder='Bonus Points'
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor='name'>Select Type :</label>
                                            <select
                                                onChange={bonusChangeHandler}
                                                className='form-control mb-3'
                                            >
                                                <option select=''>Select Type</option>
                                                <option value='Signup Bonus'>Signup Bonus</option>
                                                {/* <option value="Refer Bonus">Refer Bonus</option> */}
                                                <option value='Pan Bonus'>Pan Bonus</option>
                                                <option value='Email Bonus'>Email Bonus</option>
                                                <option value='Mobile Bonus'>Mobile Bonus</option>
                                                <option value='Bank Bonus'>Bank Bonus</option>
                                                {/* <option value="Refer Commission">Refer Commission</option> */}
                                            </select>
                                        </div>
                                        <button
                                            onClick={() => addBonus()}
                                            type='button'
                                            className='btn btn-success'
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
