import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { SeriesAPI } from '../api/seriesApi';
import { CSVLink } from 'react-csv';
import { FetchFullDetailAPI } from '../api/fullDetailAPI';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';

function PaymentHistoryPage() {
    const [paymentData, setPaymentData] = useState([]);
    const [page, setPage] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [teamName, setTeamName] = useState('');
    const [phone, setPhone] = useState('');
    const [entries, setEntries] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [pagenumber, setPagenumber] = useState(1);
    const [nextPage, setNextpage] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [previousPage, setPreviousPage] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        paymentHistoryData();
        console.log(totalPages);
        generatePagination(1, 10);
    }, [pagenumber, activeIndex, totalPages]);

    const paymentHistoryData = () => {
        setIsLoading(true);

        FetchFullDetailAPI.PaymentHistory(
            email,
            teamName,
            userId,
            phone,
            entries,
            activeIndex + 1,
            startDate,
            endDate
        ).then((data) => {
            if (data.status_code) {
                console.log(data);
                setPaymentData(data.data);
                setTotalPages(3);
            }

            setIsLoading(false);
        });
    };

    const handleDate = (e) => {
        const time = moment(e).format('hh:mm:s a');
        const date = moment(e).format('DD/MM/YYYY');
        return `${date} | ${time}`;
    };

    const handleReceipt = (e) => {
        return e.replace(/\D/g, '');
    };

    const handleCurrency = (e) => {
        if (e === 'INR') {
            return '₹';
        }
    };

    const handleClick = () => {
        setIsLoading(true);
        paymentHistoryData();
        // setIsLoading(false)
    };

    const generatePagination = (start, end, index) => {
        const arr = [];
        setNextpage(end);
        setPreviousPage(start);
        if (totalPages > 10) {
            for (let i = start; i <= end; i++) {
                arr.push(i);
                if (arr.indexOf(i) == i) {
                } else {
                }
            }
        } else {
            for (let i = start; i <= totalPages; i++) {
                arr.push(i);
                if (arr.indexOf(i) == i) {
                } else {
                }
            }
        }

        setPage(arr);
    };

    const HandlePaginate = (item, index) => {
        // console.log(item)
        setActiveIndex(index);
        setPagenumber(item);
    };

    const handleNext = () => {
        const myarr = [];
        if (totalPages > nextPage + 1) {
            generatePagination(previousPage + 1, nextPage + 1);
        }

        //  console.log(myarr)
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <form>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>User Id</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Search By User Id'
                                                    onChange={(e) => {
                                                        setUserId(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>Team Name</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Search By Team Name'
                                                    onChange={(e) => {
                                                        setTeamName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>Email</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Search By Email'
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>Phone no.</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    placeholder='Search By Phone no.'
                                                    onChange={(e) => {
                                                        setPhone(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>From :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='col'>
                                                <label htmlFor='name'>To :</label>
                                                <input
                                                    type='date'
                                                    className='form-control'
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col' style={{ paddingTop: '0.5pc' }}>
                                            <br />
                                            <label htmlFor='name'></label>
                                            <button
                                                className='btn btn-primary'
                                                type='button'
                                                onClick={handleClick}
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                            <input
                                                type='reset'
                                                // onClick={() => Reset()}
                                                className='btn btn-danger'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                {/* <div className="row"> */}
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                            {/* <Link to={"/create_series"} className="button btn btn-skyblue button-icon">
        <i className="ml-2 ri-add-line"></i>Create New Series
        </Link> */}

                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    {/* <div className="btn-group m-2" role="group">
                                        <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Export
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="btnGroupDrop1" style={{}}>
                                            <button className="dropdown-item" href="#">Export All data</button>
                                            <CSVLink filename='View All Series' data={dataArray} headers={headers}>

                                            <button onClick={handleExport} className="dropdown-item" href="#">Export This Data</button>
                                            </CSVLink>
                                            
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading && (
                <div className='col-sm-12 col-lg-12'>
                    <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                        <RotatingLines
                            marginTop='40'
                            strokeColor='#c1030d'
                            strokeWidth='5'
                            animationDuration='0.75'
                            width='50'
                            visible={true}
                        />
                    </div>
                </div>
            )}

            {!isLoading && (
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'> Payment History</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-editable' id='table'>
                                <div className='table-responsive'>
                                    <table
                                        // id="datatable"
                                        className='table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>User ID</th>
                                                <th>Order ID</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Entity</th>
                                                <th>Created at</th>
                                                <th>Updated at</th>
                                                <th>Reciept</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentData &&
                                                paymentData.length > 0 &&
                                                paymentData.map((payment, i) => {
                                                    return (
                                                        <tr>
                                                            <td>{++i}</td>
                                                            <td>{payment.user_id}</td>
                                                            <td>{payment.order_id}</td>
                                                            <td>
                                                                {handleCurrency(payment.currency)}{' '}
                                                                {payment.amount}
                                                            </td>
                                                            <td>{payment.status}</td>
                                                            <td>{payment.entity}</td>
                                                            <td>{handleDate(payment.createdAt)}</td>
                                                            <td>{handleDate(payment.updatedAt)}</td>
                                                            <td>{payment.receipt}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 d-flex align-items-center'>
                            <label className='p-2' htmlFor='name'>
                                Show
                            </label>
                            <select
                                // onChange={changePageSize}
                                className='form-control m-2 mb-3 col-2 d-flex align-items-center'
                            >
                                <option selected>50</option>
                                {/* <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option> */}
                            </select>
                            <nav aria-label='Page navigation example '>
                                <ul className='pagination '>
                                    <li
                                        className='page-item '
                                    // onClick={handlePrevious}
                                    >
                                        <a
                                            className='page-link'
                                            // href="#"
                                            aria-label='Previous'
                                        >
                                            <span aria-hidden='true'>&laquo;</span>
                                            <span className='sr-only'>Previous</span>
                                        </a>
                                    </li>

                                    {page.map((item, i) => (
                                        <li
                                            className={`page-item  
                          ${activeIndex == i ? 'active' : ''}`}
                                            onClick={() => {
                                                HandlePaginate(item, i);
                                            }}
                                        >
                                            <button className='page-link'>{item}</button>
                                        </li>
                                    ))}

                                    <li
                                        className='page-item'
                                        onClick={() => {
                                            handleNext();
                                        }}
                                    >
                                        <a className='page-link' aria-label='Next'>
                                            <span aria-hidden='true'>&raquo;</span>
                                            <span className='sr-only'>Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PaymentHistoryPage;
