import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export default function ViewChallengeQuestionPage() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'></div>
                                    <div className='col'>
                                        <Link
                                            to={'/view_all_quiz_contest'}
                                            className='mt-2 btn btn-skyblue float-right'
                                        >
                                            {' '}
                                            <i className='ri-arrow-left-line'></i> Back
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='table-responsive'>
                                    <table
                                        id='datatable'
                                        className='table data-table table-striped table-bordered'
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Question</th>
                                                <th>Option A</th>
                                                <th>Option B</th>
                                                <th>Option C</th>
                                                <th>Option D</th>
                                                <th>Correct Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>2.</td>
                                                <td>
                                                    Though 'Samber' is popular across south India,
                                                    which state is most famous for Samber?
                                                </td>
                                                <td>Tamil Nadu</td>
                                                <td>Kerala</td>
                                                <td>Andhra Pradesh</td>
                                                <td>None of these</td>
                                                <td>Option A</td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Walker cup is associate with which sport?</td>
                                                <td>Cricket</td>
                                                <td>Football</td>
                                                <td>Fencing</td>
                                                <td>Golf</td>
                                                <td>Option D</td>
                                            </tr>
                                            <tr>
                                                <td>4.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>5.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>6.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>7.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>8.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>9.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>10.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>11.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                            <tr>
                                                <td>12.</td>
                                                <td>Who Buit Hawa Mahal?</td>
                                                <td>Guru Ramdas</td>
                                                <td>Maharaja Pratap Singh</td>
                                                <td>Rabindernath Tagore</td>
                                                <td>British Govt.</td>
                                                <td>Option B</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
