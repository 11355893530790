import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ContestAPI } from '../api/contestApi';
import { imageURL } from '../api/apiBaseUrl';
import { SeriesAPI } from '../api/seriesApi';
import Notiflix from 'notiflix';
import { CSVLink } from 'react-csv';
import { RotatingLines } from 'react-loader-spinner';
import moment from 'moment';

export default function ViewContestCategoryPage() {
    const [CategoryList, setCategoryList] = useState([]);
    const [FantasyList, setFantasyList] = useState([]);
    const [Fantasy, setFantasy] = useState('cricket');
    const [contest, setContest] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const role = localStorage.getItem('role');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);

        GetCategory();
        GetFantasy();
    }, []);

    const headers = [
        { label: 'Contest Category Name', key: 'ContestCategoryName' },
        { label: 'SubTitle', key: 'SubTitle' },
        { label: 'Image', key: 'Image' },
        { label: 'Contest Order', key: 'ContestOrder' },
        { label: 'ContestCatId', key: 'ContestCatId' },
        { label: 'Fantasy', key: 'Fantasy' },
        { label: 'ContestOrder', key: 'ContestOrder' },
    ];
 

    const GetCategory = () => {
        ContestAPI.GetContestCategory(Fantasy, contest).then((data) => {
            setCategoryList(data.data);
            console.log(data.data);
            setIsLoading(false);
        });
    };

    const contestChange = (e) => {
        setContest(e.target.value);
    };

    const Reset = () => {
        setFantasy();
        setContest('');
        GetCategory('');
    };

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) setFantasyList(data.message);
        });
    };
    const fantasyChange = (e) => {
        setFantasy(e.target.value);
    };

    const deleteChange = (id) => {
        const ContestCatId = id;

        ContestAPI.DeleteContestCategory(ContestCatId).then((data) => {
            if (data.status_code) {
                Notiflix.Notify.success('Deleted successfully!!');
                GetCategory();
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    const search = () => {
        GetCategory();
    };

    const handleDate = (e) => {
        let value = moment(e).format('DD/MM/YYYY');
        return value;
    };

    const handleTime = (e) => {
        let value = moment(e).format('hh:mm:s a');
        return value;
    };

    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='form-row'>
                                    <div className='col'>
                                        <label htmlFor='name'>Fantasy* :</label>
                                        <select
                                            className='form-control mb-3'
                                            onChange={fantasyChange}
                                        >
                                            {FantasyList?.map((fan) => (
                                                <option value={fan.fantasy}>{fan.fantasy}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor='name'>Contest Name :</label>
                                        <input
                                            onChange={contestChange}
                                            value={contest}
                                            type='text'
                                            className='form-control'
                                            placeholder='Search By Contest Name'
                                        />
                                    </div>

                                    <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                        <br />
                                        <label htmlFor='name'></label>
                                        <button
                                            onClick={search}
                                            type='submit'
                                            className='btn btn-primary'
                                        >
                                            Search
                                        </button>
                                        &nbsp;
                                        <button
                                            onClick={Reset}
                                            type='submit'
                                            className='btn bg-danger'
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isLoading && (
                    <div className='col-sm-12 col-lg-12'>
                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                            <RotatingLines
                                marginTop='40'
                                strokeColor='#c1030d'
                                strokeWidth='5'
                                animationDuration='0.75'
                                width='50'
                                visible={true}
                            />
                        </div>
                    </div>
                )}

                {!isLoading && (
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    {role!=3 && role!=5 &&<Link
                                        to={'/create_category'}
                                        className='button btn btn-skyblue button-icon'
                                    >
                                        <i className='ml-2 ri-add-line'></i>Add New Contest Category
                                    </Link>}
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            {CategoryList && (
                                                <CSVLink
                                                    filename='View Contest Category'
                                                    data={CategoryList}
                                                    headers={headers}
                                                >
                                                    <button className='dropdown-item' href='#'>
                                                        Export This Data
                                                    </button>
                                                </CSVLink>
                                            )}
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className='col-sm-12 col-lg-12'>
                        <div className='card'>
                            <div className='card-header d-flex justify-content-between'>
                                <div className='header-title'>
                                    <h4 className='card-title'>View Contest Category</h4>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='table-editable' id='table'>
                                    <div className='table-responsive'>
                                        <table
                                            id='datatable'
                                            className='table data-table table-striped table-bordered'
                                        >
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Contest Category Name</th>
                                                    <th>Fantasy</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Sub Title</th>
                                                    {/* <th>Discount Value</th> */}
                                                    {role!=3 && role!=5 &&<th>Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CategoryList &&
                                                    CategoryList.length > 0 &&
                                                    CategoryList?.map((cat, ind) => (
                                                        <tr>
                                                            <td>{++ind}</td>
                                                            <td>{cat.ContestCategoryName}</td>
                                                            <td>{cat.Fantasy}</td>
                                                            <td>{handleDate(cat.createdAt)}</td>
                                                            <td>{handleTime(cat.createdAt)}</td>
                                                            <td>{cat.SubTitle}</td>
                                                            {role!=3 && role!=5 &&<td>
                                                                <Link
                                                                    to='/edit_contest_category'
                                                                    state={{
                                                                        id: cat.ContestCatId,
                                                                        CatName:
                                                                            cat.ContestCategoryName,
                                                                        subtitle: cat.SubTitle,
                                                                    }}
                                                                    title='Edit'
                                                                    className='btn btn-skyblue btn-sm'
                                                                >
                                                                    <i className='ri-edit-line'></i>
                                                                </Link>
                                                                &nbsp;
                                                                <button
                                                                    type='submit'
                                                                    title='Delete'
                                                                    className='btn btn-primary btn-sm'
                                                                    onClick={(e) => {
                                                                        if (
                                                                            window.confirm(
                                                                                'Are you sure to delete?'
                                                                            )
                                                                                ? deleteChange(
                                                                                    cat.ContestCatId
                                                                                )
                                                                                : e.preventDefault()
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className='ri-delete-bin-line'></i>
                                                                </button>
                                                            </td>}
                                                        </tr>
                                                    ))}
                                                {/* <tr>
                             
 
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Play win, big win.</td>
                            <td>High entry contest with high entry rewards</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/3q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Head-to-Head </td>
                            <td>Go head-to-head in this contest</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/4q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Mega Contest </td>
                            <td>Jeeto Dil Khol ke</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/5q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Mega Contest </td>
                            <td>Jeeto Dil Khol ke</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/6q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Mega Contest </td>
                            <td>Jeeto Dil Khol ke</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/7q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Mega Contest </td>
                            <td>Jeeto Dil Khol ke</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/8q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Mega Contest </td>
                            <td>Jeeto Dil Khol ke</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/9q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Mega Contest </td>
                            <td>Jeeto Dil Khol ke</td>
                            <td>10%</td>
                            <td><img src="/assets/images/cricket-team/10q.png" style={{width: "50px"}}/></td>
                            <td>
                                <button type="submit" className="btn btn-skyblue btn-sm"><i className="ri-edit-line"></i> Edit</button>
                                <button type="submit" className="btn btn-primary btn-sm"><i className="ri-delete-bin-line"></i> Delete</button>
                            </td>
                        </tr> */}
                                            </tbody>
                                            {/* <tfoot>
                        <tr>
                            <th>S.No.</th>
                            <th>Contest Category Name</th>
                            <th>Sub Title</th>
                            <th>Image</th>
                            <th>Action</th>
                        </tr>
                    </tfoot> */}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
