import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CreateContestAPI } from '../api/LudoAPI';
import { useDate } from '../Component/DateHook';
import { Modal } from 'antd';

function LudoPlayerTransaction() {

    const location = useLocation();
    const { userId, matchId, teamName, position } = location.state;

    useEffect(() => {
        ludoMatchesUserTrasnaction();
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const [contestMatchesData, setContestMatchesData] = useState([]);
    const [playerTransactionData, setPlayerTransactionData] = useState([]);

    const ludoMatchesUserTrasnaction = () => {
        setIsLoading(true);
        CreateContestAPI.ludoUserTransaction(matchId, userId).then((res) => {
            if (res.status_code) {
                setPlayerTransactionData(res.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                Notiflix.Notify.failure(res.message);
            }
        });
    };

    return (
        <div className='content-page rtl-page'>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                <h4 style={{marginTop: 4}}>{teamName ? teamName : ''} Ludo Transaction</h4>
                                <h6 style={{ backgroundColor: 'green', color: 'white', borderRadius: 8, padding: 12, textAlign: 'center' }}>{position === 1 ? 'Winner' : ''}</h6>
                            </div>
                        </div>

                        <div className='card-body'>
                            <div className='table-responsive'>
                                <span>
                                    <table className='table  table-striped table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Amount</th>
                                                <th>Joining Fees</th>
                                                <th>Created Date</th>
                                                <th>GST</th>
                                                <th>Old Royalty</th>
                                                <th>Old Bonus</th>
                                                <th>Old Unutilized</th>
                                                <th>Old Winning</th>
                                                <th>Old Total Balance</th>
                                                <th>New Royalty</th>
                                                <th>New Bonus</th>
                                                <th>New Unutilized</th>
                                                <th>New Winning</th>
                                                <th>New Total Balance</th>
                                                <th>Point Type</th>
                                                <th>Transaction Type</th>
                                                <th>Description</th>
                                                <th>Available Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {playerTransactionData &&
                                                playerTransactionData.length > 0 &&
                                                playerTransactionData.map((name, ind) => {
                                                    return (
                                                        <tr>
                                                            <td>{++ind}</td>
                                                            <td>{name.amount}</td>
                                                            <td>{name.joining_fee}</td>
                                                            <td>{name.createdAt}</td>
                                                            <td>{name.gst_amount}</td>
                                                            <td>{name.old_balance.royalty_point}</td>
                                                            <td>{name.old_balance.bonus}</td>
                                                            <td>{name.old_balance.unutilized}</td>
                                                            <td>{name.old_balance.winning}</td>
                                                            <td>{name.old_balance.total_balance}</td>
                                                            <td>{name.new_balance.royalty_point}</td>
                                                            <td>{name.new_balance.bonus}</td>
                                                            <td>{name.new_balance.unutilized}</td>
                                                            <td>{name.new_balance.winning}</td>
                                                            <td>{name.new_balance.total_balance}</td>
                                                            <td>{name.point_type}</td>
                                                            <td>{name.transaction_type}</td>
                                                            <td>{name.description}</td>
                                                            <td>{name.available_balance}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LudoPlayerTransaction;
