import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ViewAllUsers.css';
import Notiflix from 'notiflix';
import { SupportAPI } from '../api/Support';
import { Spin, Modal, Tooltip } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';


export default function Cases() {

    const ticketCreatedBy = localStorage.getItem('name');

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [takeSupportNextPage, setTakeSupportNextPage] = useState(false);

    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const [toolTipTeamName, setToolTipTeamName] = useState('');
    const [toolTipEmailId, setToolTipEmailId] = useState('');
    const [toolTipPhoneNumber, setToolTipPhoneNumber] = useState('');


    const verifyUserDetail = () => {
        if (emailId.length > 0 || phoneNumber.length > 0) {
            SupportAPI.verifyUserDetail(phoneNumber, emailId).then((data) => {
                if (data.status_code) {
                    console.log(data);
                    setToolTipTeamName(data.data[0].team_name);
                    setToolTipEmailId(data.data[0].email);
                    setToolTipPhoneNumber(data.data[0].phone);
                    setTakeSupportNextPage(true);
                    Notiflix.Notify.success('Verified Successfully');
                } else {
                    setTakeSupportNextPage(false);
                    Notiflix.Notify.failure('User Does not Exist');
                }
            });
        } else {
            Notiflix.Notify.warning('Please Fill any one field');
        }
    };

    const reset = () => {
        setEmailId('');
        setPhoneNumber('');
        setTakeSupportNextPage(false);
    };




    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>


                    {/* Case Basic Information */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='offers_couponDiv'>
                                <h1 className='coupon_h1'>User Verification</h1>
                            </div>

                            {/* {
                                error ? <div className='cases_error'>
                                    <h3>Please fill all details</h3>
                                </div> : ''
                            } */}

                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>

                                    </div>
                                    <div className='offers_div'>
                                        <div >
                                            <label className='offer_checkbox'>Phone Number</label>
                                            <input type='number' value={phoneNumber} required onChange={handleAllChange(setPhoneNumber)} className='offers_input' placeholder='Enter Phone Number' />
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Email ID</label>
                                            <input value={emailId} onChange={handleAllChange(setEmailId)} className='offers_input' placeholder='Enter Email ID' />
                                        </div>

                                        <div className='cases_button_div' >
                                            {
                                                takeSupportNextPage ? <Link
                                                    className='cases_verify_button'
                                                    to={
                                                        '/user_details'
                                                    }
                                                    state={{
                                                        verify_emailId: emailId,
                                                        verify_phoneNumber: phoneNumber,
                                                    }}
                                                >See User Details
                                                </Link> :
                                                    <button
                                                        onClick={verifyUserDetail}
                                                        className='cases_verify_button_2'>
                                                        Verify
                                                    </button>

                                            }
                                            <button
                                                onClick={reset}
                                                className='cases_verify_button_222'>
                                                Reset
                                            </button>

                                            <div className='cases_div_9'>
                                                <h3 className='cases_link'><span>Can't Verify ?</span> Look for User Id, Phone and Email in </h3>
                                                <h3 className='cases_link_h3'><Link to={'/userManager'}> User Manager</Link></h3>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* Usage and Limit */}

                    {/*  */}

                    {/* Button */}


                </div>
            </div>


        </div >
    );
}
