import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SeriesAPI } from '../api/seriesApi';
import { RotatingLines } from 'react-loader-spinner';
import { SettingsApplicationsOutlined } from '@mui/icons-material';
import swal from 'sweetalert';
import Notiflix from 'notiflix';

export default function MatchResultPage() {
    const [res, setRes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fantasy, setFantasy] = useState('cricket');
    const [FantasyList, setFantasyList] = useState([]);

    // useEffect(() => {
    //   console.log('isloadddddddding ');
    //   console.log(isLoading);
    // }, [isLoading])
    

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
        fetchUndeclareAllResultsMatches();
        GetFantasy();
    }, []);



    const fetchUndeclareAllResultsMatches = (fan) => {
        setIsLoading(true);
        setRes([]);
        if (!fan) {
            fan = 'cricket';

        }
        SeriesAPI.GetUndeclareAllResultsMatches(fan).then((data) => {
            Notiflix.Notify.info(data.message);
            setRes(data.data);
        });

        setIsLoading(false);

    };


    const fantasyChange = (e) => {
        setIsLoading(true);
        e.preventDefault();
        e.stopPropagation();
        const fantasy = e.target.value;
        if (fantasy == 'cricket') {
            setFantasy('cricket');
            fetchUndeclareAllResultsMatches('cricket');

        }

        else if (fantasy == 'Prediction') {
            setFantasy('Prediction');
            fetchUndeclareAllResultsMatches('Prediction');
        }

    };

    const launchWinner = (apiid, matchid) => {
        setIsLoading(true);
        const matchType = fantasy.toLowerCase();
        const json = {
            matchId: matchid,
            matchType
        };


        SeriesAPI.ResultDeclare(json).then((data) => {
            if(data.status_code){

                Notiflix.Notify.info(data.message);
                fetchUndeclareAllResultsMatches(fantasy);
                // setFantasyList([]);
                // GetFantasy();
            }
            else{
                Notiflix.Notify.failure(data.message);
                fetchUndeclareAllResultsMatches(fantasy);
            }
        });


        
        // setIsLoading(false);
    };

    const AbandanMatch = (myData) => {
        setIsLoading(true);
        const json = {
            matchId: myData.matchId,
            apiMatchId: myData.apiMatchId,
            fantasy: 'all'
        };

        SeriesAPI.ResultAbandoned(json).then((data) => {
            if(data.status_code){
                Notiflix.Notify.info(data.message);
                fetchUndeclareAllResultsMatches(fantasy);
                // setFantasyList([]);
                // GetFantasy();
            }
            else{
                Notiflix.Notify.failure(data.message);
                fetchUndeclareAllResultsMatches(fantasy);
            }
        });

    };

    const CancelMatch = (e, myData) => {
        e.preventDefault();
        console.log(isLoading);
        setIsLoading(true);
        setRes([]);

        // const myfantasy = fantasy.toLowerCase();
        const json = {
            matchId: myData.matchId,
            apiMatchId: myData.apiMatchId,
            fantasy: fantasy
        };
        console.log(json);


        SeriesAPI.ResultAbandoned(json).then((data) => {
            if(data.status_code){

                Notiflix.Notify.info(data.message);
                fetchUndeclareAllResultsMatches(fantasy);
                // setFantasyList([]);
                // GetFantasy();
            }
            else{
                Notiflix.Notify.failure(data.message);
                fetchUndeclareAllResultsMatches(fantasy);
            }
        });

    };

    const GetFantasy = () => {
        SeriesAPI.GetFantasy().then((data) => {
            if (data.status_code) {
                setFantasyList(data.message);
            } else {
                Notiflix.Notify.failure(data.msg);
            }
        });
    };

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label htmlFor='name'>Select Fantasy :</label>
                                            <select
                                                className='form-control mb-3'
                                                onChange={fantasyChange}
                                            >
                                                {FantasyList.map((fan) => (
                                                    <option value={fan.fantasy} defaultValue={fan.fantasy == 'cricket'}>
                                                        {fan.fantasy}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''>
                                    <div className='btn-group m-2' role='group'>
                                        <button
                                            id='btnGroupDrop1'
                                            type='button'
                                            className='btn btn-primary dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Export
                                        </button>
                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='btnGroupDrop1'
                                            style={{}}
                                        >
                                            <button className='dropdown-item' href='#'>
                                                Export All data
                                            </button>
                                            <button className='dropdown-item' href='#'>
                                                Export This Data
                                            </button>
                                            {/* <a className="dropdown-item" href="#">Delete</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12'>
                            <div className='card'>
                                <div className='card-header d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Match Result</h4>
                                        <br />
                                        <tr>
                                            <td colSpan='5'>
                                                <select
                                                    name='datatable_length'
                                                    aria-controls='datatable'
                                                    className=''
                                                >
                                                    <option value='10'>10</option>
                                                    <option value='25'>25</option>
                                                    <option value='50'>50</option>
                                                    <option value='100'>100</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </div>
                                </div>

                                {isLoading && (
                                    <div className='col-sm-12 col-lg-12'>
                                        <div className='px-2 h-60 d-flex align-items-center justify-content-center bg-gradient'>
                                            <RotatingLines
                                                marginTop='40'
                                                strokeColor='#c1030d'
                                                strokeWidth='5'
                                                animationDuration='0.75'
                                                width='50'
                                                visible={true}
                                            />
                                        </div>
                                    </div>
                                )}

                                {!isLoading && (
                                    <div className='card-body'>
                                        <div className='table-editable' id='table'>
                                            <div className='table-responsive'>
                                                <table
                                                    id=''
                                                    className='table table-striped table-bordered'
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Match Name</th>
                                                            {/* <th>No. of Matches</th> */}
                                                            {/* <th>Winner Declare Require</th>                                    */}
                                                            <th>Start Date</th>
                                                            <th>
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr>
                                <td colspan="5">
                                <select name="datatable_length" aria-controls="datatable" class="" onChange={fetchData}><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select>
                                </td>
                               </tr> */}

                                                        {res &&
                                                            res.length > 0 &&
                                                            res.map((team, i) => {
                                                                return (
                                                                    <tr key={team._id}>
                                                                        <td>{++i}</td>
                                                                        <td>
                                                                            {team.matchName}

                                                                        </td>
                                                                        {/* <td>
                                                                            {team.total_matches}
                                                                        </td> */}
                                                                        {/* <td>{team.winnerDeclare}</td> */}
                                                                        <td>
                                                                            {team.StartDateIST}
                                                                            {/* <strong>To</strong>{' '}
                                                                            {team.dateend} */}
                                                                        </td>
                                                                        <td>

                                                                            <button
                                                                                type='button'
                                                                                className='mt-2 btn btn-danger btn-sm mr-2'
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            'Are you sure to Declare Winner?'
                                                                                        )
                                                                                            ? launchWinner(
                                                                                                team.apiMatchId,
                                                                                                team.matchId
                                                                                            )
                                                                                            : e.preventDefault()
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i className='ri-eye-2-line'></i>{' '}
                                                                                Declare Winner
                                                                            </button>
                                                                            {fantasy=='cricket' && team.PredictionResult==false &&<button type="button" className="mt-2 btn btn-danger btn-sm mr-2" onClick={(e) => { if (window.confirm('Are you sure to Abandon the Match?') ? AbandanMatch(team) : e.preventDefault()); }}>Abandon Match</button>}
                                                                            {fantasy=='Prediction' && team.isResult==false &&<button type="button" className="mt-2 btn btn-danger btn-sm mr-2" onClick={(e) => { if (window.confirm('Are you sure to Abandon the Match?') ? AbandanMatch(team) : e.preventDefault()); }}>Abandon Match</button>}
                                                                            <button type="button" className="mt-2 btn btn-danger btn-sm mr-2" onClick={(e) => { if (window.confirm('Are you sure to Cancel the Match?') ? CancelMatch(e, team) : e.preventDefault()); }}>Cancel</button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                                <nav className='d-flex justify-content-center'></nav>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}