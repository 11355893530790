import Notiflix from 'notiflix';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { SeriesAPI } from '../api/seriesApi';
import { CSVLink } from 'react-csv';
import { AccountManagerAPI } from '../api/AccountManagerAPI';

function TotalUsersRegistered() {
    const [dataArray, setArray] = useState([]);
    const [dateChange, setDateChange] = useState('');
    


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'assets/js/app.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const getData = () => {
        AccountManagerAPI.GetRegisteredUserCountByDate(dateChange).then((data) => {
            setArray(data);
            console.log(data.length);
        });
    };

    const Search = () =>{
        getData();

    };
    return (
        <div className='content-page rtl-page'>
            <div className='container-fluid'>
                <div className='col-lg-12 mb-3'>
                    <div className='d-flex align-items-center justify-content-between welcome-content'>
                        <div className='navbar-breadcrumb'>
                            <h4 className='mb-0'>&nbsp;</h4>
                        </div>
                        <div className=''>
                            <div className='d-flex align-items-center justify-content-between welcome-content'>
                                <div className='navbar-breadcrumb'>
                                    <h4 className='mb-0'>&nbsp;</h4>
                                </div>
                                <div className=''></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header d-flex justify-content-between'>
                            <div className='header-title'>
                                <h4 className='card-title'>Total Users Registered</h4>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='form-row p-2'>
                                <div>
                                    <div className='col'>
                                        <label htmlFor='name'>Date : </label>
                                        <input
                                            type='date'
                                            value={dateChange}
                                            onChange={(e)=>setDateChange(e.target.value)}
                                            className='form-control'
                                            placeholder='Search By Name'
                                        />
                                    </div>
                                </div>

                                <div className='col' style={{ 'padding-top': '0.5pc' }}>
                                    <br />
                                    <label htmlFor='name'></label>
                                    <button
                                        className='btn btn-primary'
                                        type='button'
                                        onClick={() => Search()}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    {/* <button type="reset" className="btn bg-danger" onClick={Reset}><i className="ri-creative-commons-sa-line"></i>Reset</button> */}
                                    {/* <input
                                        type='reset'
                                        onClick={() => Reset()}
                                        className='btn btn-danger'
                                    /> */}
                                </div>
                            </div>
                            <h1 className='bg-primary' style={{fontSize:'10rem'}}>

                                {dataArray.count ? <div className="d-flex justify-content-center">{dataArray.count}</div> :''}
                            </h1>
                       
                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalUsersRegistered;
