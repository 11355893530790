import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ViewAllUsers.css';
import Notiflix from 'notiflix';
import { SupportAPI } from '../api/Support';
import { Spin, Modal, Result } from 'antd';
import { useLocation } from 'react-router-dom';


export default function CreateCases() {

    const location = useLocation();
    const { finalPhoneNumber, finalEmailId } = location.state;
    const ticketCreatedBy = localStorage.getItem('name');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [subject, setSubject] = useState('');
    const [priority, setPriority] = useState('');
    const [caseType, setCaseType] = useState('');
    const [otherCaseType, setOtherCaseType] = useState('');
    const [ticketStatus, setTicketStatus] = useState('');
    const [teamName, setTeamName] = useState('');

    const [createdBy, setCreatedBy] = useState(ticketCreatedBy);

    const [error, setError] = useState(false);
    const [verifyError, setVerifyError] = useState(false);
    const [tinyLoader, setTinyLoader] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('');


    const handleAllChange = (setterFunction) => (e) => {
        const { value } = e.target;
        setterFunction(value);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        const newImages = [...selectedFiles];
        for (let i = 0; i < files.length; i++) {
            newImages.push(files[i]);
        }
        setSelectedFiles(newImages);
        console.log(selectedFiles);
    };

    const handleCreateTicketData = () => {

        if (
            subject === '' ||
            userId === '' ||
            priority === '' ||
            caseType === '' ||
            createdBy === '' ||
            ticketStatus === ''
        ) {
            setError(true);
        } else {
            setError(false);

            const dataForm = new FormData();
            dataForm.append('body', subject);
            dataForm.append('email', emailId);
            dataForm.append('phone', phoneNumber);
            dataForm.append('user_id', userId);
            dataForm.append('priority', priority);
            dataForm.append('case_type', caseType);
            dataForm.append('status', ticketStatus);
            dataForm.append('created_by', createdBy);
            // Append selected files
            for (let i = 0; i < selectedFiles.length; i++) {
                dataForm.append('files', selectedFiles[i]);
            }

            // const finalSendData = {
            //     'Subject': subject,
            //     'email': emailId,
            //     'phone': phoneNumber,
            //     'user_id': userId,
            //     'priority': priority,
            //     'case_type': caseType,
            //     'body': otherCaseType,
            //     'status': ticketStatus,
            //     'created_by': createdBy,
            //     'files': selectedFiles,
            // };

            SupportAPI.CreateTicket(dataForm).then((data) => {
                if (data.status_code === true) {
                    setIsModalOpen(true);
                    Notiflix.Notify.success('Ticket Generated Successfully');
                } else {
                    Notiflix.Notify.failure(data.message);
                }
            });
        }
    };

    const verifyUserDetail = () => {
        if (
            finalPhoneNumber === '' &&
            finalEmailId === ''
        ) {
            Notiflix.Notify.warning('Cannot be Empty');
        } else {
            setTinyLoader(true);
            SupportAPI.verifyUserDetail(finalPhoneNumber, finalEmailId).then((data) => {
                if (data.status_code) {
                    setVerifyError(false);
                    // console.log(data.data);
                    setTinyLoader(false);
                    setUserId(data.data[0].user_id);
                    setTeamName(data.data[0].team_name);
                    setEmailId(data.data[0].email);
                    setPhoneNumber(data.data[0].phone);
                } else {
                    Notiflix.Notify.failure('Failed to Verify');
                    setVerifyError(true);
                    setTinyLoader(false);
                    setUserId('');
                    setTeamName('');
                }
            });
        }
    };

    const handleReset = () => {
        setCaseType('');
        setEmailId('');
        setError(false);
        setTinyLoader(false);
        setVerifyError(false);
        setOtherCaseType('');
        setPhoneNumber('');
        setPriority('');
        setSubject('');
        setTeamName('');
        setTicketStatus('');
        setUserId('');
        setSelectedFiles('');
    };

    const handleOk = () => {
        setIsModalOpen(false);
        handleReset();
        window.location.href = '/allcases';
    };

    const checkImageinArray = () => {
        if (selectedFiles.length > 0) {
            selectedFiles.forEach((file) => {
                const { name, size } = file;
            });
        }
    };

    useEffect(() => {
        checkImageinArray();
    }, [selectedFiles]);

    useEffect(() => {
        verifyUserDetail();
    }, [finalPhoneNumber, finalEmailId]);

    return (
        <div className='wrapper'>
            <div className='content-page rtl-page'>
                <div className='container-fluid'>


                    {/* Case Basic Information */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='offers_couponDiv'>
                                <h1 className='coupon_h1'>Create Cases</h1>

                            </div>

                            {/* {
                                error ? <div className='cases_error'>
                                    <h3>Please fill all details</h3>
                                </div> : ''
                            } */}

                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>

                                    </div>
                                    <div className='offers_div'>

                                        <div >
                                            <label className='offer_checkbox'>Phone Number</label>
                                            <h1 className='offers_input_1'>Phone:- <span>{phoneNumber}</span></h1>
                                        </div>

                                        <div >
                                            <label className='offer_checkbox'>Email Id</label>
                                            <h1 className='offers_input_1'>Email:- <span>{emailId}</span></h1>
                                        </div>

                                        <div >
                                            <label className='offer_checkbox'>User ID</label>
                                            <h1 className='offers_input_1'>ID:- <span>{userId}</span></h1>
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Team Name</label>
                                            <h1 className='offers_input_1'>Team-Name:- <span>{teamName}</span></h1>
                                        </div>

                                        <div>
                                            {tinyLoader ? <Spin className='cases_loader' size='large' /> : ''}
                                        </div>



                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* Usage and Limit */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                    </div>
                                    <div className='offers_div'>
                                        <div>
                                            <label className='offer_checkbox'>Subject</label>
                                            <textarea value={subject} onChange={handleAllChange(setSubject)} className='cases_DESCRIPTION' placeholder='Add Subject' />
                                            <div className='cases_5000'>
                                                <div>
                                                    <label className='offer_checkbox_500' htmlFor="upload">Select Images: </label>
                                                    <input
                                                        className='cases_DESCRIPTION_500'
                                                        id="file"
                                                        onChange={handleFileChange}
                                                        type="file"
                                                        accept="image/*"
                                                        multiple
                                                        name="files[]"
                                                    />
                                                </div>
                                                <div className='ticket_image_div'>

                                                    {
                                                        selectedFiles.length <= 3 ?
                                                            <div className='create_case_div'>
                                                                <h3 >{selectedFiles.length}</h3>
                                                                <h3 > {selectedFiles.length > 1 ? 'Images' : 'Image'} Selected</h3>
                                                            </div> : <div className='create_case_div_1'><h3>No More than 3 images</h3></div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {/* <textarea value={subject} placeholder='Add Subject' /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Priority and all */}

                    <div className='row'>
                        <div className='col-sm-12 col-lg-16'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='form-row'>
                                    </div>
                                    <div className='offers_div'>

                                        <div>
                                            <label className='offer_checkbox'>Set Priority</label>
                                            <select value={priority} onChange={handleAllChange(setPriority)} className='offers_select'>
                                                <option className='cases_select_option' value=''>Select</option>
                                                <option className='cases_select_option' value='Urgent' >Urgent</option>
                                                <option className='cases_select_option' value='High' >High</option>
                                                <option className='cases_select_option' value='Medium' >Medium</option>
                                                <option className='cases_select_option' value='Low' >Low</option>
                                            </select>
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Ticket Status</label>
                                            <select value={ticketStatus} onChange={handleAllChange(setTicketStatus)} className='offers_select'>
                                                <option className='cases_select_option' value='' >Select</option>
                                                <option className='cases_select_option' value='Open' >Open</option>
                                                <option className='cases_select_option' value='In Progress' >In Progress</option>
                                                <option className='cases_select_option' value='Re Open' >Re Open</option>
                                                <option className='cases_select_option' value='Closed' >Closed</option>
                                            </select>
                                        </div>

                                        <div>
                                            <label className='offer_checkbox'>Case Type</label>
                                            <select value={caseType} onChange={handleAllChange(setCaseType)} className='offers_select'>
                                                <option className='cases_select_option' value=''>Select</option>
                                                <option className='cases_select_option' value='Deposit'>Deposit</option>
                                                <option className='cases_select_option' value='Withdrawl'>Withdrawl</option>
                                                <option className='cases_select_option' value='Other' >Others</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Ticket Status */}

                    {
                        caseType == 3 ?

                            <div className='row'>
                                <div className='col-sm-12 col-lg-16'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-row'>
                                            </div>
                                            <div className='offers_div'>

                                                <div>
                                                    <label className='offer_checkbox'>Other Case</label>
                                                    <textarea value={otherCaseType} onChange={handleAllChange(setOtherCaseType)} className='cases_DESCRIPTION' placeholder='Write the case here' />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            : ''
                    }


                    {/*  */}

                    {/* Button */}

                    <div className='cases_button_div' >

                        <button onClick={handleCreateTicketData} className='offers_button'>Generate Case</button>
                        {/* <button onClick={handleReset} className='cases_reset'>Reset Ticket</button> */}

                    </div>
                </div>
            </div>

            <Modal open={isModalOpen} footer={null} closable={false} >
                <div className='ticket_mod'>
                    <Result
                        status="success"
                        title="Ticket Created Successfully"
                    />
                    <h4>{subject}</h4>
                    <h4>By:- {ticketCreatedBy}</h4>
                    <Link to='/allcases' className='create-cases-to-button'>All Cases</Link>
                </div>
            </Modal>
        </div >
    );
}
